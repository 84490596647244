//LIBRARY
import styled, { css } from "styled-components";
//MISC
import {
  COLORS,
  BORDER_RADIUS,
  SIZE,
  MARGIN,
  DEVICE_TYPE,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const CardContainer = styled.div`
  position: relative;
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  overflow: hidden;
  max-width: 960px;
  width: 100%;
  transition: 0.3s ease-in;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: ${BORDER_RADIUS.radius32};

    pointer-events: none;
  }

  @media (min-width: ${DEVICE_TYPE.tablet}) {
    &:hover::before {
      background-color: ${COLORS.Paua};
      opacity: 0.1;
    }
  }
`;
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SIZE.max24};
`;

export const CardContent = styled.div``;

export const DropdownButton = styled.button<{ biggerCursor?: boolean }>`
  background: none;
  border: none;
  background-color: transparent;
  outline: none;
`;

export const DropdownContent = styled.div`
  display: none;
  padding: 0 ${MARGIN.max24} ${MARGIN.max16} ${MARGIN.max24};
  background-color: transparent;
  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
`;

export const Img = styled.img`
  margin-left: ${MARGIN.max6};
  width: 16px;
  height: 10px;
  transition: transform 0.3s ease;
  ${({ isRotated }) =>
    isRotated &&
    css`
      transform: rotate(180deg);
    `}
`;
