import styled, { css } from "styled-components";

//MISC
import {
  COLORS,
  SIZE,
  BORDER_RADIUS,
  FONT_WEIGHT,
  MARGIN,
} from "constants/variables";
import { CardType } from "./GuideCardAtomModel";
import cursorIcon from "assets/img/Cursor.png";

interface ICardWrapper {
  cardType?: CardType;
  width?: number;
  height?: number;
  biggerCursor?: boolean;
}

export const CardWrapper = styled.a<ICardWrapper>(
  ({ cardType, width, height }) => css`
    cursor: ${({ biggerCursor }) =>
      biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: ${width};
    height: ${height};
    background-color: ${COLORS.WhiteSmoke};
    text-decoration: none;
    ${cardType === CardType.DashboardStyle &&
    css`
      opacity: 0.75;
      border-radius: ${BORDER_RADIUS.radius24};
      padding: ${SIZE.max32};
      transition: 0.3s ease-out;
      &:hover {
        transition: 0.3s ease-in;
        opacity: 1;
        transition: 0.7s;
        z-index: 10;
      }
    `}

    ${cardType === CardType.GuideStyle &&
    css`
      height: 100%;
      border-radius: ${BORDER_RADIUS.radius32};
      border: 2px solid ${COLORS.AbsoluteWhite};
      padding: ${SIZE.max40};
      text-align: center;
      transition: 0.3s ease-out;
      &:hover {
        outline: 4px solid ${COLORS.Cinnabar};
        outline-offset: -2px;
      }

      @media (max-width: 768px) {
        padding: ${SIZE.max24};
      }
    `}
    ${cardType === CardType.CarouselStyle &&
    css`
      border-radius: ${BORDER_RADIUS.radius32};
      border: 2px solid ${COLORS.AbsoluteWhite};
      padding: ${SIZE.max40};
      box-shadow: rgba(17, 12, 46, 0.15) 0px ${SIZE.max40} ${SIZE.max104} 0px;
    `}

    ${cardType === CardType.SingleGuideStyle &&
    css`
      border-radius: ${BORDER_RADIUS.radius16};
      background-color: ${COLORS.Solitude};
      padding: ${MARGIN.max20};
      border: none;

      @media (max-width: 768px) {
        padding: ${SIZE.max24};
        text-align: center;
      }
    `}
  `
);

export const CenterContainer = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleWrapper = styled.div<{ cardType }>`
  text-align: center;
  ${({ cardType }) =>
    cardType === CardType.DashboardStyle &&
    css`
      width: 100%;
    `}
  ${({ cardType }) =>
    cardType === CardType.GuideStyle &&
    css`
      background-color: ${COLORS.Solitude};
      color: ${COLORS.BlackRussian};
      border-radius: ${BORDER_RADIUS.radius24};
      padding: ${SIZE.max10} ${SIZE.max16};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: auto;

      @media (max-width: 768px) {
        background-color: transparent;
        font-size: ${SIZE.max16};
        font-weight: ${FONT_WEIGHT.bold};
      }
    `}
    ${({ cardType }) =>
    cardType === CardType.CarouselStyle &&
    css`
      background-color: ${COLORS.Solitude};
      border-radius: ${BORDER_RADIUS.radius24};
      padding: ${SIZE.max10} ${SIZE.max16};
    `};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    align-self: center;
  }
`;
