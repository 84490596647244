import ReactGA from 'react-ga4';
import { deleteCookie } from './cookieHelper';

const GA_TRACKING_ID = 'G-2FGL3Y9F1R'; // Use the correct Google Analytics ID
declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

export const initializeGoogleAnalytics = () => {
    if (!window.dataLayer) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
        script.async = true;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };

        window.gtag('js', new Date());
        window.gtag('config', GA_TRACKING_ID, {
            anonymize_ip: true,
            cookie_expires: 0, // Adjust based on your cookie expiration requirements
        });
    }

    ReactGA.initialize([{ trackingId: GA_TRACKING_ID }]);
    ReactGA.send('pageview');
    console.log('Google Analytics initialized');
};

export const disableGoogleAnalytics = () => {
    window[`ga-disable-${GA_TRACKING_ID}`] = true;
    // Optionally, remove the existing Google Analytics cookies
    deleteCookie('_ga');
    deleteCookie(`_ga_2FGL3Y9F1R`);
    console.log('Google Analytics disabled');
};
