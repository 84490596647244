import React, { useEffect, useState } from "react";

// ASSETS
import { ReactComponent as GreyPhoneIcon } from "assets/svg/GreyPhoneIcon.svg";
import { ReactComponent as ScheduleIcon } from "assets/svg/ScheduleIcon.svg";
import { ReactComponent as LocationPinIcon } from "assets/svg/LocationPinIcon.svg";
import { ReactComponent as WebsiteIcon } from "assets/svg/WebsiteIcon.svg";
import PlaceholderImage from "assets/svg/PlaceholderImage.svg";

// STYLES
import {
  PopupModal,
  ModalBody,
  DefibContainer,
  DefibBody,
  DefibPhoto,
  DetailsWrapper,
  Schedule,
  IconWrapper,
  Informations,
  ModalActions,
  PinInfoContainer,
  InformationsFormat,
  DefibPhotoContainer,
  RedLine,
  AbsolutContainer,
  InfoTittleContainer,
  DefibPhotoURL,
  InformationsContainer,
  DetailsBody,
  LinkWrapper,
} from "components/Atoms/ResponsiveModal/ResponsiveModalStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";

// MISC
import { ResponsiveModalProps, ModalType } from "./ResponsiveModalModel";
import { ButtonType } from "../ButtonAtom/ButtonAtomModel";

// REDUX
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";
import { useSelector } from "react-redux";

// COMPONENTS
import ButtonAtom from "../ButtonAtom/ButtonAtom";
import { TextType } from "../CustomText/CustomTextModel";
import CustomText from "../CustomText/CustomText";
import PillAtom from "../PillAtom/PillAtom";
import { baseUrl } from "services/base";

const ResponsiveModal = (props: ResponsiveModalProps) => {
  // PROPERTIES
  const {
    type = ModalType.Info,
    onClose,
    isOpen = false,
    selectedIpCategory,
    title,
    canCallEmergency,
    stats,
    photo,
    phone,
    adress,
    website,
    children,
    long,
    lat,
  } = props;

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS
  const [isOpened, setIsOpened] = useState(false);

  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    setIsOpened(isOpen);
  }, [isOpen]);
  const handleClose = () => {
    onClose();
  };

  // EVENT HANDLERS
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("popup-container")) {
      handleClose();
    }
  };
  // Function to handle closing the popup

  return (
    <PopupModal
      className="popup-container"
      onClick={handleOutsideClick}
      open={isOpened}
      selectedIpCategory={selectedIpCategory}
    >
      <ModalBody open={isOpen}>
        <AbsolutContainer type={type}>
          {type === ModalType.Info && (
            <CustomText text={"PUNCT DE INTERES"} type={TextType.H4fix} />
          )}
          <ButtonAtom onClick={handleClose} type={ButtonType.X} />
        </AbsolutContainer>
        {type === ModalType.Defib ? (
          <DefibContainer>
            <DefibPhotoContainer>
              {photo ? (
                <DefibPhotoURL src={`${baseUrl}${photo}`} />
              ) : (
                <DefibPhoto src={PlaceholderImage} />
              )}
            </DefibPhotoContainer>
            <DefibBody>
              <DetailsBody>
                <CustomText text={title} type={TextType.H3fix} />
                <RedLine />
                <DetailsWrapper>
                  <PillAtom
                    text={
                      canCallEmergency
                        ? t("components.responsiveModal.call")
                        : t("components.responsiveModal.noCall")
                    }
                    status={canCallEmergency}
                  />
                  <PillAtom
                    text={stats ? "Deschis" : "Închis"}
                    status={stats}
                  />
                </DetailsWrapper>
                <InformationsContainer>
                  <Schedule>
                    <IconWrapper>
                      <ScheduleIcon />
                    </IconWrapper>
                    <InformationsFormat>
                      {children ? (
                        children
                      ) : (
                        <CustomText
                          text={t("components.responsiveModal.noSchedule")}
                          type={TextType.P1fix}
                        />
                      )}
                    </InformationsFormat>
                  </Schedule>
                  <Schedule>
                    <IconWrapper>
                      <GreyPhoneIcon />
                    </IconWrapper>
                    <Informations>
                      <CustomText
                        text={phone || t("components.responsiveModal.noNumber")}
                        type={TextType.P1fix}
                      />
                    </Informations>
                  </Schedule>
                  <Schedule>
                    <IconWrapper>
                      <LocationPinIcon />
                    </IconWrapper>
                    <Informations>
                      <CustomText
                        text={
                          adress || t("components.responsiveModal.noAdress")
                        }
                        type={TextType.P1fix}
                      />
                    </Informations>
                  </Schedule>
                </InformationsContainer>
              </DetailsBody>
              <ModalActions>
                <ButtonAtom
                  text={t(`map.modal.navigate`)}
                  type={ButtonType.RedButton}
                  onClick={() => {
                    const url = `https://www.google.com/maps/dir/Current+Location/${lat},${long}`;
                    window.open(url, "_blank");
                  }}
                />
              </ModalActions>
            </DefibBody>
          </DefibContainer>
        ) : (
          <PinInfoContainer>
            <DetailsBody>
              <InfoTittleContainer>
                <CustomText text={title} type={TextType.H3fix} />
              </InfoTittleContainer>
              <RedLine />
              <InformationsContainer>
                <Schedule>
                  <IconWrapper>
                    <GreyPhoneIcon />
                  </IconWrapper>
                  <Informations>
                    <CustomText
                      text={phone || t("components.responsiveModal.noNumber")}
                      type={TextType.P1fix}
                    />
                  </Informations>
                </Schedule>
                <Schedule>
                  <IconWrapper>
                    <WebsiteIcon />
                  </IconWrapper>
                  <Informations>
                    <LinkWrapper
                      href={website}
                      target="_blank"
                      biggerCursor={biggerCursor}
                    >
                      <CustomText
                        text={
                          website
                            ? t("atoms.partnerCard.website")
                            : t("components.responsiveModal.noWebsite")
                        }
                        type={TextType.P1fix}
                      />
                    </LinkWrapper>
                  </Informations>
                </Schedule>
                <Schedule>
                  <IconWrapper>
                    <LocationPinIcon />
                  </IconWrapper>
                  <Informations>
                    <CustomText
                      text={adress || t("components.responsiveModal.noAdress")}
                      type={TextType.P1fix}
                    />
                  </Informations>
                </Schedule>
              </InformationsContainer>
            </DetailsBody>
            <ModalActions>
              <ButtonAtom
                text={t(`map.modal.navigate`)}
                type={ButtonType.RedButton}
                onClick={() => {
                  const url = `https://www.google.com/maps/dir/Current+Location/${lat},${long}`;
                  window.open(url, "_blank");
                }}
              />
            </ModalActions>
          </PinInfoContainer>
        )}
      </ModalBody>
    </PopupModal>
  );
};
export default ResponsiveModal;
