import React, { useEffect, useState } from 'react';

// MISC
import { ButtonType } from 'components/Atoms/ButtonAtom/ButtonAtomModel';
import { TextType } from 'components/Atoms/CustomText/CustomTextModel';
import { CookieItem, CookiesPreferencesModalProps } from 'view/CookieModule/CookieModuleModel';

//ASSETS
import XSign from "assets/svg/XSign.svg";

// STYLES
import { ButtonContainer, CloseButton, CloseButtonContainer, CloseButtonWrapper, Description, Icon, IconSimple, ModalBackground, ModalBody, ModalContainer, ModalContent, ModalHeader } from 'view/CookieModule/CookieModuleStyled';

// COMPONENTS
import ButtonAtom from 'components/Atoms/ButtonAtom/ButtonAtom';
import CustomText from 'components/Atoms/CustomText/CustomText';
import CustomCookiesDropdown from '../CustomCookiesDropdown/CustomCookiesDropdown';
import { getCookie, isTruthy } from 'view/CookieModule/helpers/cookieHelper';
import { translations } from 'view/CookieModule/config/cookieModuleConfig';
import { COLORS } from 'constants/variables';
import { useSelector } from 'react-redux';
import { accessibilityBiggerCursorSelector } from 'slices/UISlice/uiSliceSelectors';

// TRANSLATIONS

const CookiesPreferencesModal: React.FC<CookiesPreferencesModalProps> = ({
  showModal,
  setShowModal,
  necessaryCookies,
  analyticsCookies,
  setNecessaryCookies,
  setAnalyticsCookies,
  handleSaveSettings,
  language // Add language prop
}) => {

  const [isLoading, setIsLoading] = useState(true);
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  useEffect(() => {
    if (biggerCursor) {
      const cursorIcon = require("assets/img/Cursor.png");
      const cursor = document.querySelector("html");
      cursor.style.cursor = `url(${cursorIcon}), auto`;
    } else {
      const cursor = document.querySelector("html");
      cursor.style.cursor = "auto";
    }
  }, [biggerCursor]);

  useEffect(() => {
    if (showModal) {
      const updatedNecessaryCookies = necessaryCookies.map(cookie => ({
        ...cookie,
        checked: true,
      }));
      const updatedAnalyticsCookies = analyticsCookies.map(cookie => ({
        ...cookie,
        checked: (isTruthy(getCookie(cookie.title)) && isTruthy(`${getCookie(cookie.title)}_2FGL3Y9F1R`)),
      }));

      setNecessaryCookies(updatedNecessaryCookies);
      setAnalyticsCookies(updatedAnalyticsCookies);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [showModal]); // Only trigger when showModal changes

  const handleNecessaryCookiesChange = (newItems: CookieItem[]) => {
    // Prevent changes to necessary cookies
  };

  const handleAnalyticsCookiesChange = (newItems: CookieItem[]) => {
    setAnalyticsCookies(newItems);
  };

  const handleSave = () => {
    handleSaveSettings();
    setShowModal(false);
  };

  // Get translations based on language
  const t = translations[language];

  return (
    (showModal && !isLoading) && (
      <ModalBackground>
        {/* <CloseButtonWrapper>
          <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
        </CloseButtonWrapper> */}
      
        <ModalContainer>
      
          <ModalContent>
          <CloseButtonContainer onClick={() => setShowModal(false)} biggerCursor={biggerCursor}>
          <IconSimple src={XSign} alt="Close Button" />
        </CloseButtonContainer>
            <ModalHeader>
              <CustomText
                type={TextType.H2}
                text={t.settingsTitle}
                color={COLORS.BlackRussian}
              />
            </ModalHeader>
            <ModalBody>
              <Description>
                <CustomText
                  type={TextType.P2}
                  text={t.settingsDescription}
                  color={COLORS.BlackRussian}
                />
              </Description>

              <CustomCookiesDropdown
                title={t.necessaryTitle}
                description={t.necessaryDescription}
                items={necessaryCookies}
                onItemsChange={handleNecessaryCookiesChange}
              />
              <CustomCookiesDropdown
                title={t.analyticsTitle}
                description={t.analyticsDescription}
                items={analyticsCookies}
                onItemsChange={handleAnalyticsCookiesChange}
              />
            </ModalBody>
            <ButtonContainer>
              <ButtonAtom
                type={ButtonType.RedButton}
                text={t.saveButtonText}
                onClick={handleSave}
              />
            </ButtonContainer>
          </ModalContent>
        </ModalContainer>
      </ModalBackground>
    )
  );
};

export default CookiesPreferencesModal;
