import { AxiosInstance } from 'axios';
import axiosBase from './base';
import { store } from 'store/store'; 

class GetReadyService {
    base: AxiosInstance;

    constructor() {
        this.base = axiosBase.base;
    }

    getSteps(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/preparedness-steps?sort=step:asc&pagination[pageSize]=3&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getGetReady(getReadyId: string): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/preparedness-steps/${getReadyId}/?populate[preparedness_chapters][populate]=*&populate[cover][populate]=cover&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getPrepareMember(getReadyId: string): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/prepare-members/${getReadyId}/?populate[prepare_sections][populate]=*&populate[prepare_chapters][populate]=*&populate[cover][populate]=cover&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getPrepareMembers(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/prepare-members/?populate[prepare_sections][populate]=*&populate[prepare_chapters][populate]=*&populate[cover][populate]=cover&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }
}

const getReadyService = new GetReadyService();
export default getReadyService;
