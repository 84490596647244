// LIBRARIES
import styled from "styled-components";

//MISC;
import cursorIcon from "assets/img/Cursor.png";
import { BORDER_RADIUS, COLORS, DEVICE_SIZE, MARGIN } from "./config/variables";
import { DEVICE_TYPE } from "constants/variables";

export const CookiesPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${MARGIN.max100};
  justify-content: center;
  align-items: center;
  padding: 64px 228px 140px 228px;
  gap: ${MARGIN.fix24};
  @media (max-width: ${DEVICE_SIZE.TABLET_MAX}) {
    padding: 64px 24px 140px 24px;
  }   
`;

export const TitleContainer = styled.div`
  width: 100%;
  max-width: 1464px;
  display: flex;
  justify-content: flex-start;
  `;

export const InfoContainer = styled.div`
  width: 100%;
  max-width: 1464px;
  /* height: 100%; */
  border-radius: ${BORDER_RADIUS.radius32};
  background-color: ${COLORS.Cultured};
  padding: ${MARGIN.max40};
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.fix24};
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    gap: ${MARGIN.fix16};
    padding: ${MARGIN.max40};
  }  
`;

//CookieConsent STYLE
export const ModalWrapper = styled.div`
  position: fixed;
  bottom: 40px;
  right: 80px;
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius24};
  padding: ${MARGIN.max40};
  gap: ${MARGIN.fix24};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
  width: 406px;
  border: 8px solid ${COLORS.Paua}4d;
  @media (max-width: ${DEVICE_TYPE.mobile}) {
    bottom: 0px;
    right: 0px;
    width: 100%;
    padding: ${MARGIN.fix24};
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.fix16};
`;

//CookiePreferencesModal STYLE
export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

export const ButtonContainer = styled.div`
  width: 181px;
  height: 42px;
  position: absolute;
  bottom: 40px;
  right: 40px;
  @media (max-width: ${DEVICE_TYPE.mobile}) {
    /* position: relative; */
    position: unset;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CloseButtonWrapper = styled.button`
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  border: 0px solid transparent;
  margin-bottom: ${MARGIN.fix24};
  cursor: pointer;
  @media (max-width: ${DEVICE_TYPE.mobile}) {
    position: absolute;
    z-index: 1000000;
    top: 16px;
    right: 16px;
    /* background-color: red; */
    /* border: none;
    border-radius: 0px; */
  }
`;

export const CloseButton = styled.div`
  border-radius: ${BORDER_RADIUS.radius16};
  background-color: ${COLORS.RaisinBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  font-size: 64px;
  color: ${COLORS.MetalicSilver};
`;

export const ModalContainer = styled.div`
  background-color: #d6d6db;
  position: relative;
  /* padding: ${MARGIN.max40}; */
  border-radius: ${BORDER_RADIUS.radius32};
  width: 100%;
  height: 100%;
  max-height: 656px;
  max-width: 1250px;
  position: relative;
  padding: 8px;
  @media (max-width: ${DEVICE_TYPE.mobile}) {
    max-width: 100%;
    max-height: 100%;
    padding: 8px;
    /* background-color: red; */
    /* border: none;
    border-radius: 0px; */
  }
`;

export const ModalContent = styled.div`
  background-color: ${COLORS.WhiteSmoke};
  padding: ${MARGIN.max40};
  border-radius: ${BORDER_RADIUS.radius24};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: ${DEVICE_TYPE.mobile}) {
    overflow-y: auto;
    scrollbar-width: none; // For Firefox
    -ms-overflow-style: none; // For Internet Explorer and Edge

    &::-webkit-scrollbar {
      display: none; // For Chrome, Safari, and Opera
    }

  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${MARGIN.fix16};
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.fix16};
`;

export const Description = styled.p`
  margin-bottom: ${MARGIN.max8};
`;

//CustomCheckbox STYLE

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: #E62E2E;
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean, isDisabled: boolean }>`
display: inline-block;
width: 25px;
height: 25px;
background: ${(props) => (props.checked ? '#1D1D26' : 'transparent')};
border-radius: 10px;
border: 3px solid ${COLORS.WhiteSmoke};
transition: all 150ms;
display: flex;
align-items: center;
justify-content: center;
position: relative;
cursor: pointer;

${Icon} {
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
}

${(props) => props.isDisabled && `
  cursor: not-allowed;

  // &:hover ${DisabledIcon} {
  //   visibility: visible;
  // }
`}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DisabledIcon = styled.svg`
  fill: none;
  stroke: #FF0000;
  stroke-width: 2px;
  visibility: hidden;
`;
export const DropdownMenuItemCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownMenuItemCheckboxIcon = styled.img<{
  biggerCursor?: boolean;
  isDisabled: boolean;
}>`
  height: 24px;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
    ${(props) => props.isDisabled && `
  cursor: not-allowed;
`}

`;

// CustomCookiesDropdown STYLE

export const DropdownContainer = styled.div`
  background-color: transparent;
  border-radius: ${BORDER_RADIUS.radius24};
  padding: ${MARGIN.fix24};
  width: 100%;
  cursor: pointer;
  color: ${COLORS.WhiteSmoke};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10; 
  border-bottom-right-radius: ${({ isOpen }) => (isOpen && '0px')};
  border-bottom-left-radius: ${({ isOpen }) => (isOpen && '0px')};
`;

export const DropdownContentContainer = styled.div`
  /* background-color: #2b2b2b; */
  border-radius: ${BORDER_RADIUS.radius24};
  padding: 0px ${MARGIN.fix24} ${MARGIN.fix24} ${MARGIN.fix24};
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  /* position: absolute; */
  width: calc(100% - 80px);
  z-index: 20;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background-color: transparent;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.fix16};
  /* border-top: 2px solid #F5F5F552; */
  padding-top: ${MARGIN.fix24};
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DropdownIcon = styled.img`
  width: 16px;
  height: 10px;
  transition: transform 0.3s ease;
  ${({ isOpen }) => isOpen && `
    transform: rotate(180deg);
  `}
`;

export const SettingLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #E62E2E;
  gap: ${MARGIN.fix24};
`;

export const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.fix16};
`;

export const DropdownListElement = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CloseButtonContainer = styled.button<{ biggerCursor?: boolean }>`
  background: none;
  border: none;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  position: absolute;
  top: 36px;
  right: 36px;
  padding: 0;
  z-index: 10000;
`;

export const IconSimple = styled.img``;