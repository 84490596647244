import { configureStore, createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Middleware } from "redux";

// SLICES
import { apiSlice } from "api/apiSlice";
import sessionSlice from "slices/SessionSlice/sessionSlice";
import uiSlice from "slices/UISlice/uiSlice";
import languageSlice from "slices/LanguageSlice/languageSlice";

export const revertAll = createAction("REVERT_ALL");

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "template", "admin", "client", "ui", "language"],
  blacklist: [`${apiSlice.reducerPath}`, "temp"], // will NOT be persisted
};

const reducers = combineReducers({
  session: sessionSlice,
  ui: uiSlice,
  language: languageSlice, // include language slice
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware as Middleware), // Explicitly cast the middleware type if needed
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
