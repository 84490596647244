import React from "react";
import Lottie from "react-lottie";
import animationData from "./animations/Preloader.json";

const Preloader = (props: any) => {
  const { width, height } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true, // Set to true if you want the animation to play automatically
    animationData: animationData, // Lottie animation JSON
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}

    />
  );
};

export default Preloader;
