import { useEffect, useRef } from "react";

// ASSETS
import MaxTextIcon from "assets/svg/MaxTextIcon.svg";
import MaxCursorIcon from "assets/svg/MaxCoursorIcon.svg";
import RowSpacingIcon from "assets/svg/RowSpacingIcon.svg";
import KerningIcon from "assets/svg/KerningIcon.svg";
import BlackWhiteIcon from "assets/svg/BlackWhiteIcon.svg";
import InverseColors from "assets/svg/InverseIcon.svg";
import XSign from "assets/svg/XSign.svg";

// STYLES
import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  OptionButton,
  OptionsGrid,
  ModalHeader,
  ResetButtonWrapper,
  Icon,
  ContentWrapper,
} from "./AccesibilityModalStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

// MISC
import {
  IAccessibilityModalProps,
  AccessibilityOption,
} from "./AccesibilityModalModel";
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  handleAccessibilityBiggerText,
  handleAccesibilityBiggerCursor,
  handleAccesibilityBiggerLineHeight,
  handleAccesibilityBiggerLetterSpacing,
  handleAccesibilityBlackAndWhite,
  handleAccesibilityInvertColors,
  handldeResetAll,
} from "slices/UISlice/uiSlice";
import {
  accessibilityBiggerTextSelector,
  accessibilityBiggerCursorSelector,
  accessibilityBiggerLetterSpacingSelector,
  accessibilityBiggerLineHeightSelector,
  accessibilityBlackAndWhiteSelector,
  accessibilityInvertColorsSelector,
} from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import CustomText from "components/Atoms/CustomText/CustomText";

const AccesibilityModal = (props: IAccessibilityModalProps) => {
  // PROPERTIES
  const { isOpen, onClose } = props;

  // STATE CONSTANTS
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const biggerText = useSelector(accessibilityBiggerTextSelector);
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const biggerLetterSpacing = useSelector(
    accessibilityBiggerLetterSpacingSelector
  );
  const biggerLineHeight = useSelector(accessibilityBiggerLineHeightSelector);
  const blackAndWhite = useSelector(accessibilityBlackAndWhiteSelector);
  const invertColors = useSelector(accessibilityInvertColorsSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accessibilityOptions: AccessibilityOption[] = [
    {
      id: "increaseText",
      text: t("atoms.accesabilityModal.bigText"),
      icon: MaxTextIcon,
      selected: biggerText,
    },
    ...(!isMobile
      ? [
          {
            id: "increaseMouse",
            text: t("atoms.accesabilityModal.cursor"),
            icon: MaxCursorIcon,
            selected: biggerCursor,
          },
        ]
      : []),
    {
      id: "spacingRow",
      text: t("atoms.accesabilityModal.row"),
      icon: RowSpacingIcon,
      selected: biggerLineHeight,
    },
    {
      id: "kerning",
      text: t("atoms.accesabilityModal.letters"),
      icon: KerningIcon,
      selected: biggerLetterSpacing,
    },
    {
      id: "blackWhite",
      text: t("atoms.accesabilityModal.blackandwhite"),
      icon: BlackWhiteIcon,
      selected: blackAndWhite,
    },
    {
      id: "inverseColors",
      text: t("atoms.accesabilityModal.colors"),
      icon: InverseColors,
      selected: invertColors,
    },
  ];

  const modalRef = useRef(null);

  // LIBRARY CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleOptionClick = (optionId: string) => {
    switch (optionId) {
      case "increaseText":
        dispatch(handleAccessibilityBiggerText());
        break;
      case "increaseMouse":
        dispatch(handleAccesibilityBiggerCursor());
        break;
      case "spacingRow":
        dispatch(handleAccesibilityBiggerLineHeight());
        break;
      case "kerning":
        dispatch(handleAccesibilityBiggerLetterSpacing());
        break;
      case "blackWhite":
        dispatch(handleAccesibilityBlackAndWhite());

        break;
      case "inverseColors":
        dispatch(handleAccesibilityInvertColors());
        break;
      default:
        break;
    }
  };

  const handleResetClick = () => {
    dispatch(handldeResetAll());
  };

  useEffect(() => {
    if (blackAndWhite) {
      document.querySelector("html").style.filter = "grayscale(100%)";
    } else if (invertColors) {
      document.querySelector("html").style.filter = "invert(100%)";
    } else {
      document.querySelector("html").style.filter = "none";
    }
  }, [blackAndWhite, invertColors]);

  useEffect(() => {
    if (biggerCursor) {
      const cursorIcon = require("assets/img/Cursor.png");
      const cursor = document.querySelector("html");
      cursor.style.cursor = `url(${cursorIcon}), auto`;
    } else {
      const cursor = document.querySelector("html");
      cursor.style.cursor = "auto";
    }
  }, [biggerCursor]);

  useEffect(() => {
    const handleClose = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };
    if (isOpen) {
      setTimeout(() => {
        document.addEventListener("click", handleClose);
      }, 200);
    } else {
      setTimeout(() => {
        document.removeEventListener("click", handleClose);
      }, 200);
    }
    return () => {
      setTimeout(() => {
        document.removeEventListener("click", handleClose);
      }, 200);
    };
  }, [isOpen, onClose]);

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  return (
    isOpen && (
      <ModalOverlay onClick={handleOverlayClick}>
        <ModalContent onClick={handleModalClick} ref={modalRef}>
          <ModalHeader>
            <CustomText
              text={t("atoms.accesabilityModal.title")}
              type={TextType.H4fix}
              color={COLORS.BlackRussian}
            />
            <CloseButton onClick={onClose} biggerCursor={biggerCursor}>
              <Icon src={XSign} alt="Close Button" />
            </CloseButton>
          </ModalHeader>
          <ContentWrapper>
            <OptionsGrid>
              {accessibilityOptions.map((option) => (
                <OptionButton
                  key={option.id}
                  biggerCursor={biggerCursor}
                  isSelected={option.selected}
                  onClick={() => handleOptionClick(option.id)}
                >
                  <Icon src={option.icon} alt={option.text} />
                  <CustomText
                    text={option.text}
                    type={TextType.H5}
                    color={COLORS.BlackRussian}
                  />
                </OptionButton>
              ))}
            </OptionsGrid>
            <ResetButtonWrapper>
              <ButtonAtom
                text={t("atoms.accesabilityModal.reset")}
                type={ButtonType.RedButton}
                onClick={handleResetClick}
              />
            </ResetButtonWrapper>
          </ContentWrapper>
        </ModalContent>
      </ModalOverlay>
    )
  );
};

export default AccesibilityModal;
