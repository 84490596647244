import { AxiosInstance } from 'axios';
import axiosBase from './base';
import process from "process";
import { store } from 'store/store';

class PartsService {
    base: AxiosInstance;

    constructor() {
        this.base = axiosBase.base;
    }

    sendMessage(message: any): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;
        return new Promise(async (resolve, reject) => {
            this.base
                .post(process.env.REACT_APP_BOT_API_URL +"?lang="+language.toUpperCase(), { conversation: message })
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    search(terms: any): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`${process.env.REACT_APP_SEARCH_API_URL}?terms=${terms.toString()}&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getFAQs(page: number = 1): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/faqs?sort=position:asc&pagination[page]=${page}&pagination[pageSize]=5&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getPartners(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/partners/?populate=*&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    apiSearch(query: string): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/search/${query}?locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getEmergencyGallery(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/emergency-galleries/?pagination[pageSize]=100&populate=*&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getEmergencyInfo(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/context-emergency-info?locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    saveContact(data: any): Promise<any[]> {
        return new Promise(async (resolve, reject) => {
            this.base
                .post(`/api/contacts`, data)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }
}

const partsService = new PartsService();
export default partsService;
