import { useState, useRef, useEffect } from "react";

// ASSETS
import XSignIcon from "../../../assets/svg/XSign.svg";

// STYLES
import {
  DropdownWrapper,
  DropdownMenuContainter,
  DropdownMenuTitleContainer,
  DropdownMenuCloseButton,
  DropdownMenuCloseIcon,
  DropdownMenuItemsContainer,
  DropdownMenuItemWrapper,
  DropdownMenuItemCheckboxWrapper,
  DropdownMenuItemCheckboxIcon,
  DropdownButtonWrapper,
  WrapperFilter,
  TextWrapper,
} from "components/Atoms/MapDropDownAtom/MapDropdownAtomStyled";

// LIBRARIES

import { t } from "i18next";

// MISC

import { IProps, Option } from "./MapDropdownAtomModel";
import { ButtonType } from "../ButtonAtom/ButtonAtomModel";
import { TextType } from "../CustomText/CustomTextModel";
import { COLORS } from "constants/variables";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import ButtonAtom from "../ButtonAtom/ButtonAtom";
import CustomText from "../CustomText/CustomText";

// CONFIGURATION

const MapDropdownAtom = (props: IProps) => {
  // PROPERTIES
  const {
    options = [
      {
        label: "",
        value: "",
      },
    ],
    title = "",
    dropdownName = "",
    selected = options.length > 0 ? options[0].value : null,
    onConfirm = (options: any[]) => {},
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  const menuRef = useRef(null);
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  // LIFE CYCLE
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
      setSelectedOptions([
        options.find((option) => parseInt(option.value) === selected),
      ]);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  // EVENT HANDLERS

  const handleSelectOptions = (option: Option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions((prevState) =>
        prevState.filter((item) => item !== option)
      );
    } else {
      setSelectedOptions((prevState) => [option]);
    }
    if (onConfirm) {
      onConfirm([option]);
      setIsOpen(false);
    }
  };

  const evaluateCheckbox = (option: Option) => {
    if (selectedOptions.includes(option)) {
      return "checked";
    } else {
      return "";
    }
  };

  return (
    <DropdownWrapper ref={menuRef}>
      <DropdownButtonWrapper>
        <WrapperFilter>
          <TextWrapper>
            <CustomText type={TextType.H5} text={t("map.activeSelection")} />
          </TextWrapper>
          <ButtonAtom
            text={dropdownName}
            type={ButtonType.RedButton}
            onClick={() => setIsOpen((prevState) => !prevState)}
          />
        </WrapperFilter>
      </DropdownButtonWrapper>

      {isOpen && (
        <DropdownMenuContainter isOpen={isOpen}>
          <DropdownMenuTitleContainer>
            <CustomText
              text={title}
              type={TextType.H4}
              color={COLORS.BlackRussian}
            />
            <DropdownMenuCloseButton
              onClick={() => setIsOpen(false)}
              biggerCursor={biggerCursor}
            >
              <DropdownMenuCloseIcon src={XSignIcon} />
            </DropdownMenuCloseButton>
          </DropdownMenuTitleContainer>
          <DropdownMenuItemsContainer>
            {options?.map((option) => (
              <DropdownMenuItemWrapper key={`option-${option.value}`}>
                <CustomText
                  text={option.label}
                  type={TextType.H5}
                  color={COLORS.BlackRussian}
                />
                <DropdownMenuItemCheckboxWrapper
                  onClick={() => handleSelectOptions(option)}
                >
                  <DropdownMenuItemCheckboxIcon
                    className={evaluateCheckbox(option)}
                    biggerCursor={biggerCursor}
                  />
                </DropdownMenuItemCheckboxWrapper>
              </DropdownMenuItemWrapper>
            ))}
          </DropdownMenuItemsContainer>
        </DropdownMenuContainter>
      )}
    </DropdownWrapper>
  );
};

export default MapDropdownAtom;
