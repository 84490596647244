//LIBRARY
import styled from "styled-components";
//MISC
import {
  BORDER_RADIUS,
  COLORS,
  SIZE,
  DEVICE_TYPE,
} from "../../../../constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(29, 29, 38, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background-color: ${COLORS.WhiteSmoke};
  height: 50px;

  width: ${SIZE.max720};
  border-radius: ${BORDER_RADIUS.radius32};

  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: auto;
    max-width: 330px;
  }
`;

export const ImgStyled = styled.img``;

export const CloseButton = styled.button<{ biggerCursor?: boolean }>`
  position: absolute;
  top: ${SIZE.max32};
  right: ${SIZE.max32};
  background: ${COLORS.WhiteSmoke};
  border: none;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  color: ${COLORS.BlackRussian};
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: ${BORDER_RADIUS.radius16};
  transition: 0.3s ease-out;
  &:hover,
  &:focus {
    color: ${COLORS.Paua};
    outline: none;
    transition: 0.3s ease-in;
  }
`;
export const InputContainer = styled.div`
  width: ${SIZE.max720};
  margin-bottom: ${SIZE.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: auto;
    max-width: 330px;
  }
`;
