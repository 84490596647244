import React, { useState, useRef, useEffect, createRef } from "react";

// ASSETS

import ArrowLeft from "assets/svg/ArrowDown.svg";
import facebookIcon from "assets/svg/IconFacebook.svg";
import linkedInIcon from "assets/svg/iconsLinkedIn.svg";
import shareIcon from "assets/svg/ShareIcon.svg";
import ArrowUpIcon from "assets/svg/RightRedIcon.svg";
import coverPlaceholder from "assets/img/cover.placeholder.svg";
import iconPlaceholder from "assets/img/icon.placeholder.svg";
// STYLES
import {
  SingleGuideContainer,
  HeaderImageWrapper,
  HeaderImage,
  HeaderGuide,
  HeaderCell,
  Body,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  BackToGuidesButton,
  BackToGuidesImage,
  BackToGuidesWrapper,
  TitleWrapper,
  LeftCardTitle,
  LeftCardChapter,
  LeftCard,
  RedCircle,
  ContentCard,
  SocialMediaCard,
  SocialMediaIcons,
  SocialMediaIcon,
  OtherGuidesCard,
  ImgStyled,
  OtherChapters,
  InsideFirstColumn,
  InsideThirdColumn,
  BackOnTopWrapper,
  BackOnTopImg,
  RedCircleWrapper,
  CenterCardTitle,
  ScrollTargetWrapper,
  CopyToClipboardWrapper,
  CopyToClipboardTooltip,
  SkeletonWrapperHeader,
  SkeletonWrapperChapter,
  SkeletonWrapperOtherChapter,
} from "./SingleGuideStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { LinkedinShareButton, FacebookShareButton } from "react-share";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

// MISC
import modRewrite from "../../../locales/modRewrite";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { content } from "constants/mocks/GuideData";
import { BORDER_RADIUS, COLORS, DEVICE_TYPE } from "constants/variables";
import { CardType } from "components/Atoms/GuideCardAtom/GuideCardAtomModel";
// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import GuideCardAtom from "components/Atoms/GuideCardAtom/GuideCardAtom";
import guideService from "../../../services/guideService";

import { baseUrl } from "../../../services/base";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";

const SingleGuide = () => {
  // PROPERTIES
  const { rgxId } = useParams();
  const tmpIds: any = rgxId.split("-");
  const guideId = tmpIds.pop();
  const paramSectionId: any = tmpIds.pop();

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: DEVICE_TYPE.mobileHeader });
  const navigate = useNavigate();

  // STATE CONSTANTS
  const [selectedTab, setSelectedTab] = useState("beforeEvent");
  const [isContentReady, setIsContentReady] = useState(false);
  const [activeChapter, setActiveChapter] = useState(null);
  const [guide, setGuide] = useState<any>(null);
  const [chapters, setChapters] = useState<any>([]);
  const [randomGuides, setRandomGuides] = useState<any[]>([]);
  const contentRefs = useRef([]);
  const [copied, setCopied] = useState(false);

  const headerImageWrapperRef = useRef(null);
  const skeletonCount = 3;
  useEffect(() => {
    const anchor =
      contentRefs?.current[parseInt(activeChapter)]?.current.getAttribute("id");
    if (
      isContentReady &&
      contentRefs.current[parseInt(activeChapter)]?.current
    ) {
      contentRefs.current[parseInt(activeChapter)].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      navigate(`#${anchor}`);
    }

    // eslint-disable-next-line
  }, [activeChapter, isContentReady, contentRefs]);
  useEffect(() => {
    (async () => {
      try {
        const currentGuide: any = await guideService.getGuide(guideId);

        if (currentGuide.data.attributes.sections.data !== null) {
          currentGuide.data.attributes.sections.data.sort(
            (a, b) => a.order - b.order
          );
          currentGuide.data.attributes.chapters.data.forEach((chap: any) => {
            const sections =
              chap.attributes.sections.data !== undefined
                ? chap.attributes.sections.data.map((el: any) => el.id)
                : [];

            const item =
              currentGuide.data.attributes.sections.data !== null
                ? currentGuide.data.attributes.sections.data.find((el: any) =>
                    sections.includes(el.id)
                  )
                : null;

            if (item) {
              if (item["chapters"] === undefined) {
                item["chapters"] = [];
              }
              item["chapters"].push(chap);
            }
          });
        }

        // TODO: use only one set per action, to avoid unnecessary rerenders
        setGuide(currentGuide.data.attributes);

        if (isNaN(paramSectionId)) {
          setSelectedTab(currentGuide.data.attributes.sections.data[0].id);
        } else {
          setSelectedTab(paramSectionId);
        }

        const guides: any = await guideService.getGuides();

        setRandomGuides(
          guides.data
            .filter((el: any) => el.id !== parseInt(guideId))
            .slice()
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
        );
      } catch (err) {}
    })();
    // eslint-disable-next-line
  }, [guideId]);

  // useEffect(() => {
  //   if (guide) {
  //     //setSelectedTab(guide.sections.data[0].id);
  //   }
  // }, [guide]);

  useEffect(() => {
    if (selectedTab && guide) {
      const crtSection = guide.sections.data.find(
        (el: any) => el.id === parseInt(selectedTab)
      );
      const tmpChapters = crtSection?.chapters || [];
      let anchor = "";
      if (tmpChapters.length > 0) {
        const tmpAnchor = window.location.href.split("#");

        if (tmpAnchor.length > 1) {
          anchor = "#" + tmpAnchor[1];
        }
        navigate(
          `/ghiduri/${modRewrite(guide.title)}-${modRewrite(
            crtSection.attributes.title
          )}-${selectedTab}-${guideId}${anchor}`
        );
      }
      setChapters(tmpChapters ? tmpChapters : []);
      if (tmpChapters.length > 0) {
        contentRefs.current = guide.sections.data
          .find((el: any) => el.id === parseInt(selectedTab))
          .chapters.map(
            (_, index) => contentRefs.current[index] ?? createRef()
          );

        if (anchor.length === 0) {
          setActiveChapter(0);
        } else {
          setActiveChapter(
            tmpChapters.indexOf(
              tmpChapters.find(
                (el: any) => el.id === parseInt(anchor.split("-").pop())
              )
            )
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedTab, guide]);

  useEffect(() => {
    if (content.length > 0) {
      setIsContentReady(true);
    }
    // eslint-disable-next-line
  }, [contentRefs.current]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {});
      },
      { rootMargin: "0px", threshold: 0.1 }
    );

    contentRefs.current.forEach((ref, index) => {
      if (ref.current) {
        ref.current.setAttribute(
          "data-chapter-id",
          content[index] ? content[index].id.toString() : 0
        );
        observer.observe(ref.current);
      }
    });

    return () => {
      contentRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
    // eslint-disable-next-line
  }, [contentRefs.current]);

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const scrollToChapter = (index) => {
    setActiveChapter(index);
  };

  const scrollToTop = () => {
    if (headerImageWrapperRef.current) {
      headerImageWrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy URL: ", err));
  };

  return (
    <SingleGuideContainer>
      <HeaderImageWrapper ref={headerImageWrapperRef}>
        <HeaderImage
          src={
            guide && guide.cover.data !== null
              ? `${baseUrl}${guide.cover.data.attributes.url}`
              : coverPlaceholder
          }
          alt={"coverGuideImage"}
        />
      </HeaderImageWrapper>
      <HeaderGuide>
        {guide?.sections?.data !== null &&
        guide?.sections?.data !== undefined ? (
          guide?.sections?.data.map((tab, index) => (
            <HeaderCell
              tabIndex={0}
              key={index}
              isSelected={parseInt(selectedTab) === tab.id}
              onClick={() => {
                setSelectedTab(tab.id);
                scrollToChapter(0);
              }}
            >
              <CustomText text={tab.attributes.title} type={TextType.H7fix} />
            </HeaderCell>
          ))
        ) : (
          <SkeletonWrapperHeader>
            {[...Array(skeletonCount)].map((_, index) => (
              <Skeleton
                key={index}
                width={100}
                count={2}
                baseColor={COLORS.Solitude}
                highlightColor={COLORS.WhiteSmoke}
              />
            ))}
          </SkeletonWrapperHeader>
        )}
      </HeaderGuide>
      <Body>
        <FirstColumn>
          <InsideFirstColumn>
            <BackToGuidesWrapper onClick={() => navigate("/ghiduri")}>
              <BackToGuidesImage src={ArrowLeft} alt="backIcon" />
              <BackToGuidesButton>
                <CustomText
                  text={t("view.singleGuides.backButton")}
                  type={TextType.H4fix}
                  color={COLORS.Paua}
                />
              </BackToGuidesButton>
            </BackToGuidesWrapper>
            <TitleWrapper>
              {guide !== null ? (
                <CustomText
                  text={`${t("view.singleGuides.guide")} ${
                    guide && guide.title.toLowerCase()
                  }`}
                  type={!isMobile ? TextType.H1custom : TextType.H2}
                />
              ) : (
                <Skeleton
                  width={"100%"}
                  height={"3rem"}
                  borderRadius={BORDER_RADIUS.radius21}
                  count={1}
                  baseColor={COLORS.WhiteSmoke}
                  highlightColor={COLORS.Solitude}
                />
              )}
            </TitleWrapper>

            <LeftCard>
              <LeftCardTitle>
                <CustomText
                  text={t("view.singleGuides.chapters")}
                  type={TextType.H3}
                />
              </LeftCardTitle>

              {chapters.length > 0 ? (
                chapters.map((chapter, index) => (
                  <LeftCardChapter
                    key={index}
                    onClick={() => scrollToChapter(index)}
                    tabIndex={0}
                  >
                    <RedCircleWrapper>
                      <RedCircle />
                    </RedCircleWrapper>
                    <CustomText
                      text={chapter.attributes.title}
                      type={
                        index === parseInt(activeChapter)
                          ? TextType.P2fixbold
                          : TextType.P2fix
                      }
                    />
                  </LeftCardChapter>
                ))
              ) : (
                <LeftCardChapter>
                  <RedCircleWrapper>
                    <RedCircle />
                  </RedCircleWrapper>
                  <SkeletonWrapperChapter>
                    <Skeleton
                      width={"100%"}
                      count={1}
                      baseColor={COLORS.Solitude}
                      highlightColor={COLORS.WhiteSmoke}
                    />
                  </SkeletonWrapperChapter>
                </LeftCardChapter>
              )}
            </LeftCard>
          </InsideFirstColumn>
        </FirstColumn>
        <SecondColumn>
          {chapters.length > 0 ? (
            chapters.map((chapter, index: number) => (
              <ScrollTargetWrapper
                ref={contentRefs.current[index]}
                key={index}
                id={modRewrite(chapter.attributes.title, chapter.id)}
              >
                <ContentCard tabIndex={0}>
                  <CenterCardTitle>
                    <CustomText
                      text={chapter.attributes.title}
                      type={TextType.H3}
                    />
                  </CenterCardTitle>
                  {chapter.attributes.content && (
                    <StrapiTreeText text={chapter.attributes.content} />
                  )}
                </ContentCard>
              </ScrollTargetWrapper>
            ))
          ) : (
            <>
              <ContentCard tabIndex={0}>
                <CenterCardTitle>
                  <Skeleton
                    width={"100%"}
                    count={1}
                    baseColor={COLORS.Solitude}
                    highlightColor={COLORS.WhiteSmoke}
                  />
                </CenterCardTitle>
                <Skeleton
                  width={"100%"}
                  count={15}
                  baseColor={COLORS.Solitude}
                  highlightColor={COLORS.WhiteSmoke}
                />
              </ContentCard>
            </>
          )}
        </SecondColumn>
        <ThirdColumn>
          <InsideThirdColumn>
            <SocialMediaCard>
              <CustomText text={"SHARE"} type={TextType.H4fix} />
              <SocialMediaIcons biggerCursor={true}>
                <FacebookShareButton url={window.location.href}>
                  <SocialMediaIcon src={facebookIcon} alt="facebookIcon" />
                </FacebookShareButton>
                <LinkedinShareButton url={window.location.href}>
                  <SocialMediaIcon src={linkedInIcon} alt="linkedInIcon" />
                </LinkedinShareButton>
                <CopyToClipboardWrapper onClick={handleCopyToClipboard}>
                  <SocialMediaIcon src={shareIcon} alt="shareIcon" />
                  {copied && (
                    <CopyToClipboardTooltip>
                      <CustomText
                        text="Link-ul a fost copiat in clipboard"
                        type={TextType.P2}
                        color={COLORS.BlackRussian}
                      />
                    </CopyToClipboardTooltip>
                  )}
                </CopyToClipboardWrapper>
              </SocialMediaIcons>
            </SocialMediaCard>
            <OtherGuidesCard>
              <CustomText
                text={t("view.singleGuides.otherGuides")}
                type={TextType.H3fix}
              />
              {randomGuides.length > 0 ? (
                randomGuides.map((el: any, index: number) => {
                  return (
                    <OtherChapters
                      key={index}
                      tabIndex={0}
                      onClick={() => {
                        navigate(
                          `/ghiduri/${modRewrite(el.attributes.title, el.id)}`
                        );
                      }}
                    >
                      <GuideCardAtom
                        icon={
                          <ImgStyled
                            src={
                              el.attributes.icon.data !== null
                                ? `${baseUrl}${el.attributes.icon.data.attributes.url}`
                                : iconPlaceholder
                            }
                          />
                        }
                        cardType={CardType.SingleGuideStyle}
                        width={"80px"}
                        height={"80px"}
                        handleCardPress={() => false}
                      />
                      <CustomText
                        text={el.attributes.title}
                        type={TextType.H5fix}
                      />
                    </OtherChapters>
                  );
                })
              ) : (
                <>
                  {[...Array(skeletonCount)].map((_, index) => (
                    <SkeletonWrapperOtherChapter key={index}>
                      <Skeleton
                        width={"80px"}
                        height={"80px"}
                        borderRadius={BORDER_RADIUS.radius16}
                        baseColor={COLORS.Solitude}
                        highlightColor={COLORS.WhiteSmoke}
                      />
                      <SkeletonWrapperChapter>
                        <Skeleton
                          count={1}
                          baseColor={COLORS.Solitude}
                          highlightColor={COLORS.WhiteSmoke}
                        />
                      </SkeletonWrapperChapter>
                    </SkeletonWrapperOtherChapter>
                  ))}
                </>
              )}
            </OtherGuidesCard>
          </InsideThirdColumn>
        </ThirdColumn>
      </Body>
      {isMobile && (
        <BackOnTopWrapper onClick={scrollToTop}>
          <BackOnTopImg src={ArrowUpIcon} alt="ArrowUpIcon" />
        </BackOnTopWrapper>
      )}
    </SingleGuideContainer>
  );
};

export default SingleGuide;
