
import { AxiosInstance } from 'axios'
import axiosBase from './base'
import { store } from 'store/store';

class GuideService {
    base: AxiosInstance

    constructor() {
        this.base = axiosBase.base;
    }

    // Clients
    getGuidesCategories(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/guides-categories?locale=${language}`)
                .then(({ data }) => {
                    return resolve(data)
                })
                .catch((err) => reject(err))
        })
    }

    getGuides(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/guides/?populate[icon]=icon&populate[guides_category]=guides_category&pagination[pageSize]=100&sort[0]=guides_category.id:desc&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data)
                })
                .catch((err) => reject(err))
        })
    }

    getGuide(guideId: string): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/guides/${guideId}/?populate[sections]=*&populate[cover]=*&populate[icon]=icon&populate[chapters][populate][sections][populate]=id&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data)
                })
                .catch((err) => reject(err))
        })
    }

    getDefibrlators(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/get-scheduled&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data)
                })
                .catch((err) => reject(err))
        })
    }
}

const guideService = new GuideService();
export default guideService;
