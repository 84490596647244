import React, { useState } from "react";

// ASSETS
import searchIcon from "assets/svg/SearchIcon.svg";
import searchIconSelected from "assets/svg/SearchIconSelected.svg";
import gptIcon from "assets/svg/GptIcon.svg";
import hamburgerMenuIcon from "assets/svg/HamburgerMenuIcon.svg";
import hamburgerMenuIconSelected from "assets/svg/HamburgerMenuIconSelected.svg";

// STYLES
import { MobileHeaderContainer } from "./MobileHeaderAtomStyled";

// LIBRARIES
import { useNavigate } from "react-router";
// MISC
import { IconButtonAtomProps } from "../IconButtonAtom/IconButtonAtomModel";
import { UIState } from "./MobileHeaderAtomModel";
// REDUX

// COMPONENTS
import IconButtonAtom from "../IconButtonAtom/IconButtonAtom";
import HamburgerMenuComponent from "components/HamburgerMenuComponent/HamburgerMenuComponent";
import SearchBarAccesibility from "components/Atoms/ButtonsBarAtom/SearchBar/SearchBar";

const MobileHeaderAtom = () => {
  // PROPERTIES

  // STATE CONSTANTS
  const [uiState, setUiState] = useState<UIState>({
    selectedIconIndex: null,
    isSearchBarOpen: false,
    isHamburgerMenuOpen: false,
  });
  const iconsList: IconButtonAtomProps[] = [
    {
      id: 0,
      icon: gptIcon,
      isSelected: uiState.selectedIconIndex === 0,
      iconSelected: gptIcon,
      shouldNavigateOn: true,
      path: "/fiipregatit-chat-bot",
    },
    {
      id: 1,
      icon: searchIcon,
      isSelected: uiState.selectedIconIndex === 1,
      iconSelected: searchIconSelected,
      shouldNavigateOn: false,
    },
    {
      id: 2,
      icon: hamburgerMenuIcon,
      isSelected: uiState.selectedIconIndex === 2,
      iconSelected: hamburgerMenuIconSelected,
      shouldNavigateOn: false,
    },
  ];

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleIconClick = (iconProps: IconButtonAtomProps) => {
    const newState = {
      selectedIconIndex: iconProps.id,
      isSearchBarOpen: iconProps.id === 1,
      isHamburgerMenuOpen: iconProps.id === 2,
    };
    setUiState(newState);
    if (iconProps.shouldNavigateOn) {
      navigate(iconProps.path);
    }
  };

  return (
    <>
      <MobileHeaderContainer>
        {iconsList.map((iconProps, index) => (
          <IconButtonAtom
            key={index}
            {...iconProps}
            isSelected={uiState.selectedIconIndex === index}
            onClick={() => handleIconClick(iconProps)}
          />
        ))}
      </MobileHeaderContainer>
      <HamburgerMenuComponent
        isOpen={uiState.isHamburgerMenuOpen}
        onOpen={() =>
          setUiState({
            selectedIconIndex: null,
            isSearchBarOpen: false,
            isHamburgerMenuOpen: false,
          })
        }
      />
      <SearchBarAccesibility
        isOpen={uiState.isSearchBarOpen}
        onClose={() =>
          setUiState({
            selectedIconIndex: null,
            isSearchBarOpen: false,
            isHamburgerMenuOpen: false,
          })
        }
      />
    </>
  );
};

export default MobileHeaderAtom;
