import styled, { css } from "styled-components";
import {
  IStyled,
  TextType,
} from "components/Atoms/StrapiTreeText/StrapiTreeTextModel";
import {
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  ROW_SPACING, SIZE,
} from "constants/variables";

export const StrapiTreeTextContainer = styled.div``;
export const StrapiMarkup = styled.div`
    & > p {
        margin: ${SIZE.max10} 0;
    }

    & > pre > code {
      text-wrap: pretty;
    }
  
    & ol, ul {
        margin: ${SIZE.max24};
        > li {
  
          &::marker {
              font-weight: bold;
          }
  
          margin: ${SIZE.max24};
      }
    }
`;

export const StrapiTreeTextLabel = styled.span<IStyled>`
  && {
    font-family: ${FONT_FAMILY.poppins};

    color: ${({ color }) => color || "inherit"};
    ${({
      type,
      accessibilityBiggerText,
      accessibilityBiggerLineHeight,
      accessibilityBiggerLetterSpacing,
    }) => {
      let color;
      let fontSize;
      let fontWeight;
      let letterSpacing;
      let lineHeight;

      switch (type) {
        case TextType.H1prime:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max60
            : FONT_SIZE.max80;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max95
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max90
              : ROW_SPACING.max85;

          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "6px";
          break;
        case TextType.H1custom:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max40
            : FONT_SIZE.max60;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight = !accessibilityBiggerLineHeight
            ? "48px"
            : ROW_SPACING.max55;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "6px";
          break;
        case TextType.H1:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max40
            : FONT_SIZE.max60;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max70
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max60
              : ROW_SPACING.max48;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "6px";
          break;
        case TextType.H2:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max32
            : FONT_SIZE.max40;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max48
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max48
              : ROW_SPACING.max38;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "4px";
          break;
        case TextType.H2fix:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.fix32
            : FONT_SIZE.fix40;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.fix55
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.fix48
              : ROW_SPACING.fix38;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "4px";
          break;

        case TextType.H3:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max24
            : FONT_SIZE.max32;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max48
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max38
              : ROW_SPACING.max30;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "3px";
          break;
        case TextType.H3fix:
          fontSize = accessibilityBiggerText
            ? FONT_SIZE.max32
            : FONT_SIZE.fix24;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.fix48
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.fix38
              : ROW_SPACING.fix35;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "0" : "3px";
          break;
        case TextType.H4:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max16
            : FONT_SIZE.max18;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max35
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max30
              : ROW_SPACING.max24;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "2px";
          break;
        case TextType.H4fix:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.fix16
            : FONT_SIZE.fix18;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.fix30
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.fix24
              : ROW_SPACING.fix20;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "0.5px" : "2px";
          break;
        case TextType.H4bold18px:
          fontSize = !accessibilityBiggerText ? FONT_SIZE.max18 : "22px";
          fontWeight = FONT_WEIGHT.bold;
          lineHeight = !accessibilityBiggerLineHeight
            ? ROW_SPACING.max20
            : ROW_SPACING.max24;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "2px";
          break;
        case TextType.H4Logo:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max20
            : FONT_SIZE.max24;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max38
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max35
              : ROW_SPACING.max30;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "1px";
          break;
        case TextType.H5:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max16
            : FONT_SIZE.max18;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max30
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max24
              : ROW_SPACING.max20;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "1px";
          break;
        case TextType.H5fix:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.fix16
            : FONT_SIZE.fix18;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.fix30
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.fix24
              : ROW_SPACING.fix20;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "1px";
          break;
        case TextType.H6:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max14
            : FONT_SIZE.max16;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max30
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max24
              : ROW_SPACING.max20;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "1px";
          break;
        case TextType.H7:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max14
            : FONT_SIZE.max16;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max24
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max20
              : ROW_SPACING.max18;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "0.5px" : "1.5px";
          break;
        case TextType.H7fix:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.fix14
            : FONT_SIZE.fix16;
          fontWeight = FONT_WEIGHT.semiBold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.fix24
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.fix20
              : ROW_SPACING.fix18;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "0.5px" : "1.5px";
          break;
        case TextType.P1:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max18
            : FONT_SIZE.max24;
          fontWeight = FONT_WEIGHT.regular;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max38
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max38
              : ROW_SPACING.max35;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "1px" : "2px";
          break;
        case TextType.P1bold:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max18
            : FONT_SIZE.max24;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max38
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max35
              : ROW_SPACING.max30;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "1px" : "2px";
          break;
        case TextType.P2:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max14
            : FONT_SIZE.max16;
          fontWeight = FONT_WEIGHT.regular;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max30
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max24
              : ROW_SPACING.max18;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "0.5px" : "1px";
          break;
        case TextType.P2fix:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.fix14
            : FONT_SIZE.fix16;
          fontWeight = FONT_WEIGHT.regular;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.fix24
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.fix20
              : ROW_SPACING.fix18;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "0.5px" : "1px";
          break;
        case TextType.P2fixbold:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.fix14
            : FONT_SIZE.fix16;
          fontWeight = FONT_WEIGHT.bold;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.fix24
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.fix20
              : ROW_SPACING.fix18;
          letterSpacing = !accessibilityBiggerLetterSpacing ? 0 : "1px";
          break;
        case TextType.P3:
          fontSize = !accessibilityBiggerText
            ? FONT_SIZE.max18
            : FONT_SIZE.max24;
          fontWeight = FONT_WEIGHT.medium;
          lineHeight =
            accessibilityBiggerText && accessibilityBiggerLineHeight
              ? ROW_SPACING.max38
              : accessibilityBiggerText || accessibilityBiggerLineHeight
              ? ROW_SPACING.max35
              : ROW_SPACING.max30;
          letterSpacing = !accessibilityBiggerLetterSpacing ? "1px" : "2px";
          break;
        default:
          color = COLORS.BlackRussian;
          fontSize = FONT_SIZE.max14;
          fontWeight = FONT_WEIGHT.regular;
          lineHeight = "normal";
      }

      return css`
        font-size: ${fontSize};
        font-weight: ${fontWeight};
        letter-spacing: ${letterSpacing};
        line-height: ${lineHeight};
        color: ${color};

        & .paragraph {
          margin-bottom: 1em;
        }
      `;
    }}
  }
`;
