import React, { useEffect, useState } from "react";

// ASSETS
import { COLORS } from "constants/variables";
// STYLES
import { DisclaimerContainer, Title, Content } from "./DisclaimerStyled";

// LIBRARIES

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import getTermsService from "services/getTermsService";
// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";

const PrivacyPolicy = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const [content, setContent] = useState([]);
  const [title, setTitle] = useState("");
  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    (async () => {
      let response: any = await getTermsService.getDisclaimer();
      if (response.data !== null) {
        setTitle(response.data.attributes.title);
        setContent(response.data.attributes.content);
      }
    })();
  }, []);
  // EVENT HANDLERS

  return (
    <DisclaimerContainer>
      <Title>
        <CustomText type={TextType.H1} color={COLORS.Cinnabar} text={title} />
      </Title>
      <Content>
        <StrapiTreeText text={content} />
      </Content>
    </DisclaimerContainer>
  );
};

export default PrivacyPolicy;
