// ASSETS
import Screens from "assets/img/Screens.png";
import QrCode from "assets/svg/QrCode.svg";
import CircleBackground from "assets/svg/CircleBackground.svg";

// STYLES
import {
  ComponentWrapper,
  CardWrapper,
  CardGrid,
  ImageContainer,
  InfoContainer,
  LinkWrapper,
  LinkText,
  QrContainer,
  ButtonWrapper,
  Image1,
  Image2,
  DescriptionWrapper,
} from "./DownloadAppComponentStyled";
import { COLORS } from "../../../constants/variables";

// LIBRARIES
import { useTranslation } from "react-i18next";

// MISC
import { TextType } from "../CustomText/CustomTextModel";
import { ButtonType } from "../ButtonAtom/ButtonAtomModel";
import { IGuideCardProps } from "./DownloadAppComponentModel";

// REDUX

// COMPONENTS
import CustomText from "../CustomText/CustomText";
import ButtonAtom from "../ButtonAtom/ButtonAtom";

const DownloadAppComponent = (props: IGuideCardProps) => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const { t } = useTranslation();

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const navigateToStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open("https://apps.apple.com/ro/app/dsu/id1663813906", "_blank");
    }
    // Android detection
    else if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.orson.dsu&hl=ro&pli=1",
        "_blank"
      );
    } else {
      // Optionally handle other platforms or provide a default action
      alert(
        "Your device is not supported for direct download. Please visit the App Store or Google Play Store."
      );
    }
  };

  return (
    <ComponentWrapper>
      <CardGrid>
        <Image1 src={CircleBackground} alt="icon" />
        <ImageContainer src={Screens} alt="Screens" />
        <CardWrapper>
          <InfoContainer>
            <CustomText
              type={TextType.H2fix}
              color={COLORS.Cinnabar}
              text={t("atoms.downloadApp.DSUapp")}
            />
            <DescriptionWrapper>
              <CustomText
                type={TextType.P1}
                color={COLORS.BlackRussian}
                text={t("atoms.downloadApp.content")}
              ></CustomText>
            </DescriptionWrapper>
            <LinkWrapper>
              <LinkText>
                <CustomText
                  type={TextType.H4}
                  color={COLORS.BlackRussian}
                  text={t("atoms.downloadApp.scan")}
                />
              </LinkText>
              <QrContainer src={QrCode} alt="Qr" />
            </LinkWrapper>
            <ButtonWrapper>
              <ButtonAtom
                onClick={navigateToStore}
                text={t("atoms.downloadApp.download")}
                type={ButtonType.ArrowButton}
                rightIcon={true}
              />
            </ButtonWrapper>
          </InfoContainer>
        </CardWrapper>
        <Image2 src={CircleBackground} alt="image" />
      </CardGrid>
    </ComponentWrapper>
  );
};

export default DownloadAppComponent;
