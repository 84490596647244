import styled from "styled-components";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const PeopleCardContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 32px;
`;

export const PeopleCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PeopleCardTextContainer = styled.div`
  position: absolute;
  bottom: 14px;
  left: 14px;
  right: 14px;
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius24};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: ${SIZE.max24};
  padding-inline: ${SIZE.max24};

  @media (width<=768px) {
    bottom: 12px;
    left: 12px;
    right: 12px;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
`;
