//LIBRARY
import styled, { css, keyframes } from "styled-components";

//MISC
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

//ASSETS
import cursorIcon from "assets/img/Cursor.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;

export const LanguageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  height: auto;
  top: 70px;
  right: 0px;
  z-index: 1220;
  /* width: 100vw; */
  @media (max-width: 1280px) {
    top: 0px;
    left: 0px;
    z-index: 1002;
    width: 100%;
    height: 100%;
  }
`;

export const LanguageMenuContainter = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  width: ${SIZE.max400};
  padding: ${SIZE.max24};
  background-color: ${COLORS.WhiteSmoke};
  display: flex;
  flex-direction: column;
  row-gap: ${SIZE.max24};
  align-items: center;
  border: 8px solid ${COLORS.Paua}4d;
  border-radius: ${BORDER_RADIUS.radius32};
  position: absolute;
  /* top: calc(100% + ${SIZE.max32}); */
  /* transform: translateX(-37%); */

  /* animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${fadeIn} 0.3s ease-out
        `
      : css`
          ${fadeOut} 0.3s ease-out
        `}; */

  @media (max-width: 1280px) {
    position: relative;
    transform: none;
    background-color: ${COLORS.WhiteSmoke};
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 0px;
    top: 0;
    row-gap: none;
    padding: ${SIZE.fix32};
  }
`;

export const LanguageMenuTitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const LanguageMenuCloseButton = styled.button<{
  biggerCursor?: boolean;
}>`
  border: none;
  background-color: transparent;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  display: flex;
  align-items: center;
  padding-right: 4px;
`;

export const LanguageMenuCloseIcon = styled.img`
  height: ${SIZE.max16};
`;

export const LanguageMenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  row-gap: ${SIZE.max24};
  @media (max-width: 1280px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const LanguageMenuItemWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
`;

export const LanguageMenuItemCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1280px) {
    margin-left: 10px;
  }
`;

export const LanguageMenuItemCheckboxIcon = styled.img<{
  biggerCursor?: boolean;
}>`
  height: 24px;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
`;
