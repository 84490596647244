// LIBRARIES
import styled from "styled-components";
//MISC
import {
  BORDER_RADIUS,
  COLORS,
  MARGIN,
  DEVICE_TYPE,
} from "../../../../constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const ModalOverlay = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  top: 70px;
  overflow-y: auto;
  right: 0;
  z-index: 10;
  width: auto;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    top: 0px;
    right: 0px;
    z-index: 1002;
    width: 100%;
    height: 100%;
  }
`;

export const ModalContent = styled.div`
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  padding: 0 ${MARGIN.max32} ${MARGIN.max32} ${MARGIN.max32};
  width: 456px;
  border: 8px solid ${COLORS.Paua}4D;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  height: 800px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    border: none;
    width: 100%;
    height: 100%;
    padding: 50px auto ${MARGIN.max24} auto;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  padding-bottom: ${MARGIN.max24};
  padding-top: ${MARGIN.max40};
  justify-content: center;
  background: ${COLORS.WhiteSmoke};
`;

export const ModalTitle = styled.div`
  width: 100%;
`;
export const CloseButton = styled.button<{ biggerCursor?: boolean }>`
  background: none;
  border: none;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  color: ${COLORS.WhiteSmoke};
`;

export const AlertList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 300px;
`;

export const AlertItem = styled.li`
  display: flex;
  align-items: center;
`;

export const CloseButtonIcon = styled.img``;

export const EmptyAlerts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const EmptyAlertsIcon = styled.img`
  margin-bottom: ${MARGIN.max8};
`;
