//LIBRARY
import styled, { css, keyframes } from "styled-components";

//MISC
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  COLORS,
  SIZE,
  MARGIN,
  DEVICE_TYPE,
  BORDER_RADIUS,
} from "../../../../constants/variables";

import cursorIcon from "assets/img/Cursor.png";
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;
export const ModalOverlay = styled.div`
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  top: 70px;
  right: 0px;
  z-index: 1220;
  width: auto;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 1200;
  }
`;

export const ModalContent = styled.div<{ isOpen: boolean }>`
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  width: auto;
  border: 8px solid ${COLORS.Paua}4d;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  padding: 40px;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0px;
  }
  /* animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${fadeIn} 0.3s ease-out
        `
      : css`
          ${fadeOut} 0.3s ease-out
        `}; */
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 70;

  // TODO: align css styles with the rest of the project
  & svg.hideLeft {
    width: ${SIZE.max40};
    height: ${SIZE.max40};
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
  & svg.close {
    display: none;
    @media screen and (min-width: 1024px) {
      display: inline-block;
      width: ${SIZE.max40};
      height: ${SIZE.max40};
    }
  }
  position: relative;
  margin-bottom: ${MARGIN.max24};
`;

export const CloseButton = styled.button<{ biggerCursor?: boolean }>`
  background: none;
  border: none;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
`;

export const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

export const OptionButton = styled.button<{
  biggerCursor?: boolean;
  isSelected?: boolean;
}>`
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.semiBold};
  background: ${({ isSelected }) =>
    isSelected ? COLORS.Solitude : "transparent"};
  border: ${({ isSelected }) =>
    isSelected ? "1px solid " + COLORS.Paua : "2px"};
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  transition: all 0.3s ease-out;
  width: 160px;
  height: 99px;
  &:hover {
    background: ${COLORS.Solitude};
    transition: 0.3s ease-in;
  }
  img {
    margin-bottom: 8px;
  }
`;

export const ResetButtonWrapper = styled.div`
  width: ${SIZE.max200};
  height: ${SIZE.max40};
`;

export const Icon = styled.img``;
