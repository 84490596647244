import React, { useCallback, useEffect } from "react";
// ASSETS

import SendButton from "assets/svg/ChatSend.svg";
import BotHead from "assets/svg/BotHead.svg";
import ArrowLeft from "assets/svg/ArrowDown.svg";

// STYLES
import {
  BackToHomeButton,
  BackToHomeImage,
  BackToHomeWrapper,
  ChatBox,
  ChatBoxPromo,
  ChatBoxRow,
  ChatIcon,
  ChatText,
  Column,
  InputWrapper,
  MainWrapper,
  MoreLink,
} from "./ChatBotStyled";
import { Square } from "@mui/icons-material";

// LIBRARIES
import { useTranslation } from "react-i18next";

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import partsService from "../../services/partsService";

// REDUX

import { useSelector } from "react-redux";
import { selectLanguage } from "slices/LanguageSlice/languageSlice";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import InputAtom from "components/Atoms/ChatBoxAtom/InputAtom";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChatButton,
  InputLabel,
} from "components/Atoms/ChatBoxAtom/InputAtomStyled";
import Chatter from "assets/svg/Chatter.svg";
import LogoDSU from "assets/svg/LogoDsu.svg";
import { Img } from "view/Contact/FAQ/AccordionComponent/AccordionComponentStyled";
import ChatTyping from "components/Atoms/Lotties/ChatTyping";
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";

const ChatBot = () => {
  // PROPERTIES
  const location = useLocation();
  const paramConverse = location?.state?.message || "";

  // LIBRARY CONSTANTS

  const currentLanguage = useSelector(selectLanguage);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [conversation, setConversation] = React.useState([]);
  const [sending, setSending] = React.useState(false);

  // STATE CONSTANTS

  useEffect(() => {
    return () => document.body.scrollTo(0, 0);
  }, []);
  // API REQUESTS
  useEffect(() => {
    if (conversation.length === 0) {
      doConverse(paramConverse);
    }
    //eslint-disable-next-line
  }, [paramConverse]);

  useEffect(() => {
    const elem = document.body;
    elem.scrollTop = elem.scrollHeight;
  }, [conversation]);

  const doConverse = async (msg: string) => {
    if (msg.length > 0) {
      const converse = [...conversation, { role: "user", content: msg }];
      setConversation([
        ...converse,
        { role: "assistant", image: <ChatTyping width={50} height={20} /> },
      ]);
      partsService.sendMessage(converse).then((response) => {
        if (response) {
          setConversation(() => [...converse, response]);
          setSending(false);
        }
      });
    }
  };

  const submitForm = useCallback(
    (event) => {
      event.preventDefault();
      if (message.length === 0 || sending) {
        return false;
      }
      setSending(true);
      const msg = message;

      setMessage("");

      doConverse(msg);
    },
    //eslint-disable-next-line
    [message, conversation, sending]
  );

  // LIFE CYCLE

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    //eslint-disable-next-line
  }, []);

  // EVENT HANDLERS

  return (
    <MainWrapper>
      <Column>
        <BackToHomeWrapper onClick={() => navigate("/")}>
          <BackToHomeImage src={ArrowLeft} alt="backIcon" />
          <BackToHomeButton>
            <CustomText
              text={t("view.chatBot.back")}
              type={TextType.H4fix}
              color={COLORS.Paua}
            />
          </BackToHomeButton>
        </BackToHomeWrapper>
      </Column>
      <Column>
        <ChatBox id={"chat-box"}>
          {conversation.length > 0 ? (
            conversation.map((el: any, index: number) => (
              <ChatBoxRow key={index}>
                <ChatIcon>
                  <Img
                    src={el.role === "user" ? Chatter : LogoDSU}
                    alt="converser"
                  />
                </ChatIcon>
                <ChatText>
                  <CustomText
                    type={TextType.H4bold18px}
                    text={
                      el.role === "user"
                        ? t("view.chatBot.you")
                        : t("view.chatBot.beprepared")
                    }
                  />
                  {el.content !== undefined ? (
                    <StrapiTreeText
                      spell={index === conversation.length - 1 ? 10 : 0}
                      useMarkup={true}
                      text={[
                        {
                          type: "paragraph",
                          children: [{ type: "text", text: el.content }],
                        },
                      ]}
                    />
                  ) : (
                    el.image
                  )}
                </ChatText>
              </ChatBoxRow>
            ))
          ) : (
            <ChatBoxPromo>
              <Img src={LogoDSU} alt="converser" />
              <CustomText
                type={TextType.H3}
                color={COLORS.Cinnabar}
                text={t("view.chatBot.beprepared")}
              />
              <CustomText
                type={TextType.H6}
                color={COLORS.Paua}
                text={t("view.chatBot.subtitle")}
              />
            </ChatBoxPromo>
          )}
        </ChatBox>
      </Column>
      <Column>
        <InputWrapper onSubmit={submitForm}>
          <InputAtom
            startAdornment={<Img src={BotHead} alt="chatHead" />}
            endAdornment={
              <ChatButton disabled={sending}>
                {sending ? (
                  <Square
                    style={{
                      width: "16px",
                      height: "16px",
                      margin: "-2px 0 -1.5px 0px",
                    }}
                  />
                ) : (
                  <ButtonAtom icon={SendButton} type={ButtonType.ChatButton} />
                )}
              </ChatButton>
            }
            showLabel={true}
            label={""}
            isMultiLine={true}
            error={false}
            value={message}
            onChange={(event: any) => {
              document.getElementById("chat-box").style.paddingBottom =
                event.target.offsetHeight + "px";
              setMessage(event.target.value);
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter" && !e.shiftKey && !sending) {
                submitForm(e);
              }
            }}
          />
        </InputWrapper>
        <InputLabel>
          {t("view.chatBot.disclaimer")}

          <MoreLink href={"/declinare-de-responsabilitate"}>
            {" "}
            {t("view.chatBot.more")}
          </MoreLink>
        </InputLabel>
      </Column>
    </MainWrapper>
  );
};

export default ChatBot;
