// LIBRARIES
// LIBRARIES
import styled from "styled-components";

// MISC
import { FONT_SIZE, SIZE, DEVICE_TYPE } from "constants/variables";

// MISC

export const Title = styled.h6`
  font-size: ${FONT_SIZE.max24};
  color: #333;
  align-self: center;
  margin-bottom: 5px;
`;

export const PreloaderWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: rgba(29, 29, 38, 0.75);
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const MapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100dvh;
  position: relative;
`;

export const InterestPointsMenu = styled.ul`
  top: 15%;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  height: 6em;
  text-transform: capitalize;
  font-size: 0.8em;
  font-weight: bold;
  flex-wrap: wrap;
  z-index: 2;

  > li {
    margin: ${SIZE.max10};
  }

  @media (min-width: 769px) {
    top: auto;
    left: 0;
    bottom: 5em;
    width: 100%;
  }
  @media (min-width: 1025px) {
    left: 30%;
    top: 10%;
    width: 40%;
  }
`;
export const CaravanWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: ${SIZE.max84};
  bottom: ${SIZE.max84};
  z-index: 150;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    left: 0;
    width: 100%;
    align-items: flex-end;
    bottom: -1%;
  }
`;
