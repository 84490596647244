// LIBRARIES
import styled from "styled-components";

// MISC
import { IProps } from "components/Atoms/ImgAtom/ImgAtomModel";

export const ImgContainer = styled.span<IProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img<IProps>`
  width: 100%;
  height: ${(props) => props.height || "auto"};
`;
