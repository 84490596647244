export const COLORS = {
  MoodyBlue: "#6C63FF",
  NeonCarrot: "#E68A2E",
  TangerineYellow: "#FFCC00",
  AphroditeAqua: "#45E6B0",
  BeautyBush: "#E6B3B3",
  SunsetOrange: "#FF5353",
  Cinnabar: "#E62E2E",
  MandarianOrange: "#991F1F",
  BlackRussian: "#1D1D26",
  WhiteSmoke: "#F5F5F5",
  Solitude: "#E6E7EB",
  Paua: "#A1A1B3",
  AbsoluteWhite: "#FFFFFF",
};

export const DEVICE_SIZE = {
  GALAXY_FOLD: "280px",
  MOBILE: "480px",
  TABLET_MIN: "600px",
  TABLET_MED: "1024px",
  TABLET_MAX: "1279px",
  DESKTOP: "1280px",
  NONE: "none",
};

export const FONT_FAMILY = {
  poppins: "Poppins",
};

export const FONT_WEIGHT = {
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
};
export const FONT_SIZE = {
  max14: "clamp(12px, 0.75vw, 14px)",
  fix14: "14px",
  max16: "clamp(14px, 0.83vw, 16px)",
  fix16: "16px",
  max18: "clamp(16px, 0.93vw, 18px)",
  fix18: "18px",
  max20: "clamp(18px, 1.04vw, 20px)",
  fix20: "20px",
  max24: "clamp(18px, 1.25vw, 24px)",
  fix24: "24px",
  max32: "clamp(24px, 1.66vw, 32px)",
  fix32: "32px",
  max40: "clamp(32px, 2.08vw, 40px)",
  fix40: "40px",
  max60: "clamp(45px, 3.7vw, 60px)",
  max80: "clamp(60px, 5vw, 80px)",
  max80large: "clamp(32px, 5vw, 80px)",
  max90: "clamp(40px, 5.2vw, 90px)",
};

export const ROW_SPACING = {
  max100: "clamp(65px,5,100px)",
  max95: "clamp(60px,4.7,95px)",
  max90: "clamp(55px,4.5,90px)",
  max85: "clamp(48px,4.40,85px)",
  max70: "clamp(65, 3.9vw, 70px)",
  max60: "clamp(55px, 3.7vw, 60px)",
  max55: "clamp(50px, 2.8vw, 55px)",
  fix55: "55px",
  max48: "clamp(40px, 2.4vw, 40px)",
  fix48: "48px",
  max38: "clamp(28px, 1.9vw, 38px)",
  fix38: "38px",
  max35: "clamp(26px, 1.8vw, 35px)",
  fix35: "35px",
  max30: "clamp(20px, 1.4vw, 28px)",
  fix30: "30px",
  max24: "clamp(18px, 1.2vw, 24px)",
  fix24: "24px",
  max20: "clamp(16px, 1.0vw, 20px)",
  fix20: "20px",
  max18: "clamp(14px, 0.9vw, 18px)",
  fix18: "18px",
};

export const BORDER_RADIUS = {
  radius10: "10px",
  radius16: "16px",
  radius18: "18px",
  radius21: "21px",
  radius24: "24px",
  radius32: "32px",
  radius38: "38px",
  radius40: "40px",
  radius48: "48px",
};

export const SIZE = {
  max8: "clamp(6px, 0.5.2vw, 8px)",
  max10: "clamp(8px, 0.45vw, 10px)",
  max16: "clamp(14px, 0.83vw, 16px)",
  max24: "clamp(16px, 1.25vw, 24px)",
  max32: "clamp(24px, 1.66vw, 32px)",
  fix32: "32px",
  max40: "clamp(30px, 2.1vw, 40px)",
  fix42: "42px",
  max55: "clamp(30px, 2.8vw, 55px)",
  card60: "clamp(50px, 3.125vw, 60px)" /* it's special for DashboardCards*/,
  max60: "clamp(30px, 3.1vw, 60px)",
  max65: "clamp(35px, 3.1vw, 65px)",
  max84: "clamp(32px, 4.37vw, 84px)",
  max100: "clamp(50px, 6vw, 100px)", //size for GuideCardAtom icon
  max104: "clamp(50px, 5.41vw, 104px)",
  fix104: "104px",
  max120: "clamp(100px, 8vw, 120px)",
  max160: "clamp(100px, 8.3vw, 160px)",
  max180: "clamp(160px, 9.792vw, 188px)" /* it's special for DashboardCards*/,
  max200: "clamp(130px, 10vw, 200px)",
  max280: "clamp(170px, 15vw, 280px)",
  max320: "clamp(160px, 16.5vw, 320px)",
  max360: "clamp(300px, 18vw, 360px)" /* it's special for Cards*/,
  max390: "clamp(326px, 20.417vw, 392px)" /* it's special for DashboardCards*/,
  max400: "clamp(260px, 20vw, 400px)",
  max480: "clamp(300px, 25vw, 480px)",
  max550: "clamp(350px, 35vw, 550px)",
  max600: "clamp(375px, 37.5vw, 600px)",
  max650: "clamp(400px, 40vw, 650px)",
  max720: "clamp(450px, 42vw, 720px)",
  max800: "clamp(500px, 45vw, 800px)",
  max867: "clamp(326px, 45.156vw, 867px)",
  max900: "clamp(600px, 50vw, 900px)",
  max1200: "clamp(768px, 50vw, 960px)",
};

export const MARGIN = {
  max4: "4px",
  max6: "6px",
  max8: "8px",
  max10: "10px",
  max12: "12px",
  max14: "14px",
  max16: "16px",
  max20: "20px",
  max24: "24px",
  max32: "32px",
  max38: "38px",
  max40: "40px",
  max48: "48px",
  max50: "50px",
  max56: "56px",
  max60: "60px",
  max80: "80px",
  max100: "100px",
  max120: "120px",
  max140: "140px",
  max160: "160px",
  max180: "180px",
};

export const DEVICE_TYPE = {
  mobile: "767px",
  mobileHeader: "1024px",
  tablet: "1300px",
};
