import React, { useEffect, useRef } from "react";

// ASSETS

// STYLES
import {
  LayoutContainer,
  HeaderWrapper,
  ContentContainer,
  FooterWrapper,
} from "./LayoutWrapperStyled";

// LIBRARIES
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// MISC
import { ILayoutContext } from "./LayoutWrapperModel";
import { selectLanguage } from "slices/LanguageSlice/languageSlice";

// COMPONENTS
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import HeaderMobile from "components/HeaderMobile/HeaderMobile";

// CONFIGURATION
const LayoutWrapper = (props: ILayoutContext) => {
  const { hasHeader = true } = props;

  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS
  const location = useLocation();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1280 });

  const { i18n } = useTranslation();

  // STATE CONSTANTS
  const isSingleGuide =
    /\/ghiduri\/[^]+/.test(location.pathname) ||
    /\/campanii\/[^]+/.test(location.pathname) ||
    /\/pregateste-te\/[^]+/.test(location.pathname) ||
    location.pathname.includes("/pregateste-ti-apropiatii");

  const isGuidesPath =
    location.pathname.includes("/harta") ||
    location.pathname === "/ghiduri" ||
    location.pathname === "/campanii";

  // Select the current language from the Redux store
  const currentLanguage = useSelector(selectLanguage);

  // LIFE CYCLE
  useEffect(() => {
    setTimeout(() => {
      topOfPageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 0);
  }, [pathname]);

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    //eslint-disable-next-line
  }, []);

  // EVENT HANDLERS

  const topOfPageRef = useRef(null);

  const handleScrollToTop = () => {
    topOfPageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <LayoutContainer ref={topOfPageRef}>
      {hasHeader && (
        <HeaderWrapper isSingleGuide={isSingleGuide}>
          {isMobile ? (
            <HeaderMobile handleScrollToTop={handleScrollToTop} />
          ) : (
            <Header handleScrollToTop={handleScrollToTop} />
          )}
        </HeaderWrapper>
      )}
      <ContentContainer isGuidesPath={isGuidesPath}>
        <Outlet />
      </ContentContainer>
      {!isGuidesPath && (
        <FooterWrapper>
          <Footer handleScrollToTop={handleScrollToTop} />
        </FooterWrapper>
      )}
    </LayoutContainer>
  );
};

export default LayoutWrapper;
