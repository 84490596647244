// LIBRARIES
import styled from "styled-components";
//MISC

import { COLORS, BORDER_RADIUS } from "constants/variables";

export const Pill = styled.div<{ status?: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: center;
  border: none;
  border-radius: ${BORDER_RADIUS.radius10};
  width: auto;
  height: 100% !important;
  background-color: ${(props) =>
    props.status ? COLORS.AphroditeAqua : COLORS.Cinnabar};
  padding: 4px 8px;
`;
