// GuideCardAtom.tsx
import React from "react";

// ASSETS

// STYLES
import {
  CardWrapper,
  CenterContainer,
  LogoContainer,
  LogoImage,
  TitleContainer,
  LinkWrapper,
} from "./PartnerCardStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";

// MISC
import { IProps } from "./PartnerCardModel";
import { TextType } from "../CustomText/CustomTextModel";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";
import CustomText from "../CustomText/CustomText";

// COMPONENTS

const PartnerCard = (props: IProps) => {
  // PROPERTIES
  const { img, title, text, link } = props;

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS
  const [titleExpanded, setTitleExpanded] = React.useState(false);
  const [contentExpanded, setContentExpanded] = React.useState(false);
  const { t } = useTranslation();

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const toggleExpandTitle = () => {
    setTitleExpanded(!titleExpanded);
  };
  const toggleExpandContent = () => {
    setContentExpanded(!contentExpanded);
  };

  return (
    <CardWrapper isExpanded={titleExpanded || contentExpanded}>
      <LogoContainer>
        <LogoImage src={img} alt="Sigla parteneri" />
        <TitleContainer
          titleExpanded={titleExpanded}
          onClick={toggleExpandTitle}
        >
          <CustomText text={title} type={TextType.P1bold} />
        </TitleContainer>
      </LogoContainer>
      <CenterContainer
        contentExpanded={contentExpanded}
        onClick={toggleExpandContent}
      >
        <CustomText text={text} type={TextType.P1} />
        {link && (
          <LinkWrapper href={link} target="_blank" biggerCursor={biggerCursor}>
            <CustomText
              text={t("atoms.partnerCard.website")}
              type={TextType.P2fixbold}
            />
          </LinkWrapper>
        )}
      </CenterContainer>
    </CardWrapper>
  );
};

export default PartnerCard;
