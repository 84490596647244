//LIBRARY
import styled from "styled-components";
//MISC
import {
  COLORS,
  BORDER_RADIUS,
  SIZE,
  MARGIN,
  DEVICE_TYPE,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
  @media (max-width: ${DEVICE_TYPE.tablet}) and (min-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-top: ${MARGIN.max160};
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-top: ${MARGIN.max120};
  }
`;
export const GridContainer = styled.div`
  display: grid;
  gap: ${SIZE.max24};
  grid-template-columns: repeat(2, 1fr) auto;
  @media (max-width: ${DEVICE_TYPE.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    grid-template-columns: 1fr;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
  height: auto;
  max-height: 703px;
`;

export const ThirdColumnCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 218px;
  height: 100%;
  min-height: 128px;
  width: 22.396vw;
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  padding: ${SIZE.max40};
  @media (max-width: ${DEVICE_TYPE.tablet}) {
    width: 100%;
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
  }
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  padding: ${SIZE.max40};
  width: 100%;
  height: 100%;
  background: ${COLORS.WhiteSmoke};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
  }
`;

export const RowTablet = styled.div`
  display: flex;
  align-items: stretch;
  gap: ${SIZE.max24};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const DescribeWrapper = styled.div`
  margin-bottom: ${MARGIN.max40};
  height: 166px;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-bottom: ${MARGIN.max24};
    height: 90px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 42px;
  margin-top: auto;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-top: 40px;
  }
`;
export const Icon = styled.img`
  display: block;
  width: 36px;
  height: auto;
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: ${MARGIN.max40};
  right: ${MARGIN.max40};
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${MARGIN.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-bottom: ${MARGIN.max16};
  }
`;

export const FormularTextWrapper = styled.div`
  margin-bottom: ${MARGIN.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-bottom: ${MARGIN.max16};
  }
`;

export const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-bottom: ${SIZE.max84};
`;

export const MainWrapper = styled.div``;

export const StyledLink = styled.a`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  text-decoration: none;
  color: inherit;
`;
