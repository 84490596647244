import React, { useState } from "react";

// ASSETS
import XsignGrey from "assets/svg/XSignGrey.svg";
import bellSelected from "assets/svg/BellIconSelected.svg";
import bellIcon from "assets/svg/BellIcon.svg";
import mapIcon from "assets/svg/MapIcon.svg";
import mapIconSelected from "assets/svg/MapIconSelected.svg";
import phoneIcon from "assets/svg/PhoneIcon.svg";
import phoneIconSelected from "assets/svg/PhoneIconSelected.svg";
import RightRedIcon from "assets/svg/RightRedIcon.svg";
import manIcon from "assets/svg/ManIcon.svg";
import manIconSelected from "assets/svg/ManIconSelected.svg";
// STYLES
import {
  HamburgerMenuComponentContainer,
  CloseButton,
  Xbutton,
  ButtonsBarContainer,
  HeaderComponent,
  CirclePositionMiddle,
  CirclesMiddleLeftContainer,
  CardBody,
  Button,
  Icon,
  LanguageWrapper,
} from "./HamburgerMenuComponentStyled";

// LIBRARIES

import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

// MISC
import { UIState, IButtons } from "./HamburgerMenuComponentModel";
import { IconButtonAtomProps } from "components/Atoms/IconButtonAtom/IconButtonAtomModel";
import { COLORS } from "constants/variables";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import NotificationModal from "components/Atoms/ButtonsBarAtom/NotificationModal/NotificationModal";
import IconButtonAtom from "components/Atoms/IconButtonAtom/IconButtonAtom";
import AccesibilityModal from "components/Atoms/ButtonsBarAtom/AccesilibilyModal/AccesibilityModal";
import LanguageModal from "components/Atoms/ButtonsBarAtom/LanguageModal/LanguageModal";
import CustomText from "components/Atoms/CustomText/CustomText";

const HamburgerMenuComponent: React.FC<UIState> = (props) => {
  // PROPERTIES
  const { isOpen, onOpen } = props;

  // STATE CONSTANTS
  const sizes = ["380px", "305px", "230px", "150px", "90px"];
  const colors = {
    solitude: COLORS.Solitude,
    whiteSmoke: COLORS.WhiteSmoke,
  };
  const [uiState, setUiState] = useState<IButtons>({
    selectedIconIndex: null,
    isModalOpen: false,
    isNotificationModalOpen: false,
  });
  const [isLanguageModalOpen, setIsLanguageModalOpen] =
    useState<boolean>(false);
  const iconsList: IconButtonAtomProps[] = [
    {
      id: 0,
      icon: mapIcon,
      isSelected: uiState.selectedIconIndex === 0,
      iconSelected: mapIconSelected,
      shouldNavigateOn: true,
      path: "/harta",
    },
    {
      id: 1,
      icon: bellIcon,
      isSelected: uiState.selectedIconIndex === 1,
      iconSelected: bellSelected,
      shouldNavigateOn: false,
    },
    {
      id: 2,
      icon: manIcon,
      isSelected: uiState.selectedIconIndex === 2,
      iconSelected: manIconSelected,
      shouldNavigateOn: false,
    },
    {
      id: 3,
      icon: phoneIcon,
      isSelected: uiState.selectedIconIndex === 3,
      iconSelected: phoneIconSelected,
      shouldNavigateOn: true,
      path: "/contact",
    },
  ];
  const alertsData = [
    {
      id: "1",
      title:
        "Alertă ANM: Cod Galben de caniculă severă în județele Caraș-Severin și Hunedoara",
      subtitle: "În urmă cu 2 ore",
    },
    {
      id: "2",
      title:
        "Alertă ANM: Cod Galben de caniculă severă în județele Caraș-Severin și Hunedoara",
      subtitle: "În urmă cu 2 ore",
    },
    {
      id: "1",
      title:
        "Alertă ANM: Cod Galben de caniculă severă în județele Caraș-Severin și Hunedoara",
      subtitle: "În urmă cu 2 ore",
    },
    {
      id: "1",
      title:
        "Alertă ANM: Cod Galben de caniculă severă în județele Caraș-Severin și Hunedoara",
      subtitle: "În urmă cu 2 ore",
    },
    {
      id: "1",
      title:
        "Alertă ANM: Cod Galben de caniculă severă în județele Caraș-Severin și Hunedoara",
      subtitle: "În urmă cu 2 ore",
    },
    {
      id: "1",
      title:
        "Alertă ANM: Cod Galben de caniculă severă în județele Caraș-Severin și Hunedoara",
      subtitle: "În urmă cu 2 ore",
    },
    {
      id: "1",
      title:
        "Alertă ANM: Cod Galben de caniculă severă în județele Caraș-Severin și Hunedoara",
      subtitle: "În urmă cu 2 ore",
    },

    // ... other alerts
  ];

  // LIBRARY CONSTANTS
  const location = useLocation();
  const isHomePath = location.pathname === "/";
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleIconClick = (iconProps: IconButtonAtomProps) => {
    setUiState({
      selectedIconIndex: iconProps.id,
      isModalOpen: iconProps.id === 2,
      isNotificationModalOpen: iconProps.id === 1,
      shouldNavigateOn: iconProps.shouldNavigateOn,
      path: iconProps.path,
    });
    if (iconProps.shouldNavigateOn) {
      navigate(iconProps.path);
      onOpen();
    }
  };
  const handleCloseLanguage = () => {
    setIsLanguageModalOpen(false);
  };
  const handleOpenLanguage = () => {
    setIsLanguageModalOpen(true);
  };

  const closeNotificationModal = () =>
    setUiState((prev) => ({
      ...prev,
      isNotificationModalOpen: false,
      selectedIconIndex: null,
    }));

  const closeAccesibilityModal = () =>
    setUiState((prev) => ({
      ...prev,
      isModalOpen: false,
      selectedIconIndex: null,
    }));

  return (
    <>
      {isOpen && (
        <>
          <HamburgerMenuComponentContainer>
            <HeaderComponent>
              <ButtonsBarContainer>
                {iconsList.map((iconProps, index) => (
                  <IconButtonAtom
                    key={index}
                    {...iconProps}
                    isSelected={
                      iconProps.shouldNavigateOn
                        ? window.location.pathname === iconProps.path
                        : uiState.selectedIconIndex === index
                    }
                    onClick={() => handleIconClick(iconProps)}
                  />
                ))}
              </ButtonsBarContainer>
              {isHomePath && (
                <LanguageWrapper onClick={handleOpenLanguage}>
                  <CustomText
                    text={i18n.language.toUpperCase()}
                    type={TextType.H4Logo}
                    color={COLORS.BlackRussian}
                  />
                </LanguageWrapper>
              )}
              <CloseButton onClick={onOpen} biggerCursor={biggerCursor}>
                <Xbutton src={XsignGrey} />
              </CloseButton>
            </HeaderComponent>
            <NotificationModal
              isOpen={uiState.isNotificationModalOpen}
              onClose={closeNotificationModal}
              alerts={alertsData}
            />
            {isHomePath && (
              <LanguageModal
                isOpenProps={isLanguageModalOpen}
                setIsOpenProps={handleCloseLanguage}
                options={[
                  { label: "Romana", value: "ro" },
                  { label: "English", value: "en" },
                ]}
              />
            )}
            <AccesibilityModal
              isOpen={uiState.isModalOpen}
              onClose={closeAccesibilityModal}
            />
            <CardBody>
              <Button
                onClick={() => {
                  navigate("/ghiduri");
                  onOpen();
                }}
                biggerCursor={biggerCursor}
              >
                {t("components.hamburgerMenu.guides")}{" "}
                <Icon src={RightRedIcon} alt="arrowIcon" />
              </Button>
              <Button
                onClick={() => {
                  navigate("/pregateste-te");
                  onOpen();
                }}
                biggerCursor={biggerCursor}
              >
                {t("components.hamburgerMenu.getReady")}{" "}
                <Icon src={RightRedIcon} alt="arrowIcon" />
              </Button>
              <Button
                onClick={() => {
                  navigate("/campanii");
                  onOpen();
                }}
                biggerCursor={biggerCursor}
              >
                {t("components.hamburgerMenu.campaigns")}
                <Icon src={RightRedIcon} alt="arrowIcon" />
              </Button>
              <Button
                onClick={() => {
                  navigate("/despre");
                  onOpen();
                }}
                biggerCursor={biggerCursor}
              >
                {t("components.hamburgerMenu.about")}{" "}
                <Icon src={RightRedIcon} alt="arrowIcon" />
              </Button>
            </CardBody>
            <CirclePositionMiddle>
              <CirclesMiddleLeftContainer sizes={sizes} colors={colors} />
            </CirclePositionMiddle>
          </HamburgerMenuComponentContainer>
        </>
      )}
    </>
  );
};

export default HamburgerMenuComponent;
