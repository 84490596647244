import ANPDPDLogo from "assets/img/ANPDPDLogo.png";
import ANRLogo from "assets/img/ANRLogo.png";
import BancaMondialaLogo from "assets/img/BancaMondialaLogo.png";
import CivicLogo from "assets/img/CivicLogo.png";
import ElectricaLogo from "assets/img/ElectricaLogo.png";
import STSLogo from "assets/img/STSLogo.png";
import OMVLogo from "assets/img/OMVLogo.png";
import IOMLogo from "assets/img/IOMLogo.png";
import FundatiaSmurdLogo from "assets/img/FundatiaSmurdLogo.png";

export const partnersData = [
  {
    id: 1,
    title: "",
    text: "Implicarea în viitor în accesibilizarea conținutului site-ului pentru persoane cu dizabilități, specific, pentru înregistrarea ghidurilor de pe platformă în format audio, în studioul de înregistrări al asociației.",
    textEn:
      "Future involvement in making the site's content accessible for people with disabilities, specifically for recording the guides from the platform in audio format, in the association's recording studio.",
    img: ANRLogo,
    link: "https://www.anvr.ro/",
  },
  {
    id: 2,
    title: "",
    text: "Implicare în accesibilizarea conținutului site-ului pentru persoane cu dizabilități, prin mijlocirea de expertiză la nivelul instituției și al partenerilor naționali și internaționali.",
    textEn:
      "Involvement in making the site's content accessible for people with disabilities by providing expertise at the level of the institution and its national and international partners.",
    img: ANPDPDLogo,
    link: "https://anpd.gov.ro/web/",
  },
  {
    id: 3,
    title: "",
    text: "Implicarea în îmbunătățirea ghidurilor existente și sprijinul în elaborarea unor ghiduri noi au fost posibile cu sprijinul financiar al Programului Japonia - Banca pentru Integrarea DRM în Țările în curs de Dezvoltare, care este finanțat de Guvernul Japoniei și primește sprijin tehnic din partea Centrului de Gestionare a Riscurilor de Dezastre din Tokyo al Băncii Mondiale GFDRR.",
    textEn:
      "Involvement in improving existing guides and support in the development of new guides have been made possible with the financial support of the Japan Program - Bank for DRM Integration in Developing Countries, which is funded by the Government of Japan and receives technical support from the Tokyo Disaster Risk Management Center of the World Bank GFDRR.",
    img: BancaMondialaLogo,
    link: "https://www.worldbank.org/en/home",
  },
  {
    id: 4,
    title: "",
    text: "Implicare în realizarea și dezvoltarea versiunii anterioare a site-ului FiiPregătit.ro, CivicNet fiind echipa cu care am început gândirea, schițarea și lansarea FiiPregătit.ro, ca platformă națională de pregătire a populației în situații de urgență.",
    textEn:
      "Involvement in the creation and development of the previous version of the FiiPregătit.ro website, with CivicNet being the team with which we began the planning, sketching, and launching of FiiPregătit.ro, as a national platform for preparing the population for emergency situations.",
    img: CivicLogo,
    link: "https://civicnet.ro/",
  },
  {
    id: 5,
    title: "",
    text: "Implicarea Electrica în proiect constă în sprijin pentru promovarea platformei și furnizarea unor obiecte personalizate, cum ar fi rucsaci pentru situații de urgență.",
    textEn:
      "Electrica's involvement in the project consists of support for promoting the platform and providing customized items, such as emergency backpacks.",
    img: ElectricaLogo,
    link: "https://www.electrica.ro/",
  },
  {
    id: 6,
    title: "Fundația pentru SMURD",
    text: "Implicare prin mijlocirea relației cu sponsori și alți parteneri care au oferit resursele necesare construirii platformelor online ale Departamentului pentru Situații de Urgență (FiiPregătit.ro, aplicația DSU, site-ul DSU).",
    textEn:
      "Involving by mediating the relationship with sponsors and other partners who provided the necessary resources for building the online platforms of the Department for Emergency Situations (FiiPregătit.ro, the DSU app, the DSU website).",
    img: FundatiaSmurdLogo,
    link: "https://fundatiapentrusmurd.ro",
  },

  {
    id: 7,
    title: "",
    text: "Implicare în viitorul apropiat pentru traducerea ghidurilor în limbi ale minorităților naționale de pe teritoriul României, dar și în alte limbi de circulație internaționale.",
    textEn:
      "Future involvement in translating the guides into the languages of national minorities in Romania, as well as other widely spoken international languages.",
    img: IOMLogo,
    link: "https://romania.iom.int/",
  },
  {
    id: 8,
    title: "",
    text: "Implicare prin sponsorizări strategice de finanțare ale platformelor online ale DSU, inclusiv a regândirii și refacerii FiiPregătit.ro.",
    textEn:
      "Engagement through strategic sponsorship funding for DSU's online platforms, including the redesign and reconstruction of FiiPregătit.ro.",
    img: OMVLogo,
    link: "https://www.omvpetrom.com/ro",
  },
  {
    id: 9,
    title: "Serviciul de Telecomunicații Speciale",
    text: "Mulțumim pentru suportul în găzduirea domeniului și asistența în procesul de lansare. Colaborarea dumneavoastră a fost esențială.",
    textEn:
      "Thank you for your support in hosting the domain and assisting with the launch process. Your collaboration has been essential.",
    img: STSLogo,
    link: "https://sts.ro/",
  },
];
