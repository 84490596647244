// LIBRARIES
import styled, { keyframes } from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  DEVICE_TYPE,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  MARGIN,
  SIZE,
} from "constants/variables";

const fade = keyframes`
  0% {
    opacity: 0;
  }
  12.5%{
    opacity: .25;
  }
  25% {
    opacity: .75;
  }
  37.5% {
    opacity: 1;
  }
  50%{
    opacity: 1;
  }
  62.5%{
    opacity: 1;
  }
  75% {
    opacity: .75;
  }
  87.5%{
    opacity: .25;
  }
  100% {
    opacity: 0;
  }
`;

export const BackOnTopWrapper = styled.div`
  height: 64px;
  width: 64px;
  position: fixed;
  border-radius: 100%;
  background-color: ${COLORS.Solitude};
  bottom: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
`;

export const BackOnTopImg = styled.img`
  height: 22px;
  width: auto;
  transform: rotate(-90deg);
`;

export const SingleGuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const HeaderImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 511px;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: 400px;
  }
`;

export const ScrollTargetWrapper = styled.div`
  padding-top: ${MARGIN.max120};
  margin-top: -${MARGIN.max120};
  display: block;

  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding-top: ${MARGIN.max100};
    margin-top: -${MARGIN.max100};
    display: block;
  }
`;

export const HeaderGuide = styled.div`
  width: 100%;
  min-height: 80px;
  margin-top: -40px;
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  display: flex;
  flex-direction: row;
  padding: 0 ${SIZE.max84};
  overflow-x: auto;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: ${MARGIN.max40};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    border-radius: 0px;
    gap: ${MARGIN.max24};
  }
`;

export const HeaderCell = styled.div`
  background-color: transparent;
  width: auto;
  max-width: 110px;
  padding-top: ${MARGIN.max20};
  cursor: pointer;
  border-bottom: ${({ isSelected }) =>
    isSelected ? "4px solid " + COLORS.Cinnabar : "none"};
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${MARGIN.max40};
  padding: 0px ${SIZE.max84};
  position: sticky;
  gap: ${MARGIN.max40};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-top: ${MARGIN.max24};
    justify-content: center;
    flex-direction: column;
    padding: 0;
    gap: ${MARGIN.max24};
  }
`;

export const FirstColumn = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  top: 120px;
  z-index: 101;
  position: sticky;
  height: 100%;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    top: 0;
    position: relative;
    height: auto;
    padding: 0 ${MARGIN.max24};
  }
`;

export const InsideFirstColumn = styled.div`
  position: sticky;
  height: auto;
`;

export const SecondColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    height: auto;
    gap: ${MARGIN.max16};
  }
`;

export const ThirdColumn = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  z-index: 1001;
  height: 100%;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    top: 0;
    position: relative;
    height: auto;
    padding: 0 ${MARGIN.max32};
  }
`;

export const InsideThirdColumn = styled.div`
  position: sticky;
  height: auto;
`;

export const BackToGuidesButton = styled.button`
  background-color: transparent;
  border: none;
  margin-bottom: ${MARGIN.max6};
  cursor: pointer;
`;

export const BackToGuidesWrapper = styled.div`
  margin-bottom: ${MARGIN.max16};
  cursor: pointer;
`;

export const BackToGuidesImage = styled.img`
  margin-right: 8px;
  width: 16px;
  height: 10px;
  transform: rotate(90deg);
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${MARGIN.max16};
`;

export const AudioButtonWrapper = styled.div`
  margin-bottom: ${MARGIN.max16};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-bottom: ${MARGIN.max4};
  }
`;

export const LeftCard = styled.div`
  border-radius: ${BORDER_RADIUS.radius24};
  background-color: ${COLORS.WhiteSmoke};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: ${MARGIN.max32};
`;

export const LeftCardTitle = styled.div`
  margin-bottom: ${MARGIN.max16};
`;

export const CenterCardTitle = styled.div`
  margin-bottom: ${SIZE.max24};
`;

export const LeftCardChapter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const RedCircle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${COLORS.Cinnabar};
  border-radius: 50%;
`;

export const RedCircleWrapper = styled.div`
  margin-top: ${MARGIN.max10};
  margin-right: ${MARGIN.max8};
`;

export const ContentCard = styled.div`
  width: 100%;
  height: auto;
  padding: ${MARGIN.max40};
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max18};
  font-weight: ${FONT_WEIGHT.regular};
  & img {
    width: 100%;
  }
  & a.guideLink {
    text-decoration: none;
    color: ${COLORS.Cinnabar};
  }
`;

export const SocialMediaCard = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  padding: ${MARGIN.max16} ${MARGIN.max32};
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  margin-bottom: ${MARGIN.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-bottom: ${MARGIN.max12};
  }
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${MARGIN.max24};
`;

export const SocialMediaIcon = styled.img`
  height: 24px;
  width: auto;
  cursor: pointer;
`;

export const CopyToClipboardWrapper = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
`;

export const OtherGuidesCard = styled.div`
  width: 100%;
  height: auto;
  padding: ${MARGIN.max32};
  gap: ${MARGIN.max24};
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS.radius24};
  background-color: ${COLORS.WhiteSmoke};
`;

export const ImgStyled = styled.img`
  width: 100%;
`;

export const OtherChapters = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: ${MARGIN.max12};
  align-items: center;
  cursor: pointer;
`;

export const CopyToClipboardTooltip = styled.div`
  width: ${SIZE.max200};
  position: absolute;
  background-color: ${COLORS.Solitude};
  padding: ${MARGIN.max16};
  border-radius: ${BORDER_RADIUS.radius16};
  transform: translate(-50%, -50%);
  outline: 2px solid ${COLORS.Paua}80;
  animation: ${fade} 2s ease-in-out;
`;

export const SkeletonWrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${MARGIN.max14};
  margin-top: ${MARGIN.max10};
`;

export const SkeletonWrapperChapter = styled.div`
  width: 100%;
`;

export const SkeletonWrapperOtherChapter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${MARGIN.max10};
`;
