//LIBRARIES
import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

// ASSETS

//REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// STYLES
import {
  CarouselItem,
  CarouselItemCampaign,
  CarouselSkeletonItem,
  ScrollContainer,
  ScrollContainerCampaign,
} from "./CampaignCarouselStyled";

// MISC
import { Pagination, EffectCreative, Navigation } from "swiper/modules";
import { ICampaigneCardType } from "components/Atoms/CampaignCard/CampaignCardModel";
import { ICampaignCarouselType, IProps } from "./CampaignCarouselModel";
import { BORDER_RADIUS, COLORS } from "constants/variables";

// COMPONENTS
import CampaignCard from "components/Atoms/CampaignCard/CampaignCard";
import modRewrite from "../../locales/modRewrite";

// CONFIGURATION

const CampaignCarousel = (props: IProps) => {
  // PROPERTIES
  const { data, type = ICampaignCarouselType.HOME } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const navigate = useNavigate();

  // STATE CONSTANTS
  const campaignsSkeleton = new Array(2).fill(null);
  // LIFE CYCLE

  // EVENT HANDLERS

  return type === ICampaignCarouselType.HOME ? (
    <ScrollContainer>
      <Swiper
        pagination={true}
        modules={[Pagination, Navigation, EffectCreative]}
        className="mySwiper"
        spaceBetween={25} // Changed to numerical value for consistency
        effect="creative"
        grabCursor={true}
        touchReleaseOnEdges={true}
        loop={true}
        keyboard={true}
        creativeEffect={{
          prev: { translate: [0, 0, -400], opacity: 0.5 },
          next: { translate: ["100%", 0, 0], opacity: 0.5 },
        }}
      >
        {data.length
          ? data.map((campaign) => (
              <SwiperSlide key={campaign.id}>
                <CarouselItem biggerCursor={biggerCursor} tabIndex={0}>
                  <CampaignCard
                    type={ICampaigneCardType.ORIZONTAL}
                    {...campaign}
                    onCardClick={() => {
                      navigate(
                        "/campanii/" + modRewrite(campaign.title, campaign.id)
                      );
                    }}
                  />
                </CarouselItem>
              </SwiperSlide>
            ))
          : campaignsSkeleton.map((__, index) => (
              <SwiperSlide key={index}>
                <CarouselSkeletonItem type={ICampaigneCardType.ORIZONTAL}>
                  <Skeleton
                    height={"100%"}
                    width={"100%"}
                    baseColor={COLORS.WhiteSmoke}
                    highlightColor={COLORS.Solitude}
                    borderRadius={BORDER_RADIUS.radius24}
                  />
                </CarouselSkeletonItem>
              </SwiperSlide>
            ))}
      </Swiper>
    </ScrollContainer>
  ) : (
    <ScrollContainerCampaign>
      <Swiper
        pagination={false}
        modules={[Pagination]}
        className="mySwiper"
        effect="slide"
        grabCursor={true}
        touchReleaseOnEdges={true}
        loop={true}
        keyboard={true}
        slidesPerView="auto"
        centeredSlides={true}
        breakpoints={{
          1800: {
            slidesOffsetBefore: 500,
          },
          1280: {
            slidesOffsetBefore: 400,
          },
          1024: {
            slidesOffsetBefore: 250,
          },
          900: {
            slidesOffsetBefore: 200,
          },
          0: {
            slidesOffsetBefore: 0,
          },
        }}
      >
        {data.length
          ? data.map((campaign) => (
              <SwiperSlide key={campaign.id}>
                <CarouselItemCampaign biggerCursor={biggerCursor}>
                  <CampaignCard
                    type={ICampaigneCardType.VERTICAL}
                    {...campaign}
                    onCardClick={() => {
                      navigate(
                        "/campanii/" + modRewrite(campaign.title, campaign.id)
                      );
                    }}
                  />
                </CarouselItemCampaign>
              </SwiperSlide>
            ))
          : campaignsSkeleton.map((__, index) => (
              <SwiperSlide key={index}>
                <CarouselSkeletonItem type={ICampaigneCardType.VERTICAL}>
                  <Skeleton
                    height={"100%"}
                    width={"100%"}
                    baseColor={COLORS.WhiteSmoke}
                    highlightColor={COLORS.Solitude}
                    borderRadius={BORDER_RADIUS.radius24}
                  />
                </CarouselSkeletonItem>
              </SwiperSlide>
            ))}
      </Swiper>
    </ScrollContainerCampaign>
  );
};

export default CampaignCarousel;
