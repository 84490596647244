//LIBRARIES
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./uiSliceModel";
import { revertAll } from "store/store";

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    handleAccessibilityBiggerText: (state) => {
      state.accessibilityBiggerText = !state.accessibilityBiggerText;
    },
    handleAccesibilityBiggerCursor: (state) => {
      state.accessibilityBiggerCursor = !state.accessibilityBiggerCursor;
    },
    handleAccesibilityBiggerLineHeight: (state) => {
      state.accessibilityBiggerLineHeight =
        !state.accessibilityBiggerLineHeight;
    },
    handleAccesibilityBiggerLetterSpacing: (state) => {
      state.accessibilityBiggerLetterSpacing =
        !state.accessibilityBiggerLetterSpacing;
    },
    handleAccesibilityBlackAndWhite: (state) => {
      state.accessibilityBlackAndWhite = !state.accessibilityBlackAndWhite;
      if (state.accessibilityBlackAndWhite) {
        state.accessibilityInvertColors = false;
      }
    },
    handleAccesibilityInvertColors: (state) => {
      state.accessibilityInvertColors = !state.accessibilityInvertColors;
      if (state.accessibilityInvertColors) {
        state.accessibilityBlackAndWhite = false;
      }
    },
    handldeResetAll: () => initialState,
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  handleAccessibilityBiggerText,
  handleAccesibilityBiggerCursor,
  handleAccesibilityBiggerLineHeight,
  handleAccesibilityBiggerLetterSpacing,
  handleAccesibilityBlackAndWhite,
  handleAccesibilityInvertColors,
  handldeResetAll,
} = uiSlice.actions;

export const uiSelector = (state) => state.ui;
export default uiSlice.reducer;
