//LIBRARIES
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

// ASSETS
import { ReactComponent as RightRedArrow } from "assets/icons/RightRedArrow.svg";
import { ReactComponent as LeftRedArrow } from "assets/icons/LeftRedArrow.svg";

// STYLES
import {
  CarouselItem,
  ScrollContainer,
  AboutCarouselItem,
  AboutCustomSlider,
  CarouselScrollbar,
  CarouselDot,
  CarouselDotButton,
  ArrowWrapper,
} from "./CardCarouselStyled";

// LIBRARIES
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// MISC
import { SIZE } from "../../constants/variables";
import { IProps } from "./CardCarouselModel";
import { CardType } from "components/Atoms/GuideCardAtom/GuideCardAtomModel";

// COMPONENTS
import GuideCardAtom from "components/Atoms/GuideCardAtom/GuideCardAtom";
import PeopleCard from "view/About/PeopleCard/PeopleCard";

// CONFIGURATION

const CardCarousel = (props: IProps) => {
  // PROPERTIES
  const { type, data } = props;

  const CustomLeftArrow = (props) => {
    const { currentSlide, slideCount, onClick, ...arrowProps } = props;
    return (
      <ArrowWrapper tabIndex={0} onClick={() => onClick()}>
        <LeftRedArrow {...arrowProps} />
      </ArrowWrapper>
    );
  };

  const CustomRightArrow = (props) => {
    const { currentSlide, slideCount, onClick, ...arrowProps } = props;
    return (
      <ArrowWrapper tabIndex={0} onClick={() => onClick()}>
        <RightRedArrow {...arrowProps} />
      </ArrowWrapper>
    );
  };
  const settings = {
    accessibility: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    prevArrow: <CustomLeftArrow />,
    nextArrow: <CustomRightArrow />,

    responsive: [
      {
        breakpoint: 3700,
        settings: {
          arrows: true,
          centerMode: true,
          slidesToShow: 5,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 3200,
        settings: {
          arrows: true,
          centerMode: true,
          slidesToShow: 5,
          slidesToScroll: 1,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 2800,
        settings: {
          arrows: true,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 2500,
        settings: {
          arrows: true,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 2200,
        settings: {
          arrows: true,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "200px",
        },
      },

      {
        breakpoint: 1600,
        settings: {
          arrows: true,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          arrows: true,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1360,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1120,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 980,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "280px",
        },
      },
      {
        breakpoint: 940,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "260px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 850,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 780,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "160px",
        },
      },
      {
        breakpoint: 700,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "130px",
        },
      },
      {
        breakpoint: 650,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "110px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "90px",
        },
      },
      {
        breakpoint: 560,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 540,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "70px",
        },
      },
      {
        breakpoint: 520,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 460,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 440,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 420,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  const aboutSettings = {
    infinite: data?.length > 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 3700,
        settings: {
          centerPadding: "850px",
        },
      },
      {
        breakpoint: 3500,
        settings: {
          centerPadding: "800px",
        },
      },
      {
        breakpoint: 3300,
        settings: {
          centerPadding: "750px",
        },
      },
      {
        breakpoint: 3100,
        settings: {
          centerPadding: "700px",
        },
      },
      {
        breakpoint: 2900,
        settings: {
          centerPadding: "650px",
        },
      },
      {
        breakpoint: 2700,
        settings: {
          centerPadding: "600px",
        },
      },
      {
        breakpoint: 2500,
        settings: {
          centerPadding: "550px",
        },
      },
      {
        breakpoint: 2300,
        settings: {
          centerPadding: "500px",
        },
      },
      {
        breakpoint: 2100,
        settings: {
          centerPadding: "450px",
        },
      },
      {
        breakpoint: 1720,
        settings: {
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1520,
        settings: {
          centerPadding: "260px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "230px",
        },
      },
      {
        breakpoint: 1300,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "140px",
        },
      },
      {
        breakpoint: 1100,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: "250px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "180px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerPadding: "150px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          centerPadding: "135px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerPadding: "115px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
      {
        breakpoint: 510,
        settings: {
          slidesToShow: 1,
          centerPadding: "95px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerPadding: "75px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          centerPadding: "35px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerPadding: "15px",
          dots: true,
          appendDots: (dots) => (
            <CarouselScrollbar>
              <CarouselDot> {dots} </CarouselDot>
            </CarouselScrollbar>
          ),
          customPaging: () => <CarouselDotButton />,
        },
      },
    ],
  };

  // API REQUESTS

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <>
      {type === "HOME" && (
        <ScrollContainer type={type} biggerCursor={biggerCursor} tabIndex={0}>
          <Slider {...settings}>
            {data?.map((item) => (
              <CarouselItem key={item?.id}>
                <GuideCardAtom
                  {...item}
                  cardType={CardType.CarouselStyle}
                  width={SIZE.max360}
                  height={SIZE.max360}
                />
              </CarouselItem>
            ))}
          </Slider>
        </ScrollContainer>
      )}
      {type === "ABOUT" && (
        <AboutCustomSlider {...aboutSettings}>
          {data?.map((item, index) => (
            <AboutCarouselItem key={item?.id || index} type={type}>
              <PeopleCard {...item} />
            </AboutCarouselItem>
          ))}
        </AboutCustomSlider>
      )}
    </>
  );
};

export default CardCarousel;
