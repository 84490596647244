import styled from "styled-components";

//MISC
import { BORDER_RADIUS, COLORS } from "../../../constants/variables";
import cursorIcon from "assets/img/Cursor.png";

// LIBRARIES
export const IconButtonAtomContainer = styled.div``;

export const IconWrapper = styled.button<{
  isSelected: boolean;
  biggerCursor?: boolean;
}>`
  position: relative;
  border: none;
  background: ${({ isSelected }) =>
    isSelected ? COLORS.BlackRussian : COLORS.WhiteSmoke};
  border-radius: 100%;
  width: 42px;
  height: 42px;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  margin: 0 4px;
  padding: 5px;
  transition: 0.3s background-color ease-out;

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? COLORS.BlackRussian : COLORS.Solitude};
    transition: 0.3s ease-in;
  }

  &:focus {
    outline: none;
  }
`;

export const IconImage = styled.img`
  width: auto;
  height: auto;
`;

export const HoverText = styled.div<{ isMobile?: boolean }>`
  visibility: ${({ isMobile }) => (isMobile ? "hidden" : "hidden")};
  background-color: ${COLORS.BlackRussian};
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: ${COLORS.WhiteSmoke};
  padding: 8px;
  border-radius: ${BORDER_RADIUS.radius21};

  &::before {
    content: ${({ isMobile }) => (isMobile ? "none" : '""')};
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${COLORS.BlackRussian};
  }

  ${IconWrapper}:hover & {
    visibility: ${({ isMobile }) => (isMobile ? "hidden" : "visible")};
  }
`;
