// LIBRARIES
import styled from "styled-components";

// MISC
import cursorIcon from "assets/img/Cursor.png";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: ${SIZE.max24} 0;
  position: relative;
`;

export const Logo = styled.img<{ biggerCursor?: boolean }>`
  height: 50px;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  padding: 4px;
`;
export const LogoTextContainer = styled.button<{ biggerCursor?: boolean }>`
  display: flex;
  align-items: center;
  border: 0;
  border-top-right-radius: ${BORDER_RADIUS.radius32};
  border-bottom-right-radius: ${BORDER_RADIUS.radius32};
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  background-color: ${COLORS.WhiteSmoke};
  height: 50px;
`;

export const HeaderText = styled.div`
  margin: 0;
  padding: 0 ${SIZE.max10};
`;

export const LogoContainer = styled.div<{ biggerCursor?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  position: absolute;
  left: 0;
`;

export const CenterComponentWrapper = styled.div`
  height: 50px;
`;
export const ButtonsBarContainer = styled.div`
  position: absolute;
  right: 0;
`;
