// ASSETS

// STYLES
import { CirclePosition, CirclesContainer } from "./CirclesAtomStyled";
// LIBRARIES

// MISC
import { IProps } from "./CirclesAtomModel";

// REDUX

// COMPONENTS

const CirclesAtom = (props: IProps) => {
  // PROPERTIES
  const {
    containerTopPosition,
    containerBottomPosition,
    containerLeftPosition,
    containerRightPosition,
    sizes,
    colors,
    top,
    bottom,
    left,
    right,
  } = props;

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <CirclePosition
      top={containerTopPosition}
      bottom={containerBottomPosition}
      left={containerLeftPosition}
      right={containerRightPosition}
    >
      <CirclesContainer
        sizes={sizes}
        colors={colors}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
      />
    </CirclePosition>
  );
};

export default CirclesAtom;
