// LIBRARIES
import styled from "styled-components";

//MISC

import { COLORS, BORDER_RADIUS } from "../../../constants/variables";

//ASSETS

import cursorIcon from "assets/img/Cursor.png";

export const ButtonsBarWrapper = styled.div``;
export const ButtonsBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.WhiteSmoke};
  border-radius: 30px;
  width: auto;
  height: 50px;
  z-index: 10;
`;

export const WrapperLanguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  background: ${({ isSelected }) =>
    isSelected ? COLORS.BlackRussian : COLORS.WhiteSmoke};
  border-radius: 100%;
  width: 42px;
  height: 42px;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  margin: 0 4px;
  padding: 5px;
  transition: 0.3s background-color ease-out;

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? COLORS.BlackRussian : COLORS.Solitude};
    transition: 0.3s ease-in;
  }

  &:focus {
    outline: none;
  }
`;

export const HoverText = styled.div<{ isMobile?: boolean }>`
  visibility: ${({ isMobile }) => (isMobile ? "hidden" : "hidden")};
  background-color: ${COLORS.BlackRussian};
  position: absolute;
  bottom: -38px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: ${COLORS.WhiteSmoke};
  padding: 4px 8px;

  border-radius: ${BORDER_RADIUS.radius21};

  &::before {
    content: ${({ isMobile }) => (isMobile ? "none" : '""')};
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${COLORS.BlackRussian};
  }

  ${WrapperLanguage}:hover & {
    visibility: ${({ isMobile }) => (isMobile ? "hidden" : "visible")};
  }
`;
