import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
// ASSETS

import ArrowLeft from "assets/svg/ArrowDown.svg";
import facebookIcon from "assets/svg/IconFacebook.svg";
import linkedInIcon from "assets/svg/iconsLinkedIn.svg";
import shareIcon from "assets/svg/ShareIcon.svg";
import ArrowUpIcon from "assets/svg/RightRedIcon.svg";
// STYLES
import {
  SingleGuideContainer,
  HeaderImageWrapper,
  HeaderImage,
  Body,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  BackToGuidesButton,
  BackToGuidesImage,
  BackToGuidesWrapper,
  TitleWrapper,
  ContentCard,
  SocialMediaCard,
  SocialMediaIcons,
  SocialMediaIcon,
  OtherGuidesCard,
  ImgStyled,
  OtherChapters,
  InsideFirstColumn,
  InsideThirdColumn,
  BackOnTopWrapper,
  BackOnTopImg,
  LeftCard,
  OtherCampaignsContent,
  DateWrapper,
  CopyToClipboardWrapper,
  SkeletonWrapperOtherChapter,
  CopyToClipboardTooltip,
  SkeletonWrapperChapter,
} from "./SingleCampaignStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { LinkedinShareButton, FacebookShareButton } from "react-share";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { BORDER_RADIUS, COLORS, DEVICE_TYPE } from "constants/variables";
// REDUX
import coverPlaceHolder from "assets/img/cover.placeholder.svg";
import iconPlaceHolder from "assets/img/icon.placeholder.svg";
// COMPONENTSf
import CustomText from "components/Atoms/CustomText/CustomText";
import campaignService from "../../../services/campaignService";
import { baseUrl } from "../../../services/base";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";
import modRewrite from "../../../locales/modRewrite";

const SingleGuide = () => {
  const { rgxId } = useParams();
  const campaignId = rgxId.split("-").pop();
  // PROPERTIES

  // LIBRARY CONSTANTS
  const isMobile = useMediaQuery({ maxWidth: DEVICE_TYPE.mobileHeader });
  const navigate = useNavigate();
  const { t } = useTranslation();

  // STATE CONSTANTS
  const skeletonCount = 3;
  const [campaign, setCampaign] = useState<any | null>(null);
  const [randomCampaigns, setRandomCampaigns] = useState<any[]>([]);
  const [copied, setCopied] = useState(false);

  const headerImageWrapperRef = useRef(null);

  useEffect(() => {
    (async () => {
      const campaign: any = await campaignService.getCampaign(campaignId);
      if (campaign && campaign.data) {
        setCampaign(campaign.data);
      }
      const campaigns: any = await campaignService.getCampaigns();

      setRandomCampaigns(
        campaigns.data
          .filter((el: any) => el.id !== parseInt(campaignId))
          .slice()
          .sort(() => 0.5 - Math.random())
          .slice(0, 3)
      );
    })();
  }, [campaignId]);

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  const scrollToTop = () => {
    if (headerImageWrapperRef.current) {
      headerImageWrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy URL: ", err));
  };

  return (
    <SingleGuideContainer>
      <HeaderImageWrapper ref={headerImageWrapperRef}>
        <HeaderImage
          src={
            campaign !== null
              ? `${baseUrl}${campaign.attributes.cover.data.attributes.url}`
              : coverPlaceHolder
          }
          alt={"coverGuideImage"}
        />
      </HeaderImageWrapper>
      <Body>
        <FirstColumn>
          <InsideFirstColumn>
            <BackToGuidesWrapper onClick={() => navigate("/campanii")}>
              <BackToGuidesImage src={ArrowLeft} alt="backIcon" />
              <BackToGuidesButton>
                <CustomText
                  text={t("view.singleCampaign.available.campaigns")}
                  type={TextType.H4bold18px}
                  color={COLORS.Paua}
                />
              </BackToGuidesButton>
            </BackToGuidesWrapper>
            <TitleWrapper>
              {campaign !== null ? (
                <CustomText
                  text={campaign.attributes.title}
                  type={!isMobile ? TextType.H1custom : TextType.H2}
                />
              ) : (
                <Skeleton
                  width={"100%"}
                  height={"3rem"}
                  borderRadius={BORDER_RADIUS.radius21}
                  count={1}
                  baseColor={COLORS.WhiteSmoke}
                  highlightColor={COLORS.Solitude}
                />
              )}
            </TitleWrapper>
            <LeftCard>
              {campaign !== null ? (
                <CustomText
                  text={campaign.attributes.start_date
                    .split("-")
                    .reverse()
                    .join(".")}
                  type={TextType.H5fix}
                  color={COLORS.Paua}
                />
              ) : (
                <Skeleton
                  width={"100%"}
                  height={"1rem"}
                  count={1}
                  baseColor={COLORS.Solitude}
                  highlightColor={COLORS.WhiteSmoke}
                />
              )}
            </LeftCard>
          </InsideFirstColumn>
        </FirstColumn>
        <SecondColumn>
          <ContentCard tabIndex={0}>
            {campaign !== null ? (
              <StrapiTreeText text={campaign.attributes.content} />
            ) : (
              <Skeleton
                width={"100%"}
                count={15}
                baseColor={COLORS.Solitude}
                highlightColor={COLORS.WhiteSmoke}
              />
            )}
          </ContentCard>
        </SecondColumn>
        <ThirdColumn>
          <InsideThirdColumn>
            <SocialMediaCard>
              <CustomText text={"SHARE"} type={TextType.H4bold18px} />
              <SocialMediaIcons>
                <FacebookShareButton url={window.location.href}>
                  <SocialMediaIcon src={facebookIcon} alt="facebookIcon" />
                </FacebookShareButton>
                <LinkedinShareButton url={window.location.href}>
                  <SocialMediaIcon src={linkedInIcon} alt="linkedInIcon" />
                </LinkedinShareButton>
                <CopyToClipboardWrapper onClick={handleCopyToClipboard}>
                  <SocialMediaIcon src={shareIcon} alt="shareIcon" />
                  {copied && (
                    <CopyToClipboardTooltip>
                      <CustomText
                        text="Link-ul a fost copiat in clipboard"
                        type={TextType.P2}
                        color={COLORS.BlackRussian}
                      />
                    </CopyToClipboardTooltip>
                  )}
                </CopyToClipboardWrapper>
              </SocialMediaIcons>
            </SocialMediaCard>
            <OtherGuidesCard>
              <CustomText
                text={t("view.singleCampaign.available.others")}
                type={TextType.H3fix}
              />
              {randomCampaigns.length > 0 ? (
                randomCampaigns.map((campaign: any, index: number) => (
                  <OtherChapters key={index}>
                    <ImgStyled
                      src={
                        campaign.attributes.cover
                          ? `${baseUrl}${campaign.attributes.cover.data.attributes.formats.small.url}`
                          : iconPlaceHolder
                      }
                      alt={"imageOthercampaigns"}
                      onClick={() =>
                        navigate(
                          `/campanii/${modRewrite(
                            campaign.attributes.title,
                            campaign.id
                          )}`
                        )
                      }
                    />
                    <OtherCampaignsContent
                      onClick={() =>
                        navigate(
                          `/campanii/${modRewrite(
                            campaign.attributes.title,
                            campaign.id
                          )}`
                        )
                      }
                    >
                      <DateWrapper>
                        <CustomText
                          text={campaign.attributes.start_date
                            .split("-")
                            .reverse()
                            .join(".")}
                          type={TextType.H5fix}
                          color={COLORS.Paua}
                        />
                      </DateWrapper>
                      <CustomText
                        text={campaign.attributes.title}
                        type={TextType.H5fix}
                      />
                    </OtherCampaignsContent>
                  </OtherChapters>
                ))
              ) : (
                <>
                  {[...Array(skeletonCount)].map((_, index) => (
                    <SkeletonWrapperOtherChapter key={index}>
                      <Skeleton
                        width={"80px"}
                        height={"80px"}
                        borderRadius={BORDER_RADIUS.radius16}
                        baseColor={COLORS.Solitude}
                        highlightColor={COLORS.WhiteSmoke}
                      />
                      <SkeletonWrapperChapter>
                        <Skeleton
                          count={1}
                          baseColor={COLORS.Solitude}
                          highlightColor={COLORS.WhiteSmoke}
                        />
                      </SkeletonWrapperChapter>
                    </SkeletonWrapperOtherChapter>
                  ))}
                </>
              )}
            </OtherGuidesCard>
          </InsideThirdColumn>
        </ThirdColumn>
      </Body>
      {isMobile && (
        <BackOnTopWrapper onClick={scrollToTop}>
          <BackOnTopImg src={ArrowUpIcon} alt="ArrowUpIcon" />
        </BackOnTopWrapper>
      )}
    </SingleGuideContainer>
  );
};

export default SingleGuide;
