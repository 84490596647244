import { useEffect, useRef, useState } from 'react';

// ASSETS
import ArrowDown from "assets/svg/ArrowDownYellow.svg";

// MISC
import { TextType } from 'components/Atoms/CustomText/CustomTextModel';
import { COLORS } from 'constants/variables';

// STYLES
import { DropdownContainer, DropdownContent, DropdownContentContainer, DropdownHeader, DropdownIcon, DropdownList, DropdownListElement, SettingLabel } from 'view/CookieModule/CookieModuleStyled';

// COMPONENTS
import CustomText from 'components/Atoms/CustomText/CustomText';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import { getCookie, isTruthy } from 'view/CookieModule/helpers/cookieHelper';
import { translations } from 'view/CookieModule/config/cookieModuleConfig';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'slices/LanguageSlice/languageSlice';

const CustomCookiesDropdown = ({ title, description, items, onItemsChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [itemStates, setItemStates] = useState(items);
    const [allChecked, setAllChecked] = useState(false);
    const currentLanguage = useSelector(selectLanguage);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleMasterCheckboxChange = () => {
        const newAllChecked = !allChecked;
        setAllChecked(newAllChecked);
        const newItems = itemStates.map(item => ({ ...item, checked: newAllChecked }));
        setItemStates(newItems);
        onItemsChange(newItems);
    };

    const handleCheckboxChange = (index) => {
        const newItems = [...itemStates];
        newItems[index].checked = !newItems[index].checked;
        setItemStates(newItems);
        onItemsChange(newItems);

        const allChecked = newItems.every(item => item.checked);
        setAllChecked(allChecked);
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        const allChecked = itemStates.every(item => item.checked);
        setAllChecked(allChecked);
    }, [itemStates]);

    const t = translations[currentLanguage];

    return (
        <div ref={dropdownRef}>
            <DropdownContainer onClick={toggleDropdown} isOpen={isOpen}>
                <DropdownHeader>
                    <SettingLabel>
                        <CustomText
                            type={TextType.P1}
                            text={title}
                            color={COLORS.BlackRussian}
                        />
                        <CustomCheckbox
                            checked={allChecked}
                            onChange={handleMasterCheckboxChange}
                            isDisabled={title === "Necesare" || title === "Necessary"}
                        />
                    </SettingLabel>
                    <DropdownIcon src={ArrowDown} alt="Details arrow" isOpen={isOpen} />
                </DropdownHeader>
            </DropdownContainer>
            <DropdownContentContainer isOpen={isOpen}>
                <DropdownContent>
                    <CustomText
                        type={TextType.P2}
                        text={description}
                        color={COLORS.BlackRussian}
                    />
                    <DropdownList>
                        {/* {itemStates.map((item, index) => (
                            <DropdownListElement key={index}>
                                <SettingLabel>
                                    {item.title}
                                    {item.hasCheckbox && <CustomCheckbox
                                        checked={item.checked}
                                        onChange={() => handleCheckboxChange(index)}
                                        isDisabled={title === "Necesar" || title === "Necessary"}
                                    />}
                                </SettingLabel>
                                <CustomText
                                    type={TextType.P2}
                                    text={title === "Necesar" || title === "Necessary" ? t.cookies.necessary[0].description : t.cookies.analytics[0].description}
                                    color={COLORS.BlackRussian}
                                />
                            </DropdownListElement>
                        ))} */}
                    </DropdownList>
                </DropdownContent>
            </DropdownContentContainer>
        </div>
    );
};

export default CustomCookiesDropdown;
