import React from "react";

// STYLES
import * as styled from "./LoadingAnimationStyled";

// LIBRARIES
import { Ping } from "@uiball/loaders";

// MISC

// REDUX

// COMPONENTS

const LoadingAnimation = () => {
  // PROPS

  // CONSTANTS USING LIBRARIES

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <styled.LoadingAnimationContainer>
      <Ping size={135} color="gray" />
    </styled.LoadingAnimationContainer>
  );
};

export default LoadingAnimation;
