
import { useEffect } from 'react';

// MISC
import { CookieItem } from '../CookieModuleModel';

const useCookieChangeListener = (onCookieChange: (necessaryCookies: CookieItem[], analyticsCookies: CookieItem[]) => void) => {
  useEffect(() => {
    const handleCookieChange = (event: CustomEvent) => {
      const { necessaryCookies, analyticsCookies } = event.detail;
      onCookieChange(necessaryCookies, analyticsCookies);
    };

    window.addEventListener('cookieChange', handleCookieChange as EventListener);

    return () => {
      window.removeEventListener('cookieChange', handleCookieChange as EventListener);
    };
  }, [onCookieChange]);
};

export default useCookieChangeListener;
