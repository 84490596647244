import React, { useEffect, useState } from "react";

// ASSETS
import { LandscapeOverlay, LandscapeMessage } from "./AppStyled";
// STYLES

// LIBRARIES
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "vanilla-cookieconsent/dist/cookieconsent.css";
// import * as CookieConsent from "vanilla-cookieconsent";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";

//REDUX
import { handleChangeSnackBar } from "slices/SessionSlice/sessionSlice";
import {
  isRequestLoadingSelector,
  snackBar,
  snackBarAlert,
} from "slices/SessionSlice/sessionSliceSelectors";
import { useAppSelector } from "hooks/reduxHooks";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// MISC

// COMPONENTS
import AppRoutes from "./config/AppRoutes";
import LoadingAnimation from "components/Atoms/LoadingAnimation/LoadingAnimation";
import CookieConsent from "view/CookieModule/components/CookieConsent/CookieConsent";
import useCookieChangeListener from "view/CookieModule/hooks/useCookieChangeListener";
import useCookieConsent from "view/CookieModule/hooks/useCookieConsent";
import { initialAnalyticsCookies, initialNecessaryCookies, translations } from "view/CookieModule/config/cookieModuleConfig";
import { disableGoogleAnalytics, initializeGoogleAnalytics } from "view/CookieModule/helpers/googleAnalyticsHelper";
import { selectLanguage } from "slices/LanguageSlice/languageSlice";

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}
// CONFIGURATION

const App = () => {
  // API REQUESTS

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackBarData = useSelector(snackBar);
  const snackBarAlertData = useSelector(snackBarAlert);
  const isRequestLoadingData = useAppSelector(isRequestLoadingSelector);
  const currentLanguage = useSelector(selectLanguage);
  // STATE CONSTANTS
  const [isLandscape, setIsLandscape] = useState(false);
  // LIFE CYCLE

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(isMobileLandscape());
    };

    handleOrientationChange();

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);
  // EVENT HANDLERS
  const handleClose = (_, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      handleChangeSnackBar({
        snackBar: { open: false, autoHideDuration: 2000 },
      })
    );
  };
  // if (window.gtag) {
  //   window.gtag("js", new Date());
  //   window.gtag("config", "G-E3PTGWN5SX", {
  //     anonymize_ip: true,
  //     // Add other configurations here
  //   });
  // }

  // useEffect(() => {
  //   function gtag(...args) {
  //     window.dataLayer.push(args);
  //   }
  //   // eslint-disable-next-line
  //   const initializeGoogleAnalytics = () => {
  //     if (window.dataLayer) return; // Check if GA script is already loaded

  //     const script = document.createElement("script");
  //     script.src = "https://www.googletagmanager.com/gtag/js?id=G-E3PTGWN5SX";
  //     script.async = true;
  //     document.body.appendChild(script);

  //     window.dataLayer = window.dataLayer || [];
  //     gtag("js", new Date());
  //     gtag("config", "G-E3PTGWN5SX", {
  //       anonymize_ip: true,
  //       cookie_expires: 0, // Adjust based on your cookie expiration requirements
  //     });
  //   };
  //   CookieConsent.run({
  //     onChange: function ({ changedCategories, changedServices }) {
  //       if (changedCategories.includes("analytics")) {
  //         if (CookieConsent.acceptedCategory("analytics")) {
  //           // Analytics category was just enabled
  //         } else {
  //         }

  //         if (changedServices["analytics"].includes("Google Analytics")) {
  //           if (
  //             CookieConsent.acceptedService("Google Analytics", "analytics")
  //           ) {
  //           } else {
  //             // Google Analytics was just disabled
  //           }
  //         }
  //       }
  //     },
  //     categories: {
  //       necessary: {
  //         enabled: true, // this category is enabled by default
  //         readOnly: true, // this category cannot be disabled
  //       },
  //       analytics: {},
  //     },

  //     language: {
  //       default: "ro",
  //       translations: {
  //         ro: {
  //           consentModal: {
  //             title: "Folosim cookie-uri",
  //             description: "",
  //             acceptAllBtn: "Acceptă toate",
  //             acceptNecessaryBtn: "Respinge toate",
  //             showPreferencesBtn: "Gestionează preferințele individuale",
  //           },
  //           preferencesModal: {
  //             title: "Gestionează preferințele pentru cookie-uri",
  //             acceptAllBtn: "Acceptă toate",
  //             acceptNecessaryBtn: "Respinge toate",
  //             savePreferencesBtn: "Acceptă selecția curentă",
  //             closeIconLabel: "Închide",
  //             sections: [
  //               {
  //                 title: "",
  //                 description: "",
  //               },
  //               {
  //                 title: "Cookie-uri strict necesare",
  //                 description:
  //                   "Aceste cookie-uri sunt esențiale pentru funcționarea corectă a site-ului și nu pot fi dezactivate.",
  //                 linkedCategory: "necessary",
  //               },
  //               {
  //                 title: "Performanță și analiză",
  //                 description:
  //                   "Aceste cookie-uri colectează informații despre modul în care folosești site-ul nostru. Toate datele sunt anonimizate și nu pot fi folosite pentru a te identifica.",
  //                 linkedCategory: "analytics",
  //               },
  //               {
  //                 title: "Mai multe informații",
  //                 description:
  //                   "Pentru orice întrebări legate de politica privind cookie-urile și alegerile tale, te rog scrie-ne la adresa dsu@mai.gov.ro",
  //               },
  //             ],
  //           },
  //         },
  //         en: {
  //           consentModal: {
  //             title: "We use cookies",
  //             description: "",
  //             acceptAllBtn: "Accept all",
  //             acceptNecessaryBtn: "Reject all",
  //             showPreferencesBtn: "Manage Individual preferences",
  //           },
  //           preferencesModal: {
  //             title: "Manage cookie preferences",
  //             acceptAllBtn: "Accept all",
  //             acceptNecessaryBtn: "Reject all",
  //             savePreferencesBtn: "Accept current selection",
  //             closeIconLabel: "Close",
  //             sections: [
  //               {
  //                 title: "",
  //                 description: "",
  //               },
  //               {
  //                 title: "Strictly Necessary cookies",
  //                 description:
  //                   "These cookies are essential for the proper functioning of the website and cannot be disabled.",
  //                 linkedCategory: "necessary",
  //               },
  //               {
  //                 title: "Performance and Analytics",
  //                 description:
  //                   "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
  //                 linkedCategory: "analytics",
  //               },
  //               {
  //                 title: "More information",
  //                 description:
  //                   "For any queries in relation to my policy on cookies and your choices, please contact us at dsu@mai.gov.ro",
  //               },
  //             ],
  //           },
  //         },
  //       },
  //     },
  //   });
  // }, []);

  function isMobileLandscape() {
    const maxWidthForMobile = 1023;

    const minHeightForLandscape = 600;

    const isLandscape = window.innerWidth > window.innerHeight;

    const isMobileDevice = window.innerWidth <= maxWidthForMobile;

    const isHeightEligibleForLandscape =
      window.innerHeight > minHeightForLandscape;

    return isLandscape && isMobileDevice && !isHeightEligibleForLandscape;
  }
  useCookieChangeListener((necessaryCookies, analyticsCookies) => {
    // Trigger actions based on cookie changes
    console.log('Cookies have changed:', { necessaryCookies, analyticsCookies });

    // Example actions based on analytics cookies
    const isGoogleAnalyticsEnabled = analyticsCookies.find(cookie => cookie.title === '_ga' && cookie.checked);
    if (isGoogleAnalyticsEnabled) {
      initializeGoogleAnalytics();
      console.log('Google Analytics enabled');
    } else {
      disableGoogleAnalytics();
      console.log('Google Analytics disabled');
    }

    // Add other cookie-based actions here
  });

  const {
    handleSaveSettings,
  } = useCookieConsent(initialNecessaryCookies, initialAnalyticsCookies);

  return (
    <>
      <SkeletonTheme baseColor="#ededed" highlightColor="#d3d3d3">
        <LandscapeOverlay isVisible={isLandscape}>
          <BrowserRouter>
            <Routes>
              <Route path={"/*"} element={<AppRoutes />} />
            </Routes>
            <Snackbar {...snackBarData} onClose={handleClose}>
              <MuiAlert {...snackBarAlertData} onClose={handleClose}>
                {snackBarAlertData?.message}
              </MuiAlert>
            </Snackbar>
            <CookieConsent
              language={currentLanguage}
              necessaryCookies={initialNecessaryCookies}
              analyticsCookies={initialAnalyticsCookies}
              onSave={handleSaveSettings}
            />

            {isRequestLoadingData && <LoadingAnimation />}
          </BrowserRouter>
        </LandscapeOverlay>
        <LandscapeMessage isVisible={isLandscape}>
          {t("view.app.portrait")}
        </LandscapeMessage>
      </SkeletonTheme>
    </>
  );
};

export default App;
