import { AxiosInstance } from 'axios';
import axiosBase from './base';
import { store } from 'store/store'; 

class CampaignService {
    base: AxiosInstance;

    constructor() {
        this.base = axiosBase.base;
    }

    getCampaigns(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/campaigns?populate=*&pagination[pageSize]=100&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getCampaign(campaignId: string): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/campaigns/${campaignId}/?populate=*&locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }
}

const campaignService = new CampaignService();
export default campaignService;
