import React from "react";

// ASSETS

// STYLES
import { CustomTextContainer, CustomTextLabel } from "./CustomTextStyled";

// LIBRARIES

// MISC
import {IProps, TextSize, TextSpacing, TextKerning, TextType} from "./CustomTextModel";

// REDUX
import { useSelector } from "react-redux";
import {
  accessibilityBiggerLetterSpacingSelector,
  accessibilityBiggerLineHeightSelector,
  accessibilityBiggerTextSelector,
} from "slices/UISlice/uiSliceSelectors";

// COMPONENTS

// CONFIGURATION
const CustomText = (props: IProps) => {
  // PROPERTIES
  const {
    type,
    text,
    size = TextSize.default,
    color,
    rowSpacing = TextSpacing.default,
    kerning = TextKerning.default,
    isAboutPage,
  } = props;

  const getHtmlTag = (type) => {
    if (typeof type === "string") {
      if (type.startsWith("H")) {
        return `h${type.substring(1)}`;
      } else if (type.startsWith("P")) {
        return "p";
      }
    }
    if (typeof type === "number" && type === TextType.Option) {
      return "div";
    } else {
      return "span";
    }
  };

  const Tag = getHtmlTag(type);

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const accessibilityBiggerText: boolean = useSelector(
    accessibilityBiggerTextSelector
  );
  const accessibilityBiggerLineHeight: boolean = useSelector(
    accessibilityBiggerLineHeightSelector
  );
  const accessibilityBiggerLetterSpacing: boolean = useSelector(
    accessibilityBiggerLetterSpacingSelector
  );

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <CustomTextContainer isAboutPage={isAboutPage}>
      {text && (
        <CustomTextLabel
          as={Tag}
          type={type}
          size={size}
          rowSpacing={rowSpacing}
          kerning={kerning}
          color={color}
          accessibilityBiggerText={accessibilityBiggerText}
          accessibilityBiggerLineHeight={accessibilityBiggerLineHeight}
          accessibilityBiggerLetterSpacing={accessibilityBiggerLetterSpacing}
        >
          {text}
        </CustomTextLabel>
      )}
    </CustomTextContainer>
  );
};
export default CustomText;
