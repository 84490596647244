import styled, { css } from "styled-components";
import { BORDER_RADIUS, COLORS, DEVICE_TYPE, SIZE } from "constants/variables";
import { ModalType } from "./ResponsiveModalModel";
import cursorIcon from "assets/img/Cursor.png";

export const PopupModal = styled.div<{ open: boolean }>`
  // Common styles

  position: absolute;
  z-index: 1500 !important;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    align-items: center;
    justify-content: center;
  }
  // Media query for landscape screens
  @media screen and (orientation: landscape) {
    width: 100vw;
    height: 100dvh;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: ${(props) => (props.open ? "flex" : "none")};
    align-items: center;
    justify-content: flex-end;
  }
  // Media query for mobile screens
  @media (orientation: portrait) {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    transition: left 0.3s ease-in-out;
  }
`;
export const ModalBody = styled.div<{ open: boolean }>`
  margin-top: ${SIZE.max40};
  background: ${COLORS.WhiteSmoke};
  outline: 8px solid rgba(161, 161, 179, 0.3);
  outline-offset: 0px;
  right: ${SIZE.max84};
  transition: right 1s ease-in-out;
  box-sizing: border-box;
  height: auto;
  min-height: 300px;
  max-height: 90%;
  z-index: 1500 !important;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    border-bottom: none;
    max-height: 100%;
    height: 100dvh;
    width: 100%;
    max-width: 650px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    bottom: 0;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }
  height: auto;
  max-width: 400px;
  min-width: 300px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-radius: ${BORDER_RADIUS.radius32};
  position: relative;
`;
export const DefibContainer = styled.div`
  height: auto;
  max-height: 100%;
  width: auto;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
  }
`;
export const AbsolutContainer = styled.div<ModalType>`
  ${({ type }) =>
    type === ModalType.Info &&
    css`
      justify-content: space-between;
    `}
  ${({ type }) =>
    type === ModalType.Defib &&
    css`
      justify-content: flex-end;
    `}
  position: absolute;
  top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 16px 0 32px;
  z-index: 100;
`;
export const DefibPhotoContainer = styled.div`
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  background-color: ${COLORS.Solitude};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${BORDER_RADIUS.radius24};
  aspect-ratio: 1/1;
`;
export const DefibPhotoURL = styled.img`
  border-radius: ${BORDER_RADIUS.radius24};
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
export const DefibPhoto = styled.img`
  height: 100px;
  width: auto;
`;
export const DefibBody = styled.div`
  padding: ${SIZE.fix32};
  max-height: 100%;
  height: 100%;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: calc(100vh - 300px);
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const InformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
`;
export const DetailsBody = styled.div`
  max-width: 100%;
  overflow-y: auto;
`;
export const RedLine = styled.div`
  height: 4px;
  width: 32px;
  background-color: ${COLORS.Cinnabar};
  margin-top: 4px;
  margin-bottom: ${SIZE.max24};
`;
export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1;
  width: auto;
  height: auto;
  margin-bottom: ${SIZE.max24};
`;
export const Schedule = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: ${SIZE.max10} 0;
`;
export const IconWrapper = styled.div`
  margin-top: 4px;
  > svg {
    height: 24px;
    width: 24px;
  }
`;
export const LinkWrapper = styled.a<{ biggerCursor?: boolean }>`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  text-decoration: none;
  color: ${COLORS.BlackRussian};
`;
export const InformationsFormat = styled.div``;
export const Informations = styled.div`
  margin-top: 5px;
  white-space: normal;
  overflow-wrap: break-word;
`;
export const ModalActions = styled.div`
  margin-top: ${SIZE.max16};
  width: fit-content;
  align-self: center;
  align-items: center;
  justify-content: center;
`;
export const PinInfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: ${SIZE.fix32};
  padding-top: ${SIZE.fix42};
  height: auto;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const InfoTittleContainer = styled.div`
  width: 100%;
  max-width: 100% !important;
  height: auto;
  margin-top: ${SIZE.max40};
`;
export const PinInfoBody = styled.div``;
