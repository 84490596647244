import React, { useEffect } from "react";

// ASSETS
import familySvg from "assets/svg/SvgGetReady.svg";
import FilesIcon from "assets/svg/Mapa.svg";
import MedkitIcon from "assets/svg/Trusa.svg";
import FolderIcon from "assets/svg/Docu.svg";

// STYLES
import {
  GetReadyContainer,
  Card,
  FamilyImage,
  TextContainer,
  Title,
  Subtitle,
  TwoColumnContainer,
  FirstColumn,
  SecondColumn,
  Column,
  ColumnsWrapper,
  TitleCard,
  SubtitleCard,
  SubtitleWrapper,
  NumberSubtitleWrapper,
  BodyElemet,
  IconSvg,
  ButonAtomWrapper,
  IconSvgMiddle,
} from "./GetReadyStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import getReadyService from "../../services/getReadyService";
// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import PrepareYourself from "./PrepareYourself/PrepareYourself";
import modRewrite from "../../locales/modRewrite";

const GetReady = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const navigate = useNavigate();
  // STATE CONSTANTS
  const [steps, setSteps] = React.useState([]);
  // API REQUESTS
  useEffect(() => {
    (async () => {
      try {
        const response: any = await getReadyService.getSteps();
        setSteps(response.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <>
      <GetReadyContainer>
        <TwoColumnContainer>
          <FirstColumn tabIndex={0}>
            <TextContainer>
              <Title>
                <CustomText
                  text={t("view.getReady.section1.title")}
                  type={TextType.H1custom}
                />
              </Title>
              <Subtitle>
                <CustomText
                  text={t("view.getReady.section1.content")}
                  type={TextType.P1}
                />
              </Subtitle>
            </TextContainer>
          </FirstColumn>
          <SecondColumn>
            <FamilyImage src={familySvg} alt="Family" />
          </SecondColumn>
        </TwoColumnContainer>

        <Card tabIndex={0}>
          <TitleCard>
            <CustomText
              text={t("view.getReady.section2.title")}
              type={TextType.H2}
              color={COLORS.TangerineYellow}
            />
          </TitleCard>
          <ColumnsWrapper>
            <Column>
              <SubtitleCard>
                <NumberSubtitleWrapper>
                  <CustomText
                    text={"1."}
                    type={TextType.H1}
                    color={COLORS.Cinnabar}
                  />
                </NumberSubtitleWrapper>
                <SubtitleWrapper>
                  <CustomText
                    text={
                      steps.length > 0
                        ? steps[0].attributes.title
                        : t("view.getReady.section2.step1")
                    }
                    type={TextType.H3}
                    color={COLORS.WhiteSmoke}
                  />
                </SubtitleWrapper>
              </SubtitleCard>
              <BodyElemet>
                <IconSvg src={FolderIcon} alt="Folder Icon" />
                <ButonAtomWrapper>
                  <ButtonAtom
                    text={t("view.getReady.section2.open")}
                    type={ButtonType.ArrowButton}
                    rightIcon={true}
                    onClick={() => {
                      navigate(
                        `/pregateste-te/${modRewrite(
                          steps[0].attributes.title,
                          steps[0].id
                        )}`
                      );
                    }}
                  />
                </ButonAtomWrapper>
              </BodyElemet>
            </Column>
            <Column>
              <SubtitleCard>
                <NumberSubtitleWrapper>
                  <CustomText
                    text={"2."}
                    type={TextType.H1}
                    color={COLORS.Cinnabar}
                  />
                </NumberSubtitleWrapper>
                <SubtitleWrapper>
                  <CustomText
                    text={
                      steps.length > 0
                        ? steps[1].attributes.title
                        : t("view.getReady.section2.step2")
                    }
                    type={TextType.H3}
                    color={COLORS.WhiteSmoke}
                  />
                </SubtitleWrapper>
              </SubtitleCard>
              <BodyElemet>
                <IconSvgMiddle src={MedkitIcon} alt="Medkit Icon" />
                <ButonAtomWrapper>
                  <ButtonAtom
                    text={t("view.getReady.section2.open")}
                    type={ButtonType.ArrowButton}
                    rightIcon={true}
                    onClick={() => {
                      navigate(
                        `/pregateste-te/${modRewrite(
                          steps[1].attributes.title,
                          steps[1].id
                        )}`
                      );
                    }}
                  />
                </ButonAtomWrapper>
              </BodyElemet>
            </Column>
            <Column>
              <SubtitleCard>
                <NumberSubtitleWrapper>
                  <CustomText
                    text={"3."}
                    type={TextType.H1}
                    color={COLORS.Cinnabar}
                  />
                </NumberSubtitleWrapper>
                <SubtitleWrapper>
                  <CustomText
                    text={
                      steps.length > 0
                        ? steps[2].attributes.title
                        : t("view.getReady.section2.step3")
                    }
                    type={TextType.H3}
                    color={COLORS.WhiteSmoke}
                  />
                </SubtitleWrapper>
              </SubtitleCard>
              <BodyElemet>
                <IconSvg src={FilesIcon} alt="Files Icon" />
                <ButonAtomWrapper>
                  <ButtonAtom
                    text={t("view.getReady.section2.open")}
                    type={ButtonType.ArrowButton}
                    rightIcon={true}
                    onClick={() => {
                      navigate(
                        `/pregateste-te/${modRewrite(
                          steps[2].attributes.title,
                          steps[2].id
                        )}`
                      );
                    }}
                  />
                </ButonAtomWrapper>
              </BodyElemet>
            </Column>
          </ColumnsWrapper>
        </Card>
      </GetReadyContainer>
      <PrepareYourself />
    </>
  );
};

export default GetReady;
