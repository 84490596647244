export const COLORS = {
  FaluRed: "#77191A",
  Crimson: "#871F20",
  ButtonHoverRed: "#C72828",
  VividAuburn: "#991F1F",
  MetalicSilver: "#A1A1B3",
  ChineseSilver: "#C8CBD5",
  RaisinBlack: "#1D1D26",
  Orange: "#F2B920",
  Gunmetal: "#2F2F39",
  Yellow: "#FFCC00",
  Cultured: "#F5F5F5",
  Background: "#E6E7EB",
  AbsoluteWhite: "#FFFFFF",
  CrimsonFire: "#c72828",
  MahoganyBlaze: "#991f1f",
  GhostWhite: "#E6E7EB",
  DustyLavender: "#A1A1B3",
  WhiteSmoke: "#E6E7EB",
  Paua: "#A1A1B3",
  lineColor: "#A1A1B347",
  Red: "#E62E2E",
};

export const DEVICE_SIZE = {
  GALAXY_FOLD: "280px",
  MOBILE: "480px",
  TABLET_MIN: "600px",
  TABLET_MED: "1024px",
  TABLET_MAX: "1279px",
  DESKTOP: "1280px",
  NONE: "none",
};

export const FONT_FAMILY = {
  poppins: "Poppins",
};

export const FONT_WEIGHT = {
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
};
export const FONT_SIZE = {
  fix14: "14px",
  fix16: "16px",
  max16: "clamp(12px, 0.93vw, 16px)",
  max18: "clamp(16px, 0.93vw, 18px)",
  max24: "clamp(18px, 1.25vw, 24px)",
  max32: "clamp(24px, 1.66vw, 32px)",
  max40: "clamp(32px, 2.08vw, 40px)",
  max45: "clamp(38px, 2.15vw, 45px)",
};

export const ROW_SPACING = {
  fix18: "18px",
  fix20: "20px",
  max24: "clamp(20px, 1.25vw, 24px)",
  max28: "clamp(24px, 1.4vw, 28px)",
  max32: "clamp(28px, 1.8vw, 32px)",
  max40: "clamp(32px, 1.9vw, 40px)",
  max48: "clamp(40px, 2.4vw, 48px)",
  max55: "clamp(48px, 2.6vw, 55px)",
  max60: "clamp(55px, 2.8vw, 60px)",
};

export const BORDER_RADIUS = {
  radius8: "8px",
  radius16: "16px",
  radius21: "21px",
  radius24: "24px",
  radius32: "32px",
  radius40: "40px",
  radius48: "48px",
};

export const SIZE = {
  max8: "clamp(6px, 0.5.2vw, 8px)",
  max10: "clamp(8px, 0.45vw, 10px)",
  max12: "clamp(8px, 0.6vw, 12px)",
  max18: "clamp(14px, 1.04vw, 18px)",
  max24: "clamp(16px, 1.25vw, 24px)",
  max16: "clamp(12px, 0.83vw, 16px)",
  fix16: "16px",
  max28: "clamp(16px, 1.25vw, 24px)",
  max32: "clamp(24px, 1.66vw, 32px)",
  fix32: "32px",
  max40: "clamp(24px, 2.1vw, 40px)",
  fix42: "42px",
  max55: "clamp(30px, 2.8vw, 55px)",
  card60: "clamp(50px, 3.125vw, 60px)" /* it's special for DashboardCards*/,
  max60: "clamp(30px, 3.1vw, 60px)",
  max65: "clamp(35px, 3.1vw, 65px)",
  max80: "clamp(40px, 4.16vw, 80px)",
  max78: "clamp(24px, 4vw, 78px)" /*special for HeaderTab*/,
  max84: "clamp(32px, 4.37vw, 84px)",
  max100: "clamp(50px, 6vw, 100px)", //size for GuideCardAtom icon
  max104: "clamp(50px, 5.41vw, 104px)",
  fix104: "104px",
  max120: "clamp(100px, 8vw, 120px)",
  max140: "clamp(100px, 8.3vw, 140px)",
  fix160: "clamp(100px, 8.3vw, 160px)",
  max160: "clamp(130px, 9vw, 160px)",
  max180: "clamp(160px, 9.792vw, 188px)" /* it's special for DashboardCards*/,
  max200: "clamp(130px, 10vw, 200px)",
  max224: "clamp(160px, 12vw, 224px)",
  max240: "clamp(180px, 12.5vw, 240px)",
  max260: "clamp(200px, 13vw, 260px)",
  max220: "clamp(165px, 11vw, 220px)",
  max280: "clamp(170px, 15vw, 280px)",
  max320: "clamp(160px, 16.5vw, 320px)",
  max330: "clamp(250px, 17vw, 330px)",
  max360: "clamp(300px, 18vw, 360px)" /* it's special for Cards*/,
  max380: "clamp(300px, 18.75vw, 380px)",
  max390: "clamp(326px, 20.417vw, 392px)" /* it's special for DashboardCards*/,
  max400: "clamp(260px, 20vw, 400px)",
  max472: "clamp(300px, 25vw, 472px)",
  max480: "clamp(300px, 25vw, 480px)",
  max500: "clamp(300px, 25.5vw, 500px)",
  max510: "clamp(300px, 25.5vw, 510px)",
  max550: "clamp(350px, 35vw, 550px)",
  max600: "clamp(375px, 37.5vw, 600px)",
  max650: "clamp(400px, 40vw, 650px)",
  max720: "clamp(450px, 42vw, 720px)",
  max750: "clamp(450px, 45vw, 750px)",
  max780: "clamp(500px, 45vw, 780px)",
  max800: "clamp(500px, 45vw, 800px)",
  max867: "clamp(326px, 45.156vw, 867px)",
  max900: "clamp(600px, 50vw, 900px)",
  max1200: "clamp(768px, 50vw, 960px)",
};

export const MARGIN = {
  max4: "4px",
  max6: "6px",
  max8: "8px",
  max10: "10px",
  max12: "12px",
  fix14: "14px",
  fix16: "16px",
  max20: "20px",
  max24: "24px",
  max32: "32px",
  fix24: "24px",
  max28: "28px",
  fix32: "32px",
  max40: "40px",
  max48: "48px",
  max50: "50px",
  max56: "56px",
  max60: "60px",
  max70: "70px",
  max64: "64px",
  max80: "80px",
  max100: "100px",
  max110: "110px",
  max120: "120px",
  fix140: "140px",
  fix150: "150px",
  fix160: "160px",
  max180: "180px",
  max190: "190px",
  max200: "200px",
  max230: "230px",
};

export const DEVICE_TYPE = {
  smallDevice: "360px",
  mobile: "767px",
  mobileHeader: "1024px",
  tablet: "1227px",
};
