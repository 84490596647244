import React, { useEffect, useState } from "react";
// ASSETS
//TODO uncomment the import of the vanIcon when the caravan card is ready
// import vanIcon from "assets/svg/VanIcon.svg";
import HomepageMap from "assets/animated/HomepageMap.json";
// STYLES
import {
  DasboardContainer,
  TwoColumnContainer,
  Column1,
  Column2,
  Title,
  ContainerText,
  ButtonsContainer,
  // CardComponent,
  // CardTitle,
  // CardSVG,
  InfoContainer,
  // ButtonContainer,
  DesktopColumnsContainer,
  // ButtonContainerGalaxy,
  BackgroundAnimation,
} from "./MapFromDashboardStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MISC
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS, DEVICE_TYPE } from "constants/variables";
import mapService from "services/mapService";
// REDUX

// COMPONENTS
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import CustomText from "components/Atoms/CustomText/CustomText";

const MapFromDashboard = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: DEVICE_TYPE.mobileHeader });
  const navigate = useNavigate();
  const [ipCategories, setIpCategories] = useState<any[]>([]);

  // STATE CONSTANTS
  const webWhiteList = [
    { localeKey: "defibrillator", name: "DEFIBRILATOARE" },
    { localeKey: "pharmacies", name: "FARMACII_NON_STOP" },
    { localeKey: "hospitals", name: "SPITALE" },
    { localeKey: "emergencies", name: "UNITATI_DE_PRIMIRI_URGENTE" },
    { localeKey: "ambulance", name: "SERVICII_DE_AMBULANTA" },
    { localeKey: "fire", name: "UNITATI_DE_POMPIERI" },
    { localeKey: "constabulary", name: "UNITATI_JANDARMERIE" },
  ];

  const mobileWhiteList = [
    { localeKey: "defibrillator", name: "DEFIBRILATOARE" },
    { localeKey: "pharmacies", name: "FARMACII_NON_STOP" },
    { localeKey: "constabulary", name: "UNITATI_JANDARMERIE" },
    { localeKey: "emergencies", name: "UNITATI_DE_PRIMIRI_URGENTE" },
    { localeKey: "hospitals", name: "SPITALE" },
    { localeKey: "ambulance", name: "SERVICII_DE_AMBULANTA" },
    { localeKey: "fire", name: "UNITATI_DE_POMPIERI" },
  ];
  // API REQUESTS

  // LIFE CYCLE

  useEffect(() => {
    mapService.getIpCategories().then((response: any) => {
      const ipCats = response.data.map((el: any) => ({
        nume: el.attributes.nume,
        id: el.id,
      }));

      setIpCategories(ipCats);
    });
  }, []);

  // EVENT HANDLERS
  return (
    <DasboardContainer>
      <TwoColumnContainer>
        <BackgroundAnimation animationData={HomepageMap} loop autoplay />
        {isMobile ? (
          <Column1>
            <InfoContainer>
              <Title>
                <CustomText
                  type={TextType.H1custom}
                  text={t("components.mapFromDashboard.title")}
                  color={COLORS.TangerineYellow}
                />
              </Title>
              <ContainerText>
                <CustomText
                  type={TextType.P1}
                  text={t("components.mapFromDashboard.info")}
                  color={COLORS.WhiteSmoke}
                />
              </ContainerText>
              <ButtonsContainer>
                {mobileWhiteList.map((el) => {
                  const ipCategory = ipCategories.find(
                    (ipCat) => ipCat.nume === el.name
                  );
                  if (ipCategory) {
                    return (
                      <ButtonAtom
                        key={ipCategory.id}
                        text={t(`components.mapFromDashboard.${el.localeKey}`)}
                        type={ButtonType.Link}
                        onClick={() =>
                          navigate("/harta", { state: { id: ipCategory.id } })
                        }
                      />
                    );
                  }
                  return null;
                })}
              </ButtonsContainer>
            </InfoContainer>
            {/* TODO: hid the caravan card until further notice */}
            {/* <CardComponent>
              <CardTitle>
                <CustomText
                  type={TextType.H3fix}
                  text={t("components.mapFromDashboard.caravan")}
                  color={COLORS.BlackRussian}
                />
              </CardTitle>
              <ButtonContainer>
                <ButtonAtom
                  text={t("components.mapFromDashboard.see")}
                  type={ButtonType.RedButton}
                />
              </ButtonContainer>
              <CardSVG src={vanIcon} alt="vanIcon" />
            </CardComponent>
            <ButtonContainerGalaxy>
              <ButtonAtom
                text={t("components.mapFromDashboard.caravan")}
                type={ButtonType.RedButton}
              />
            </ButtonContainerGalaxy> */}
          </Column1>
        ) : (
          <DesktopColumnsContainer>
            <Column1>
              <Title>
                <CustomText
                  type={TextType.H1custom}
                  text={t("components.mapFromDashboard.title")}
                  color={COLORS.TangerineYellow}
                />
              </Title>
              <ContainerText>
                <CustomText
                  type={TextType.P1}
                  text={t("components.mapFromDashboard.info")}
                  color={COLORS.WhiteSmoke}
                />
              </ContainerText>
              <ButtonsContainer>
                {webWhiteList.map((el) => {
                  const ipCategory = ipCategories.find(
                    (ipCat) => ipCat.nume === el.name
                  );
                  if (ipCategory) {
                    return (
                      <ButtonAtom
                        key={ipCategory.id}
                        text={t(`components.mapFromDashboard.${el.localeKey}`)}
                        type={ButtonType.Link}
                        onClick={() =>
                          navigate("/harta", { state: { id: ipCategory.id } })
                        }
                      />
                    );
                  }
                  return null;
                })}
              </ButtonsContainer>
            </Column1>
            <Column2>
              {/* TODO hid the caravan card until further notice */}
              {/* <CardComponent>
                <CardTitle>
                  <CustomText
                    type={TextType.H3}
                    text={t("components.mapFromDashboard.caravan")}
                    color={COLORS.BlackRussian}
                  />
                </CardTitle>
                <ButtonContainer>
                  <ButtonAtom
                    text={t("components.mapFromDashboard.see")}
                    type={ButtonType.RedButton}
                  />
                </ButtonContainer>
                <CardSVG src={vanIcon} alt="vanIcon" />
              </CardComponent> */}
            </Column2>
          </DesktopColumnsContainer>
        )}
      </TwoColumnContainer>
    </DasboardContainer>
  );
};

export default MapFromDashboard;
