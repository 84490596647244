//LIBRARY
import styled from "styled-components";
//MISC

export const MobileHeaderContainer = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
`;
