// LIBRARIES
import styled from "styled-components";

// MISC
import { COLORS, SIZE, DEVICE_TYPE, MARGIN } from "constants/variables";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  background-color: ${COLORS.Solitude};
`;

export const ContentContainer = styled.div`
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 300px;
  margin-top: -98px;
`;
export const HeaderWrapper = styled.div`
  padding: 0 ${SIZE.max84};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: 0 ${SIZE.max55};
  }
  @media (max-width: 750px) {
    padding: 0 ${MARGIN.max32};
  }
  z-index: 1000;
  position: ${({ isSingleGuide }) => (isSingleGuide ? "relative" : "sticky")};
  top: 0;
  width: 100%;
`;
export const FooterWrapper = styled.div`
  padding: ${SIZE.max84};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: ${SIZE.max55};
  }
  @media (max-width: 750px) {
    padding: ${MARGIN.max32};
  }
`;
