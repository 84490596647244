import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import * as process from "process";
export const baseUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include", // This allows server to set cookies
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs | any,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {

  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "ALL_USERS",
    "ALL_FILES",
    "ALL_ACTIVITIES",
    "ALL_COMMUNITY_CENTERS",
    "ALL_TARGET_GROUPS",
    "ALL_BENEFICIARIES",
    "ALL_BENEFICIARY_ACTIVITIES",
    "ALL_USERS_WITH_ACCESS",
  ],
  endpoints: (builder) => ({}),
});
