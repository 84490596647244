import React from "react";

// ASSETS
import LogoDsu from "assets/svg/LogoDsu.svg";

// STYLES
import {
  HeaderContainer,
  Logo,
  LogoTextContainer,
  HeaderText,
  LogoContainer,
} from "./HeaderMobileStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MISC
import { IProps } from "./HeaderMobileModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";
// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import MobileHeaderAtom from "components/Atoms/MobileHeaderAtom/MobileHeaderAtom";
import CustomText from "components/Atoms/CustomText/CustomText";

const Header = (props: IProps) => {
  // PROPERTIES
  const { handleScrollToTop } = props;
  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const goToHomePage = () => {
    navigate("/");
    handleScrollToTop();
  };
  return (
    <HeaderContainer>
      <LogoContainer onClick={goToHomePage} biggerCursor={biggerCursor}>
        <Logo src={LogoDsu} alt="Logo" biggerCursor={biggerCursor} />
        <LogoTextContainer biggerCursor={biggerCursor}>
          <HeaderText>
            <CustomText
              text={t("components.header.beprepared")}
              type={TextType.H4Logo}
              color={COLORS.Cinnabar}
            />
          </HeaderText>
        </LogoTextContainer>
      </LogoContainer>
      <MobileHeaderAtom />
    </HeaderContainer>
  );
};

export default Header;
