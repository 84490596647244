// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import {
  COLORS,
  SIZE,
  BORDER_RADIUS,
  MARGIN,
  DEVICE_TYPE,
} from "constants/variables";

export const PrepareYourselfContainer = styled.div`
  display: flex;

  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  margin: ${MARGIN.max180} 0;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: 100%;
    margin: ${MARGIN.max140} 0;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${MARGIN.max80};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    max-width: 224px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${SIZE.max24};
  padding: 0px ${SIZE.max84};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${SIZE.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const RedCard = styled.div<{ isAccessibilityOn?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 260px;
  aspect-ratio: 1/1;
  border-radius: ${BORDER_RADIUS.radius32};
  background-color: ${COLORS.Cinnabar};
  padding: ${MARGIN.max48};
  ${({ isAccessibilityOn }) =>
    isAccessibilityOn &&
    css`
      height: 320px;
      max-height: 100%;
    `}

  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: 111px;
    width: 100%;
    max-width: 100%;
  }
`;

export const RedCardBody = styled.div`
  width: 100%;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    max-width: 156px;
  }
`;

export const LongCard = styled.div<{ isAccessibilityOn?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 868px;
  height: 260px;
  padding: ${MARGIN.max32};
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
  }

  ${({ isAccessibilityOn }) =>
    isAccessibilityOn &&
    css`
      height: 320px;
      max-height: 100%;
    `}
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: ${MARGIN.max14};
`;

export const ContentCard = styled.div`
  margin-bottom: ${MARGIN.max32};
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 10em;
  width: 100%;
  @media (width>=${DEVICE_TYPE.mobileHeader}) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: auto;
  }
`;

export const ButtonCardWrapper = styled.div`
  height: 42px;
`;
