// LIBRARIES
import styled from "styled-components";

// MISC
import { DEVICE_TYPE, MARGIN, SIZE } from "constants/variables";

export const PrivacyPolicyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  padding: ${MARGIN.max24};
  margin-top: ${MARGIN.max120};
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  padding: ${MARGIN.max80} ${SIZE.max550} 0 ${SIZE.max550};
  @media (max-width: ${DEVICE_TYPE.tablet}) {
    padding: ${MARGIN.max80} ${SIZE.max320} 0 ${SIZE.max320};
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: ${MARGIN.max80} ${MARGIN.max32} 0 ${MARGIN.max32};
  }
`;
