import React from "react";

// LIBRARIES
import styled from "styled-components";

// MISC
import {
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  MARGIN,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const HamburgerMenuComponentContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${COLORS.WhiteSmoke};
  display: flex;
  flex-direction: column;
  z-index: 2400;
  padding: ${MARGIN.max32};
`;

export const CloseButton = styled.button<{ biggerCursor?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${COLORS.WhiteSmoke};
  justify-content: flex-end;
  border: none;
  z-index: 1001;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  margin-left: auto;
`;

export const ButtonsBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.Solitude};
  border-radius: 30px;
  width: auto;
  height: 64px;
  padding: ${MARGIN.max16};
  z-index: 10;
`;

export const Xbutton = styled.img``;

export const HeaderComponent = styled.div`
  display: flex;
`;

export const LanguageComponent = styled.div`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max16};
  font-weight: ${FONT_WEIGHT.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${MARGIN.max16};
`;

export const CardBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${MARGIN.max38};
  margin-top: 50px;
  width: 100%;
  z-index: 1;
`;

export const CircleMiddle = styled.div`
  position: absolute;
  border-radius: 50%;
  background: ${(props) => props.color};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  top: ${(props) => props.top || "50%"};
  right: ${(props) => props.right || "50%"};
  transform: ${(props) => props.transform || "translate(50%, -50%)"};
`;

const CircleLayerMiddle = ({ size, index, colors }) => (
  <CircleMiddle
    color={index % 2 === 0 ? colors.solitude : colors.whiteSmoke}
    size={size}
    top="60%"
    left="0"
    transform="translate(50%, -50%)"
  />
);

export const CirclesMiddleLeftContainer = ({ sizes, colors }) => (
  <>
    {sizes.map((size, index) => (
      <CircleLayerMiddle
        key={index}
        size={size}
        index={index}
        colors={colors}
      />
    ))}
  </>
);

export const CirclePositionMiddle = styled.div`
  position: absolute;
  top: 65%;
  left: 0;
`;

export const Button = styled.button<{ biggerCursor?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${MARGIN.max10};
  border: none;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  border-radius: 20px;
  background-color: transparent;
  color: ${COLORS.BlackRussian};
  font-weight: ${FONT_WEIGHT.semiBold};
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max32};
  padding: ${MARGIN.max10} 0 ${MARGIN.max10} ${MARGIN.max16};
  margin-bottom: ${MARGIN.max24};
  transition: 0.3s background-color ease-out;
  &:hover {
    background-color: ${COLORS.Solitude};
    transition: 0.3s ease-in;
  }
  max-width: 240px;
`;

export const Icon = styled.img`
  width: 12px;
  height: 12px;
  margin: 0 5px;
`;

export const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`;
