import React from "react";

// ASSETS

// STYLES
import { ImgContainer, Image } from "./ImgAtomStyled";

// LIBRARIES

// MISC
import { IProps } from "./ImgAtomModel";

// COMPONENTS

// CONFIGURATION

const ImgAtom = (props: IProps) => {
  // PROPERTIES

  const { img, label, height = "100px" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <ImgContainer aria-label={label}>
      <Image src={img} alt={label} height={height} />
    </ImgContainer>
  );
};

export default ImgAtom;
