import React, { useState, useRef, useEffect, createRef } from "react";

// ASSETS
import coverPlaceholder from "assets/img/cover.placeholder.svg";
import iconPlaceholder from "assets/img/icon.placeholder.svg";
import ArrowLeft from "assets/svg/ArrowDown.svg";
import facebookIcon from "assets/svg/IconFacebook.svg";
import linkedInIcon from "assets/svg/iconsLinkedIn.svg";
import shareIcon from "assets/svg/ShareIcon.svg";

import ArrowUpIcon from "assets/svg/RightRedIcon.svg";
// STYLES
import {
  SingleGuideContainer,
  HeaderImageWrapper,
  HeaderImage,
  HeaderGuide,
  HeaderCell,
  Body,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  BackToGuidesButton,
  BackToGuidesImage,
  BackToGuidesWrapper,
  TitleWrapper,
  LeftCardTitle,
  LeftCardChapter,
  LeftCard,
  RedCircle,
  ContentCard,
  SocialMediaCard,
  SocialMediaIcons,
  SocialMediaIcon,
  OtherGuidesCard,
  ImgStyled,
  OtherChapters,
  InsideFirstColumn,
  InsideThirdColumn,
  BackOnTopWrapper,
  BackOnTopImg,
  RedCircleWrapper,
  CenterCardTitle,
  ScrollTargetWrapper,
  CopyToClipboardWrapper,
  SkeletonWrapperHeader,
  SkeletonWrapperChapter,
  SkeletonWrapperOtherChapter,
} from "./SinglePrepareYourselfStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { LinkedinShareButton, FacebookShareButton } from "react-share";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

// MISC

import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import getReadyService from "../../../services/getReadyService";
import guideService from "../../../services/guideService";
import modRewrite from "../../../locales/modRewrite";
import { baseUrl } from "../../../services/base";
import { content } from "constants/mocks/GuideData";
import { BORDER_RADIUS, COLORS, DEVICE_TYPE } from "constants/variables";
import { CardType } from "components/Atoms/GuideCardAtom/GuideCardAtomModel";

// REDUX

// COMPONENTS

import CustomText from "components/Atoms/CustomText/CustomText";
import GuideCardAtom from "components/Atoms/GuideCardAtom/GuideCardAtom";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";

const SingleGuide = () => {
  // PROPERTIES
  const { rgxId } = useParams();
  const tmpPrep = rgxId.split("-");
  const prepId = tmpPrep.pop();
  const sectionId: number = parseInt(tmpPrep.pop());
  // LIBRARY CONSTANTS

  const isMobile = useMediaQuery({ maxWidth: DEVICE_TYPE.mobileHeader });
  const navigate = useNavigate();
  const { t } = useTranslation();

  // STATE CONSTANTS
  const [selectedTab, setSelectedTab] = useState("beforeEvent");
  const [isContentReady, setIsContentReady] = useState(false);
  const [activeChapter, setActiveChapter] = useState(null);
  const [prep, setPrep] = useState<any>(null);
  const [chapters, setChapters] = useState<any>([]);
  const [randomGuides, setRandomGuides] = useState<any[]>([]);
  const contentRefs = useRef([]);
  const skeletonCount = 3;
  const headerImageWrapperRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const currentPrep: any = await getReadyService.getPrepareMember(prepId);

        if (currentPrep.data.attributes.prepare_sections.data !== null) {
          currentPrep.data.attributes.prepare_sections.data.sort(
            (a, b) => a.order - b.order
          );
          currentPrep.data.attributes.prepare_chapters.data.forEach(
            (chap: any) => {
              const prepare_sections =
                chap.attributes.prepare_sections.data !== undefined
                  ? chap.attributes.prepare_sections.data.map(
                      (el: any) => el.id
                    )
                  : [];

              const item =
                currentPrep.data.attributes.prepare_sections.data !== null
                  ? currentPrep.data.attributes.prepare_sections.data.find(
                      (el: any) => prepare_sections.includes(el.id)
                    )
                  : null;

              if (item) {
                if (item["chapters"] === undefined) {
                  item["chapters"] = [];
                }
                item["chapters"].push(chap);
              }
            }
          );
        }

        // TODO: use only one set per action, to avoid unnecessary rerenders

        setPrep(currentPrep.data.attributes);
        setSelectedTab(
          !isNaN(sectionId)
            ? sectionId
            : currentPrep.data.attributes.prepare_sections.data[0].id
        );

        const preps: any = await guideService.getGuides();

        setRandomGuides(
          preps.data
            .filter((el: any) => el.id !== parseInt(prepId))
            .slice()
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
        );
      } catch (err) {}
    })();
    // eslint-disable-next-line
  }, [prepId]);

  useEffect(() => {
    const anchor =
      contentRefs?.current[parseInt(activeChapter)]?.current.getAttribute("id");
    if (
      isContentReady &&
      contentRefs.current[parseInt(activeChapter)]?.current
    ) {
      contentRefs.current[parseInt(activeChapter)].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      navigate(`#${anchor}`);
    }
    // eslint-disable-next-line
  }, [activeChapter, isContentReady, contentRefs]);

  useEffect(() => {
    if (selectedTab && prep) {
      const crtSection = prep.prepare_sections.data.find(
        (el: any) => el.id === selectedTab
      );
      const tmpChapters = crtSection.chapters;

      setChapters(tmpChapters ? tmpChapters : []);

      if (tmpChapters.length > 0) {
        navigate(
          `/pregateste-ti-apropiatii/${modRewrite(prep.title)}-${modRewrite(
            crtSection.attributes.title
          )}-${selectedTab}-${prepId}`
        );
      }
      setChapters(tmpChapters ? tmpChapters : []);
      if (tmpChapters.length > 0) {
        contentRefs.current = prep.prepare_sections.data
          .find((el: any) => el.id === parseInt(selectedTab))
          .chapters.map(
            (_, index) => contentRefs.current[index] ?? createRef()
          );
        setActiveChapter(0);
      }
    }
    // eslint-disable-next-line
  }, [selectedTab, prep]);
  useEffect(() => {
    if (content.length > 0) {
      setIsContentReady(true);
    }
    // eslint-disable-next-line
  }, [contentRefs.current]);

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const scrollToChapter = (index) => {
    setActiveChapter(index);
    if (isContentReady && contentRefs.current[index]?.current) {
      contentRefs.current[index].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToTop = () => {
    if (headerImageWrapperRef.current) {
      headerImageWrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <SingleGuideContainer>
      <HeaderImageWrapper ref={headerImageWrapperRef}>
        <HeaderImage
          src={
            prep && prep.cover.data !== null
              ? `${baseUrl}${prep.cover.data.attributes.url}`
              : coverPlaceholder
          }
          alt={"coverGuideImage"}
        />
      </HeaderImageWrapper>
      <HeaderGuide>
        {prep?.prepare_sections?.data !== null &&
        prep?.prepare_sections?.data !== undefined ? (
          prep?.prepare_sections?.data.map((tab, index) => (
            <HeaderCell
              key={index}
              isSelected={selectedTab === tab.id}
              onClick={() => setSelectedTab(tab.id)}
            >
              <CustomText text={tab.attributes.title} type={TextType.H7fix} />
            </HeaderCell>
          ))
        ) : (
          <SkeletonWrapperHeader>
            {[...Array(skeletonCount)].map((_, index) => (
              <Skeleton
                key={index}
                width={100}
                count={2}
                baseColor={COLORS.Solitude}
                highlightColor={COLORS.WhiteSmoke}
              />
            ))}
          </SkeletonWrapperHeader>
        )}
      </HeaderGuide>
      <Body>
        <FirstColumn>
          <InsideFirstColumn>
            <BackToGuidesWrapper onClick={() => navigate("/pregateste-te")}>
              <BackToGuidesImage src={ArrowLeft} alt="backIcon" />
              <BackToGuidesButton>
                <CustomText
                  text={t("view.getReady.getready")}
                  type={TextType.H4fix}
                  color={COLORS.Paua}
                />
              </BackToGuidesButton>
            </BackToGuidesWrapper>
            <TitleWrapper>
              {prep !== null ? (
                <CustomText
                  text={`${prep && prep.title}`}
                  type={!isMobile ? TextType.H1custom : TextType.H2}
                />
              ) : (
                <Skeleton
                  width={"100%"}
                  height={"3rem"}
                  count={1}
                  borderRadius={BORDER_RADIUS.radius21}
                  baseColor={COLORS.WhiteSmoke}
                  highlightColor={COLORS.Solitude}
                />
              )}
            </TitleWrapper>

            <LeftCard>
              <LeftCardTitle>
                <CustomText
                  text={t("view.singleGuides.chapters")}
                  type={TextType.H3}
                />
              </LeftCardTitle>
              {chapters.length > 0 ? (
                chapters.map((chapter, index) => (
                  <LeftCardChapter
                    key={index}
                    onClick={() => scrollToChapter(index)}
                  >
                    <RedCircleWrapper>
                      <RedCircle />
                    </RedCircleWrapper>
                    <CustomText
                      text={chapter.attributes.title}
                      type={
                        index === activeChapter
                          ? TextType.P2fixbold
                          : TextType.P2fix
                      }
                    />
                  </LeftCardChapter>
                ))
              ) : (
                <LeftCardChapter>
                  <RedCircleWrapper>
                    <RedCircle />
                  </RedCircleWrapper>
                  <SkeletonWrapperChapter>
                    <Skeleton
                      width={"100%"}
                      count={1}
                      baseColor={COLORS.Solitude}
                      highlightColor={COLORS.WhiteSmoke}
                    />
                  </SkeletonWrapperChapter>
                </LeftCardChapter>
              )}
            </LeftCard>
          </InsideFirstColumn>
        </FirstColumn>
        <SecondColumn>
          {chapters !== null && chapters.length > 0 ? (
            chapters.map((chapter, index: number) => (
              <ScrollTargetWrapper
                ref={contentRefs.current[index]}
                key={index}
                id={modRewrite(chapter.attributes.title, chapter.id)}
              >
                <ContentCard>
                  <CenterCardTitle>
                    <CustomText
                      text={chapter.attributes.title}
                      type={TextType.H3}
                    />
                  </CenterCardTitle>
                  {chapter.attributes.content && (
                    <StrapiTreeText text={chapter.attributes.content} />
                  )}
                </ContentCard>
              </ScrollTargetWrapper>
            ))
          ) : (
            <>
              <ContentCard tabIndex={0}>
                <CenterCardTitle>
                  <Skeleton
                    width={"100%"}
                    count={1}
                    baseColor={COLORS.Solitude}
                    highlightColor={COLORS.WhiteSmoke}
                  />
                </CenterCardTitle>
                <Skeleton
                  width={"100%"}
                  count={15}
                  baseColor={COLORS.Solitude}
                  highlightColor={COLORS.WhiteSmoke}
                />
              </ContentCard>
            </>
          )}
        </SecondColumn>
        <ThirdColumn>
          <InsideThirdColumn>
            <SocialMediaCard>
              <CustomText text={"SHARE"} type={TextType.H4fix} />
              <SocialMediaIcons>
                <FacebookShareButton url={window.location.href}>
                  <SocialMediaIcon src={facebookIcon} alt="facebookIcon" />
                </FacebookShareButton>
                <LinkedinShareButton url={window.location.href}>
                  <SocialMediaIcon src={linkedInIcon} alt="linkedInIcon" />
                </LinkedinShareButton>
                <CopyToClipboardWrapper onClick={handleCopyToClipboard}>
                  <SocialMediaIcon src={shareIcon} alt="shareIcon" />
                </CopyToClipboardWrapper>
              </SocialMediaIcons>
            </SocialMediaCard>
            <OtherGuidesCard>
              <CustomText
                text={t("view.getReady.getreadyOtherChapters")}
                type={TextType.H3fix}
              />
              {randomGuides.length > 0 ? (
                randomGuides.map((el: any, index: number) => {
                  return (
                    <OtherChapters
                      key={index}
                      onClick={() =>
                        navigate(
                          `/ghiduri/${modRewrite(el.attributes.title, el.id)}`
                        )
                      }
                    >
                      <GuideCardAtom
                        icon={
                          <ImgStyled
                            src={
                              el.attributes.icon.data !== null
                                ? `${baseUrl}${el.attributes.icon.data.attributes.url}`
                                : iconPlaceholder
                            }
                          />
                        }
                        cardType={CardType.SingleGuideStyle}
                        width={"80px"}
                        height={"80px"}
                        handleCardPress={() => false}
                      />
                      <CustomText
                        text={el.attributes.title}
                        type={TextType.H5fix}
                      />
                    </OtherChapters>
                  );
                })
              ) : (
                <>
                  {[...Array(skeletonCount)].map((_, index) => (
                    <SkeletonWrapperOtherChapter key={index}>
                      <Skeleton
                        width={"80px"}
                        height={"80px"}
                        borderRadius={BORDER_RADIUS.radius16}
                        baseColor={COLORS.Solitude}
                        highlightColor={COLORS.WhiteSmoke}
                      />
                      <SkeletonWrapperChapter>
                        <Skeleton
                          count={1}
                          baseColor={COLORS.Solitude}
                          highlightColor={COLORS.WhiteSmoke}
                        />
                      </SkeletonWrapperChapter>
                    </SkeletonWrapperOtherChapter>
                  ))}
                </>
              )}
            </OtherGuidesCard>
          </InsideThirdColumn>
        </ThirdColumn>
      </Body>
      {isMobile && (
        <BackOnTopWrapper onClick={scrollToTop}>
          <BackOnTopImg src={ArrowUpIcon} alt="ArrowUpIcon" />
        </BackOnTopWrapper>
      )}
    </SingleGuideContainer>
  );
};

export default SingleGuide;
