import React, { useState, useRef, useEffect } from "react";

// ASSETS
import XSignIcon from "assets/svg/XSign.svg";
import emptyCheckboxIcon from "assets/svg/emptyCheckbox.svg";
import filledCheckboxIcon from "assets/svg/filledCheckbox.svg";

// STYLES
import {
  LanguageWrapper,
  LanguageMenuContainter,
  LanguageMenuTitleContainer,
  LanguageMenuCloseButton,
  LanguageMenuCloseIcon,
  LanguageMenuItemsContainer,
  LanguageMenuItemWrapper,
  LanguageMenuItemCheckboxWrapper,
  LanguageMenuItemCheckboxIcon,
} from "./LanguageModalStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// MISC
import { IProps, Option } from "./LanguageModalModel";
import { TextType } from "../../CustomText/CustomTextModel";
import { COLORS } from "constants/variables";

// REDUX
import {
  setLanguage,
  selectLanguage,
} from "slices/LanguageSlice/languageSlice";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import CustomText from "../../CustomText/CustomText";

// CONFIGURATION

const LanguageModal = (props: IProps) => {
  // PROPERTIES
  const {
    options = [
      {
        label: "",
        value: "",
      },
    ],
    onConfirm = (options: any[]) => {
      return options;
    },
    setIsOpenProps,
    isOpenProps = false,
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const selectedLanguage = useSelector(selectLanguage);

  // STATE CONSTANTS
  const [selectedOptions, setSelectedOptions] = useState<Option>();

  // LIFE CYCLE
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpenProps();
      }
    };

    if (isOpenProps) {
      setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
      }, 200);
    }

    return () => {
      setTimeout(() => {
        document.removeEventListener("click", handleClickOutside);
      }, 200);
    };
  }, [isOpenProps, setIsOpenProps]);

  useEffect(() => {
    const initialSelectedOption = options.find(
      (option) => option.value === selectedLanguage
    );
    if (initialSelectedOption) {
      setSelectedOptions(initialSelectedOption);
    }
  }, [selectedLanguage, options]);

  // EVENT HANDLERS
  const handleSelectOptions = (option: Option) => {
    setSelectedOptions(option);
    i18n.changeLanguage(option.value);
    dispatch(setLanguage(option.value));
    handleConfirm([option]);
  };

  const evaluateCheckbox = (option: Option) => {
    return selectedOptions.value === option.value
      ? filledCheckboxIcon
      : emptyCheckboxIcon;
  };

  const handleConfirm = (selected: Option[]) => {
    onConfirm(selected);
    setIsOpenProps();
  };

  return (
    isOpenProps && (
      <LanguageWrapper ref={menuRef}>
        <LanguageMenuContainter isOpen={isOpenProps}>
          <LanguageMenuTitleContainer>
            <CustomText
              text={t("components.header.selectLanguage")}
              type={TextType.H4}
              color={COLORS.BlackRussian}
            />
            <LanguageMenuCloseButton
              onClick={() => setIsOpenProps()}
              biggerCursor={biggerCursor}
            >
              <LanguageMenuCloseIcon src={XSignIcon} />
            </LanguageMenuCloseButton>
          </LanguageMenuTitleContainer>
          <LanguageMenuItemsContainer>
            {options?.map((option) => (
              <LanguageMenuItemWrapper key={`option-${option.value}`}>
                <CustomText
                  text={option.label}
                  type={TextType.H5}
                  color={COLORS.BlackRussian}
                />
                <LanguageMenuItemCheckboxWrapper
                  onClick={() => handleSelectOptions(option)}
                >
                  <LanguageMenuItemCheckboxIcon
                    src={evaluateCheckbox(option)}
                    biggerCursor={biggerCursor}
                  />
                </LanguageMenuItemCheckboxWrapper>
              </LanguageMenuItemWrapper>
            ))}
          </LanguageMenuItemsContainer>
        </LanguageMenuContainter>
      </LanguageWrapper>
    )
  );
};

export default LanguageModal;
