// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import {
  COLORS,
  FONT_WEIGHT,
  FONT_FAMILY,
  FONT_SIZE,
  BORDER_RADIUS,
  SIZE,
  DEVICE_TYPE,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const DashboardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${SIZE.max160} 0px ${SIZE.max84} 0px;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
    justify-content: flex-start;
  }
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FONT_SIZE.max60};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.bold};
  color: ${COLORS.Cinnabar};
  margin-bottom: ${SIZE.max10};
  z-index: 10;
`;

export const Subtitle = styled.h2`
  font-size: ${FONT_SIZE.max24};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.semiBold};
  color: ${COLORS.WhiteSmoke};
  margin-bottom: ${SIZE.max55};
  z-index: 10;
`;

export const InputContainer = styled.div`
  width: ${SIZE.max720};
  margin-bottom: ${SIZE.max24};
  z-index: 500;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: auto;
    max-width: 330px;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: ${SIZE.max16};
  align-items: center;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max16};

  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    align-items: center;
    order: 2;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: ${SIZE.max16};
  justify-content: start;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const CardWrapper = styled.a(
  ({ width, height, biggerCursor }) => css`
    position: relative;
    overflow: hidden;
    opacity: 0.75;
    width: ${width};
    height: ${height};
    background-color: ${COLORS.MandarianOrange};
    border-radius: ${BORDER_RADIUS.radius24};
    cursor: ${biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
    z-index: 10;
    transition: 0.3s opacity ease-out;
    text-decoration: none;
    &:hover {
      transition: 0.3s ease-in;
      opacity: 1;
    }
    @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
      order: 1;
    }
  `
);

export const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  overflow: hidden;
`;

export const CardImage = styled.img`
  position: relative;
  right: 0;
  top: 5%;
  bottom: 0;
  width: 100%;
  height: 100%;
  left: 15%;
  object-fit: cover;
  overflow: auto;
`;

export const CardTextOverlay = styled.div`
  font-size: ${FONT_SIZE.max16};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.medium};
  position: absolute;
  top: ${SIZE.max32};
  left: ${SIZE.max40};
  color: ${COLORS.TangerineYellow};
  font-size: ${FONT_SIZE.max18};
  z-index: 2;
`;

export const CardWrapperVertical = styled.a(
  ({ width, height, biggerCursor }) => css`
    cursor: ${biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
    position: relative;
    padding: ${SIZE.max65} 0 ${SIZE.fix32} 0;
    display: flex;
    flex-direction: column;
    opacity: 0.75;
    width: ${width};
    height: ${height};
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: ${COLORS.MandarianOrange};
    border-radius: ${BORDER_RADIUS.radius24};
    gap: ${SIZE.max40};
    transition: 0.3s opacity ease-out;
    text-decoration: none;
    &:hover {
      transition: 0.3s ease-in;
      opacity: 1;
    }
    @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
      order: 2;
    }
  `
);
export const CardTitleVertical = styled.div`
  max-width: 55%;
  text-align: center;
`;

export const CardImageVertical = styled.img`
  width: 60%;
  height: auto;
`;

export const ImgStyled = styled.img`
  cursor: pointer;
  transition: 0.3s ease-in;
`;

export const StyledSource = styled.source``;
