// ASSETS

// STYLES
import {
  CardContainer,
  CardWrapper,
  Antet,
  DateWrapper,
  InfoContainer,
  ContentWrapper,
  ImageContainer,
  TitleContainer,
  Image,
  ButtonWrapper,
} from "./CampaignCardStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MISC
import { ButtonType } from "../ButtonAtom/ButtonAtomModel";
import { TextType } from "../CustomText/CustomTextModel";
import { ICampaigneCardType, ICampaignCardProps } from "./CampaignCardModel";
import { COLORS } from "../../../constants/variables";

// REDUX

// COMPONENTS
import CustomText from "../CustomText/CustomText";
import ButtonAtom from "../ButtonAtom/ButtonAtom";

const CampaignCard = (props: ICampaignCardProps) => {
  // PROPERTIES
  const {
    id,
    date,
    title,
    text,
    imageUrl,
    onCardClick,
    type = ICampaigneCardType.ORIZONTAL,
  } = props;

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const { t } = useTranslation();

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  return (
    <CardWrapper onClick={onCardClick} type={type}>
      <CardContainer type={type}>
        <InfoContainer type={type}>
          {type === ICampaigneCardType.ORIZONTAL && (
            <Antet>
              <CustomText
                type={TextType.H4}
                color={COLORS.Cinnabar}
                text={t("atoms.campaignCard.campaign")}
              />
            </Antet>
          )}
          <DateWrapper>
            <CustomText type={TextType.H4} color={COLORS.Paua} text={date} />
          </DateWrapper>
          <TitleContainer type={type}>
            <CustomText
              type={TextType.H2}
              color={COLORS.BlackRussian}
              text={title}
            />
          </TitleContainer>
          {type === ICampaigneCardType.ORIZONTAL && (
            <ContentWrapper type={type}>
              <CustomText
                type={TextType.P1fix}
                color={COLORS.BlackRussian}
                text={text}
              />
            </ContentWrapper>
          )}

          <ButtonWrapper type={type}>
            <ButtonAtom
              text={t("atoms.campaignCard.open")}
              type={ButtonType.ArrowButton}
              rightIcon={true}
              onClick={() => {
                navigate("/campanii/" + id);
              }}
            />
          </ButtonWrapper>
        </InfoContainer>
      </CardContainer>
      <ImageContainer type={type}>
        <Image src={imageUrl} alt="icon" />
      </ImageContainer>
    </CardWrapper>
  );
};

export default CampaignCard;
