// GuideCardAtomStyled.tsx
import styled, { css } from "styled-components";
//MISC
import {
  COLORS,
  SIZE,
  BORDER_RADIUS,
  DEVICE_SIZE,
} from "../../../constants/variables";
import { ICampaigneCardType } from "./CampaignCardModel";

export const CardWrapper = styled.div<{ type?: ICampaigneCardType }>`
  display: grid;
  width: 100%;
  max-height: 600px;
  height: 100%;
  min-height: 500px;
  //
  max-width: 100%;
  ${({ type }) =>
    type === ICampaigneCardType.ORIZONTAL &&
    css`
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      min-width: 320px;
      width: 100%;
      max-width: 1500px;
    `}
  ${({ type }) =>
    type === ICampaigneCardType.VERTICAL &&
    css`
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 1fr);
      max-width: 700px;
      /* width: 100%; */
      width: 45vw;
      min-width: 600px;
      height: 100%;
    `}
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    max-width: 740px;
    min-width: 450px;
    width: 100%;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    min-width: 280px;
    width: 100%;
    min-height: 100%;
  }
  @media (min-aspect-ratio: 1/1) and (max-width: ${DEVICE_SIZE.TABLET_MED}) and (max-height: 900px) {
    min-height: 300px;
    height: 100%;
    max-height: 480px;
  }
  @media (max-aspect-ratio: 1/2) and (max-width: ${DEVICE_SIZE.MOBILE}) and (max-height: 1000px) {
    max-height: 85%;
    min-height: 280px;
  }
  //SURFACE DUO
  @media (width: 540px) and (height: 720px) {
    max-width: 450px;
    max-height: 500px;
    min-height: 400px;
    min-width: 300px;
    ${({ type }) =>
      type === ICampaigneCardType.VERTICAL &&
      css`
        height: 500px;
        width: 500px;
      `}
  }
  //GalaxyFold
  @media (width: 280px) and (height: 653px) {
    min-width: 200px;
    width: 100%;
    max-width: 260px;
  }
`;

export const CardContainer = styled.div<{ type?: ICampaigneCardType }>`
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius40};
  display: flex;
  z-index: 1;
  height: 100%;
  padding: ${SIZE.max40};
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 6;
    align-items: flex-end;
    padding-right: ${SIZE.max65};
  }
  @media (width: 540px) and (height: 720px) {
    ${({ type }) =>
      type === ICampaigneCardType.VERTICAL &&
      css`
        width: 500px;
      `}
  }
  @media (width: 280px) and (height: 653px) {
    max-width: 260px;
    min-width: 200px;
    width: 100%;
  }
  ${({ type }) =>
    type === ICampaigneCardType.ORIZONTAL &&
    css`
      border: solid ${SIZE.max16} ${COLORS.Solitude};
      @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
        border: solid ${SIZE.max10} ${COLORS.Solitude};
      }
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      padding-right: ${SIZE.max320};
    `}

  ${({ type }) =>
    type === ICampaigneCardType.VERTICAL &&
    css`
      min-width: 600px;
      max-width: 740px;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      grid-column-start: 1;
      grid-row-start: 2;
      grid-row-end: 6;
      align-items: flex-end;
      padding-right: ${SIZE.max65};
      //
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
        min-width: 280px;
        width: 100%;
      }
    `}
`;

export const Antet = styled.div`
  margin-bottom: ${SIZE.max24};
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    display: none;
  }
`;
export const DateWrapper = styled.div``;
export const InfoContainer = styled.div<{ type?: ICampaigneCardType }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${SIZE.max16};
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    gap: ${SIZE.max8};
  }
  @media (width: 540px) and (height: 720px) {
    ${({ type }) =>
      type === ICampaigneCardType.VERTICAL &&
      css`
        max-width: 100%;
      `}
  }
  ${({ type }) =>
    type === ICampaigneCardType.VERTICAL &&
    css`
      width: 100%;
      height: 100%;
      padding-top: ${SIZE.max32};
      align-items: space-between;
      grid-column-start: 1;
      grid-row-start: 2;
      grid-row-end: 3;
      position: relative;
    `}
`;

export const TitleContainer = styled.div<{ type?: ICampaigneCardType }>`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    margin-bottom: 0;
    height: 90px;
    display: flex;
    align-items: flex-start;
    text-overflow: ellipsis;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    -webkit-line-clamp: 3;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    height: 60px;
  }
  @media (width: 540px) and (height: 720px) {
    -webkit-line-clamp: 2 !important;
    height: 60px;
  }
  @media (min-aspect-ratio: 1/1) and (max-width: ${DEVICE_SIZE.TABLET_MED}) and (max-height: 900px) {
    ${({ type }) =>
      type === ICampaigneCardType.ORIZONTAL &&
      css`
        -webkit-line-clamp: 2 !important;
        height: 55px;
      `}
  }
  @media (width: 540px) and (height: 720px) {
    ${({ type }) =>
      type === ICampaigneCardType.VERTICAL &&
      css`
        max-width: 100%;
      `}
  }
  //GalaxyFold
  @media (width: 280px) and (height: 653px) {
    -webkit-line-clamp: 1 !important;
    height: 30px;
  }
  ${({ type }) =>
    type === ICampaigneCardType.ORIZONTAL &&
    css`
      -webkit-line-clamp: 3;
    `}
  ${({ type }) =>
    type === ICampaigneCardType.VERTICAL &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      //
      width: 100%;
    `}
`;
export const ButtonWrapper = styled.div<{ type?: ICampaigneCardType }>`
  ${({ type }) =>
    type === ICampaigneCardType.VERTICAL &&
    css`
      position: absolute;
      left: 0;
      bottom: 0;
    `}
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    display: none;
  }
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${SIZE.max24};
  max-height: 95px;
`;
export const ImageContainer = styled.div<{ type?: ICampaigneCardType }>`
  flex-grow: 1;
  z-index: 2;
  border-radius: ${BORDER_RADIUS.radius40};
  object-fit: cover;
  max-width: 100%;
  max-height: 650px;
  position: relative;

  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 4;
    aspect-ratio: 1 !important;
    width: 100%;
    height: auto;
  }
  @media (width: 540px) and (height: 720px) {
    ${({ type }) =>
      type === ICampaigneCardType.VERTICAL &&
      css`
        width: 450px;
        min-width: 450px;
        max-width: 450px;
      `}
  }
  @media (width: 280px) and (height: 653px) {
    max-width: 260px;
    min-width: 200px;
    width: 100%;
  }
  ${({ type }) =>
    type === ICampaigneCardType.ORIZONTAL &&
    css`
      height: 100% !important;
      width: auto;
      grid-column-start: 3;
      grid-column-end: 7;
      grid-row-start: 1;
      align-self: end;
    `}
  ${({ type }) =>
    type === ICampaigneCardType.VERTICAL &&
    css`
      width: 100%;
      height: auto !important;
      max-height: 100%;
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 4;
      min-width: 600px;
      max-width: 740px;
      width: 100% !important;
      @media (max-width: ${DEVICE_SIZE.MOBILE}) {
        width: 100%;
        min-width: 280px;
      }
    `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  border-radius: ${BORDER_RADIUS.radius40};
`;
