import React from "react";

// ASSETS

// STYLES
import { SectionContainer } from "./SectionWrapperStyled";

// LIBRARIES

// MISC
import { ISectionProps } from "./SectionWrapperModel";

// COMPONENTS

// CONFIGURATION
const SectionWrapper = (props: ISectionProps) => {
  const { hasPadding = true, children, background } = props;

  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <SectionContainer hasPadding={hasPadding} background={background}>
      {children}
    </SectionContainer>
  );
};

export default SectionWrapper;
