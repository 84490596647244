import React from "react";

// ASSETS
import { IconButtonAtomProps } from "./IconButtonAtomModel";

// STYLES
import {
  IconWrapper,
  IconImage,
  IconButtonAtomContainer,
  HoverText,
} from "./IconButtonAtomStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
// MISC
import { TextType } from "../CustomText/CustomTextModel";
// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import CustomText from "../CustomText/CustomText";

const IconButtonAtom: React.FC<IconButtonAtomProps> = (props) => {
  // PROPERTIES
  const { icon, iconSelected, isSelected, onClick, hoverText } = props;
  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const isMobile = useMediaQuery({ maxWidth: 1280 });
  // STATE CONSTANTS
  const iconToUse = isSelected ? iconSelected : icon;

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <IconButtonAtomContainer tabIndex={0}>
      <IconWrapper
        isSelected={isSelected}
        onClick={onClick}
        biggerCursor={biggerCursor}
      >
        <IconImage src={iconToUse} alt="icon" />
        {!isMobile && (
          <HoverText isMobile={isMobile}>
            <CustomText text={hoverText} type={TextType.H5fix} />
          </HoverText>
        )}
      </IconWrapper>
    </IconButtonAtomContainer>
  );
};

export default IconButtonAtom;
