// LIBRARIES
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./sessionSliceModel";
import { revertAll } from "store/store";

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    handleLogout: () => initialState,
    handleSetToken: (state, action) => {
      state.token = action.payload;
    },
    handleChangeSnackBar: (state, action) => {
      state.snackBar = {
        ...state.snackBar,
        ...action.payload.snackBar,
      };
      state.snackBarAlert = {
        ...state.snackBarAlert,
        ...action.payload.snackBarAlert,
      };
    },
    handleIsLoadingChange: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  handleLogout,
  handleChangeSnackBar,
  handleSetToken,
  handleIsLoadingChange,
} = sessionSlice.actions;

export const sessionSelector = (state) => state.session;
export default sessionSlice.reducer;
