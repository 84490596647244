import styled from "styled-components";

export const CirclePosition = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  z-index: 0;
`;

export const Circle = styled.div`
  position: absolute;
  border-radius: 50%;
  background: ${(props) => props.color};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  bottom: ${(props) => props.bottom || "50%"};
  left: ${(props) => props.left || "50%"};
  transform: ${(props) => props.transform || "translate(-50%, 50%)"};
  z-index: 0;
`;

const CircleLayer = ({ size, index, colors, top, left, right, bottom }) => (
  <Circle
    color={index % 2 === 0 ? colors.firstColor : colors.secondColor}
    size={size}
    bottom={bottom}
    left={left}
    top={top}
    right={right}
  />
);

export const CirclesContainer = ({
  sizes,
  colors,
  top,
  left,
  right,
  bottom,
}) => (
  <>
    {sizes.map((size, index) => (
      <CircleLayer
        key={index}
        size={size}
        index={index}
        colors={colors}
        bottom={bottom}
        left={left}
        top={top}
        right={right}
      />
    ))}
  </>
);
