// LIBRARIES
import styled, { css } from "styled-components";
import "swiper/css";

// MISC
import { SIZE, COLORS, DEVICE_SIZE } from "../../constants/variables";
import { ICampaigneCardType } from "components/Atoms/CampaignCard/CampaignCardModel";

export const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${SIZE.max40};
  max-height: 750px;
  .mySwiper {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination-bullet {
    background: ${COLORS.BlackRussian};
    height: 12px;
    width: 12px;
  }
  .swiper-pagination {
  }
  .swiper-pagination-bullet-active {
    background: ${COLORS.Cinnabar};
  }
  .swiper-slide-active {
  }
  .swiper-slide-next {
  }
`;

export const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: ${SIZE.max65};
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    margin: ${SIZE.max24} 0;
  }

  .campaign-card {
    overflow: hidden;
  }
`;

export const ScrollContainerCampaign = styled.div`
  width: 100% !important;
  height: 100%;
  align-items: center;
  justify-content: center;
  .swiper-slide {
    margin: 0 30px;
    width: fit-content;
  }
  .mySwiper {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination-bullet-active {
  }
  .swiper-slide-active {
  }
  .swiper-slide-next {
  }
`;

export const CarouselItemCampaign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  .campaign-card {
    overflow: visible;
  }
`;

export const CarouselSkeletonItem = styled.div<{ type: string }>`
  width: 100%;
  max-height: 650px;
  height: 100%;
  min-height: 600px;
  //
  max-width: 100%;
  ${({ type }) =>
    type === ICampaigneCardType.ORIZONTAL &&
    css`
      min-width: 320px;
      width: 100%;
      max-width: 1720px;
      height: ${SIZE.max550};
    `}
  ${({ type }) =>
    type === ICampaigneCardType.VERTICAL &&
    css`
      max-width: 740px;
      width: 45vw;
      min-width: 600px;
      height: 100%;
    `}
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    max-width: 740px;
    min-width: 450px;
    width: 100%;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    min-width: 280px;
    width: 100%;
    min-height: 100%;
  }
  @media (min-aspect-ratio: 1/1) and (max-width: ${DEVICE_SIZE.TABLET_MED}) and (max-height: 900px) {
    min-height: 300px;
    height: 100%;
    max-height: 480px;
  }
  @media (max-aspect-ratio: 1/2) and (max-width: ${DEVICE_SIZE.MOBILE}) and (max-height: 1000px) {
    max-height: 85%;
    min-height: 280px;
  }
  //SURFACE DUO
  @media (width: 540px) and (height: 720px) {
    max-width: 450px;
    max-height: 500px;
    min-height: 400px;
    min-width: 300px;
    ${({ type }) =>
      type === ICampaigneCardType.VERTICAL &&
      css`
        height: 500px;
        width: 500px;
      `}
  }
  //GalaxyFold
  @media (width: 280px) and (height: 653px) {
    min-width: 200px;
    width: 100%;
    max-width: 260px;
  }
`;
