// LIBRARIES
import styled from "styled-components";

// MISC
import {
  COLORS,
  FONT_SIZE,
  FONT_FAMILY,
  FONT_WEIGHT,
  BORDER_RADIUS,
  SIZE,
  MARGIN,
  DEVICE_TYPE,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  background-color: transparent;
  z-index: 1000;
  align-items: center;
  padding: ${MARGIN.max24} 0;
`;

export const Logo = styled.img<{ biggerCursor?: boolean }>`
  height: 42px;
  padding: 4px;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
`;
export const LogoTextContainer = styled.button<{ biggerCursor?: boolean }>`
  display: flex;
  align-items: center;
  border: 0;
  border-top-right-radius: ${BORDER_RADIUS.radius32};
  border-bottom-right-radius: ${BORDER_RADIUS.radius32};
  padding-right: ${SIZE.max16};
  background-color: ${COLORS.WhiteSmoke};
  height: 50px;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: 42px;
  }
`;

export const HeaderText = styled.p`
  margin: 0;
  font-size: ${FONT_SIZE.max18};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.bold};
  color: ${COLORS.Cinnabar};
  background-color: transparent;
`;

export const LogoContainer = styled.div<{ biggerCursor?: boolean }>`
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: ${BORDER_RADIUS.radius32};
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  background-color: ${COLORS.WhiteSmoke};
`;
