// LIBRARIES
import styled from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  DEVICE_SIZE,
  DEVICE_TYPE,
  SIZE,
} from "constants/variables";

import CircleRightCut from "assets/img/CirleRightCut.png";

export const AboutContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${COLORS.Solitude};
`;

export const AboutPageHeroContainer = styled.div`
  width: 100%;
  padding: ${SIZE.max160} ${SIZE.max84} ${SIZE.max480} ${SIZE.max84};
  position: relative;
`;
export const PartnersSectionsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: ${SIZE.max40} 0;
`;
export const PartnersCardsContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${SIZE.max32} ${SIZE.max104};
`;
export const PartnersCardsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${SIZE.max24};
  flex-direction: row;
  z-index: 50;
`;
export const AboutPageHeroComponent = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;

  @media (width<1300px) {
    height: 100%;
  }
`;

export const AboutPageHeroIconWrapper = styled.div`
  width: fit-content;
  z-index: 4;
  position: absolute;
`;

export const AboutPageHeroIcon = styled.img`
  height: clamp(100px, 18vw, 360px);

  @media (width<768px) {
    height: clamp(120px, 22vw, 160px);
  }
`;

export const AboutPageHeroTextContainer = styled.div`
  width: 100%;
  border-radius: ${BORDER_RADIUS.radius48};
  overflow: hidden;
  position: relative;
  top: clamp(120px, 15vw, 280px);
  background-color: ${COLORS.WhiteSmoke};
  padding-inline: ${SIZE.max200};
  padding-block: ${SIZE.max100};

  @media (width<1300px) {
    height: ${SIZE.max360};
    padding-inline: ${SIZE.max104};
  }

  @media (width<= ${DEVICE_SIZE.TABLET_MED}) {
    padding-inline: ${SIZE.max32};
    height: fit-content;
    top: clamp(120px, 12vw, 140px);
  }
  @media (width<768px) {
    padding-inline: ${SIZE.max32};
    height: fit-content;
    top: clamp(100px, 10vw, 120px);
  }
`;

export const CirclesWrapper = styled.div`
  z-index: 1;
`;

export const AboutPageHeroTextContainerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;

  @media (width<= ${DEVICE_SIZE.TABLET_MED}) {
    align-items: center;
    row-gap: ${SIZE.max24};
    padding-inline: ${SIZE.max24};
    padding-block: ${SIZE.max104};
  }

  @media (width<= 900px) {
    flex-direction: column;
  }

  @media (width<768px) {
    flex-direction: column;

    row-gap: ${SIZE.max24};
    padding-inline: ${SIZE.max24};
    padding-block: ${SIZE.max104} ${SIZE.max200};
  }
`;

export const AboutPageHeroTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 4;
`;

export const AboutPageHeroTextWrapper = styled.div`
  max-width: ${SIZE.max480};
  z-index: 4;
`;
export const InlineContainer = styled.div`
  display: inline;
`;

export const AboutPagePreventionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 4;
  margin: ${SIZE.max16} 0;
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    padding: 0 ${SIZE.max40};
  }
`;

export const SubtitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;
  margin: ${SIZE.max16} 0;
`;

export const AboutPagePreventionCardsContainer = styled.div`
  padding-top: ${SIZE.max84};
`;

export const AboutPagePreventionCardWrapper = styled.div``;

export const AboutPageSupportContainer = styled.div`
  width: 100%;
  position: relative;
  background-image: url(${CircleRightCut});
  background-size: 40vh;
  background-position: right 0 top 50px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SIZE.max104} 0;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
  }
`;

export const AboutPageSupportersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AboutPageSupportersLogosWrapper = styled.div`
  display: flex;
  column-gap: ${SIZE.max24};
  @media (width<768px) {
    flex-direction: column;
    row-gap: ${SIZE.max16};
  }
`;

export const SupportersLogoContainer = styled.div`
  width: fit-content;
  max-width: 380px;
  height: ${SIZE.max160};
  background-color: ${COLORS.WhiteSmoke};
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${SIZE.max24};
  padding-inline: ${SIZE.max32};
  border-radius: ${BORDER_RADIUS.radius24};
  z-index: 4;
  @media (width<768px) {
    max-width: 320px;
    padding-inline: ${SIZE.max40};
    height: ${SIZE.max200};
  }
`;
export const DeveloperLogoContainer = styled.div`
  width: fit-content;
  max-width: 380px;
  height: ${SIZE.max160};
  background-color: ${COLORS.WhiteSmoke};
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${SIZE.max24};
  padding-inline: ${SIZE.max32};
  border-radius: ${BORDER_RADIUS.radius24};
  z-index: 4;
  @media (width<768px) {
    max-width: 320px;
    padding-inline: ${SIZE.max40};
    height: ${SIZE.max200};
  }
`;
export const DeveloperLogo = styled.img`
  width: 200px;
`;
export const SupportersLogo = styled.img`
  width: 60px;
`;
