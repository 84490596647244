import React from "react";

// ASSETS

// STYLES
import { IconContainer } from "./IconAtomStyled";

// LIBRARIES

// MISC
import { IProps } from "./IconAtomModel";

// COMPONENTS

// CONFIGURATION

const IconAtom = (props: IProps) => {
  // PROPERTIES

  const { icon, label, height = "100px" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <IconContainer height={height} aria-label={label}>
      {icon}
    </IconContainer>
  );
};

export default IconAtom;
