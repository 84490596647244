import React, { useState } from "react";

// ASSETS
import ArrowDownBlack from "assets/svg/ArrowDownBlack.svg";
// STYLES
import {
  CardContainer,
  CardHeader,
  CardContent,
  DropdownButton,
  DropdownContent,
  Img,
} from "./AccordionComponentStyled";

// LIBRARIES

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";

const AccordionComponent = ({ content, dropdownContent }) => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS
  const [isOpen, setIsOpen] = useState(false);
  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <CardContainer onClick={toggleDropdown} biggerCursor={biggerCursor}>
      <CardHeader>
        <CardContent>
          <CustomText text={content} type={TextType.H3} />
        </CardContent>
        <DropdownButton>
          <Img src={ArrowDownBlack} alt="arrowdownIcon" isRotated={isOpen} />
        </DropdownButton>
      </CardHeader>
      <DropdownContent isOpen={isOpen}>{dropdownContent}</DropdownContent>
    </CardContainer>
  );
};

export default AccordionComponent;
