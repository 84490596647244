import styled, { css } from "styled-components";
import { BORDER_RADIUS, COLORS, DEVICE_SIZE, MARGIN, SIZE } from "../../config/variables";
import { ProjectCardType } from "./CookiesCardModel";

export const CardContainer = styled.div`
  width: 100%;
  max-width: 1464px;
  border-radius: ${BORDER_RADIUS.radius32};
  background-color: ${COLORS.Cultured};
  padding: ${MARGIN.max40};
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.fix24};
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    gap: ${MARGIN.fix16};
    padding: ${MARGIN.max24};
  }  
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.max8};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.max8};
  margin-top: -${MARGIN.max8};
  align-items: flex-start;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${MARGIN.max8};
`;

export const Bullet = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #E62E2E;
`;

export const ButtonContainer = styled.div`
  width: 152px;
  height: 42px;
`;