// LIBRARIES
import styled from "styled-components";

// MISC
import { COLORS } from "constants/variables";

export const LoadingAnimationContainer = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 1301;
  left: 0;
  top: 0;
  background-color: ${COLORS.BlackRussian}25;

  display: flex;
  align-items: center;
  justify-content: center;
`;
