// LIBRARIES
import styled from "styled-components";

// MISC
import { IProps } from "components/Atoms/IconAtom/IconAtomModel";

export const IconContainer = styled.span<IProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: "auto";
    height: ${(props) => props.height || "auto"};
  }
`;
