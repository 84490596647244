export enum TextType {
  H1prime,
  H1custom,
  H1large,
  H1,
  H2,
  H2fix,
  H3,
  H3fix,
  H4,
  H4fix,
  H4Logo,
  H4bold18px,
  H5,
  H5fix,
  H6,
  H7,
  H7fix,
  P1,
  P1bold,
  P2,
  P2fix,
  P2fixbold,
  P3,
  strong,
  LI,
  UL,
  OL
}
export enum TextSize {
  default,
  large,
}
export enum TextSpacing {
  default,
  large,
}
export enum TextKerning {
  default,
  large,
}

export interface IProps {
  type?: TextType;
  children: any[];
  size?: TextSize;
  color?: string;
  rowSpacing?: TextSpacing;
  kerning?: TextKerning;
  withContainer?: boolean
}
export interface IStyled {
  type: TextType;
  size: TextSize;
  rowSpacing: TextSpacing;
  kerning: TextKerning;
}
