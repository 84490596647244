// GuideCardAtom.tsx
import React from "react";

// ASSETS

// STYLES
import {
  CardWrapper,
  TitleWrapper,
  IconWrapper,
  CenterContainer,
} from "./GuideCardAtomStyled";

// LIBRARIES

// MISC
import { CardType, IGuideCardProps } from "./GuideCardAtomModel";
import { TextType } from "../CustomText/CustomTextModel";
import { SIZE, COLORS, BORDER_RADIUS } from "../../../constants/variables";


// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import IconAtom from "../IconAtom/IconAtom";
import CustomText from "../CustomText/CustomText";
import modRewrite from "../../../locales/modRewrite";
import Skeleton from "react-loading-skeleton";

const GuideCardAtom: React.FC<IGuideCardProps> = (props: IGuideCardProps) => {
  // PROPERTIES
  const { id, title, icon, cardType, width, height, tabIndex } = props;

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS
  const href = id ? { href: "/ghiduri/" + modRewrite(title, id) } : {};
  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  return (
    <>
      {title !== undefined || icon !== undefined ? (
        <CardWrapper
          cardType={cardType}
          width={width}
          height={height}
          tabIndex={tabIndex}
          biggerCursor={biggerCursor}
          {...href}
          draggable="false"
        >
          <TitleWrapper cardType={cardType}>
            <CustomText
              text={title}
              type={TextType.H4fix}
              color={COLORS.BlackRussian}
            />
          </TitleWrapper>
          <CenterContainer>
            <IconWrapper>
              <Skeleton />
            </IconWrapper>
            <IconWrapper>
              <IconAtom
                icon={icon}
                label={title ? title : ""}
                height={
                  cardType === CardType.DashboardStyle
                    ? SIZE.max60
                    : cardType === CardType.SingleGuideStyle
                    ? SIZE.max40
                    : cardType === CardType.GuideStyle
                    ? SIZE.max100
                    : SIZE.max120
                }
              />
            </IconWrapper>
          </CenterContainer>
        </CardWrapper>
      ) : (
        <Skeleton
          width={width}
          height={height}
          baseColor={COLORS.WhiteSmoke + "BF"}
          highlightColor={COLORS.Solitude + "BF"}
          borderRadius={BORDER_RADIUS.radius24}
        />
      )}
    </>
  );
};

export default GuideCardAtom;
