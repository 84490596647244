import React, {Fragment, useEffect} from "react";

// ASSETS
// STYLES
// LIBRARIES
import DOMPurify from "dompurify";
// MISC
import {IProps, TextKerning, TextSize, TextSpacing,} from "./StrapiTreeTextModel";

// REDUX
import {useSelector} from "react-redux";
import {
  accessibilityBiggerLetterSpacingSelector,
  accessibilityBiggerLineHeightSelector,
  accessibilityBiggerTextSelector,
} from "slices/UISlice/uiSliceSelectors";
import {
  StrapiMarkup,
  StrapiTreeTextContainer,
  StrapiTreeTextLabel,
} from "components/Atoms/StrapiTreeText/StrapiTreeTextStyled";

import StrapiText from "components/Atoms/StrapiText/StrapiText";
import {marked} from "marked";
import CustomText from "components/Atoms/CustomText/CustomText";
//import {TextType} from "components/Atoms/CustomText/CustomTextModel";
// import StrapiText from "components/Atoms/StrapiText/StrapiText";
 import { TextType } from "components/Atoms/StrapiText/StrapiTextModel";


// COMPONENTS
const TextSpeller = (props: any) => {
  const {
    text,
    wait
  } = props

  useEffect(() => {
    let counter = 1
    const intervalId = setInterval(()=>{
      setSpell(()=>text.slice(0,counter))
      counter++
      if(counter % 32 === 0) {
        const elem = document.body;
        elem.scrollTop = elem.scrollHeight
      }
      if(counter > text.length) {
        const elem = document.body;
        elem.scrollTop = elem.scrollHeight
        document.getElementById("chat-box").querySelectorAll("a").forEach((el)=>{
          el.target = "_blank"
        })
        clearInterval(intervalId)
      }
    },wait)
  }, [text,wait]);

  const [spell, setSpell] = React.useState<any>("")

  return (<Fragment>
    <StrapiMarkup dangerouslySetInnerHTML={{__html:spell}}/>
  </Fragment>)
}

const checkLink = (child: any, index) => {
  // eslint-disable-next-line
  const regex: RegExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\?(?:(?:\S+&)?v(?:i)?=|\S*[?&]?(?:vi?=))|youtu\.be\/)([a-zA-Z0-9_-]+)/;


  const match = child.url.match(regex);

  if (match) {
    return (
      <div className={"youtube"} key={index}>
        <iframe
          title={"youtube"}
          style={{ width: "100%" }}
          height="315"
          src={`https://www.youtube.com/embed/${match[1]}`}
          allowFullScreen
        ></iframe>
        {/* <div key={`${child.id}-${index}`}><a href={child.url} className={"guideLink"} */}
          {/* rel={"noreferrer"} target={"_blank"}>{renderChildren(child.children)}</a></div> */}
      </div>
    );
  } else {
    return (
      <a
        key={index}
        href={child.url}
        className={"guideLink"}
        rel={"noreferrer"}
        target={"_blank"}
      >
        {renderChildren(child.children)}
      </a>
    );
  }
};



const createMarkup = (markdown) => {
  const renderer = new marked.Renderer();

    renderer.link = function(href, title, text) {

      return `<a href="${href}" target="_blank" title="${text}">${text}</a>`;
    };

    const rawMarkup = marked(markdown,{
      renderer: renderer
    });

    return  DOMPurify.sanitize(rawMarkup);

  };

const renderText = (children: any) => {
    return children.map((child) => {
      if(child.type === "text") {
          return child.text;
      }

      return renderText(child.children);
    })
}
const renderChildren = (children: any[],spell = 0, useMarkup = false) => {
  const Heading = ({ level, children }) => {
    return <StrapiText type={TextType[`H${level}`]}>{children}</StrapiText>;
  };

  const applyMarkup = useMarkup ;

  return children.map((child, index: number) => {

    switch (child.type) {
      case "link":
        return checkLink(child, index);
      case "image":
        return (<Fragment key={index}>
          <img key={index} src={child.image.url} alt={"pht-extern"} />
          <>{renderChildren(child.children,spell,applyMarkup)}</>
        </Fragment>);
      case "paragraph":
        return <StrapiText type={TextType.P1} key={index}>{renderChildren(child.children,spell,applyMarkup)}</StrapiText>;
      case "list-item":
        return <StrapiText type={TextType.LI} key={index}>{renderChildren(child.children,spell,applyMarkup)}</StrapiText>;
      case "heading":
        return <Heading key={index} level={child.level}>{renderChildren(child.children,spell,applyMarkup)}</Heading>;
      case "list":

        if (child.format === "unordered") {
          return <StrapiText key={index} type={TextType.UL}>{renderChildren(child.children,spell,applyMarkup)}</StrapiText>;
        } else {
          return <StrapiText key={index} type={TextType.OL}>{renderChildren(child.children,spell,applyMarkup)}</StrapiText>;
        }


      case "text":

        return child.bold ? <strong key={index}>{child.text}</strong> :
            <Fragment key={index}>
              {spell > 0? <TextSpeller wait={spell} text={createMarkup(child.text)} /> : applyMarkup ?
                  <StrapiMarkup dangerouslySetInnerHTML={{__html:createMarkup(child.text)}}   />
                  :
                  <>{child.text}</>
              }
            </Fragment>
      default:
        return "";
    }
  })
};

// CONFIGURATION
const StrapiTreeText = (props: IProps) => {
  // PROPERTIES
  const {
    type,
    text,
    size = TextSize.default,
    color,
    rowSpacing = TextSpacing.default,
    kerning = TextKerning.default,
    spell = 0,
    textOnly = false,
    useMarkup= false
  } = props;

  const getHtmlTag = (type) => {
    if (typeof type === "string") {
      if (type.startsWith("H")) {
        return `h${type.substring(1)}`;
      } else if (type.startsWith("P")) {
        return "p";
      }
    }
    return "span";
  };

  const Tag = getHtmlTag(type);

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const accessibilityBiggerText: boolean = useSelector(
    accessibilityBiggerTextSelector
  );
  const accessibilityBiggerLineHeight: boolean = useSelector(
    accessibilityBiggerLineHeightSelector
  );
  const accessibilityBiggerLetterSpacing: boolean = useSelector(
    accessibilityBiggerLetterSpacingSelector
  );

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <StrapiTreeTextContainer>
      {text && (
        <StrapiTreeTextLabel
          as={Tag}
          type={type}
          size={size}
          rowSpacing={rowSpacing}
          kerning={kerning}
          color={color}
          accessibilityBiggerText={accessibilityBiggerText}
          accessibilityBiggerLineHeight={accessibilityBiggerLineHeight}
          accessibilityBiggerLetterSpacing={accessibilityBiggerLetterSpacing}
        >

          {textOnly ? <CustomText text={renderText(text)} type={17} /> : renderChildren(text,spell,useMarkup)}

        </StrapiTreeTextLabel>
      )}
    </StrapiTreeTextContainer>
  );
};
export default StrapiTreeText;
