import styled from "styled-components";

export const LandscapeOverlay = styled.div`
  display: ${({ isVisible }) => (isVisible ? "none" : "relative")};
`;

export const LandscapeMessage = styled.div`
  display: ${({ isVisible }) => (isVisible ? "relative" : "none")};
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  flex-direction: column;
  text-align: center;
`;
