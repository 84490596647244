//LIBRARY
import styled from "styled-components";
//MISC
import {
  SIZE,
  MARGIN,
  DEVICE_TYPE,
  COLORS,
  BORDER_RADIUS,
  FONT_SIZE,
} from "constants/variables";

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding: 0 ${SIZE.max84};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: 0 ${MARGIN.max32};
  }
`;
export const ViewMore = styled.div`
  width: 48%;
`;

export const ViewMoreLink = styled.div`
  border-radius: ${BORDER_RADIUS.radius24};
  padding: ${SIZE.max16};
  text-align: center;
  text-decoration: none;
  color: ${COLORS.Paua};
  cursor: pointer;
  background: transparent;
  font-size: ${FONT_SIZE.max16};
  &:hover {
    background: ${COLORS.Paua + "30"};
    color: ${COLORS.BlackRussian};
  }
`;
export const TitleContainer = styled.div`
  padding: ${MARGIN.max40} 0 ${MARGIN.max80} 0;
  @media (max-width: ${DEVICE_TYPE.tablet}) {
    padding-top: ${MARGIN.max80};
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding-top: ${MARGIN.max80};
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${MARGIN.max16};
  width: 100%;
`;
