import { useEffect, useState } from "react";
// ASSETS
import { ReactComponent as DefaultIcon } from "assets/svg/DefaultIcon.svg";

// STYLES
import {
  CarouselSectionWrapper,
  CardCarouselTitle,
  CardCarouselSubtitle,
  TextContent,
} from "./CarouselSectionStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import guideService from "services/guideService";
import { baseUrl } from "services/base";

// REDUX

import { currentLanguageSelector } from "slices/LanguageSlice/languageSliceSelectors";
import { useSelector } from "react-redux";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CardCarousel from "components/CardCarousel/CardCarousel";
import ImgAtom from "components/Atoms/ImgAtom/ImgAtom";
const CarouselSection = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS

  const currentLanguage = useSelector(currentLanguageSelector);

  // STATE CONSTANTS
  const { t } = useTranslation();
  const [guides, setGuides] = useState<any[]>([]);

  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    (async () => {
      let response: any = await guideService.getGuides();
      if (response.data !== null) {
        const tmpGuides = response.data.map((el: any) => ({
          id: el.id,
          title: el.attributes.title,
          icon:
            el.attributes.icon.data == null ? (
              <DefaultIcon />
            ) : (
              <ImgAtom
                img={`${baseUrl}${el.attributes.icon.data.attributes.url}`}
                label={el.attributes.title}
              />
            ),
        }));
        setGuides(tmpGuides);
      }
    })();
  }, [currentLanguage]);

  // EVENT HANDLERS
  return (
    <CarouselSectionWrapper tabIndex={0}>
      <TextContent>
        <CardCarouselTitle>
          <CustomText
            text={t("components.carouselSection.title")}
            type={TextType.H1custom}
          ></CustomText>
        </CardCarouselTitle>
        <CardCarouselSubtitle>
          <CustomText
            text={t("components.carouselSection.info")}
            type={TextType.P1}
          />
        </CardCarouselSubtitle>
      </TextContent>
      <CardCarousel type="HOME" data={guides} />
    </CarouselSectionWrapper>
  );
};

export default CarouselSection;
