import React from "react";

// ASSETS
import RedTriangleIcon from "assets/svg/RedTriangleIcon.svg";
import WhiteTriangleIcon from "assets/svg/WhiteTriangleIcon.svg";
import RightRedIcon from "assets/svg/RightRedIcon.svg";
import RightWhiteIcon from "assets/svg/RightWhiteIcon.svg";
import XSign from "assets/svg/XSign.svg";

// STYLES
import { Button, Icon } from "./ButtonAtomStyled";

// LIBRARIES
import { useSelector } from "react-redux";

// MISC
import { TextType } from "../CustomText/CustomTextModel";
import { COLORS } from "constants/variables";
import { ButtonAtomProps, ButtonType } from "./ButtonAtomModel";

// REDUX
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import CustomText from "../CustomText/CustomText";

const ButtonAtom = (props: ButtonAtomProps) => {
  // PROPERTIES
  const { text, leftIcon, rightIcon, type, onClick, disabled = false, icon } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <Button
      type={type}
      onClick={onClick}
      biggerCursor={biggerCursor}
      disabled={disabled}
    >
      {type === ButtonType.X && <Icon src={XSign} alt="x" type={type} />}
      {type === ButtonType.ChatButton && icon && <Icon src={icon} alt="x" type={type} />}
      {leftIcon && (
        <Icon
          src={disabled ? WhiteTriangleIcon : RedTriangleIcon}
          alt="LeftIcon"
          type={type}
        />
      )}
      {type === ButtonType.Link ? (
        <CustomText
          text={text}
          type={TextType.H7}
          color={COLORS.BlackRussian}
        />
      ) : (
        <CustomText
          text={text}
          type={TextType.H5fix}
          color={
            disabled ||
              type === ButtonType.MapActive ||
              type === ButtonType.RedButton
              ? COLORS.WhiteSmoke
              : type === ButtonType.ArrowDown ||
                type === ButtonType.ManagementButton
                ? COLORS.WhiteSmoke
                : COLORS.BlackRussian
          }
        />
      )}
      {rightIcon && (
        <Icon
          src={disabled ? RightWhiteIcon : RightRedIcon}
          alt="RightIcon"
          type={type}
        />
      )}
    </Button>
  );
};

export default ButtonAtom;
