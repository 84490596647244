import { AxiosInstance } from 'axios';
import axiosBase from './base';
import { store } from 'store/store';

class MapService {
    base: AxiosInstance;

    constructor() {
        this.base = axiosBase.base;
    }

    // Clients
    getIpCategories(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/ip-categories/?locale=${language}`) 
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getIpPoints(categoryId: number): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/ip-points/?pagination[pageSize]=10000&populate=*&filters[ip_category][id][$eq]=${categoryId}&locale=${language}`) 
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getDefibrlators(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/get-scheduled?locale=${language}`) 
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }
}

const mapService = new MapService();
export default mapService;
