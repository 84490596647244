//REACT
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

// ASSETS
import {
  default as defib,
  default as defibOff,
} from "assets/img/map/marker.defib.svg";
import farma from "assets/img/map/marker.farma.svg";
import jandarm from "assets/img/map/marker.jandarm.svg";
import police from "assets/img/map/marker.police.svg";
import pomp from "assets/img/map/marker.pomp.svg";
import salva from "assets/img/map/marker.salva.svg";
import sam from "assets/img/map/marker.sam.svg";
import shelter from "assets/img/map/marker.shelter.svg";
import spital from "assets/img/map/marker.spital.svg";
import upu from "assets/img/map/marker.upu.svg";

// STYLES
import {
  InterestPointsMenu,
  MapContainer,
  PreloaderWrapper,
} from "./MapStyled";

// LIBRARIES
import mapboxgl, { Map } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useTranslation } from "react-i18next";
import Supercluster from "supercluster";
// import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";

//MISC
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS, SIZE } from "constants/variables";
import mapService from "../../services/mapService";
import { IpCategory, IpCategorySet, IpPoint } from "./MapModel";

// REDUX

// COMPONENTS
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import CustomText from "components/Atoms/CustomText/CustomText";
import ResponsiveModal from "components/Atoms/ResponsiveModal/ResponsiveModal";
import { ModalType } from "components/Atoms/ResponsiveModal/ResponsiveModalModel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MapDropdownAtom from "components/Atoms/MapDropDownAtom/MapDropdownAtom";
import { MapDropdownWrapper } from "components/Atoms/MapDropDownAtom/MapDropdownAtomStyled";
import { useMediaQuery } from "react-responsive";
import Preloader from "components/Atoms/Lotties/Preloader";
import modRewrite from "../../locales/modRewrite";

mapboxgl.accessToken =
  "pk.eyJ1Ijoib3Jzb25hcGlrZXkiLCJhIjoiY2w4Y3Y4YXFrMTVmYzNxcGJnNnRpNXRvdCJ9.RaCSdr1r6sz6NpyL8vFwqA";
const mapper = {
  "1": { icon: jandarm, name: "constabulary" },
  "2": { icon: sam, name: "ambulance" },
  "3": { icon: pomp, name: "fire" },
  "4": { icon: salva, name: "salvamont" },
  "5": { icon: shelter, name: "shelters" },
  "6": { icon: farma, name: "pharmacies" },
  "7": { icon: spital, name: "hospitals" },
  "8": { icon: police, name: "police" },
  "9": { icon: upu, name: "emergencies" },
  "10": { icon: defib, name: "defibrilators" },
};

const createCustomMarkerElement = (icon, size = SIZE.max24, callback, data) => {
  const el = document.createElement("div");
  el.className = "custom-marker";
  el.style.backgroundImage = `url(${icon})`;
  el.style.backgroundSize = "cover";
  el.style.backgroundPosition = "center";
  el.style.width = size;
  el.style.height = size;
  el.style.backgroundRepeat = "no-repeat";

  el.addEventListener("click", () => callback(data));
  return el;
};

const getBounds = (map) => {
  const bounds = map.getBounds();

  return [
    bounds.getWest(),
    bounds.getSouth(),
    bounds.getEast(),
    bounds.getNorth(),
  ];
};
//
// const crvIts = [
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "București, Sector 3, Strada Matei Basarab, Nr. 32, in incinta colegiului national Matei Basarab ",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
// ];
// const dsu = [
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "Municipiul Slobozia, Liceul de Arte ”Ionel Perlea”, str. Mihai Eminescu, nr. 3",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
//   {
//     date: "16.10.2023 - 18.10.2023",
//     address:
//       "București, Sector 3, Strada Matei Basarab, Nr. 32, in incinta colegiului national Matei Basarab ",
//     info: "Enim ut sem viverra aliquet eget sit amet. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Volutpat sed cras ornare arcu dui. Duis convallis convallis tellus id. Nulla malesuada pellentesque elit eget gravida.",
//   },
// ];

const MapComponent: React.FC = () => {
  // PROPERTIES
  const location = useLocation();
  const { rgxId } = useParams();
  let paramCategoryId = 10;
  if (rgxId !== undefined) {
    const tmpIds: any = rgxId.split("-");
    paramCategoryId = parseInt(tmpIds.pop());
  } else {
    paramCategoryId = location?.state?.id || 10;
  }

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // STATE CONSTANTS
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [ipPoints, setIpPoints] = useState<IpCategorySet>({});
  const [ipCategories, setIpCategories] = useState<IpCategory[]>([]);
  const [pinInfo, setPinInfo] = useState<any | null>(null);
  const [selectedIpCategory, setSelectedIpCategory] =
    useState<number>(paramCategoryId);
  // eslint-disable-next-line
  const [shownMarkers, setShownMarkers] = useState<mapboxgl.Marker[]>([]);
  const [bounds, setBounds] = useState<number[]>([]);
  // eslint-disable-next-line
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [styleLoaded, setStyleLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const doClusters = (clusters) => {
    if (map === null) {
      return false;
    }
    try {
      if (map.getLayer("cluster-count")) {
        map.removeLayer("cluster-count");
      }
      if (map.getLayer("clusters")) {
        map.removeLayer("clusters");
      }
      if (map.getLayer("cluster-casing")) {
        map.removeLayer("cluster-casing");
      }
      if (map.getSource("clusters")) {
        map.removeSource("clusters");
      }
    } catch (e) {
      console.error(e);
    }
    map.addSource("clusters", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: clusters,
      },
    });

    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "clusters",

      paint: {
        "circle-color": COLORS.WhiteSmoke,

        "circle-radius": {
          property: "point_count",
          type: "interval",
          stops: [
            [1, 20],
            [100, 30],
            [750, 40],
          ],
        },
      },
    });

    map.addLayer({
      id: "cluster-casing",
      type: "circle",
      source: "clusters",

      paint: {
        "circle-color": COLORS.BeautyBush,

        "circle-radius": {
          property: "point_count",
          type: "interval",
          stops: [
            [1, 18],
            [100, 29],
            [750, 39],
          ],
        },
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "clusters",
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });
  };

  const doMarkers = useCallback(
    (map, points) => {
      if (map === null) {
        return false;
      }
      const tmpMarkers: mapboxgl.Marker[] = [];
      const usePoints: any = [];
      points.forEach((el: IpPoint) => {
        if (
          el.longitudine < 90 &&
          el.longitudine > -90 &&
          el.latitudine < 90 &&
          el.latitudine > -90
        ) {
          usePoints.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [el.longitudine, el.latitudine],
            },
            properties: {
              data: el,
            },
          });

          tmpMarkers.push(
            new mapboxgl.Marker({
              element: createCustomMarkerElement(
                el.icon,
                SIZE.max100,
                (data: any) => {
                  setPinInfo(data);
                },
                el
              ),
            }).setLngLat([el.longitudine, el.latitudine])
          ); // Marker location [lng, lat]
        }
      });
      setShownMarkers((prevState) => {
        prevState.forEach((el: mapboxgl.Marker) => {
          if (el) {
            el.remove();
          }
        });

        const radius = tmpMarkers.length > 80 ? 100 : 50;
        const zoom = tmpMarkers.length > 80 ? 14 : 8;

        if (map.getZoom() > zoom + 1) {
          tmpMarkers.forEach((el: mapboxgl.Marker) => el.addTo(map));
        }
        const initializedCluster = new Supercluster({
          radius: radius, // Radius for clustering
          maxZoom: zoom, // Maximum zoom level to cluster
          minZoom: 1,
        });

        if (initializedCluster) {
          const bbox = getBounds(map);

          initializedCluster.load(usePoints);

          const clusters = initializedCluster.getClusters(
            bbox,
            Math.floor(map.getZoom())
          );
          clusters.forEach((el: any) => {
            if (el.properties.point_count === undefined) {
              const extraMarker = new mapboxgl.Marker({
                element: createCustomMarkerElement(
                  mapper[selectedIpCategory].icon,
                  SIZE.max100,
                  (data: any) => {
                    setPinInfo(data);
                  },
                  el.properties.data
                ),
              }).setLngLat(el.geometry.coordinates);
              tmpMarkers.push(extraMarker);
              extraMarker.addTo(map);
            }
          });

          doClusters(clusters);
        }
        return tmpMarkers;
      });
    },
    //eslint-disable-next-line
    [selectedIpCategory, bounds]
  );

  // LIFE CYCLE

  useEffect(() => {
    // Subscribe to the resize event
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    mapService.getIpCategories().then((response: any) => {
      const ipCats = response.data.map((el: any) => ({
        nume: el.attributes.nume,
        id: el.id,
      }));
      setIpCategories(ipCats);

      [response.data[selectedIpCategory]].forEach((cat: any) => {
        if (selectedIpCategory === 10) {
          if (cat) {
          }
        }
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [pinInfo]);

  useEffect(() => {
    setIsLoading(true);
    if (mapContainerRef.current) {
      const initializeMap = () => {
        let initializedMap = null;
        try {
          initializedMap = new mapboxgl.Map({
            container: mapContainerRef.current!,
            style: "mapbox://styles/orsonapikey/cl8d64cck00ab14qqzkx2zawi",
            center: [26.1062, 44.4377], // Initial coordinates [lng, lat]
            zoom: 10, // Initial zoom level
          });
        } catch (err) {
          return initializeMap();
        }
        initializedMap.on("idle", (event) => {
          if (event.target.isStyleLoaded() && !map) {
            setMap(initializedMap);
          }
        });

        initializedMap.on("load", () => {
          setStyleLoaded(true);

          initializedMap.on("wheel", () => {
            const bbox = getBounds(initializedMap);
            setBounds(bbox);
          });

          initializedMap.on("touchend", (event) => {
            const bbox = getBounds(initializedMap);
            setBounds(bbox);
          });

          initializedMap.on("mouseup", (event) => {
            const bbox = getBounds(initializedMap);
            setBounds(bbox);
          });

          setIsLoading(() => false);
          // Add directions control

          // const directions = new MapboxDirections({
          //   accessToken: mapboxgl.accessToken,
          //   unit: "metric",
          //   profile: "mapbox/driving",
          //   interactive: false, // Disable interactive mode to hide the controls
          //   controls: {
          //     inputs: false,
          //     instructions: false,
          //     profileSwitcher: false,
          //   },
          // });
          // const wps = [
          //   {
          //     coordinates: [26.1062, 44.4377], // Pipera coordinates
          //     approach: "unrestricted",
          //   },
          //   {
          //     coordinates: [26.1459, 44.4859], // Pipera coordinates
          //     approach: "restricted",
          //   },
          //   {
          //     coordinates: [26.084, 44.5712], // Otopeni Airport coordinates
          //     approach: "restricted",
          //   },
          //   {
          //     coordinates: [26.0165, 44.9366], // Ploiesti coordinates
          //     approach: "restricted",
          //   },
          // ];

          // initializedMap.addControl(directions);

          // directions.setOrigin([26.1062, 44.4377]); // Piata Universitatii coordinates
          // directions.setDestination([26.0165, 44.9366]); // Piata Universitatii coordinates

          // wps.forEach((waypoint: any, index) => {
          //   directions.addWaypoint(index, waypoint.coordinates);
          // });

          // const wpStyle = initializedMap
          //   .getStyle()
          //   .layers.find((el) => el.id === "directions-origin-point");

          // wpStyle["paint"]["circle-color"] = "red";
          // const newStyle = initializedMap.getStyle();

          // const wp = newStyle.layers.find(
          //   (layer) => layer.id === "directions-waypoint-point"
          //  );
          // wp["paint"]["circle-color"] = "#FFF";

          // const wpc = newStyle.layers.find(
          //   (layer) => layer.id === "directions-waypoint-point-casing"
          // );
          // wpc["paint"]["circle-color"] = COLORS.MandarianOrange;

          // const op = newStyle.layers.find(
          //   (layer) => layer.id === "directions-origin-point"
          // );
          // op["paint"]["circle-color"] = COLORS.MandarianOrange;
          // op["paint"]["circle-radius"] = 10;
          // op["filter"] = [
          //   "all",
          //   ["in", "$type", "Point"],
          //   ["in", "marker-symbol", ""],
          // ];

          // const routeLine = newStyle.layers.find(
          //   (layer) => layer.id === "directions-route-line"
          // );
          // routeLine["paint"]["line-color"] = COLORS.Cinnabar;
          // routeLine["paint"]["line-width"] = 2;

          // const routeLLineCasing = newStyle.layers.find(
          //   (layer) => layer.id === "directions-route-line-casing"
          // );
          // routeLLineCasing["paint"]["line-color"] = COLORS.MandarianOrange;
          // routeLLineCasing["paint"]["line-width"] = 6;

          // newStyle.layers[80]["paint"]["circle-color"] = COLORS.Cinnabar;

          // initializedMap.setStyle(newStyle);

          // initializedMap.removeLayer("directions-route-line-alt");
          // initializedMap.removeLayer("directions-destination-point");
        });
      };

      if (!map) initializeMap();
    }
  }, [map]);

  useEffect(() => {
    console.log("IP CAT", selectedIpCategory, ipPoints[selectedIpCategory]);
    if (selectedIpCategory && ipPoints[selectedIpCategory] === undefined) {
      setIsLoading(true);
      mapService
        .getIpPoints(selectedIpCategory)
        .then((response: any) => {
          const tmp: IpCategorySet = { ...ipPoints };
          if (selectedIpCategory === 10) {
            (async (cat: any) => {
              const response: any = await mapService.getDefibrlators();
              const tmp: IpCategorySet = { [selectedIpCategory]: [] };
              tmp[cat] = [
                ...response.On,
                ...response.On112,
                ...response.Off,
                ...response.Off112,
              ].map((el: any) => {
                el.defibrilator.defibrilator_schedules_id =
                  el.defibrilator.defibrilator_schedules_id == null
                    ? []
                    : el.defibrilator.defibrilator_schedules_id.map(
                        (el: any) => {
                          return {
                            days: el.days.map((el: any) => el[0]).join(" "),
                            hours: el.is24Hour
                              ? "NON-STOP"
                              : el.startTime.split(" ")[0] +
                                " - " +
                                el.startTime.split(" ")[0],
                            available: !el.isIndisponible,
                          };
                        }
                      );

                return {
                  id: el.id,
                  icon: el.isOn ? defib : defibOff,
                  titlu: el.titlu,
                  website: el.website,
                  adresa: el.adresa,
                  numar_telefon:
                    el.numar_telefon !== ""
                      ? el.numar_telefon
                      : el.defibrilator.phone,
                  latitudine: el.latitudine,
                  longitudine: el.longitudine,
                  defibrilator: el.defibrilator,
                  canCallEmergency: el.defibrilator.canCallEmergency,
                };
              });
              setIpPoints(() => tmp);
            })(selectedIpCategory);
          } else {
            tmp[selectedIpCategory] = response.data.map((el: any) => {
              return {
                id: el.id,
                icon: mapper[selectedIpCategory].icon,
                ...el.attributes,
              };
            });
            setIpPoints(() => tmp);
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [selectedIpCategory]);

  useEffect(() => {
    // if (map && ipCategories.length && Object.keys(ipPoints).length > 0) {
    //   setSelectedIpCategory(ipCategories[9].id);
    // }
  }, [map, ipCategories, ipPoints]);

  useEffect(() => {
    if (
      selectedIpCategory &&
      ipPoints[selectedIpCategory] !== undefined &&
      map !== null &&
      map !== undefined
    ) {
      const bounds = map.getBounds();
      const bbox = [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ];

      const points = ipPoints[selectedIpCategory].filter((el: IpPoint) => {
        //0: w,1: S: 2: e, 3: n
        return (
          el.latitudine <= bbox[3] &&
          el.latitudine >= bbox[2] &&
          el.longitudine <= bbox[1] &&
          el.longitudine >= bbox[0]
        );
      });

      doMarkers(map, points);
      setIsLoading(() => false);
    }
    // eslint-disable-next-line
  }, [map, selectedIpCategory, bounds, ipPoints]);

  // EVENT HANDLERS
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  return styleLoaded !== null ? (
    <>
      <MapContainer ref={mapContainerRef}>
        {!isMobile ? (
          <InterestPointsMenu>
            {ipCategories.map((el: IpCategory, index: number) => {
              return (
                <li key={index}>
                  <ButtonAtom
                    text={t(`components.ipCategories.${mapper[el.id]["name"]}`)}
                    type={
                      el.id === selectedIpCategory
                        ? ButtonType.MapActive
                        : ButtonType.Audio
                    }
                    leftIcon={false}
                    rightIcon={false}
                    onClick={() => {
                      setSelectedIpCategory(el.id);
                      navigate(
                        `/harta/${modRewrite(el.nume.replace(/_/g, "-"))}-${
                          el.id
                        }`
                      );
                    }}
                  />
                </li>
              );
            })}
          </InterestPointsMenu>
        ) : (
          <MapDropdownWrapper>
            <MapDropdownAtom
              options={ipCategories.map((el: any) => ({
                label: t(`components.ipCategories.${mapper[el.id]["name"]}`),
                value: el.id,
              }))}
              title={t("view.guides.category")}
              selected={selectedIpCategory}
              dropdownName={t(
                `components.ipCategories.${mapper[selectedIpCategory]["name"]}`
              )}
              onConfirm={(values) => {
                setSelectedIpCategory(values[0].value);
              }}
            />
          </MapDropdownWrapper>
        )}
        {/* <CaravanWrapper>
          <Caravan beReadyData={crvIts} dsuData={dsu} />
        </CaravanWrapper> */}

        {pinInfo && (
          <ResponsiveModal
            type={selectedIpCategory === 10 ? ModalType.Defib : ModalType.Info}
            onClose={() => {
              setPinInfo(null);
            }}
            isOpen={pinInfo !== null}
            selectedIpCategory={pinInfo.category}
            title={pinInfo.titlu}
            canCallEmergency={pinInfo.canCallEmergency}
            photo={pinInfo.defibrilator?.photo?.url}
            phone={pinInfo.numar_telefon}
            adress={pinInfo.adresa}
            website={pinInfo.website}
            long={pinInfo.longitudine}
            lat={pinInfo.latitudine}
            // stats={availableStatuses}
            stats={
              pinInfo.defibrilator &&
              Array.isArray(pinInfo.defibrilator?.defibrilator_schedules_id)
                ? pinInfo.defibrilator?.defibrilator_schedules_id.map(
                    (el: any) => el.available
                  )
                : null
            }
          >
            {pinInfo.defibrilator &&
            pinInfo.defibrilator?.defibrilator_schedules_id?.length > 0 ? (
              pinInfo.defibrilator?.defibrilator_schedules_id.map((el: any) => (
                <Fragment key={el.id}>
                  <CustomText
                    text={el.days + "\n"}
                    type={TextType.H4bold18px}
                  />
                  <CustomText text={el.hours} type={TextType.P1fix} />
                </Fragment>
              ))
            ) : (
              <></>
            )}
          </ResponsiveModal>
        )}
      </MapContainer>
      {isLoading && (
        <PreloaderWrapper>
          <Preloader width={100} height={100} />
        </PreloaderWrapper>
      )}
    </>
  ) : (
    <PreloaderWrapper>
      <Preloader width={100} height={100} />
    </PreloaderWrapper>
  );
};

export default MapComponent;
