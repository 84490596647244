import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import {
  PrepareYourselfContainer,
  TitleWrapper,
  Body,
  Row,
  RedCard,
  RedCardBody,
  LongCard,
  Title,
  ContentCard,
  ButtonCardWrapper,
} from "./PrepareYourselfStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
// MISC
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS, DEVICE_TYPE } from "constants/variables";
import getReadyService from "../../../services/getReadyService";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";
import {
  accessibilityBiggerLetterSpacingSelector,
  accessibilityBiggerLineHeightSelector,
  accessibilityBiggerTextSelector,
} from "slices/UISlice/uiSliceSelectors";

// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import modRewrite from "../../../locales/modRewrite";

const PrepareYourself = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const isMobileHeader = useMediaQuery({ maxWidth: DEVICE_TYPE.mobileHeader });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const accessibilityBiggerLetterSpacing = useSelector(
    accessibilityBiggerLetterSpacingSelector
  );
  const accessibilityBiggerLineHeight = useSelector(
    accessibilityBiggerLineHeightSelector
  );
  const accessibilityBiggerText = useSelector(accessibilityBiggerTextSelector);
  // STATE CONSTANTS
  const [prepare, setPrepare] = useState<any>([]);
  const skeletonCount = 2;
  // API REQUESTS
  useEffect(() => {
    (async () => {
      try {
        const response: any = await getReadyService.getPrepareMembers();
        setPrepare(response.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  // LIFE CYCLE

  // EVENT HANDLERS
  const isAccessibilityOn =
    accessibilityBiggerLetterSpacing ||
    accessibilityBiggerLineHeight ||
    accessibilityBiggerText;

  return (
    <PrepareYourselfContainer>
      <TitleWrapper>
        <CustomText
          text={t("view.getReady.prepareYourself.title")}
          type={TextType.H2fix}
        />
      </TitleWrapper>
      <Body>
        {prepare && prepare.length > 0 ? (
          prepare.map((el: any, index: number) => (
            <Row key={`row--${el.id}`}>
              {index % 2 === 0 && !isMobileHeader && (
                <RedCard isAccessibilityOn={isAccessibilityOn} tabIndex={0}>
                  <RedCardBody>
                    <CustomText
                      text={el.attributes.title}
                      type={TextType.H3fix}
                      color={COLORS.WhiteSmoke}
                    />
                  </RedCardBody>
                </RedCard>
              )}
              <LongCard isAccessibilityOn={isAccessibilityOn} tabIndex={0}>
                <Title>
                  <CustomText
                    text={el.attributes.title}
                    type={TextType.H3fix}
                  />
                </Title>
                <ContentCard>
                  <StrapiTreeText
                    textOnly={true}
                    text={
                      el.attributes.prepare_chapters.data !== null &&
                      el.attributes.prepare_chapters.data.length > 0 &&
                      el.attributes.prepare_chapters.data[0].attributes.content
                    }
                  />
                </ContentCard>
                <ButtonCardWrapper>
                  <ButtonAtom
                    text={t("view.getReady.prepareYourself.learn")}
                    type={ButtonType.ArrowButton}
                    rightIcon={true}
                    onClick={() => {
                      navigate(
                        `/pregateste-ti-apropiatii/${modRewrite(
                          el.attributes.title,
                          el.id
                        )}`
                      );
                    }}
                  />
                </ButtonCardWrapper>
              </LongCard>
              {index % 2 !== 0 && !isMobileHeader && (
                <RedCard isAccessibilityOn={isAccessibilityOn} tabIndex={0}>
                  <RedCardBody>
                    <CustomText
                      text={el.attributes.title}
                      type={TextType.H3fix}
                      color={COLORS.WhiteSmoke}
                    />
                  </RedCardBody>
                </RedCard>
              )}
            </Row>
          ))
        ) : (
          <>
            {[...Array(skeletonCount)].map((el, index) => (
              <Row key={index}>
                {index % 2 === 0 && !isMobileHeader && (
                  <RedCard isAccessibilityOn={isAccessibilityOn} tabIndex={0}>
                    <RedCardBody>
                      <Skeleton
                        width={"100%"}
                        height={"1.5rem"}
                        count={2}
                        baseColor={COLORS.Cinnabar}
                        highlightColor={COLORS.WhiteSmoke}
                      />
                    </RedCardBody>
                  </RedCard>
                )}
                <LongCard isAccessibilityOn={isAccessibilityOn} tabIndex={0}>
                  <Title>
                    <Skeleton
                      width={"100%"}
                      baseColor={COLORS.Solitude}
                      highlightColor={COLORS.WhiteSmoke}
                    />
                  </Title>
                  <ContentCard>
                    <Skeleton
                      key={index}
                      width={"100%"}
                      count={2}
                      baseColor={COLORS.Solitude}
                      highlightColor={COLORS.WhiteSmoke}
                    />
                  </ContentCard>
                  <ButtonCardWrapper>
                    <ButtonAtom
                      text={t("view.getReady.prepareYourself.learn")}
                      type={ButtonType.ArrowButton}
                      rightIcon={true}
                    />
                  </ButtonCardWrapper>
                </LongCard>
                {index % 2 !== 0 && !isMobileHeader && (
                  <RedCard isAccessibilityOn={isAccessibilityOn} tabIndex={0}>
                    <RedCardBody>
                      <Skeleton
                        width={"100%"}
                        height={"1.5rem"}
                        count={2}
                        baseColor={COLORS.Cinnabar}
                        highlightColor={COLORS.WhiteSmoke}
                      />
                    </RedCardBody>
                  </RedCard>
                )}
              </Row>
            ))}
          </>
        )}
      </Body>
    </PrepareYourselfContainer>
  );
};

export default PrepareYourself;
