import React, { useEffect } from "react";
// ASSETS
import RedPhone from "assets/svg/RedPhone.svg";
import RedMail from "assets/svg/RedMail.svg";

// STYLES
import {
  BigContainer,
  GridContainer,
  Column,
  ContactCard,
  RowTablet,
  ThirdColumnCard,
  DescribeWrapper,
  ButtonWrapper,
  IconWrapper,
  Icon,
  TitleWrapper,
  FormularTextWrapper,
  FAQWrapper,
  MainWrapper,
  StyledLink,
} from "./ContactStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS, DEVICE_TYPE } from "constants/variables";
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";
// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import SectionWrapper from "components/SectionWrapper/SectionWrapper";
import InputAtom from "components/Atoms/InputAtom/InputAtom";
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import FAQ from "./FAQ/FAQ";
import partsService from "../../services/partsService";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";

interface ContactProps {
  name: string;
  email: string;
  description: string;
}
const Contact = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ maxWidth: DEVICE_TYPE.tablet });
  const defaultContact = {
    name: "",
    email: "",
    description: "",
  };

  // STATE CONSTANTS
  const [emergencyInfo, setEmergencyInfo] = React.useState(undefined);
  const [errors, setErrors] = React.useState([]);
  const [contactForm, setContactForm] =
    React.useState<ContactProps>(defaultContact);
  const [finalResponse, setFinalResponse] = React.useState(null);
  // API REQUESTS
  useEffect(() => {
    (async () => {
      try {
        const response: any = await partsService.getEmergencyInfo();
        if (response.data) {
          setEmergencyInfo(response.data);
        }
      } catch (err) {}
    })();
  }, []);

  const validate = (field) => {
    const errs = [...errors].filter((el: any) => el !== field);
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      (field === "name" &&
        (contactForm[field].length < 3 || contactForm[field].length > 40)) ||
      (field === "email" && !emailRegex.test(contactForm[field])) ||
      (field === "description" && contactForm[field].length < 10)
    ) {
      setErrors([...errs, field]);
    } else {
      setErrors(errs);
    }
  };

  const submitForm = () => {
    if (errors.length === 0) {
      partsService
        .saveContact({ data: contactForm })
        .then((response) => {
          setContactForm(defaultContact);
          setFinalResponse(true);
        })
        .catch((err) => {
          setFinalResponse(false);
        });

      setContactForm(defaultContact);
    }
  };

  // LIFE CYCLE
  useEffect(() => {}, [errors]);
  // EVENT HANDLERS

  return (
    <MainWrapper>
      <SectionWrapper hasPadding={true}>
        <BigContainer>
          {isTablet && (
            <RowTablet>
              <ThirdColumnCard>
                <TitleWrapper>
                  <CustomText
                    text={t("view.contact.section1.phone")}
                    type={TextType.H4}
                    color={COLORS.Cinnabar}
                  />
                </TitleWrapper>
                <StyledLink
                  biggerCursor={biggerCursor}
                  href={`tel:021 312 25 47`}
                >
                  <CustomText text={"021 312 25 47"} type={TextType.H2} />
                </StyledLink>
                <IconWrapper src={RedPhone} />
                <IconWrapper>
                  <Icon src={RedPhone} />
                </IconWrapper>
              </ThirdColumnCard>
              <ThirdColumnCard>
                <TitleWrapper>
                  <CustomText
                    text={t("view.contact.section1.central")}
                    type={TextType.H4}
                    color={COLORS.Cinnabar}
                  />
                </TitleWrapper>
                <StyledLink
                  biggerCursor={biggerCursor}
                  href={`tel:021 303 70 80`}
                >
                  <CustomText text={"021 303 70 80"} type={TextType.H2} />
                </StyledLink>
                <IconWrapper>
                  <Icon src={RedPhone} />
                </IconWrapper>
              </ThirdColumnCard>
              <ThirdColumnCard>
                <TitleWrapper>
                  <CustomText
                    text={t("view.contact.section1.email")}
                    type={TextType.H4}
                    color={COLORS.Cinnabar}
                  />
                </TitleWrapper>
                <StyledLink
                  biggerCursor={biggerCursor}
                  href={`mailto:${t("components.footer.card6.email")}`}
                >
                  <CustomText text={"dsu@mai.gov.ro"} type={TextType.H2} />
                </StyledLink>
                <IconWrapper>
                  <Icon src={RedMail} />
                </IconWrapper>
              </ThirdColumnCard>
            </RowTablet>
          )}
          <GridContainer>
            <Column>
              <ContactCard>
                <TitleWrapper tabIndex={0}>
                  <CustomText
                    text={t("view.contact.section1.form")}
                    type={TextType.H4}
                    color={COLORS.Cinnabar}
                  />
                </TitleWrapper>
                <FormularTextWrapper tabIndex={0}>
                  <CustomText
                    text={t("view.contact.section1.content")}
                    type={TextType.P3}
                  />
                  {finalResponse !== null && (
                    <CustomText
                      color={
                        finalResponse === true
                          ? COLORS.MoodyBlue
                          : COLORS.MandarianOrange
                      }
                      text={
                        finalResponse
                          ? t("view.contact.section1.responsOk")
                          : t("view.contact.section1.responsNotOk")
                      }
                      type={TextType.P3}
                    />
                  )}
                </FormularTextWrapper>
                <InputAtom
                  showLabel={true}
                  label={t("view.contact.section1.name")}
                  error={errors.includes("name")}
                  onChange={(event: any) =>
                    setContactForm({ ...contactForm, name: event.target.value })
                  }
                  value={contactForm.name}
                  onBlur={() => validate("name")}
                />

                <InputAtom
                  showLabel={true}
                  label={t("view.contact.section1.mail")}
                  error={errors.includes("email")}
                  onChange={(event: any) =>
                    setContactForm({
                      ...contactForm,
                      email: event.target.value,
                    })
                  }
                  onBlur={() => validate("email")}
                  value={contactForm.email}
                />
                <DescribeWrapper>
                  <InputAtom
                    showLabel={true}
                    label={t("view.contact.section1.description")}
                    isMultiLine={true}
                    error={errors.includes("description")}
                    onChange={(event: any) =>
                      setContactForm({
                        ...contactForm,
                        description: event.target.value,
                      })
                    }
                    onBlur={() => validate("description")}
                    value={contactForm.description}
                  />
                </DescribeWrapper>
                <ButtonWrapper>
                  <ButtonAtom
                    text={t("view.contact.section1.send")}
                    type={ButtonType.ArrowButton}
                    rightIcon={true}
                    onClick={() => submitForm()}
                  />
                </ButtonWrapper>
              </ContactCard>
            </Column>

            <Column>
              <ContactCard tabIndex={0}>
                <TitleWrapper>
                  {emergencyInfo !== undefined ? (
                    <CustomText
                      text={
                        emergencyInfo !== undefined
                          ? emergencyInfo.attributes.title
                          : t("view.contact.section1.covid")
                      }
                      type={TextType.H4}
                      color={COLORS.Cinnabar}
                    />
                  ) : (
                    <Skeleton
                      width={100}
                      count={1}
                      baseColor={COLORS.Solitude}
                      highlightColor={COLORS.WhiteSmoke}
                    />
                  )}
                </TitleWrapper>
                {emergencyInfo !== undefined ? (
                  <StrapiTreeText
                    text={
                      emergencyInfo !== undefined
                        ? emergencyInfo.attributes.content
                        : ""
                    }
                  />
                ) : (
                  <Skeleton
                    width={"100%"}
                    count={15}
                    baseColor={COLORS.Solitude}
                    highlightColor={COLORS.WhiteSmoke}
                  />
                )}
              </ContactCard>
            </Column>
            {!isTablet && (
              <Column>
                <ThirdColumnCard>
                  <TitleWrapper>
                    <CustomText
                      text={t("view.contact.section1.phone")}
                      type={TextType.H4}
                      color={COLORS.Cinnabar}
                    />
                  </TitleWrapper>
                  <StyledLink
                    biggerCursor={biggerCursor}
                    href={`tel:021 312 25 47`}
                  >
                    <CustomText text={"021 312 25 47"} type={TextType.H2} />
                  </StyledLink>
                  <IconWrapper src={RedPhone} />
                  <IconWrapper>
                    <Icon src={RedPhone} />
                  </IconWrapper>
                </ThirdColumnCard>
                <ThirdColumnCard>
                  <TitleWrapper>
                    <CustomText
                      text={t("view.contact.section1.central")}
                      type={TextType.H4}
                      color={COLORS.Cinnabar}
                    />
                  </TitleWrapper>
                  <StyledLink
                    biggerCursor={biggerCursor}
                    href={`tel:021 303 70 80`}
                  >
                    <CustomText text={"021 303 70 80"} type={TextType.H2} />
                  </StyledLink>
                  <IconWrapper>
                    <Icon src={RedPhone} />
                  </IconWrapper>
                </ThirdColumnCard>
                <ThirdColumnCard>
                  <TitleWrapper>
                    <CustomText
                      text={t("view.contact.section1.email")}
                      type={TextType.H4}
                      color={COLORS.Cinnabar}
                    />
                  </TitleWrapper>
                  <StyledLink
                    biggerCursor={biggerCursor}
                    href={`mailto:${t("components.footer.card6.email")}`}
                  >
                    <CustomText text={"dsu@mai.gov.ro"} type={TextType.H2} />
                  </StyledLink>
                  <IconWrapper>
                    <Icon src={RedMail} />
                  </IconWrapper>
                </ThirdColumnCard>
              </Column>
            )}
          </GridContainer>
        </BigContainer>
      </SectionWrapper>
      <FAQWrapper>
        <FAQ />
      </FAQWrapper>
    </MainWrapper>
  );
};

export default Contact;
