import { useState, useEffect } from 'react';
import { getCookie, setCookie } from '../helpers/cookieHelper';
import { CookieItem } from '../CookieModuleModel';

const useCookieConsent = (initialNecessaryCookies: CookieItem[], initialAnalyticsCookies: CookieItem[]) => {
  const [isVisible, setIsVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [necessaryCookies, setNecessaryCookies] = useState(initialNecessaryCookies);
  const [analyticsCookies, setAnalyticsCookies] = useState(initialAnalyticsCookies);

  const initializeCookies = () => {
    const updatedNecessaryCookies = necessaryCookies.map(cookie => ({
      ...cookie,
      checked: true,
    }));
    const updatedAnalyticsCookies = analyticsCookies.map(cookie => ({
      ...cookie,
      checked: getCookie(cookie.title) === 'true',
    }));
    setNecessaryCookies(updatedNecessaryCookies);
    setAnalyticsCookies(updatedAnalyticsCookies);
  };

  useEffect(() => {
    const essentialCookies = getCookie('essential_accept');
    if (essentialCookies === 'true') {
      setIsVisible(false);
    }
    initializeCookies();
    // eslint-disable-next-line
  }, []);

  const handleAccept = () => {
    const updatedNecessaryCookies = necessaryCookies.map(cookie => ({ ...cookie, checked: true }));
    const updatedAnalyticsCookies = analyticsCookies.map(cookie => ({ ...cookie, checked: true }));
    setNecessaryCookies(updatedNecessaryCookies);
    setAnalyticsCookies(updatedAnalyticsCookies);

    updatedNecessaryCookies.forEach(cookie => setCookie(cookie.title, 'true', 365));
    updatedAnalyticsCookies.forEach(cookie => setCookie(cookie.title, 'true', 365));
    setCookie('essential_accept', 'true', 365); // Ensure this is set to hide the consent modal
    notifyCookieChange(updatedNecessaryCookies, updatedAnalyticsCookies);
    setIsVisible(false);
    setShowModal(false);
  };

  const handleSaveSettings = () => {
    necessaryCookies.forEach(cookie => setCookie(cookie.title, cookie.checked.toString(), 365));
    analyticsCookies.forEach(cookie => setCookie(cookie.title, cookie.checked.toString(), 365));
    setCookie('essential_accept', 'true', 365); // Ensure this is set to hide the consent modal
    notifyCookieChange(necessaryCookies, analyticsCookies);
    setIsVisible(false);
    setShowModal(false);
  };

  const handleManageSettings = () => {
    setShowModal(true);
  };

  const notifyCookieChange = (necessaryCookies: CookieItem[], analyticsCookies: CookieItem[]) => {
    const event = new CustomEvent('cookieChange', {
      detail: {
        necessaryCookies,
        analyticsCookies,
      }
    });
    window.dispatchEvent(event);
  };

  return {
    isVisible,
    showModal,
    necessaryCookies,
    analyticsCookies,
    setNecessaryCookies,
    setAnalyticsCookies,
    handleAccept,
    handleSaveSettings,
    handleManageSettings,
    setShowModal,
  };
};

export default useCookieConsent;
