import { createSelector } from "reselect";
import { uiSelector } from "./uiSlice";

export const accessibilityBiggerTextSelector = createSelector(
  uiSelector,
  (ui) => ui.accessibilityBiggerText
);

export const accessibilityBiggerCursorSelector = createSelector(
  uiSelector,
  (ui) => ui.accessibilityBiggerCursor
);

export const accessibilityBiggerLineHeightSelector = createSelector(
  uiSelector,
  (ui) => ui.accessibilityBiggerLineHeight
);

export const accessibilityBiggerLetterSpacingSelector = createSelector(
  uiSelector,
  (ui) => ui.accessibilityBiggerLetterSpacing
);

export const accessibilityBlackAndWhiteSelector = createSelector(
  uiSelector,
  (ui) => ui.accessibilityBlackAndWhite
);

export const accessibilityInvertColorsSelector = createSelector(
  uiSelector,
  (ui) => ui.accessibilityInvertColors
);
