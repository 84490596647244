//LIBRARY
import styled from "styled-components";
//MISC
import {
  COLORS,
  BORDER_RADIUS,
  SIZE,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  DEVICE_TYPE,
  MARGIN,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const BigContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};

  flex-direction: column;
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: ${SIZE.max24};
  @media (max-width: 1300px) {
    grid-template-columns: 1.75fr 1.25fr;
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    grid-template-columns: 1fr;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${SIZE.max24};
`;

export const Card = styled.div`
  position: relative;
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  padding: ${SIZE.max55};
  color: ${COLORS.BlackRussian};
  width: 100%;
  overflow: hidden;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: ${MARGIN.max56} ${MARGIN.max40} ${MARGIN.max56} ${MARGIN.max40};
  }
`;

export const LargeCard = styled(Card)`
  padding: ${MARGIN.max56} ${MARGIN.max48} ${MARGIN.max56} ${MARGIN.max48};
  width: 100%;
  height: 100%;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
    padding: ${MARGIN.max56} ${MARGIN.max40} ${MARGIN.max56} ${MARGIN.max40};
  }
`;

export const MediumCard = styled(Card)`
  padding: ${MARGIN.max32} ${MARGIN.max48};
  width: 100%;
  min-height: 150px;
  height: 100%;
  max-height: 180px;
  @media (max-width: 1300px) {
    height: auto;
    max-height: none;
    padding: ${MARGIN.max32} ${MARGIN.max40};
  }
`;

export const SecondCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 18.229vw;
  flex: 1;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
  }
`;
export const ThirdCard = styled(Card)`
  padding: ${MARGIN.max40} ${MARGIN.max48} ${MARGIN.max50} ${MARGIN.max48};
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 18.229vw;
  @media (min-width: ${DEVICE_TYPE.mobileHeader}) {
    flex: 1;
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: ${MARGIN.max40} ${MARGIN.max40} ${MARGIN.max50} ${MARGIN.max40};
  }
`;

export const Row = styled.div`
  display: flex;
  height: 100%;
  gap: ${SIZE.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    flex-direction: column;
  }
`;

export const RowTablet = styled.div`
  display: flex;
  align-items: stretch;
  gap: ${SIZE.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    flex-direction: column;
  }
`;

export const TitleLarge = styled.div`
  margin-bottom: ${MARGIN.max24};
`;

export const TitleMedium = styled.h2`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.fix24};
  font-weight: ${FONT_WEIGHT.semiBold};
  color: ${COLORS.Cinnabar};
  margin-bottom: ${({ isSocialMediaCard }) =>
    isSocialMediaCard ? MARGIN.max24 : MARGIN.max32};
  @media (max-width: ${DEVICE_TYPE.tablet}) {
    margin-bottom: ${MARGIN.max16};
  }
`;

export const Paragraph = styled.div`
  position: relative;
  max-width: 560px;

  z-index: 1;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    max-width: 100%;
    height: 47.385vh;
  }
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 320px;
`;

export const Icon = styled.img`
  width: 60px;
  height: auto;
  margin-right: ${MARGIN.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-right: 10px;
  }
`;

export const MiddleCard = styled(Card)`
  width: 100%;
  height: 100%;
  padding: ${MARGIN.max56} ${MARGIN.max48};
  @media (max-width: ${DEVICE_TYPE.tablet}) {
    padding: ${MARGIN.max32} ${MARGIN.max40};
  }
`;

export const FirstCard = styled(Card)`
  width: 100%;
  height: 100%;
  max-height: 450px;
  @media (max-width: 1024px) {
    width: auto;
  }
`;
export const FourthCard = styled(Card)<{ biggerCursor?: boolean }>`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${SIZE.max55};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    justify-content: center;
    order: 3;
    max-height: none;
    padding: 0 ${MARGIN.max40};
  }
  min-height: 150px;
  width: 100%;
`;

export const Title = styled.h2`
  color: ${COLORS.Paua};
  font-size: ${FONT_SIZE.max32};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.semiBold};
  white-space: nowrap;
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  height: auto;
  gap: ${MARGIN.max20};
  @media (min-width: ${DEVICE_TYPE.mobileHeader}) and (max-width: ${DEVICE_TYPE.tablet}) {
    margin-top: ${MARGIN.max40};
  }
`;
export const SocialIcon = styled.img<{ biggerCursor?: boolean }>`
  height: 32px;
  transition: filter 0.3s ease-in-out;
  filter: saturate(0%);
  :hover {
    filter: saturate(100%);
  }
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: 32px;
  }
`;

export const TextMargin = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 245px;
  gap: ${MARGIN.max14};
  z-index: 10;
  position: relative;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding-bottom: ${MARGIN.max24};
    max-width: none;
  }
`;
export const ContactStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MARGIN.max16};
`;

export const LinkWrapper = styled.a`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  text-decoration: none;
  color: inherit;
`;

export const StyledLink = styled.a`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  text-decoration: none;
  color: inherit;
`;
