export interface ResponsiveModalProps {
  type?: ModalType;
  isOpen: boolean;
  onClose: () => void;
  selectedIpCategory?: number;
  title?: string;
  stats?: boolean;
  canCallEmergency?: any;
  photo?: string;
  phone?: string;
  adress?: string;
  website?: string;
  children?: React.ReactNode;
  long: any;
  lat: any;
}
export enum ModalType {
  Info,
  Defib,
}
