import React, { useState, useEffect, useRef } from "react";

// ASSETS

import Arms from "assets/svg/ArmsIcon.svg";
import Medkit from "assets/svg/MedkitIcon.svg";
import fiipregatitVideoBG from "assets/mp4/fiipregatitVideoBG.mp4";
import { ReactComponent as Fire } from "../../assets/icons/FireIcon.svg";

import SendButton from "assets/svg/ChatSend.svg";

// STYLES
import {
  DashboardContainer,
  Title,
  Subtitle,
  InputContainer,
  CardsContainer,
  Column,
  Row,
  CardWrapper,
  VideoBackground,
  CardImage,
  CardTextOverlay,
  CardWrapperVertical,
  CardTitleVertical,
  CardImageVertical,
  ImgStyled,
  HeaderContainer,
  StyledSource,
} from "./DashboardStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

// MISC
import { CardType } from "components/Atoms/GuideCardAtom/GuideCardAtomModel";
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";
import { OptionType } from "components/Atoms/InputAtom/InputAtomModel";
import guideService from "services/guideService";
import { baseUrl } from "services/base";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";
import { currentLanguageSelector } from "slices/LanguageSlice/languageSliceSelectors";

// COMPONENTS
import InputAtom from "components/Atoms/InputAtom/InputAtom";
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import GuideCardAtom from "components/Atoms/GuideCardAtom/GuideCardAtom";
import CustomText from "components/Atoms/CustomText/CustomText";
import ImgAtom from "components/Atoms/ImgAtom/ImgAtom";
import BotHead from "assets/svg/BotHead.svg";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const Dashboard = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const videoRef = useRef(null);

  //REDUX
  const currentLanguage = useSelector(currentLanguageSelector);

  // STATE CONSTANTS
  const [inputText, setInputText] = useState("");
  // eslint-disable-next-line
  const [options, setOptions] = useState<OptionType[]>([]);
  const [guides, setGuides] = useState<any[]>([]);
  // eslint-disable-next-line
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const titles = [
    "Fă un curs de prim ajutor!",
    "Canicula",
    "Incendiu",
    "Inundații",
    "Furtună",
    "Cutremur",
  ];
  const titlesEn = [
    "Take a first aid course!",
    "Heat",
    "Fire",
    "Floods",
    "Storm",
    "Earthquake",
  ];
  const navigate = useNavigate();
  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    (async () => {
      let response: any = await guideService.getGuides();
      const tmpGuides = response.data.filter((el: any) =>
        currentLanguage === "en"
          ? titlesEn.some((title) => el.attributes.title.includes(title))
          : titles.some((title) => el.attributes.title.includes(title))
      );

      const dashBoardGuides = tmpGuides.map((el: any) => ({
        id: el.id,
        title: el.attributes.title,
        category: el.attributes.guides_category?.data?.id,
        icon:
          el.attributes.icon.data == null ? (
            <Fire />
          ) : (
            <ImgAtom
              img={`${baseUrl}${el.attributes.icon.data.attributes.url}`}
              label={el.attributes.title}
              height={SIZE.card60}
            />
          ),
      }));
      setGuides(dashBoardGuides);
    })();
    // eslint-disable-next-line
  }, [currentLanguage]);

  useEffect(() => {
    const handleClose = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsFocus(false);
      }
    };
    if (options.length > 0) {
      setTimeout(() => {
        document.addEventListener("click", handleClose);
      }, 200);
    } else {
      setTimeout(() => {
        document.removeEventListener("click", handleClose);
      }, 200);
    }
    return () => {
      setTimeout(() => {
        document.removeEventListener("click", handleClose);
      }, 200);
    };
  }, [options]);

  // EVENT HANDLERS

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  return (
    <DashboardContainer>
      <HeaderContainer>
        <VideoBackground
          onClick={handleVideoClick}
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
        >
          <StyledSource src={fiipregatitVideoBG} type="video/mp4" />
          <CustomText
            text={t("components.dashboard.noVideo")}
            type={TextType.P1}
          />
        </VideoBackground>
        <Title>
          <CustomText
            text={t("components.dashboard.title")}
            type={TextType.H1prime}
            color={COLORS.Cinnabar}
          />
        </Title>
        <Subtitle>
          <CustomText
            text={t("components.dashboard.subtitle")}
            type={TextType.P3}
          />
        </Subtitle>
        <InputContainer ref={modalRef}>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              if (inputText !== "") {
                navigate("/fiipregatit-chat-bot", {
                  state: { message: inputText },
                });
              }
            }}
          >
            <InputAtom
              startAdornment={<ImgStyled src={BotHead} alt="BotHeadIcon" />}
              endAdornment={
                <ButtonAtom icon={SendButton} type={ButtonType.ChatButton} />
              }
              placeholder={t("components.dashboard.placeholder")}
              isSearchBar={true}
              onChange={(e) => setInputText(e.target.value)}
              onFocus={() => setIsFocus(true)}
            />
          </form>
        </InputContainer>
      </HeaderContainer>
      <CardsContainer>
        <Column>
          <Row>
            <GuideCardAtom
              tabIndex={0}
              title={guides[1]?.title}
              icon={guides[1]?.icon}
              cardType={CardType.DashboardStyle}
              width={SIZE.max180}
              height={SIZE.max180}
              id={guides[1]?.id}
            />
            <GuideCardAtom
              tabIndex={0}
              title={guides[2]?.title}
              icon={guides[2]?.icon}
              cardType={CardType.DashboardStyle}
              width={SIZE.max180}
              height={SIZE.max180}
              id={guides[2]?.id}
            />
          </Row>
          <Row>
            {guides.length > 0 ? (
              <CardWrapper
                tabIndex={0}
                width={SIZE.max390}
                height={SIZE.max180}
                biggerCursor={biggerCursor}
                href={"/ghiduri/" + guides[0]?.id}
              >
                <CardTextOverlay>
                  <CustomText
                    text={t("components.dashboard.cards.firstAid")}
                    type={TextType.H4fix}
                    color={COLORS.TangerineYellow}
                  />
                </CardTextOverlay>
                <CardImage src={Arms} alt="Image Description" />
              </CardWrapper>
            ) : (
              <Skeleton
                width={SIZE.max390}
                height={SIZE.max180}
                baseColor={COLORS.MandarianOrange}
                highlightColor={COLORS.Cinnabar}
                borderRadius={BORDER_RADIUS.radius24}
              />
            )}
          </Row>
        </Column>
        <Column>
          {guides.length > 0 ? (
            <CardWrapperVertical
              tabIndex={0}
              width={isMobile ? "326px" : "316px"}
              height={SIZE.max390}
              biggerCursor={biggerCursor}
              href="/pregateste-te"
            >
              <CardTitleVertical>
                <CustomText
                  text={t("components.dashboard.cards.prepare")}
                  type={TextType.H3fix}
                  color={COLORS.TangerineYellow}
                />
              </CardTitleVertical>
              <CardImageVertical src={Medkit} alt="Medkit Image" />
            </CardWrapperVertical>
          ) : (
            <Skeleton
              width={isMobile ? "326px" : "316px"}
              height={SIZE.max390}
              baseColor={COLORS.MandarianOrange}
              highlightColor={COLORS.Cinnabar}
              borderRadius={BORDER_RADIUS.radius24}
            />
          )}
        </Column>
        <Column>
          {isMobile ? (
            <Row>
              <GuideCardAtom
                tabIndex={0}
                title={guides[3]?.title}
                icon={guides[3]?.icon}
                cardType={CardType.DashboardStyle}
                width={SIZE.max180}
                height={SIZE.max180}
                id={guides[3]?.id}
              />
              <GuideCardAtom
                tabIndex={0}
                title={guides[4]?.title}
                icon={guides[4]?.icon}
                cardType={CardType.DashboardStyle}
                width={SIZE.max180}
                height={SIZE.max180}
                id={guides[4]?.id}
              />
            </Row>
          ) : (
            <>
              <Row>
                <GuideCardAtom
                  tabIndex={0}
                  title={guides[5]?.title}
                  icon={guides[5]?.icon}
                  cardType={CardType.DashboardStyle}
                  width={SIZE.max180}
                  height={SIZE.max180}
                  id={guides[5]?.id}
                />
              </Row>
              <Row>
                <GuideCardAtom
                  tabIndex={0}
                  title={guides[3]?.title}
                  icon={guides[3]?.icon}
                  cardType={CardType.DashboardStyle}
                  width={SIZE.max180}
                  height={SIZE.max180}
                  id={guides[3]?.id}
                />
              </Row>
            </>
          )}
        </Column>
      </CardsContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
