
// ASSETS

// STYLES
import {
  Bullet,
  ButtonContainer,
  CardContainer,
  ListContainer, ListItem, TextsContainer
} from "./CookiesCardStyled";

// LIBRARIES

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "../../config/variables";
import { IProps } from "./CookiesCardModel";
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";

// REDUX

// COMPONENTS
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import CustomText from "components/Atoms/CustomText/CustomText";

const CookiesCard = (props: IProps) => {
  // PROPERTIES
  const {
    id = 0,
    texts = [],
    title = "",
    list = [],
    hasButton = false,
    onClick = () => { }
  } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  return (
    <CardContainer>
      {title && <CustomText
        type={TextType.H2}
        text={title}
        color={COLORS.RaisinBlack}
      />}
      {texts.length > 0 &&
        <TextsContainer>
          {texts.map((text, index) => (
            <CustomText
              key={index}
              type={TextType.P2}
              text={text}
              color={COLORS.RaisinBlack}
            />
          ))}
        </TextsContainer>}
      {list.length > 0 &&
        <ListContainer>
          {list.map((item, index) => (
            <ListItem
              key={index}
            >
              <Bullet />
              <CustomText
                type={TextType.P2}
                text={item}
                color={"#E62E2E"}
              />
            </ListItem>
          ))}
        </ListContainer>
      }
      {hasButton &&
        <ButtonContainer>
          <ButtonAtom
            type={ButtonType.RedButton}
            text={"Setări Cookie"}
            onClick={onClick}
          />
        </ButtonContainer>
      }
    </CardContainer>
  );
};

export default CookiesCard;
