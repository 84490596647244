// LIBRARIES
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// MISC
import { COLORS, SIZE } from "../../constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const ScrollContainer = styled.nav<{ biggerCursor?: boolean }>`
  padding-top: ${SIZE.max160};
  padding-inline: ${SIZE.max40};
  width: 100%;
  .slick-list {
    overflow: visible;
    margin: 0;
    padding: 0;
  }

  .slick-slide {
    transition: transform 0.3s ease, opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 !important;
    padding: 0;
  }
  .slick-active {
    margin: 0;
    padding: 0;
  }
  .slick-slide.slick-center {
    transform: scale(1.2, 1.3) translate(0, -11%);
    @media (max-width: 1280px) {
      transform: scale(1.1, 1.2) translate(0, -8%);
    }
    z-index: 2;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .slick-center {
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    border-radius: 50%;
    background-color: ${COLORS.TangerineYellow};
    width: ${SIZE.max40};
    height: ${SIZE.max40};
    border: none;
    padding: 8px;
    position: absolute;
    top: -15%;
    cursor: ${({ biggerCursor }) =>
      biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  }
  .slick-prev {
    left: 32vw;
  }

  .slick-next {
    right: 32vw;
  }
`;
export const CarouselItem = styled.div`
  outline: none;
  transition: transform 0.3s ease, opacity 0.3s ease;
  margin: 0;
  padding: 0;
  &.slick-center {
    position: relative;
  }
`;

export const AboutCustomSlider = styled(Slider)`
  .slick-slide {
    transition: transform 0.3s ease, opacity 0.3s ease;
    padding-inline: 12px;
  }

  @media (width <= 768px) {
    .slick-slide {
      padding-top: ${SIZE.max120};
    }
    .slick-center {
      transform: scale(1.1, 1.2) translate(0, -8%);
    }
  }
`;

export const AboutCarouselItem = styled.div`
  aspect-ratio: 1/1.4;

  @media (width <= 768px) {
    aspect-ratio: 1/1.2;
  }
`;

export const CampaignCustomSlider = styled(Slider)`
  background-color: pink;
  height: 100%;
  display: flex;
  /* align-items: center; */
  align-items: stretch;
  max-height: 700px;
  padding: ${SIZE.max40} 0;
  .slick-track > div:not(:first-child) {
    padding-inline: 12px;
  }
  .slick-track > div:first-child {
    padding-inline: 0 12px;
  }

  .slick-slide {
    max-width: 740px;
  }

  @media (width <= 768px) {
    .slick-slide {
      padding-inline: 32px;
    }
  }

  .slick-dots {
    bottom: -40px;
  }

  .slick-list {
    padding-inline: ${SIZE.max280};

    @media (max-width: 768px) {
      padding-inline: ${SIZE.max32};
    }
  }

  .slick-dots > li > button::before {
    opacity: 1;
    font-size: 12px;
    color: ${COLORS.Paua};
  }
  .slick-dots > li.slick-active > button::before {
    opacity: 1;
    font-size: 12px;
    color: ${COLORS.Cinnabar};
  }

  .slick-dots > li {
    display: none;
  }
`;

export const CampaignCarouselItem = styled.div<{ biggerCursor?: boolean }>`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  height: 100%;
  align-items: stretch;
  @media (width<=1280px) {
    max-width: ${SIZE.max900};
  }
  @media (width<=768px) {
    max-width: 100%;
    height: 100%;
  }
`;

export const CarouselScrollbar = styled.div`
  display: flex;
  justify-content: center;
  height: 8px;
`;

export const CarouselDot = styled.div`
  border-radius: 4px;
  height: 8px;
  width: 60%;
  display: flex;
  justify-content: space-between;

  & > li {
    height: 8px;
    width: 100%;
    margin: 0;
    background-color: ${COLORS.WhiteSmoke};
    transition: background-color 0.3s ease-out, border-radius 0.3s ease-out;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
  & > li.slick-active {
    border-radius: 4px;
    background-color: ${COLORS.Cinnabar};
    transition: background-color 0.3s ease-out, border-radius 0.3s ease-out;
  }

  @media (width<550px) {
    width: 80%;
  }
`;

export const CarouselDotButton = styled.div`
  width: auto;
  height: 8px;
  border-radius: 4px;

  background-color: inherit;
`;

export const ArrowWrapper = styled.div``;
