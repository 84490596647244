//LIBRARY
import styled, { css } from "styled-components";
//MISC
import {
  COLORS,
  FONT_FAMILY,
  FONT_WEIGHT,
  BORDER_RADIUS,
  FONT_SIZE,
  MARGIN,
  SIZE,
  DEVICE_TYPE,
  //ROW_SPACING,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const InputWrapper = styled.div<{
  isMultiLine?: boolean;
  isSearchBar?: boolean;
  error?: boolean;
  height?: number;
}>`
  box-sizing: border-box;
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.medium};
  display: flex;
  align-items: center;
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius48};

  width: 100%;

  ${({ isMultiLine }) =>
    isMultiLine &&
    css`
      height: auto;
    `}/* transition: outline 0.3s ease-out; */
`;

export const InputLabel = styled.label<{
  showLabel?: boolean;
  biggerCursor?: boolean;
}>`
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.medium};
  color: ${COLORS.BlackRussian};
  font-size: ${FONT_SIZE.max18};
  margin: 1em 0 1em 0;
  text-align: center;
  margin-bottom: 8px;
  width: 100%;

  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `default`};
`;

export const InputField = styled.input<{
  isMultiLine?: boolean;
  biggerCursor?: boolean;
  error?: boolean;
}>`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${({ accessibilityBiggerText }) =>
    !accessibilityBiggerText ? FONT_SIZE.max18 : FONT_SIZE.max24};
  font-weight: ${FONT_WEIGHT.regular};
  line-height: ${({ accessibilityBiggerLineHeight }) =>
    !accessibilityBiggerLineHeight ? "1.5em" : "1.4em"};
  letter-spacing: ${({ accessibilityBiggerLetterSpacing }) =>
    !accessibilityBiggerLetterSpacing ? 0 : "1px"};
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: ${COLORS.BlackRussian};
  padding: 8px;
  width: 100%;
  ${({ error }) =>
    error &&
    css`
      border-bottom: solid ${COLORS.Cinnabar} 1px;
      color: ${COLORS.Cinnabar};
    `}
  ${({ isMultiLine }) =>
    isMultiLine &&
    css`
      resize: none;
      overflow-y: scroll;
    `}

  &:focus {
    outline: none;
  }
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `text`};
  &::placeholder {
    color: ${COLORS.Paua};
  }
`;

export const StartAdornment = styled.div`
  margin: 6px 0 0 6px;
  & > img {
    width: 2em;
    height: 2em;
  }
`;
export const EndAdornment = styled.div`
  padding: 6px;
`;

export const OptionsWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: ${BORDER_RADIUS.radius32};
  border-bottom-right-radius: ${BORDER_RADIUS.radius32};
  width: ${SIZE.max720};
  background-color: ${COLORS.WhiteSmoke};
  padding-bottom: ${MARGIN.max40};
  position: absolute;
  max-height: 400px;
  overflow-y: auto;

  transform: translateY(-10px);
  padding-top: ${MARGIN.max20};
  transition: 0.3s ease-in;
  &:focus {
    outline: none;
  }

  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    max-width: 330px;
  }
`;

export const Option = styled.div`
  width: 100%;
  height: 41px;
  color: ${COLORS.BlackRussian};
  padding: ${MARGIN.max8} ${MARGIN.max32};
  &:hover {
    background-color: ${COLORS.Paua};
    border-radius: ${BORDER_RADIUS.radius32};
  }
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : "pointer"};
`;

export const ChatButton = styled.div`
  border: none;
  outline: none;
  background: ${COLORS.WhiteSmoke};
  padding-right: 0.7em;
  border-radius: ${BORDER_RADIUS.radius32};

  & img {
    width: 24px;
    height: 24px;
    margin: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  background: ${COLORS.WhiteSmoke};
  border-radius: 80px;
`;
