import React from "react";

// ASSETS
import { PillAtomProps } from "./PillAtomModel";

// STYLES
import { Pill } from "./PillAtomStyled";

import { TextType } from "../CustomText/CustomTextModel";

// COMPONENTS
import CustomText from "../CustomText/CustomText";
import { COLORS } from "constants/variables";

// TODO: stylize it
const PillAtom = (props: PillAtomProps) => {
  // PROPERTIES
  const { text, status } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <Pill status={status}>
      <CustomText
        text={text}
        type={TextType.H4fix}
        color={status ? COLORS.BlackRussian : COLORS.WhiteSmoke}
      />
    </Pill>
  );
};

export default PillAtom;
