export enum CardType {
  DashboardStyle,
  GuideStyle,
  CarouselStyle,
  SingleGuideStyle,
}
export interface IGuideCardProps {
  id?: any;
  title?: string;
  icon?: React.ReactNode;
  cardType?: CardType;
  width?: string;
  tabIndex?: number;
  height?: string;
  handleCardPress?: () => void;
}
