import React from "react";

// STYLES
import { HomePageContainer } from "./HomePageStyled";

// LIBRARIES

// MISC
import { COLORS } from "constants/variables";

// COMPONENTS
import MapFromDashboard from "components/MapFromDashboard/MapFromDashboard";
import Dashboard from "components/Dashboard/Dashboard";
import DownloadAppComponent from "components/Atoms/DownloadAppComponent/DownloadAppComponent";
import SectionWrapper from "components/SectionWrapper/SectionWrapper";
import CampaigneSection from "components/Sections/CampaigneSection/CampaigneSection";
import CarouselSection from "components/Sections/CarouselSection/CarouselSection";

// CONFIGURATION

const HomePage = () => {
  // PROPERTIES

  // API REQUESTS

  // The tabs for the menu

  // Function to handle tab selection

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <HomePageContainer>
      <SectionWrapper hasPadding={false}>
        <Dashboard />
      </SectionWrapper>
      <SectionWrapper
        hasPadding={false}
        background={`linear-gradient(to bottom, ${COLORS.Solitude} 65%, ${COLORS.WhiteSmoke} 35%)`}
      >
        <CarouselSection />
      </SectionWrapper>
      <SectionWrapper hasPadding={false} background={COLORS.WhiteSmoke}>
        <CampaigneSection />
      </SectionWrapper>
      <SectionWrapper
        hasPadding={false}
        background={`linear-gradient(to bottom, ${COLORS.WhiteSmoke} 50%, ${COLORS.Solitude} 50%)`}
      >
        <MapFromDashboard />
      </SectionWrapper>
      <SectionWrapper hasPadding={true}>
        <DownloadAppComponent />
      </SectionWrapper>
    </HomePageContainer>
  );
};

export default HomePage;
