import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { store } from "store/store";

const resources = {
  ro: {
    translation: require("./ro.json"),
  },
  en: {
    translation: require("./en.json"),
  },
};

// Function to get the language from the Redux store
const getInitialLanguage = () => {
  const state = store.getState();
  return state.language?.language || 'ro'; 
};

i18n.use(initReactI18next).init({
  resources,
  lng: getInitialLanguage(),
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
