import axios, { AxiosError, AxiosInstance } from "axios";
import * as process from "process";

export const baseUrl = process.env.REACT_APP_API_URL;

class AxiosBase {
  base: AxiosInstance;

  constructor() {
    this.base = axios.create({
      baseURL: baseUrl,
    });

    this.base.interceptors.request.use(
      async (config) => {
        console.log("Base URL:", this.base.defaults.baseURL); // Log the base URL
        return config;
      },
      (error) => {
        console.error("Request error:", JSON.stringify(error, null, 2)); // Log request errors
        return Promise.reject(error);
      }
    );

    this.base.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: AxiosError) => {
        // Handle errors globally
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response error:", JSON.stringify(error.response, null, 2)); // Log response errors

          if (error.response.status === 404) {
            return Promise.reject({ error: "not found" });
          } else {
            return Promise.reject({ error: true });
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request error:", JSON.stringify(error.request, null, 2));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
        }
        return Promise.reject(error);
      }
    );
  }

  logBaseURL() {
    console.log("Base URL:", this.base.defaults.baseURL);
  }
}

const base = new AxiosBase();
base.logBaseURL(); // Log the base URL when the instance is created

export default base;
