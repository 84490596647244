// LIBRARIES
import styled from "styled-components";

// MISC
import { DEVICE_SIZE, DEVICE_TYPE, MARGIN, SIZE } from "constants/variables";

export const CampaigneSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${SIZE.max24} 0 ${SIZE.max40} 0;
`;
export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${SIZE.max84} ${SIZE.max84} 0 ${SIZE.max84};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: ${MARGIN.max32} ${MARGIN.max32} 0 ${MARGIN.max32};
  }
`;
export const CardCarouselTitle = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${SIZE.max32};
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    margin-bottom: ${SIZE.max24};
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    margin-bottom: ${SIZE.max10};
  }
`;

export const CardCarouselSubtitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    width: 100%;
  }
`;
