import React from "react";

// ASSETS
import LogoDsu from "assets/svg/LogoDsu.svg";
// STYLES
import {
  HeaderContainer,
  Logo,
  LogoTextContainer,
  HeaderText,
  LogoContainer,
  ButtonsBarContainer,
} from "./HeaderStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";
import { IProps } from "./HeaderModel";
// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import ButtonsBarAtom from "components/Atoms/ButtonsBarAtom/ButtonsBarAtom";
import HeaderAtom from "components/Atoms/HeaderAtom/HeaderAtom";
import CustomText from "components/Atoms/CustomText/CustomText";

const Header = (props: IProps) => {
  // PROPERTIES
  const { handleScrollToTop } = props;
  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  const goToHomePage = () => {
    navigate("/");
    handleScrollToTop();
  };
  return (
    <HeaderContainer>
      <LogoContainer onClick={goToHomePage} biggerCursor={biggerCursor}>
        <Logo src={LogoDsu} alt="Logo" biggerCursor={biggerCursor} />
        <LogoTextContainer biggerCursor={biggerCursor}>
          <HeaderText>
            <CustomText
              text={t("components.header.beprepared")}
              type={TextType.H4Logo}
              color={COLORS.Cinnabar}
            />
          </HeaderText>
        </LogoTextContainer>
      </LogoContainer>

      <HeaderAtom
        tabs={[
          {
            id: 0,
            tab: t("components.hamburgerMenu.guides"),
            path: "/ghiduri",
          },
          {
            id: 1,
            tab: t("components.hamburgerMenu.getReady"),
            path: "/pregateste-te",
          },
          {
            id: 2,
            tab: t("components.hamburgerMenu.campaigns"),
            path: "/campanii",
          },
          {
            id: 3,
            tab: t("components.hamburgerMenu.about"),
            path: "/despre",
          },
        ]}
      />

      <ButtonsBarContainer>
        <ButtonsBarAtom />
      </ButtonsBarContainer>
    </HeaderContainer>
  );
};

export default Header;
