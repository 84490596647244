import React, { useEffect, useState } from "react";
// ASSETS
import { BORDER_RADIUS, COLORS } from "constants/variables";
// STYLES
import {
  FAQContainer,
  TitleContainer,
  DropdownContainer,
  ViewMore,
  ViewMoreLink,
} from "./FAQStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import AccordionComponent from "./AccordionComponent/AccordionComponent";
import partsService from "../../../services/partsService";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";
const FAQ = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const { t } = useTranslation();
  const [faqs, setFAQs] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>(undefined);
  // API REQUESTS

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response: any = await partsService.getFAQs(page);
        if (response.data) {
          if (page === 1) {
            setFAQs((faqs) => response.data);
          } else {
            setFAQs((faqs) => [...faqs, ...response.data]);
          }
          setMeta(() => response.meta.pagination);
        }
      } catch (err) {}
    })();
  }, [page]);

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <FAQContainer>
      <TitleContainer>
        <CustomText text={t("view.contact.FAQ.title")} type={TextType.H2} />
      </TitleContainer>
      <DropdownContainer>
        {faqs.length > 0 ? (
          faqs.map((faq: any, index: number) => (
            <AccordionComponent
              key={index}
              content={faq.attributes.title}
              dropdownContent={<StrapiTreeText text={faq.attributes.content} />}
            />
          ))
        ) : (
          <Skeleton
            width={300}
            height={"4rem"}
            count={1}
            borderRadius={BORDER_RADIUS.radius24}
            baseColor={COLORS.WhiteSmoke}
            highlightColor={COLORS.Solitude}
          />
        )}
        {meta !== undefined && meta.pageCount > page && (
          <ViewMore>
            <ViewMoreLink onClick={() => setPage(page + 1)} tabIndex={0}>
              Mai multe
            </ViewMoreLink>
          </ViewMore>
        )}
      </DropdownContainer>
    </FAQContainer>
  );
};

export default FAQ;
