export const cookieInformation = [
    {
        title: "Generalitati",
        texts: [
            "Fiipregatit.ro folosește cookie-uri în scopul de a oferi o experiență mai bună pentru utilizator. În scopul funcționalității complete a site-urilor web usluga.hr, va trebui să acceptați cookie-uri pe computer, tabletă sau dispozitiv mobil. Credem că este important să știți ce cookie-uri sunt folosite pe site-urile noastre web și în ce scopuri. Acest lucru face mai ușor să vă protejați confidențialitatea, oferindu-vă în același timp cea mai bună experiență de utilizator. Fiipregatit.ro se străduiește să vă facă experiența de utilizator și interacțiunea cât mai informative și relevante posibil. În acest scop, folosim cookie-uri sau tehnici similare. Vă rugăm să rețineți că, continuând să utilizați site-ul nostru, acceptați utilizarea cookie-urilor așa cum este descris în această Politică privind cookie-urile. Prin urmare, credem că este important să citiți cu atenție Politica privind cookie-urile pentru a afla ce cookie-uri sunt utilizate pe site-ul nostru și în ce scopuri."
        ]
    },
    {
        title: "Ce este un cookie?",
        texts: [
            "Cookie-urile sunt fișiere text mici cu o cantitate mică de date, care sunt descărcate și pot fi stocate pe dispozitivul dvs., cum ar fi computerul dvs. (sau alte dispozitive cu o conexiune la Internet activă, cum ar fi un smartphone sau o tabletă). În Fiipregatit.ro putem folosi tehnici similare, cum ar fi pixeli, trackere web și amprenta dispozitivului. Din motive de coerență, toate aceste tehnici sunt denumite în mod colectiv „cookie-uri” în textul următor. Această Politică privind cookie-urile conține informații despre cookie-urile pe care le folosim și motivele utilizării acestora."
        ]
    },
    {
        title: "Cookie-uri utilizate pe site",
        texts: [
            "Fiipregatit.ro folosește mai multe tipuri diferite de cookie-uri. Unele dintre acestea sunt cookie-uri de sesiune care sunt temporare și ne permit să asociem acțiunile dumneavoastră în timpul sesiunii de browser. Cookie-urile de sesiune sunt șterse după ce închideți browserul. Alte cookie-uri sunt permanente și rămân pe dispozitivul dumneavoastră pentru perioada specificată în cookie. Cookie-urile de pe site sunt utilizate în următoarele scopuri:"
        ]
    },
    {
        title: "Cookie-uri obligatorii",
        texts: [
            "Cookie-urile obligatorii sunt cookie-uri de bază care vă permit să navigați pe site. Acestea facilitează utilizarea site-ului vizitatorilor. Aceste cookie-uri vă permit să trimiteți formularul cu confirmarea vârstei o singură dată. Aceste cookie-uri îmbunătățesc, de asemenea, securitatea și funcționalitatea de bază a site-ului web."
        ]
    },
    {
        title: "Cookie-uri de performanță",
        texts: [
            "Cookie-urile de performanță ne ajută să înțelegem comportamentul vizitatorilor noștri și utilizarea site-ului într-o manieră agregată. De exemplu, putem folosi aceste module cookie pentru a obține o perspectivă asupra modului în care vizitatorii folosesc site-ul nostru. În acest fel, putem determina ce este funcțional și ce nu, astfel putem îmbunătăți constant site-ul și măsura eficiența reclamei și comunicării noastre. Folosim cookie-uri Google Analytics pentru a obține o imagine de ansamblu cuprinzătoare a obiceiurilor și a numărului de vizitatori și pentru a îmbunătăți experiența generală pe site-ul nostru. Puteți refuza utilizarea acestor cookie-uri selectând setările corespunzătoare din browser, așa cum este descris mai sus. În plus, puteți împiedica colectarea și utilizarea datelor (cookie-uri și adresă IP) de către Google prin descărcarea și instalarea plug-in-ului de browser disponibil la următorul link: https://tools.google.com/dlpage/gaoptout?hl=ro-GB# . Puteți dezactiva utilizarea Google Analytics făcând clic pe linkul pentru a dezactiva Google Analytics . Acest link creează un cookie de renunțare care împiedică prelucrarea ulterioară a datelor dumneavoastră. Mai multe informații despre cookie-urile Google Analytics pot fi găsite pe paginile de ajutor online ale Google și în Politica de confidențialitate: Politica de confidențialitate Google Pagina de ajutor Google Analytics."
        ]
    },
    {
        title: "Cookie-uri de comunicare",
        texts: [
            "Site-ul web permite utilizarea cookie-urilor de rețele sociale (ex. Facebook, YouTube, Twitter, LinkedIn). Acest lucru vă permite să partajați conținut din Network Place pe rețelele sociale. Aceste terțe părți pot folosi cookie-uri în propriile lor scopuri. Serviciul Poreč nu poate influența modul în care aceste rețele sociale vă folosesc datele. Mai multe informații despre cookie-urile setate de rețelele sociale pot fi găsite în politicile lor de confidențialitate și cookie-uri."
        ]
    },
    {
        title: "Gestionați setările cookie-urilor",
        texts: [
            "Când ați fost de acord cu utilizarea cookie-urilor, vom stoca un cookie pe computerul sau dispozitivul dumneavoastră pentru a reține aceste informații pentru următoarea vizită a site-ului nostru web. Dacă doriți să vă retrageți consimțământul pentru utilizarea cookie-urilor în orice moment, va trebui să ștergeți cookie-urile noastre din setările browserului dvs. de internet. Ar trebui să faceți acest lucru în fiecare browser pe care îl utilizați. Vă rugăm să rețineți că unele dintre serviciile noastre nu vor funcționa dacă nu acceptați cookie-uri în browser. Cu toate acestea, puteți acorda permisiunea pentru utilizarea cookie-urilor de pe anumite site-uri, marcându-le ca „site-uri de încredere” în browserul dumneavoastră. Puteți găsi ajutor în gestionarea setărilor cookie-urilor pe următoarele link-uri sau puteți utiliza opțiunea „Ajutor” din browserul dumneavoastră de internet pentru mai multe informații.",
            "Pentru a bloca numai cookie-urile Google Analytics, puteți instala „Google Analytics Opt-Out Browser Add-on” oferit de Google."
        ],
        list: ["Internet Explorer", "Mozilla Firefox", "Google Chrome", "Safari", "Operă"]
    },
    {
        title: "Concluzie",
        texts: [
            "Vom revizui periodic și ocazional vom actualiza această Politică privind cookie-urile. Orice modificare a acestei Politici privind cookie-urile va fi postată pe site-ul nostru web și, în măsura în care este rezonabil posibil, veți fi notificat. Puteți găsi cea mai recentă versiune pe acest site. În cazul unor întrebări și/sau comentarii suplimentare, trimiteți întrebarea dvs. la următoarea adresă de e-mail: dsu@mai.gov.ro"
        ],
        hasButton: true,
        onClick: () => {

        }
    }
];

export const initialNecessaryCookies = [
    { title: "essential_accept", description: "Verifică dacă utilizatorul a acceptat cookie-urile necesare.", checked: true },
    // Add more necessary cookies if needed
];

export const initialAnalyticsCookies = [
    { title: "_ga", description: "Google Analytics cookie used to distinguish users.", checked: false },
    // Add more analytics cookies if needed
];

export const translations = {
    en: {
        consentTitle: "Cookies",
        consentText: "We use cookies to ensure you get the best experience on our website.",
        acceptButtonText: "Accept All",
        manageSettingsText: "Manage Settings",
        settingsTitle: "Storage Settings",
        settingsDescription: "We use cookies to ensure our website functions properly and to offer you the best user experience. Necessary cookies are always active for proper functionality. With your permission, we use optional cookies to provide social media features, show personalized content, and analyze your behavior on our website.",
        necessaryTitle: "Necessary",
        necessaryDescription: "These cookies are essential for the proper functioning of our website. They enable basic functionalities such as page navigation and access to secure areas of the website. Without these cookies, the site cannot function properly.",
        analyticsTitle: "Analytics",
        analyticsDescription: "These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. We use Google Analytics to analyze website traffic and improve user experience. The information collected includes data about the pages visited, duration of the visit, and other relevant metrics.",
        saveButtonText: "Save Settings",
        cookies: {
            necessary: [
                { title: "essential_accept", description: "Checks if the user has accepted necessary cookies.", checked: true },
                { title: "session_id", description: "Maintains user session information.", checked: true }
            ],
            analytics: [
                { title: "_ga", description: "Google Analytics cookie used to distinguish users.", checked: false },
                { title: "_gid", description: "Google Analytics cookie used to distinguish users and sessions.", checked: false }
            ]
        },
        cookieModuleTitle: "Cookies",
        cookieInformation: [
            {
                title: "General Information",
                texts: [
                    "Fiipregatit.ro uses cookies to provide a better user experience. For the full functionality of the usluga.hr websites, you will need to accept cookies on your computer, tablet, or mobile device. We believe it is important for you to know what cookies are used on our websites and for what purposes. This makes it easier to protect your privacy while providing you with the best user experience. Fiipregatit.ro strives to make your user experience and interaction as informative and relevant as possible. To this end, we use cookies or similar techniques. Please note that by continuing to use our site, you agree to the use of cookies as described in this Cookie Policy. Therefore, we believe it is important to read the Cookie Policy carefully to find out which cookies are used on our site and for what purposes."
                ]
            },
            {
                title: "What is a Cookie?",
                texts: [
                    "Cookies are small text files with a small amount of data that are downloaded and can be stored on your device, such as your computer (or other devices with an active Internet connection, such as a smartphone or tablet). At Fiipregatit.ro we may use similar techniques, such as pixels, web trackers, and device fingerprinting. For the sake of consistency, all these techniques are collectively referred to as 'cookies' in the following text. This Cookie Policy contains information about the cookies we use and the reasons for using them."
                ]
            },
            {
                title: "Cookies Used on the Site",
                texts: [
                    "Fiipregatit.ro uses several different types of cookies. Some of these are session cookies, which are temporary and allow us to link your actions during a browser session. Session cookies are deleted after you close your browser. Other cookies are permanent and remain on your device for the specified period in the cookie. Cookies on the site are used for the following purposes:"
                ]
            },
            {
                title: "Mandatory Cookies",
                texts: [
                    "Mandatory cookies are basic cookies that allow you to navigate the site. They facilitate the use of the site for visitors. These cookies allow you to submit the age confirmation form only once. These cookies also improve the security and basic functionality of the website."
                ]
            },
            {
                title: "Performance Cookies",
                texts: [
                    "Performance cookies help us understand our visitors' behavior and the use of the site in an aggregated manner. For example, we may use these cookies to gain insight into how visitors use our site. This way, we can determine what is functional and what is not, thus we can constantly improve the site and measure the effectiveness of our advertising and communication. We use Google Analytics cookies to gain a comprehensive overview of the habits and number of visitors and to improve the overall experience on our site. You can refuse the use of these cookies by selecting the appropriate settings in your browser, as described above. Additionally, you can prevent Google from collecting and using data (cookies and IP address) by downloading and installing the browser plug-in available at the following link: https://tools.google.com/dlpage/gaoptout?hl=en-GB#. You can disable the use of Google Analytics by clicking the link to disable Google Analytics. This link creates an opt-out cookie that prevents further processing of your data. More information about Google Analytics cookies can be found on Google's online help pages and in the Privacy Policy: Google Privacy Policy Google Analytics Help Page."
                ]
            },
            {
                title: "Communication Cookies",
                texts: [
                    "The website allows the use of social media cookies (e.g. Facebook, YouTube, Twitter, LinkedIn). This allows you to share content from Network Place on social media. These third parties may use cookies for their own purposes. The Poreč Service cannot influence how these social networks use your data. More information about the cookies set by social networks can be found in their privacy and cookie policies."
                ]
            },
            {
                title: "Manage Cookie Settings",
                texts: [
                    "When you have agreed to the use of cookies, we will store a cookie on your computer or device to remember this information for your next visit to our website. If you wish to withdraw your consent to the use of cookies at any time, you will need to delete our cookies from your internet browser settings. You should do this in every browser you use. Please note that some of our services will not function if you do not accept cookies in your browser. However, you can grant permission for the use of cookies on certain sites by marking them as 'trusted sites' in your browser. You can find help in managing cookie settings at the following links or you can use the 'Help' option in your internet browser for more information.",
                    "To block only Google Analytics cookies, you can install the 'Google Analytics Opt-Out Browser Add-on' provided by Google."
                ],
                list: ["Internet Explorer", "Mozilla Firefox", "Google Chrome", "Safari", "Opera"]
            },
            {
                title: "Conclusion",
                texts: [
                    "We will periodically review and occasionally update this Cookie Policy. Any changes to this Cookie Policy will be posted on our website and, as far as reasonably possible, you will be notified. You can find the latest version on this site. If you have any further questions and/or comments, please send your question to the following email address: Fiipregatit.ro"
                ],
                hasButton: true,
                onClick: () => { }
            }
        ]
    },
    ro: {
        consentTitle: "Cookie-uri",
        consentText: "Folosim cookie-uri pentru a vă asigura cea mai bună experiență pe site-ul nostru.",
        acceptButtonText: "Acceptați toate",
        manageSettingsText: "Gestionați setările",
        settingsTitle: "Setări de Stocare",
        settingsDescription: "Folosim cookie-uri pentru a asigura funcționarea corectă a site-ului nostru și pentru a vă oferi cea mai bună experiență de utilizator. Cookie-urile necesare sunt întotdeauna active pentru funcționalitatea corectă. Cu permisiunea dumneavoastră, folosim cookie-uri opționale pentru a oferi funcții de rețele sociale, pentru a afișa conținut personalizat și pentru a analiza comportamentul dumneavoastră pe site-ul nostru.",
        necessaryTitle: "Necesare",
        necessaryDescription: "Aceste cookies sunt esențiale pentru funcționarea corectă a site-ului nostru. Ele permit utilizarea funcțiilor de bază, cum ar fi navigarea pe pagină și accesul la zonele securizate ale site-ului. Fără aceste cookies, site-ul nu poate funcționa corespunzător.",
        analyticsTitle: "Analitice",
        analyticsDescription: "Aceste cookies ne ajută să înțelegem cum interacționează vizitatorii cu site-ul nostru prin colectarea și raportarea informațiilor în mod anonim. Folosim Google Analytics pentru a analiza traficul pe site și pentru a îmbunătăți experiența utilizatorilor. Informațiile colectate includ date despre paginile vizitate, durata vizitei și alte metrici relevante.",
        saveButtonText: "Salvează setările",
        cookies: {
            necessary: [
                { title: "essential_accept", description: "Verifică dacă utilizatorul a acceptat cookie-urile necesare.", checked: true },
                { title: "session_id", description: "Menține informațiile despre sesiunea utilizatorului.", checked: true }
            ],
            analytics: [
                { title: "_ga", description: "Cookie Google Analytics folosit pentru a distinge utilizatorii.", checked: false },
                { title: "_gid", description: "Cookie Google Analytics folosit pentru a distinge utilizatorii și sesiunile.", checked: false }
            ]
        },
        cookieModuleTitle: "Cookie-uri",
        cookieInformation: [
            {
                title: "Generalitati",
                texts: [
                    "Fiipregatit.ro folosește cookie-uri în scopul de a oferi o experiență mai bună pentru utilizator. În scopul funcționalității complete a site-urilor web usluga.hr, va trebui să acceptați cookie-uri pe computer, tabletă sau dispozitiv mobil. Credem că este important să știți ce cookie-uri sunt folosite pe site-urile noastre web și în ce scopuri. Acest lucru face mai ușor să vă protejați confidențialitatea, oferindu-vă în același timp cea mai bună experiență de utilizator. Fiipregatit.ro se străduiește să vă facă experiența de utilizator și interacțiunea cât mai informative și relevante posibil. În acest scop, folosim cookie-uri sau tehnici similare. Vă rugăm să rețineți că, continuând să utilizați site-ul nostru, acceptați utilizarea cookie-urilor așa cum este descris în această Politică privind cookie-urile. Prin urmare, credem că este important să citiți cu atenție Politica privind cookie-urile pentru a afla ce cookie-uri sunt utilizate pe site-ul nostru și în ce scopuri."
                ]
            },
            {
                title: "Ce este un cookie?",
                texts: [
                    "Cookie-urile sunt fișiere text mici cu o cantitate mică de date, care sunt descărcate și pot fi stocate pe dispozitivul dvs., cum ar fi computerul dvs. (sau alte dispozitive cu o conexiune la Internet activă, cum ar fi un smartphone sau o tabletă). În Fiipregatit.ro putem folosi tehnici similare, cum ar fi pixeli, trackere web și amprenta dispozitivului. Din motive de coerență, toate aceste tehnici sunt denumite în mod colectiv „cookie-uri” în textul următor. Această Politică privind cookie-urile conține informații despre cookie-urile pe care le folosim și motivele utilizării acestora."
                ]
            },
            {
                title: "Cookie-uri utilizate pe site",
                texts: [
                    "Fiipregatit.ro folosește mai multe tipuri diferite de cookie-uri. Unele dintre acestea sunt cookie-uri de sesiune care sunt temporare și ne permit să asociem acțiunile dumneavoastră în timpul sesiunii de browser. Cookie-urile de sesiune sunt șterse după ce închideți browserul. Alte cookie-uri sunt permanente și rămân pe dispozitivul dumneavoastră pentru perioada specificată în cookie. Cookie-urile de pe site sunt utilizate în următoarele scopuri:"
                ]
            },
            {
                title: "Cookie-uri obligatorii",
                texts: [
                    "Cookie-urile obligatorii sunt cookie-uri de bază care vă permit să navigați pe site. Acestea facilitează utilizarea site-ului vizitatorilor. Aceste cookie-uri vă permit să trimiteți formularul cu confirmarea vârstei o singură dată. Aceste cookie-uri îmbunătățesc, de asemenea, securitatea și funcționalitatea de bază a site-ului web."
                ]
            },
            {
                title: "Cookie-uri de performanță",
                texts: [
                    "Cookie-urile de performanță ne ajută să înțelegem comportamentul vizitatorilor noștri și utilizarea site-ului într-o manieră agregată. De exemplu, putem folosi aceste module cookie pentru a obține o perspectivă asupra modului în care vizitatorii folosesc site-ul nostru. În acest fel, putem determina ce este funcțional și ce nu, astfel putem îmbunătăți constant site-ul și măsura eficiența reclamei și comunicării noastre. Folosim cookie-uri Google Analytics pentru a obține o imagine de ansamblu cuprinzătoare a obiceiurilor și a numărului de vizitatori și pentru a îmbunătăți experiența generală pe site-ul nostru. Puteți refuza utilizarea acestor cookie-uri selectând setările corespunzătoare din browser, așa cum este descris mai sus. În plus, puteți împiedica colectarea și utilizarea datelor (cookie-uri și adresă IP) de către Google prin descărcarea și instalarea plug-in-ului de browser disponibil la următorul link: https://tools.google.com/dlpage/gaoptout?hl=ro-GB# . Puteți dezactiva utilizarea Google Analytics făcând clic pe linkul pentru a dezactiva Google Analytics . Acest link creează un cookie de renunțare care împiedică prelucrarea ulterioară a datelor dumneavoastră. Mai multe informații despre cookie-urile Google Analytics pot fi găsite pe paginile de ajutor online ale Google și în Politica de confidențialitate: Politica de confidențialitate Google Pagina de ajutor Google Analytics."
                ]
            },
            {
                title: "Cookie-uri de comunicare",
                texts: [
                    "Site-ul web permite utilizarea cookie-urilor de rețele sociale (ex. Facebook, YouTube, Twitter, LinkedIn). Acest lucru vă permite să partajați conținut din Network Place pe rețelele sociale. Aceste terțe părți pot folosi cookie-uri în propriile lor scopuri. Serviciul Poreč nu poate influența modul în care aceste rețele sociale vă folosesc datele. Mai multe informații despre cookie-urile setate de rețelele sociale pot fi găsite în politicile lor de confidențialitate și cookie-uri."
                ]
            },
            {
                title: "Gestionați setările cookie-urilor",
                texts: [
                    "Când ați fost de acord cu utilizarea cookie-urilor, vom stoca un cookie pe computerul sau dispozitivul dumneavoastră pentru a reține aceste informații pentru următoarea vizită a site-ului nostru web. Dacă doriți să vă retrageți consimțământul pentru utilizarea cookie-urilor în orice moment, va trebui să ștergeți cookie-urile noastre din setările browserului dvs. de internet. Ar trebui să faceți acest lucru în fiecare browser pe care îl utilizați. Vă rugăm să rețineți că unele dintre serviciile noastre nu vor funcționa dacă nu acceptați cookie-uri în browser. Cu toate acestea, puteți acorda permisiunea pentru utilizarea cookie-urilor de pe anumite site-uri, marcându-le ca „site-uri de încredere” în browserul dumneavoastră. Puteți găsi ajutor în gestionarea setărilor cookie-urilor pe următoarele link-uri sau puteți utiliza opțiunea „Ajutor” din browserul dumneavoastră de internet pentru mai multe informații.",
                    "Pentru a bloca numai cookie-urile Google Analytics, puteți instala „Google Analytics Opt-Out Browser Add-on” oferit de Google."
                ],
                list: ["Internet Explorer", "Mozilla Firefox", "Google Chrome", "Safari", "Opera"]
            },
            {
                title: "Concluzie",
                texts: [
                    "Vom revizui periodic și ocazional vom actualiza această Politică privind cookie-urile. Orice modificare a acestei Politici privind cookie-urile va fi postată pe site-ul nostru web și, în măsura în care este rezonabil posibil, veți fi notificat. Puteți găsi cea mai recentă versiune pe acest site. În cazul unor întrebări și/sau comentarii suplimentare, trimiteți întrebarea dvs. la următoarea adresă de e-mail: dsu@mai.gov.ro"
                ],
                hasButton: true,
                onClick: () => { }
            }
        ]
    }
};
