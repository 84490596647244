import React, { useState } from "react";

// ASSETS
import bellSelected from "assets/svg/BellIconSelected.svg";
import bellIcon from "assets/svg/BellIcon.svg";
import mapIcon from "assets/svg/MapIcon.svg";
import mapIconSelected from "assets/svg/MapIconSelected.svg";
import searchIcon from "assets/svg/SearchIcon.svg";
import searchIconSelected from "assets/svg/SearchIconSelected.svg";
import manIcon from "assets/svg/ManIcon.svg";
import manIconSelected from "assets/svg/ManIconSelected.svg";
import phoneIcon from "assets/svg/PhoneIcon.svg";
import phoneIconSelected from "assets/svg/PhoneIconSelected.svg";
import gptIcon from "assets/svg/GptIcon.svg";

// STYLES
import {
  ButtonsBarWrapper,
  ButtonsBarContainer,
  HoverText,
  WrapperLanguage,
} from "./ButtonsBarAtomStyled";
import IconButtonAtom from "../IconButtonAtom/IconButtonAtom";

// LIBRARIES

import { useNavigate, useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

// MISC

import { IconButtonAtomProps } from "../IconButtonAtom/IconButtonAtomModel";
import { UIState } from "./ButtonsBarAtomModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";
import { useTranslation } from "react-i18next";

// REDUX

import { selectLanguage } from "slices/LanguageSlice/languageSlice";

// COMPONENTS
import AccesibilityModal from "./AccesilibilyModal/AccesibilityModal";
import SearchBarAccesibility from "./SearchBar/SearchBar";
import NotificationModal from "./NotificationModal/NotificationModal";
import LanguageModal from "./LanguageModal/LanguageModal";
import CustomText from "components/Atoms/CustomText/CustomText";

const ButtonsBarAtom: React.FC = () => {
  // PROPERTIES

  // STATE CONSTANTS

  const [uiState, setUiState] = useState<UIState>({
    selectedIconIndex: null,
    isLanguageModalOpen: false,
    isModalOpen: false,
    isSearchBarOpen: false,
    isNotificationModalOpen: false,
  });

  // LIBRARY CONSTANTS

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1280 });
  const language = useSelector(selectLanguage);

  const iconsList: IconButtonAtomProps[] = [
    {
      id: 0,
      icon: gptIcon,
      isSelected: uiState.selectedIconIndex === 0,
      iconSelected: gptIcon,
      shouldNavigateOn: true,
      isText: false,
      path: "/fiipregatit-chat-bot",
      hoverText: t("components.header.chatBot"),
    },
    {
      id: 1,
      icon: searchIcon,
      isSelected: uiState.selectedIconIndex === 1,
      iconSelected: searchIconSelected,
      shouldNavigateOn: false,
      isText: false,
      hoverText: t("components.header.search"),
    },
    {
      id: 2,
      icon: mapIcon,
      isSelected: uiState.selectedIconIndex === 2,
      iconSelected: mapIconSelected,
      shouldNavigateOn: true,
      isText: false,
      path: "/harta",
      hoverText: t("components.header.map"),
    },
    {
      id: 3,
      icon: bellIcon,
      isSelected: uiState.selectedIconIndex === 3,
      iconSelected: bellSelected,
      shouldNavigateOn: false,
      isText: false,
      hoverText: t("components.header.alert"),
    },
    {
      id: 4,
      isText: true,
      text: language.toUpperCase(),
      isSelected: uiState.selectedIconIndex === 4,
      shouldNavigateOn: false,
      hoverText: t("components.header.language"),
    },
    {
      id: 5,
      icon: manIcon,
      isSelected: uiState.selectedIconIndex === 5,
      iconSelected: manIconSelected,
      shouldNavigateOn: false,
      isText: false,
      hoverText: t("components.header.accesibility"),
    },
    {
      id: 6,
      icon: phoneIcon,
      isSelected: uiState.selectedIconIndex === 6,
      iconSelected: phoneIconSelected,
      shouldNavigateOn: true,
      isText: false,
      path: "/contact",
      hoverText: t("components.header.contact"),
    },
  ];
  const alertsData = [];

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleIconClick = (iconProps: IconButtonAtomProps) => {
    const newState = {
      selectedIconIndex: iconProps.id,
      isModalOpen: iconProps.id === 5,
      isLanguageModalOpen: iconProps.id === 4,
      isSearchBarOpen: iconProps.id === 1,
      isNotificationModalOpen: iconProps.id === 3,
      shouldNavigateOn: iconProps.shouldNavigateOn,
      path: iconProps.path,
    };

    setUiState(newState);

    if (iconProps.shouldNavigateOn) {
      navigate(iconProps.path);
    }
  };
  const closeAccesibilityModal = () =>
    setUiState((prev) => ({
      ...prev,
      isModalOpen: false,
      selectedIconIndex: null,
    }));
  const closeLanguageModal = () =>
    setUiState((prev) => ({
      ...prev,
      isLanguageModalOpen: false,
      selectedIconIndex: null,
    }));
  const closeSearchModal = () =>
    setUiState((prev) => ({
      ...prev,
      isSearchBarOpen: false,
      selectedIconIndex: null,
    }));
  const closeNotificationModal = () =>
    setUiState((prev) => ({
      ...prev,
      isNotificationModalOpen: false,
      selectedIconIndex: null,
    }));

  return (
    <ButtonsBarWrapper>
      <ButtonsBarContainer>
        {iconsList.map((iconProps, index) =>
          iconProps.isText === false ? (
            <IconButtonAtom
              key={index}
              {...iconProps}
              isSelected={
                iconProps.shouldNavigateOn
                  ? window.location.pathname.includes(iconProps.path)
                  : uiState.selectedIconIndex === index
              }
              hoverText={iconProps.hoverText}
              onClick={() => handleIconClick(iconProps)}
            />
          ) : (
            location.pathname === "/" && (
              <WrapperLanguage
                key={index}
                onClick={() => handleIconClick(iconProps)}
                tabIndex={0}
                isSelected={
                  iconProps.shouldNavigateOn
                    ? window.location.pathname.includes(iconProps.path)
                    : uiState.selectedIconIndex === index
                }
              >
                <CustomText
                  text={language.toUpperCase()}
                  type={TextType.H4Logo}
                  color={
                    uiState.selectedIconIndex === index
                      ? COLORS.WhiteSmoke
                      : COLORS.BlackRussian
                  }
                />

                {!isMobile && (
                  <HoverText isMobile={isMobile}>
                    <CustomText
                      text={iconProps.hoverText}
                      type={TextType.H5fix}
                    />
                  </HoverText>
                )}
              </WrapperLanguage>
            )
          )
        )}
      </ButtonsBarContainer>
      {location.pathname === "/" && (
        <LanguageModal
          isOpenProps={uiState.isLanguageModalOpen}
          setIsOpenProps={closeLanguageModal}
          options={[
            { label: "Romana", value: "ro" },
            { label: "English", value: "en" },
          ]}
        />
      )}
      <AccesibilityModal
        isOpen={uiState.isModalOpen}
        onClose={closeAccesibilityModal}
      />
      <SearchBarAccesibility
        isOpen={uiState.isSearchBarOpen}
        onClose={closeSearchModal}
      />
      <NotificationModal
        isOpen={uiState.isNotificationModalOpen}
        onClose={closeNotificationModal}
        alerts={alertsData}
      />
    </ButtonsBarWrapper>
  );
};

export default ButtonsBarAtom;
