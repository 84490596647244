// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { SIZE, DEVICE_TYPE, MARGIN } from "constants/variables";

export const SectionContainer = styled.section<{ hasPadding; background }>`
  width: 100%;
  min-width: 300px;
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background || "none"};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
  }
  ${({ hasPadding }) =>
    hasPadding &&
    css`
      padding: ${SIZE.max84};
      @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
        padding: 0 ${MARGIN.max32};
      }
    `}
`;
