import React from "react";

// ASSETS

// STYLES
import { HeaderWrapper, MenuItem } from "./HeaderAtomStyled";

// LIBRARIES

import { useNavigate } from "react-router-dom";

// MISC
import { TextType } from "../CustomText/CustomTextModel";
import { IProps } from "./HeaderAtomModel";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import CustomText from "../CustomText/CustomText";

const HeaderAtom: React.FC<IProps> = ({ tabs }) => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const navigate = useNavigate();
  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <HeaderWrapper>
      {tabs.map((tab, index) => (
        <MenuItem
          key={index}
          tabIndex={0}
          isSelected={tab.path === window.location.pathname}
          biggerCursor={biggerCursor}
          onClick={() => navigate(tab.path)}
        >
          <CustomText text={tab.tab} type={TextType.H5fix} />
        </MenuItem>
      ))}
    </HeaderWrapper>
  );
};

export default HeaderAtom;
