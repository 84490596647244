// LIBRARIES
import styled from "styled-components";

// MISC
import {
  COLORS,
  SIZE,
  BORDER_RADIUS,
  MARGIN,
  DEVICE_TYPE,
} from "constants/variables";
export const GetReadyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: ${SIZE.max84} ${SIZE.max84} 0 ${SIZE.max84};
`;

export const Card = styled.div`
  background-color: ${COLORS.BlackRussian};
  width: 100%;
  height: 770px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS.radius32};
  padding: ${MARGIN.max48} 0px ${MARGIN.max60} 0px;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
  }
`;

export const FamilyImage = styled.img`
  display: flex;
  align-items: flex-end;
  max-width: 1019px;
  width: 100%;
  min-width: 326px;
  height: auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${MARGIN.max20};
`;

export const Title = styled.div`
  max-width: 500px;
  margin-bottom: ${MARGIN.max16};
`;

export const Subtitle = styled.div`
  max-width: 380px;
`;

export const TwoColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${SIZE.max160};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: ${MARGIN.max80};
  }
`;

export const FirstColumn = styled.div`
  width: 36%;
  display: flex;
  justify-content: flex-end;
  padding-top: ${SIZE.max32};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const SecondColumn = styled.div`
  width: 64%;
  display: flex;
  align-items: flex-end;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
  }
`;

export const Column = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    flex-direction: column;
    gap: ${MARGIN.max32};
  }
`;

export const TitleCard = styled.div`
  padding-bottom: ${MARGIN.max80};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding-bottom: ${MARGIN.max40};
    max-width: 190px;
  }
`;

export const SubtitleCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${MARGIN.max24};
`;

export const NumberSubtitleWrapper = styled.div`
  margin-top: -6px;
  margin-right: ${SIZE.max10};
`;

export const SubtitleWrapper = styled.div`
  max-width: 320px;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    max-width: 240px;
  }
`;
export const BodyElemet = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const ButonAtomWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -${MARGIN.max20};
`;
export const IconSvg = styled.img`
  display: flex;
  align-items: flex-end;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    max-height: 160px;
  }
`;

export const IconSvgMiddle = styled.img`
  display: flex;
  align-items: flex-end;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    max-height: 210px;
  }
`;
