import styled from "styled-components";
import {
  COLORS,
  FONT_SIZE,
  FONT_FAMILY,
  FONT_WEIGHT,
  MARGIN,
  SIZE,
  DEVICE_TYPE,
  BORDER_RADIUS,
  DEVICE_SIZE,
} from "constants/variables";
import Lottie from "lottie-react";

export const DasboardContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: ${SIZE.max180} 0;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: 0;
  }
`;

export const BackgroundAnimation = styled(Lottie)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scale: 5;
  z-index: -1;
  @media (width<=1024px) {
    scale: 3;
  }
`;
export const TwoColumnContainer = styled.div`
  overflow: hidden;
  background-image: url(${(props) => props.backgroundImage});
  background-color: ${COLORS.MandarianOrange};
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  padding: ${SIZE.max200};
  justify-content: center;
  align-items: center;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    padding: ${SIZE.max104} ${SIZE.max200};
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    padding: 0 ${MARGIN.max32};
  }
`;
export const DesktopColumnsContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoContainer = styled.div``;

export const Column1 = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    padding: ${SIZE.max104} 0;
    justify-content: space-around;
  }
`;
export const Column2 = styled.div`
  flex: 1;
  max-width: 540px;
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max40};
  font-weight: ${FONT_WEIGHT.medium};
  color: ${COLORS.TangerineYellow};
  margin-bottom: ${MARGIN.max6};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-bottom: ${SIZE.max16};
  }
`;

export const ContainerText = styled.div`
  max-width: 480px;
  padding-right: ${SIZE.max24};
  margin-bottom: ${SIZE.max32};
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SIZE.max16};
  max-width: ${SIZE.max550};
`;

export const CardComponent = styled.div`
  position: relative;
  max-width: 540px;
  width: 100%;
  min-width: 300px;
  height: 188px;
  border-radius: ${BORDER_RADIUS.radius32};
  background-color: ${COLORS.WhiteSmoke};
  padding: ${SIZE.max32} ${SIZE.fix32};
  @media (max-width: ${DEVICE_SIZE.GALAXY_FOLD}) {
    display: none;
  }
`;
export const ButtonContainerGalaxy = styled.div`
  display: none;
  @media (max-width: ${DEVICE_SIZE.GALAXY_FOLD}) {
    display: block;
    width: 100%;
  }
`;

export const CardTitle = styled.div`
  max-width: 130px;
`;

export const CardSVG = styled.img`
  position: absolute;
  max-width: 224px;
  width: auto;
  max-height: 119px;
  height: 100%;
  min-height: 80px;
  bottom: ${SIZE.max10};
  right: ${SIZE.max16};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 152px;
    bottom: 0px;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: ${SIZE.fix104};
  height: ${SIZE.fix42};
  bottom: ${MARGIN.max24};
  left: ${MARGIN.max32};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    left: ${MARGIN.max24};
  }
`;
