// LIBRARIES
import styled, { css } from "styled-components";
// MISC
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  COLORS,
  BORDER_RADIUS,
} from "../../../constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const HeaderWrapper = styled.nav`
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  display: inline-flex;
  padding: 4px;
  height: 50px;
  gap: 4px;
`;

export const MenuItem = styled.a<{
  isSelected: boolean;
  biggerCursor?: boolean;
}>(
  ({ isSelected }) => css`
    font-family: ${FONT_FAMILY.poppins};
    font-size: 16px;
    font-weight: ${FONT_WEIGHT.semiBold};
    padding: 10px 16px;
    border-radius: ${BORDER_RADIUS.radius24};
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
    ${!isSelected &&
    css`
      transition: 0.3s background-color ease-out;
      :hover {
        background-color: ${COLORS.Solitude};
        transition: 0.3s ease-in;
        cursor: ${({ biggerCursor }) =>
          biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
      }
    `}
    ${isSelected &&
    css`
      background-color: ${COLORS.BlackRussian};
      color: ${COLORS.WhiteSmoke};
    `}
  `
);
