//LIBRARY
import styled, { css } from "styled-components";
//MISC
import {
  COLORS,
  FONT_FAMILY,
  FONT_WEIGHT,
  BORDER_RADIUS,
  FONT_SIZE,
  MARGIN,
  SIZE,
  DEVICE_TYPE,
  ROW_SPACING,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const InputWrapper = styled.div<{
  isMultiLine?: boolean;
  isSearchBar?: boolean;
  error?: boolean;
}>`
  box-sizing: border-box;
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.medium};
  display: flex;
  align-items: center;
  background-color: ${({ isSearchBar }) =>
    isSearchBar ? COLORS.WhiteSmoke : COLORS.Solitude};
  border-radius: ${({ isSearchBar }) =>
    isSearchBar ? BORDER_RADIUS.radius32 : BORDER_RADIUS.radius18};
  border-bottom-left-radius: ${({ hasOptions, isSearchBar }) =>
    isSearchBar
      ? hasOptions
        ? "0px"
        : BORDER_RADIUS.radius32
      : BORDER_RADIUS.radius18};
  border-bottom-right-radius: ${({ hasOptions, isSearchBar }) =>
    isSearchBar
      ? hasOptions
        ? "0px"
        : BORDER_RADIUS.radius32
      : BORDER_RADIUS.radius18};
  padding: ${({ isSearchBar }) => (isSearchBar ? "24px 16px" : "10px 16px")};
  width: 100%;
  height: 56px;
  ${({ isMultiLine }) =>
    isMultiLine &&
    css`
      height: 120px;
    `}

  @media (max-width: 1024px) {
    height: 52px;
    ${({ isMultiLine }) =>
      isMultiLine &&
      css`
        height: 90px;
      `}
  }
`;

export const InputLabel = styled.label<{
  showLabel?: boolean;
  biggerCursor?: boolean;
}>`
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.medium};
  display: ${({ showLabel }) => (showLabel ? "block" : "none")};
  color: ${COLORS.Paua};
  font-size: 0.875rem;
  margin-bottom: 8px;
  width: 100%;
  margin-top: ${MARGIN.max16};
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `default`};
`;

export const InputField = styled.input<{
  isMultiLine?: boolean;
  biggerCursor?: boolean;
  error?: boolean;
}>`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${({ accessibilityBiggerText }) =>
    !accessibilityBiggerText ? FONT_SIZE.max18 : FONT_SIZE.max24};
  font-weight: ${FONT_WEIGHT.regular};
  line-height: ${({ accessibilityBiggerLineHeight }) =>
    !accessibilityBiggerLineHeight ? ROW_SPACING.max20 : ROW_SPACING.max24};
  letter-spacing: ${({ accessibilityBiggerLetterSpacing }) =>
    !accessibilityBiggerLetterSpacing ? 0 : "1px"};
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: ${COLORS.BlackRussian};
  padding: 8px;
  width: 100%;

  ${({ isMultiLine }) =>
    isMultiLine &&
    css`
      height: 100%;
      resize: none;
      overflow-y: hidden;
    `}

  &:focus {
    outline: none;
  }
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `text`};
  &::placeholder {
    color: ${COLORS.Paua};
  }
`;

export const StartAdornment = styled.div`
  margin: 6px 0px 0px 6px;
`;
export const EndAdornment = styled.div`
  padding: 6px;
`;

export const OptionsWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: ${BORDER_RADIUS.radius32};
  border-bottom-right-radius: ${BORDER_RADIUS.radius32};
  width: ${SIZE.max720};
  background-color: ${COLORS.WhiteSmoke};
  padding-bottom: ${MARGIN.max40};
  overflow-x:hidden ;
  position: absolute;
  max-height: 400px;
  overflow-y: inherit;

  transform: translateY(-10px);
  padding-top: ${MARGIN.max20};
  transition: 0.3s ease-in;
  &:focus {
    outline: none;
  }

  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
    max-width: 330px;
  }
`;

export const Option = styled.div`
  width: 100%;
  height: 41px;
  color: ${COLORS.BlackRussian};
  padding: ${MARGIN.max8} ${MARGIN.max32};
  &:hover {
    background-color: ${COLORS.Paua};
    border-radius: ${BORDER_RADIUS.radius32};
  }
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : "pointer"};
`;

export const Content = styled.div`
  position: relative;
  border-radius: ${BORDER_RADIUS.radius32};
  ${InputWrapper} {
    outline: ${({ error }) => error && `3px solid ${COLORS.Cinnabar}`};
  }
  &:hover {
    ${InputWrapper} {
      outline: ${({ error }) =>
        error ? `3px solid ${COLORS.Cinnabar}` : `3px solid ${COLORS.Paua}`};
      transition: 0.3s ease-in;
    }
    ${OptionsWrapper} {
      box-shadow: 0 -1px 0 0 transparent, 3px 0 0 0 ${COLORS.Paua},
        0 3px 0 0 ${COLORS.Paua}, -3px 0 0 0 ${COLORS.Paua};
    }
  }
`;
