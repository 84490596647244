import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import {
  CampaigneSectionWrapper,
  CardCarouselSubtitle,
  CardCarouselTitle,
  TextContent,
} from "./CampaigneSectionStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MISC
import campaignService from "services/campaignService";
import { baseUrl } from "services/base";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";

// REDUX

import { currentLanguageSelector } from "slices/LanguageSlice/languageSliceSelectors";
import { useSelector } from "react-redux";

// COMPONENTS

import CampaignCarousel from "components/CampaigneCarousel/CampaignCarousel";
import CustomText from "components/Atoms/CustomText/CustomText";

const CampaigneSection = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS

  const currentLanguage = useSelector(currentLanguageSelector);

  // STATE CONSTANTS
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // API REQUESTS
  // LIFE CYCLE
  useEffect(() => {
    (async () => {
      const campaigns: any = await campaignService.getCampaigns();

      if (campaigns.data.length > 0) {
        const tmpCampaigns = campaigns.data.map((campaign: any) => {
          // eslint-disable-next-line
          const tmp = campaign.attributes.content.find(
            (el: any) => el.type === "paragraphx"
          );

          return {
            id: campaign.id,
            date: campaign.attributes.start_date.split("-").reverse().join("."),
            title: campaign.attributes.title,
            text: campaign.attributes.content[0].children[0].text,
            imageUrl: `${baseUrl}${campaign.attributes.cover.data.attributes.url}`,
            onCardClick: () => navigate(`/campaigns/${campaign.id}`),
          };
        });
        setCampaigns(tmpCampaigns);
      }
    })();
    // eslint-disable-next-line
  }, [currentLanguage]);

  // EVENT HANDLERS

  return (
    <CampaigneSectionWrapper>
      <TextContent>
        <CardCarouselTitle>
          <CustomText
            text={t("components.campaignSection.title")}
            type={TextType.H1custom}
          ></CustomText>
        </CardCarouselTitle>
        <CardCarouselSubtitle>
          <CustomText
            text={t("components.campaignSection.info")}
            type={TextType.P1}
          />
        </CardCarouselSubtitle>
      </TextContent>

      <CampaignCarousel data={campaigns} />
    </CampaigneSectionWrapper>
  );
};

export default CampaigneSection;
