import React from "react";

// ASSETS

// STYLES
import {
  InputWrapper,
  InputLabel,
  InputField,
  EndAdornment,
  StartAdornment,
  OptionsWrapper,
  Option,
  Content,
} from "./InputAtomStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
// MISC
import { InputAtomProps } from "./InputAtomModel";
import { TextType } from "../CustomText/CustomTextModel";
// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";
import {
  accessibilityBiggerLineHeightSelector,
  accessibilityBiggerTextSelector,
  accessibilityBiggerLetterSpacingSelector,
} from "slices/UISlice/uiSliceSelectors";
// COMPONENTS
import CustomText from "../CustomText/CustomText";
const InputAtom = (props: InputAtomProps) => {
  const {
    startAdornment,
    endAdornment,
    label,
    id,
    showLabel = false,
    isMultiLine = false,
    placeholder,
    isSearchBar = false,
    options = [],
    error = false,
    onChange,
    closeModal,
    onBlur,
    onFocus,
    isModal = false,
    value,
  } = props;

  // PROPERTIES

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const navigate = useNavigate();
  // STATE CONSTANTS
  const accessibilityBiggerText: boolean = useSelector(
    accessibilityBiggerTextSelector
  );
  const accessibilityBiggerLineHeight: boolean = useSelector(
    accessibilityBiggerLineHeightSelector
  );
  const accessibilityBiggerLetterSpacing: boolean = useSelector(
    accessibilityBiggerLetterSpacingSelector
  );
  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const renderInput = () => {
    if (isMultiLine) {
      return (
        <InputField
          as="textarea"
          placeholder={placeholder}
          isMultiLine={true}
          biggerCursor={biggerCursor}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      );
    }
    return (
      <InputField
        type="text"
        placeholder={placeholder}
        biggerCursor={biggerCursor}
        accessibilityBiggerText={accessibilityBiggerText}
        accessibilityBiggerLineHeight={accessibilityBiggerLineHeight}
        accessibilityBiggerLetterSpacing={accessibilityBiggerLetterSpacing}
        error={error}
        {...(id !== undefined ? {id: id} : {})}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
    );
  };
  return (
    <Content onFocus={onFocus} error={error}>
      {showLabel && label && (
        <InputLabel showLabel={showLabel} biggerCursor={biggerCursor}>
          {label}
        </InputLabel>
      )}
      <InputWrapper
        isMultiLine={isMultiLine}
        isSearchBar={isSearchBar}

        hasOptions={options.length > 0 ? true : false}
      >
        {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
        {renderInput()}
        {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
      </InputWrapper>

      {isSearchBar && options.length > 0 && (
        <OptionsWrapper>
          {options.map((element, index) => (
            <Option
              key={index}
              biggerCursor={biggerCursor}
              onClick={() => {
                navigate(element.path);
                isModal && closeModal();
              }}
            >
              <CustomText text={element.option} type={TextType.Option} />
            </Option>
          ))}
        </OptionsWrapper>
      )}
    </Content>
  );
};

export default InputAtom;
