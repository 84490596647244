import React from "react";

// ASSETS
import LogoDsu from "assets/svg/LogoDsu.svg";
import animationData from "assets/animated/Arrow.json";
import YoutubeIconHovered from "assets/svg/HoverYoutube.svg";
import FacebookIconHovered from "assets/svg/HoverFacebook.svg";
import TwitterIconHovered from "assets/svg/twitterIconSelected.svg";
import InstagramIconHovered from "assets/svg/instagramIconSelected.svg";

// STYLES
import {
  BigContainer,
  GridContainer,
  Column,
  Row,
  MediumCard,
  LargeCard,
  FirstCard,
  SecondCard,
  ThirdCard,
  MiddleCard,
  TitleLarge,
  Paragraph,
  IconTextContainer,
  Icon,
  TitleMedium,
  FourthCard,
  Title,
  SocialMediaIconsContainer,
  SocialIcon,
  TextMargin,
  ContactStyled,
  RowTablet,
  LinkWrapper,
  StyledLink,
} from "./FooterStyled";

// LIBRARIES
import Lottie from "lottie-react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";
import { IProps } from "./FooterModel";
// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CirclesAtom from "components/Atoms/CirclesAtom/CirclesAtom";

const Footer = (props: IProps) => {
  // PROPERTIES
  const { handleScrollToTop } = props;
  // LIBRARY CONSTANTS

  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);
  const { t } = useTranslation();
  const sizes = ["380px", "305px", "230px", "150px", "90px"];
  const sizesMobile = ["270px", "220px", "170px", "120px", "70px"];

  const lottieStyle = {
    width: 144,
    height: 144,
    transform: "rotate(180deg)",
  };
  const colors = {
    firstColor: COLORS.Solitude,
    secondColor: COLORS.WhiteSmoke,
  };
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1300 });

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const scrollToTop = () => {
    handleScrollToTop();
  };

  return (
    <>
      <BigContainer>
        <GridContainer>
          <Column>
            <FirstCard tabIndex={0}>
              <TitleLarge>
                <CustomText
                  type={TextType.H2}
                  text={t("components.footer.card1.title")}
                  color={COLORS.Cinnabar}
                />
              </TitleLarge>
              <Paragraph tabIndex={0}>
                <CustomText
                  type={TextType.P1}
                  text={t("components.footer.card1.content")}
                />
              </Paragraph>
              <CirclesAtom
                containerTopPosition={isMobile ? null : "25px"}
                containerBottomPosition={isMobile ? "25px" : null}
                containerRightPosition="25px"
                sizes={isMobile ? sizesMobile : sizes}
                colors={colors}
                top={isMobile ? "0%" : "50%"}
                right="50%"
                left="0%"
                bottom={isMobile ? "50%" : "0%"}
              />
            </FirstCard>
            <Row>
              <SecondCard tabIndex={0}>
                <TitleMedium>{t("components.footer.card2.title")}</TitleMedium>
                <LinkWrapper
                  href={"http://www.dsu.mai.gov.ro"}
                  target="_blank"
                  biggerCursor={biggerCursor}
                >
                  <IconTextContainer>
                    <Icon src={LogoDsu} alt="LogoDsu" />
                    <CustomText
                      type={TextType.H4bold18px}
                      text={t("components.footer.card2.DSU")}
                    />
                  </IconTextContainer>
                </LinkWrapper>
              </SecondCard>
              <ThirdCard tabIndex={0}>
                <TitleMedium> {t("components.footer.card3.title")}</TitleMedium>
                <TextMargin tabIndex={0}>
                  <LinkWrapper biggerCursor={biggerCursor} href="/ghiduri">
                    <CustomText
                      type={TextType.H5fix}
                      text={t("components.footer.card3.guides")}
                    />
                  </LinkWrapper>
                  <LinkWrapper
                    href="/pregateste-te"
                    biggerCursor={biggerCursor}
                  >
                    <CustomText
                      type={TextType.H5fix}
                      text={t("components.footer.card3.getReady")}
                    />
                  </LinkWrapper>

                  <LinkWrapper href="/campanii" biggerCursor={biggerCursor}>
                    <CustomText
                      type={TextType.H5fix}
                      text={t("components.footer.card3.campaigns")}
                    />
                  </LinkWrapper>

                  <LinkWrapper href="/despre" biggerCursor={biggerCursor}>
                    <CustomText
                      type={TextType.H5fix}
                      text={t("components.footer.card3.about")}
                    />
                  </LinkWrapper>
                  <LinkWrapper href="/harta" biggerCursor={biggerCursor}>
                    <CustomText
                      type={TextType.H5fix}
                      text={t("components.footer.card3.map")}
                    />
                  </LinkWrapper>
                  <LinkWrapper href="/contact" biggerCursor={biggerCursor}>
                    <CustomText
                      type={TextType.H5fix}
                      text={t("components.footer.card3.contact")}
                    />
                  </LinkWrapper>
                  <LinkWrapper
                    href="/cookies"
                    biggerCursor={biggerCursor}
                  >
                    <CustomText
                      type={TextType.H5fix}
                      text={t("components.footer.card3.cookies")}
                    />
                  </LinkWrapper>
                </TextMargin>
              </ThirdCard>
            </Row>
          </Column>

          <Column>
            <LargeCard tabIndex={0}>
              <TitleMedium>{t("components.footer.card4.title")}</TitleMedium>
              <TextMargin>
                <LinkWrapper
                  href={"http://www.dsu.mai.gov.ro"}
                  target="_blank"
                  biggerCursor={biggerCursor}
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.DSU")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://www.gov.ro/"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.GR")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"http://www.aviatie.mai.gov.ro"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.IGAM")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://igi.mai.gov.ro"}
                  target="_blank"
                  biggerCursor={biggerCursor}
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.IGI")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://www.igsu.ro"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.IGSU")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://insp.gov.ro/"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.INSP")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://www.mae.ro/"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.MAE")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://www.mai.gov.ro/"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.MAI")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://www.ms.ro/ro/"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.MS")}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://www.politiaromana.ro"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.POL")}
                  />
                </LinkWrapper>

                <LinkWrapper
                  href={"https://www.presidency.ro/"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.PR")}
                  />
                </LinkWrapper>

                <LinkWrapper
                  href={"http://www.salvamontromania.ro"}
                  biggerCursor={biggerCursor}
                  target="_blank"
                >
                  <CustomText
                    type={TextType.H5fix}
                    text={t("components.footer.card4.SR")}
                  />
                </LinkWrapper>
              </TextMargin>
              <CirclesAtom
                containerBottomPosition="25px"
                containerRightPosition={isMobile ? "25px" : null}
                containerLeftPosition={!isMobile ? "25px" : null}
                sizes={isMobile ? sizesMobile : sizes}
                colors={colors}
                bottom="50%"
                left={isMobile ? "0%" : "50%"}
                top="0%"
                right={isMobile ? "50%" : "0%"}
              />
            </LargeCard>
          </Column>
          {!isTablet && (
            <Column>
              <FourthCard
                biggerCursor={biggerCursor}
                onClick={() => scrollToTop()}
                tabIndex={0}
              >
                <Title>{t("components.footer.card5.back")}</Title>
                <Lottie animationData={animationData} style={lottieStyle} />
              </FourthCard>
              <MiddleCard tabIndex={0}>
                <TitleMedium>{t("components.footer.card6.title")}</TitleMedium>
                <ContactStyled>
                  <StyledLink
                    biggerCursor={biggerCursor}
                    href={`tel:${t("components.footer.card6.phone")}`}
                  >
                    <CustomText
                      type={TextType.H4bold18px}
                      text={t("components.footer.card6.phone")}
                    />
                  </StyledLink>
                  <StyledLink
                    biggerCursor={biggerCursor}
                    href={`tel:${t("components.footer.card6.central")}`}
                  >
                    <CustomText
                      type={TextType.H4bold18px}
                      text={t("components.footer.card6.central")}
                    />
                  </StyledLink>
                  <StyledLink
                    biggerCursor={biggerCursor}
                    href={`mailto:${t("components.footer.card6.email")}`}
                  >
                    <CustomText
                      type={TextType.H4bold18px}
                      text={t("components.footer.card6.email")}
                    />
                  </StyledLink>
                  <StyledLink
                    href={"/termeni-si-conditii"}
                    biggerCursor={biggerCursor}
                  >
                    <CustomText
                      type={TextType.H4bold18px}
                      text={t("components.footer.card6.termsAndConditions")}
                      color={COLORS.Paua}
                    />
                  </StyledLink>
                  <StyledLink
                    href={"/politica-de-confidentialitate"}
                    biggerCursor={biggerCursor}
                  >
                    <CustomText
                      type={TextType.H4bold18px}
                      text={t("components.footer.card6.privacyPolicy")}
                      color={COLORS.Paua}
                    />
                  </StyledLink>
                  <StyledLink
                    href={"/declinare-de-responsabilitate"}
                    biggerCursor={biggerCursor}
                  >
                    <CustomText
                      type={TextType.H4bold18px}
                      text={t("components.footer.card6.disclaimer")}
                      color={COLORS.Paua}
                    />
                  </StyledLink>
                </ContactStyled>
              </MiddleCard>
              <MediumCard tabIndex={0}>
                <TitleMedium isSocialMediaCard={true}>
                  {t("components.footer.card7.title")}
                </TitleMedium>
                <SocialMediaIconsContainer>
                  <LinkWrapper
                    href={"https://www.youtube.com/@DSU9979"}
                    biggerCursor={biggerCursor}
                    target="_blank"
                  >
                    <SocialIcon
                      src={YoutubeIconHovered}
                      alt="YouTube"
                      biggerCursor={biggerCursor}
                    />
                  </LinkWrapper>
                  <LinkWrapper
                    href={
                      "https://www.facebook.com/departamenturgente/?locale=ro_RO"
                    }
                    biggerCursor={biggerCursor}
                    target="_blank"
                  >
                    <SocialIcon
                      src={FacebookIconHovered}
                      alt="Facebook"
                      biggerCursor={biggerCursor}
                    />
                  </LinkWrapper>
                  <LinkWrapper
                    href={
                      "https://instagram.com/dsu_romania?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                    }
                    biggerCursor={biggerCursor}
                    target="_blank"
                  >
                    <SocialIcon
                      src={InstagramIconHovered}
                      alt="instagram"
                      biggerCursor={biggerCursor}
                    />
                  </LinkWrapper>
                  <LinkWrapper
                    href={"https://twitter.com/DSURomania"}
                    biggerCursor={biggerCursor}
                    target="_blank"
                  >
                    <SocialIcon
                      src={TwitterIconHovered}
                      alt="twitter"
                      biggerCursor={biggerCursor}
                    />
                  </LinkWrapper>
                </SocialMediaIconsContainer>
              </MediumCard>
            </Column>
          )}
        </GridContainer>
        {isTablet && (
          <RowTablet>
            <MiddleCard>
              <TitleMedium>{t("components.footer.card6.title")}</TitleMedium>
              <ContactStyled>
                <StyledLink
                  biggerCursor={biggerCursor}
                  href={`tel:${t("components.footer.card6.phone")}`}
                >
                  <CustomText
                    type={TextType.H4bold18px}
                    text={t("components.footer.card6.phone")}
                  />
                </StyledLink>
                <StyledLink
                  biggerCursor={biggerCursor}
                  href={`tel:${t("components.footer.card6.central")}`}
                >
                  <CustomText
                    type={TextType.H4bold18px}
                    text={t("components.footer.card6.central")}
                  />
                </StyledLink>
                <StyledLink
                  biggerCursor={biggerCursor}
                  href={`mailto:${t("components.footer.card6.email")}`}
                >
                  <CustomText
                    type={TextType.H4bold18px}
                    text={t("components.footer.card6.email")}
                  />
                </StyledLink>
                <StyledLink
                  href={"/termeni-si-conditii"}
                  biggerCursor={biggerCursor}
                >
                  <CustomText
                    type={TextType.H4bold18px}
                    text={t("components.footer.card6.termsAndConditions")}
                    color={COLORS.Paua}
                  />
                </StyledLink>
                <StyledLink
                  href={"/politica-de-confidentialitate"}
                  biggerCursor={biggerCursor}
                >
                  <CustomText
                    type={TextType.H4bold18px}
                    text={t("components.footer.card6.privacyPolicy")}
                    color={COLORS.Paua}
                  />
                </StyledLink>
                <StyledLink
                  href={"/declinare-de-responsabilitate"}
                  biggerCursor={biggerCursor}
                >
                  <CustomText
                    type={TextType.H4bold18px}
                    text={t("components.footer.card6.disclaimer")}
                    color={COLORS.Paua}
                  />
                </StyledLink>
              </ContactStyled>
            </MiddleCard>
            <MediumCard>
              <TitleMedium>{t("components.footer.card7.title")}</TitleMedium>
              <SocialMediaIconsContainer>
                <LinkWrapper
                  href={"https://www.youtube.com/@DSU9979"}
                  target="_blank"
                  biggerCursor={biggerCursor}
                >
                  <SocialIcon
                    src={YoutubeIconHovered}
                    alt="YouTube"
                    biggerCursor={biggerCursor}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={
                    "https://www.facebook.com/departamenturgente/?locale=ro_RO"
                  }
                  target="_blank"
                  biggerCursor={biggerCursor}
                >
                  <SocialIcon
                    src={FacebookIconHovered}
                    alt="Facebook"
                    biggerCursor={biggerCursor}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={
                    "https://instagram.com/dsu_romania?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                  }
                  target="_blank"
                  biggerCursor={biggerCursor}
                >
                  <SocialIcon
                    src={InstagramIconHovered}
                    alt="instagram"
                    biggerCursor={biggerCursor}
                  />
                </LinkWrapper>
                <LinkWrapper
                  href={"https://twitter.com/DSURomania"}
                  target="_blank"
                  biggerCursor={biggerCursor}
                >
                  <SocialIcon
                    src={TwitterIconHovered}
                    alt="twitter"
                    biggerCursor={biggerCursor}
                  />
                </LinkWrapper>
              </SocialMediaIconsContainer>
            </MediumCard>
            <FourthCard
              biggerCursor={biggerCursor}
              onClick={() => scrollToTop()}
            >
              <Title>{t("components.footer.card5.back")}</Title>
              <Lottie animationData={animationData} style={lottieStyle} />
            </FourthCard>
          </RowTablet>
        )}
      </BigContainer>
    </>
  );
};

export default Footer;
