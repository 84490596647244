import styled, { css } from "styled-components";

//MISC
import { COLORS, SIZE, BORDER_RADIUS } from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const CardWrapper = styled.div`
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius24};
  max-width: 400px;
  width: 20vw;
  min-width: 300px;
  padding: ${SIZE.fix32};
  max-height: 200px;
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: scale(1, auto);
      z-index: 80;
      position: relative;
      max-height: 100%;
    `}
`;
export const TitleContainer = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  cursor: pointer;
  max-width: 100%;
  ${({ titleExpanded }) =>
    titleExpanded
      ? css`
          -webkit-line-clamp: none;
          overflow: visible;
          text-overflow: clip;
          word-wrap: break-word;
          hyphens: auto;
          overflow-wrap: break-word;
        `
      : css`
          -webkit-line-clamp: 2;
        `}
`;
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${SIZE.max24};
  max-width: 100%;
`;
export const LogoImage = styled.img`
  height: 70px;
  max-height: 70px;
  max-width: 100%;
  width: auto;
  object-fit: contain;
`;
export const CenterContainer = styled.div`
  padding-top: ${SIZE.max16};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  cursor: pointer;
  ${({ contentExpanded }) =>
    contentExpanded
      ? css`
          -webkit-line-clamp: none;
        `
      : css`
          -webkit-line-clamp: 2;
        `}
`;
export const LinkWrapper = styled.a<{ biggerCursor?: boolean }>`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  text-decoration: none;
  color: ${COLORS.BlackRussian};
`;
