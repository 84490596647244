interface ISessionInitialState {
  isLoading: boolean;
  token: string;
  snackBar: {
    open: boolean;
    autoHideDuration: number;
  };
  snackBarAlert: {
    severity: string;
    elevation: number;
    variant: string;
    message: string;
  };
}

export const initialState: ISessionInitialState = {
  isLoading: false,
  token: "",
  snackBar: {
    open: false,
    autoHideDuration: 2000,
  },
  snackBarAlert: {
    severity: "error", // error, warning, info, success
    variant: "filled", // standard, filled, outlined
    elevation: 6,
    message: "",
  },
};
