// LIBRARIES
import styled from "styled-components";

// MISC
import { COLORS, SIZE, DEVICE_SIZE } from "constants/variables";

export const CampaignsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.Solitude};
  padding: ${SIZE.max120} 0px 0px 0px;
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    height: 100vh;
  }
`;

export const CirclesWrapper = styled.div`
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const CampaignsPageTitleWrapper = styled.div`
  padding-inline: ${SIZE.max280};
  z-index: 1;
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    padding-inline: ${SIZE.max32};
  }
`;

export const CampaignsCarouselWrapper = styled.div`
  z-index: 1;
  height: 100%;
  width: 100%;
  padding: ${SIZE.max32} 0;
`;
