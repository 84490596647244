import styled, { css, keyframes } from "styled-components";
import { BORDER_RADIUS, COLORS, SIZE, MARGIN } from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const DropdownButtonWrapper = styled.div`
  padding: 0 ${SIZE.max84};
  margin-bottom: 6em;
  display: flex;
  justify-content: center;
  max-height: ${SIZE.max40};
`;

export const WrapperFilter = styled.div`
  width: fit-content;
  border-radius: ${BORDER_RADIUS.radius21};
  text-align: center;
  background-color: ${COLORS.WhiteSmoke};
  & > button {
    display: block;
    height: auto;
    width: auto;
    margin: 0 auto;
  }
  height: fit-content;
`;

export const TextWrapper = styled.div`
  padding: ${MARGIN.max12};
`;

export const ApplyFiltersButtonWrapper = styled.div`
  max-width: ${SIZE.max200};
  max-height: ${SIZE.max40};

  @media (max-width: 1350px) {
    max-width: ${SIZE.max280};
  }
`;

export const DropdownMenuContainter = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  width: ${SIZE.max400};
  padding: ${SIZE.max24};
  background-color: ${COLORS.WhiteSmoke};
  display: flex;
  flex-direction: column;
  row-gap: ${SIZE.max24};
  align-items: center;
  outline: 8px solid ${COLORS.Paua}4d;
  border-radius: ${BORDER_RADIUS.radius32};
  margin: 0 auto;

  animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${fadeIn} 0.3s ease-out
        `
      : css`
          ${fadeOut} 0.3s ease-out
        `};
  @media (max-width: 1500px) {
    width: ${SIZE.max480};
  }

  @media (max-width: 1300px) {
    width: ${SIZE.max400};
  }
  @media (max-width: 1000px) {
    width: ${SIZE.max400};
  }
  @media (max-width: 768px) {
    width: ${SIZE.max480};
  }
`;

export const DropdownMenuTitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownMenuCloseButton = styled.button<{
  biggerCursor?: boolean;
}>`
  border: none;
  background-color: transparent;
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  display: flex;
  align-items: center;
  padding-right: 4px;
`;

export const DropdownMenuCloseIcon = styled.img`
  height: ${SIZE.max16};
`;

export const DropdownMenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  row-gap: ${SIZE.max24};
`;

export const DropdownMenuItemWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownMenuItemCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownMenuItemCheckboxIcon = styled.div<{
  biggerCursor?: boolean;
}>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 3px solid ${COLORS.BlackRussian};
  &.checked {
    background-color: ${COLORS.Cinnabar};
  }
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
`;

export const MapDropdownWrapper = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 10vh;
`;
