import React from 'react';

// MISC
import { CustomCheckboxProps } from 'view/CookieModule/CookieModuleModel';
import emptyCheckboxIcon from "assets/svg/emptyCheckbox.svg";
import filledCheckboxIcon from "assets/svg/filledCheckbox.svg";
// STYLES
import { CheckboxContainer, DropdownMenuItemCheckboxIcon, DropdownMenuItemCheckboxWrapper, HiddenCheckbox, Icon, StyledCheckbox } from 'view/CookieModule/CookieModuleStyled';


const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange, isDisabled = false }) => (

  <CheckboxContainer>
  <HiddenCheckbox
    checked={checked}
    onChange={isDisabled ? undefined : onChange}
    disabled={isDisabled}
  />
  <StyledCheckbox checked={checked} isDisabled={true}>
    <DropdownMenuItemCheckboxIcon
      src={checked ? filledCheckboxIcon : emptyCheckboxIcon}
      isDisabled={isDisabled}
    // biggerCursor={biggerCursor}
    />

  </StyledCheckbox>
</CheckboxContainer>
);

export default CustomCheckbox;
