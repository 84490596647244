// ASSETS

// STYLES
import {
  PeopleCardContainer,
  PeopleCardImage,
  PeopleCardTextContainer,
  TextWrapper,
} from "./PeopleCardStyled";

// LIBRARIES

// MISC
import { IProps } from "./PeopleCardModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { BORDER_RADIUS, COLORS } from "constants/variables";

// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import Skeleton from "react-loading-skeleton";

const PeopleCard = (props: IProps) => {
  // PROPERTIES
  const { image, fullName, occupation } = props;

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  return (
    <PeopleCardContainer>
      {image !== undefined && fullName !== undefined ? (
        <>
          <PeopleCardImage src={image} />
          <PeopleCardTextContainer>
            <TextWrapper>
              <CustomText text={fullName} type={TextType.H3} color="191919" />
            </TextWrapper>
            <TextWrapper>
              <CustomText
                text={occupation}
                type={TextType.H4}
                color={COLORS.Paua}
              />
            </TextWrapper>
          </PeopleCardTextContainer>
        </>
      ) : (
        <Skeleton
          height={"100%"}
          width={"100%"}
          baseColor={COLORS.WhiteSmoke}
          highlightColor={COLORS.Solitude}
          borderRadius={BORDER_RADIUS.radius24}
        />
      )}
    </PeopleCardContainer>
  );
};

export default PeopleCard;
