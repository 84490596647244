import React, { useEffect } from "react";

// LIBRARIES
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// MISC

// COMPONENTS
import HomePage from "view/Home/HomePage";
import AboutPage from "view/About/About";
import Guides from "view/Guides/Guides";
import SingleGuide from "view/Guides/SingleGuide/SingleGuide";
import SingleCampaign from "view/Campaigns/SingleCampaign/SingleCampaign";
import GetReady from "view/GetReady/GetReady";
import Campaigns from "view/Campaigns/Campaigns";
import Map from "view/Map/Map";
import Contact from "view/Contact/Contact";
import ChatBot from "view/ChatBot/ChatBot";
import LayoutWrapper from "components/LayoutWrapper/LayoutWrapper";
import SingleGetReady from "view/GetReady/SingleGetReady/SingleGetReady";
import SinglePrepareYourself from "view/GetReady/SinglePrepareYourself/SinglePrepareYourself";
import TermsAndConditions from "view/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "view/PrivacyPolicy/PrivacyPolicy";
import Disclaimer from "view/Disclaimer/Disclaimer";

//REDUX
import { setLanguage } from "slices/LanguageSlice/languageSlice";
import CookieModule from "view/CookieModule/CookieModule";

const AppRoutes = () => {
  // LIBRARY CONSTANTS

  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  // LIFE CYCLE

  useEffect(() => {
    if (location.pathname === "/en") {
      const language = "en";
      i18n.changeLanguage(language);
      dispatch(setLanguage(language));
      navigate("/", { replace: true });
    }
    //eslint-disable-next-line
  }, [location, dispatch]);

  return (
    <Routes>
      <Route path="/" element={<LayoutWrapper hasHeader={true} />}>
        <Route index element={<HomePage />} />
        <Route path="despre" element={<AboutPage />} />
        <Route path="ghiduri" element={<Guides />} />
        <Route path="ghiduri/:rgxId" element={<SingleGuide />} />
        <Route path="pregateste-te" element={<GetReady />} />
        <Route path="pregateste-te/:rgxId" element={<SingleGetReady />} />
        <Route
          path="pregateste-ti-apropiatii/:rgxId"
          element={<SinglePrepareYourself />}
        />
        <Route path="campanii" element={<Campaigns />} />
        <Route path="campanii/:rgxId" element={<SingleCampaign />} />
        <Route path="harta" element={<Map />} />
        <Route path="harta/:rgxId" element={<Map />} />
        <Route path="contact" element={<Contact />} />
        <Route path="termeni-si-conditii" element={<TermsAndConditions />} />
        <Route
          path="politica-de-confidentialitate"
          element={<PrivacyPolicy />}
        />
        <Route path="declinare-de-responsabilitate" element={<Disclaimer />} />
        <Route path="cookies" element={<CookieModule />} />
      </Route>
      <Route path="/fiipregatit-chat-bot" element={<ChatBot />} />
    </Routes>
  );
};

export default AppRoutes;
