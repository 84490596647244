// LIBRARIES
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

// ASSETS
import XSign from "assets/svg/XSign.svg";
import greyBellIcon from "assets/svg/GreyBellIcon.svg";
// STYLES
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  EmptyAlerts,
  CloseButtonIcon,
  EmptyAlertsIcon,
} from "./NotificationModalStyled";

// MISC
import { NotificationModalProps } from "./NotificationModalModel";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { COLORS } from "constants/variables";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";

const NotificationModal: React.FC<NotificationModalProps> = (props) => {
  const { isOpen, onClose } = props;
  // PROPERTIES

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  useEffect(() => {
    const handleClose = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };
    if (isOpen) {
      setTimeout(() => {
        document.addEventListener("click", handleClose);
      }, 200);
    } else {
      setTimeout(() => {
        document.removeEventListener("click", handleClose);
      }, 200);
    }
    return () => {
      setTimeout(() => {
        document.removeEventListener("click", handleClose);
      }, 200);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()} ref={modalRef}>
        <ModalHeader>
          <ModalTitle>
            <CustomText
              text={t("components.header.alerts")}
              type={TextType.H4fix}
            />
          </ModalTitle>
          <CloseButton onClick={onClose} biggerCursor={biggerCursor}>
            <CloseButtonIcon src={XSign} alt="Close Button" />
          </CloseButton>
        </ModalHeader>
        <EmptyAlerts>
          <EmptyAlertsIcon src={greyBellIcon} alt="bell" />
          <CustomText
            type={TextType.H5}
            text={t("components.header.errorAlerts")}
            color={COLORS.Paua}
          />
        </EmptyAlerts>
      </ModalContent>
    </ModalOverlay>
  );
};

export default NotificationModal;
