// GuideCardAtomModel.tsx
export enum ICampaigneCardType {
  ORIZONTAL,
  VERTICAL,
}
export interface ICampaignCardProps {
  id?: number;
  date: string;
  title: string;
  text: string;
  imageUrl?: string;
  onCardClick?: () => void;
  type?: ICampaigneCardType;
}
