// STYLES
import { CookiesPageContainer, TitleContainer } from "./CookieModuleStyled";

// LIBRARIES

// MISC
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { cookieInformation, initialAnalyticsCookies, initialNecessaryCookies, translations } from "./config/cookieModuleConfig";
import { COLORS } from "./config/variables";
import useCookieConsent from "./hooks/useCookieConsent";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import { useEffect, useState } from "react";
import CookiesCard from "view/CookieModule/components/CookiesCard/CookiesCard";
import CookiesPreferencesModal from "./components/CookiePreferencesModal/CookiesPreferencesModal";
import { getCookie, isTruthy } from "./helpers/cookieHelper";
import { selectLanguage } from "slices/LanguageSlice/languageSlice";
import { useSelector } from "react-redux";

// CONFIGURATION

const CookieModule = () => {
  // PROPERTIES
  const {
    showModal,
    necessaryCookies,
    analyticsCookies,
    setNecessaryCookies,
    setAnalyticsCookies,
    handleSaveSettings,
    handleManageSettings,
    setShowModal,
  } = useCookieConsent(initialNecessaryCookies, initialAnalyticsCookies);

  // API REQUESTS

  // LIBRARY CONSTANTS
  const currentLanguage = useSelector(selectLanguage);
  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  // const [tempNecessaryCookies, setTempNecessaryCookies] = useState(initialNecessaryCookies);
  // const [tempAnalyticsCookies, setTempAnalyticsCookies] = useState(initialAnalyticsCookies);



  // useEffect(() => {
  //   const initializeCookies = () => {
  //     const updatedNecessaryCookies = necessaryCookies.map(cookie => ({
  //       ...cookie,
  //       checked: true,
  //     }));
  //     const updatedAnalyticsCookies = analyticsCookies.map(cookie => ({
  //       ...cookie,
  //       checked: isTruthy(getCookie(cookie.title)) && isTruthy(`${getCookie(cookie.title)}_2FGL3Y9F1R`),
  //     }));
  //     console.log("getCookie(_ga)", getCookie("_ga"))
  //     setTempNecessaryCookies(updatedNecessaryCookies);
  //     setTempAnalyticsCookies(updatedAnalyticsCookies);
  //   };

  //   initializeCookies();
  // }, [necessaryCookies, analyticsCookies]);
  const t = translations[currentLanguage];
  return (
    <CookiesPageContainer>
      <TitleContainer>
        <CustomText type={TextType.H1} text={t.cookieModuleTitle} color={COLORS.RaisinBlack} />
      </TitleContainer>
      {t.cookieInformation.map((info, index) => (
        <CookiesCard key={index} title={info.title} texts={info.texts} list={info.list} hasButton={info.hasButton} onClick={handleManageSettings} />
      ))}
      <CookiesPreferencesModal
        showModal={showModal}
        setShowModal={setShowModal}
        necessaryCookies={necessaryCookies}
        analyticsCookies={analyticsCookies}
        setNecessaryCookies={setNecessaryCookies}
        setAnalyticsCookies={setAnalyticsCookies}
        handleSaveSettings={handleSaveSettings}
        language={currentLanguage}
      />
    </CookiesPageContainer>
  );
};

export default CookieModule;
