import React, {useEffect, useState} from "react";

// ASSETS
import RedLoop from "assets/svg/SearchIconRed.svg";
import CloseButtonIcon from "assets/svg/XSignGrey.svg";

// STYLES
import {
  ModalOverlay,
  ModalContainer,
  ImgStyled,
  CloseButton,
  InputContainer,
} from "./SearchBarStyled";

// LIBRARIES
import { useTranslation } from "react-i18next";

// MISC
import { SearchBarProps } from "./SearchBarModel";
import { ButtonType } from "components/Atoms/ButtonAtom/ButtonAtomModel";
import { OptionType } from "components/Atoms/InputAtom/InputAtomModel";
// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";

// COMPONENTS
import InputAtom from "components/Atoms/InputAtom/InputAtom";
import ButtonAtom from "components/Atoms/ButtonAtom/ButtonAtom";
import partsService from "../../../../services/partsService";

const SearchBar = (props: SearchBarProps) => {
  // PROPERTIES
  const { isOpen, onClose } = props;

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS
  const { t } = useTranslation();
  const [inputText, setInputText] = useState("");
  const [options, setOptions] = useState<OptionType[]>([]);
  // API REQUESTS

  // LIFE CYCLE

    useEffect(() => {
        if(isOpen && document.getElementById('searchBar')) {
            document.getElementById('searchBar').focus();
        } else {
            setOptions([])
        }

    }, [isOpen]);
  // EVENT HANDLERS
  const closeModal = () => {
    onClose();
  };

  if (!isOpen) return null;
  return (
    <ModalOverlay onClick={onClose}>
      <CloseButton onClick={onClose} biggerCursor={biggerCursor} tabIndex={2}>
        <ImgStyled src={CloseButtonIcon} alt="CloseButtonIcon" />
      </CloseButton>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <InputContainer>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              if (inputText !== "") {
                (async () => {
                  const response: any = await partsService.search(inputText);

                  if (response && response.length > 0) {
                    setOptions(
                      response.map((el: any) => ({
                        option: el.value,
                        id: el.href,
                        path: `/ghiduri/${el.href}`,
                      }))
                    );
                  } else {
                    setOptions([{ option: "0 rezultate", id: 0, path: `/` }]);
                  }
                })();
              }
            }}
          >
            <InputAtom
              startAdornment={<ImgStyled src={RedLoop} alt="RedLoopIcon" />}
              id={"searchBar"}
              endAdornment={
                <ButtonAtom
                  text={t("components.dashboard.search")}
                  type={ButtonType.ArrowButton}
                />
              }
              placeholder={t("components.dashboard.searchGuides")}
              isSearchBar={true}
              options={options}
              onChange={(e) => setInputText(e.target.value)}
              closeModal={closeModal}
              isModal={true}
            />
          </form>
        </InputContainer>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default SearchBar;
