// ASSETS
import { ReactComponent as QuarterCircle } from "assets/svg/QuarterCircle.svg";

// STYLES
import {
  CampaignsContainer,
  CampaignsPageTitleWrapper,
  CirclesWrapper,
  CampaignsCarouselWrapper,
} from "./CampaignsStyled";

// LIBRARIES

import { useTranslation } from "react-i18next";

// MISC
import { COLORS } from "constants/variables";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { baseUrl } from "../../services/base";

// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import { useEffect, useState } from "react";
import campaignService from "../../services/campaignService";
import { useNavigate } from "react-router-dom";
import { ICampaignCarouselType } from "components/CampaigneCarousel/CampaignCarouselModel";
import CampaignCarousel from "components/CampaigneCarousel/CampaignCarousel";
import SectionWrapper from "components/SectionWrapper/SectionWrapper";

const Campaings = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const navigate = useNavigate();

  // STATE CONSTANTS
  const [campaigns, setCampaigns] = useState<any[]>([]);
  // API REQUESTS

  // LIFE CYCLE

  useEffect(() => {
    (async () => {
      const campaigns: any = await campaignService.getCampaigns();
      if (campaigns.data.length > 0) {
        const tmpCampaigns = campaigns.data.map((campaign: any) => {
          // const tmp = campaign.attributes.content.find((el: any )=> el.type === 'paragraphx')
          // let text =  ""
          // if(tmp !== undefined) {
          //     text = tmp.children.map((el: any) => el.text).join(" ")
          // }
          return {
            id: campaign.id,
            date: campaign.attributes.start_date.split("-").reverse().join("."),
            title: campaign.attributes.title,
            text: "",
            imageUrl: `${baseUrl}${campaign.attributes.cover.data.attributes.url}`,
            onCardClick: () => navigate(`/campanii/${campaign.id}`),
          };
        });
        setCampaigns(tmpCampaigns);
      }
    })();
    // eslint-disable-next-line
  }, []);
  // EVENT HANDLERS

  return (
    <SectionWrapper hasPadding={false}>
      <CirclesWrapper>
        <QuarterCircle />
      </CirclesWrapper>
      <CampaignsContainer>
        <CampaignsPageTitleWrapper>
          <CustomText
            text={t("view.campaigns.section1.title")}
            type={TextType.H1}
            color={COLORS.BlackRussian}
          />
        </CampaignsPageTitleWrapper>
        <CampaignsCarouselWrapper>
          <CampaignCarousel
            type={ICampaignCarouselType.CAMPAIGN}
            data={campaigns}
          />
        </CampaignsCarouselWrapper>
      </CampaignsContainer>
    </SectionWrapper>
  );
};

export default Campaings;
