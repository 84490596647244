import { createSelector } from "reselect";
import { sessionSelector } from "slices/SessionSlice/sessionSlice";

export const tokenSelector = createSelector(
  sessionSelector,
  (session) => session.token
);
export const snackBar = createSelector(
  sessionSelector,
  (session) => session.snackBar
);
export const snackBarAlert = createSelector(
  sessionSelector,
  (session) => session.snackBarAlert
);
export const isRequestLoadingSelector = createSelector(
  sessionSelector,
  (session) => session.isLoading
);
