export default function modRewrite(utf8String,id = null) {
    const romanianDiacritics = ['a','t','a','a','i','s','t','A','A','I','S','T'];
    ['ă','ţ','ă', 'â', 'î', 'ș', 'ț', 'Ă', 'Â', 'Î', 'Ș', 'Ț']?.forEach((el: string,index)=>{
      utf8String = utf8String?.replace(el,romanianDiacritics[index]);
    })
    if(id !== null) {
        return utf8String?.toLowerCase()?.split(" ")?.join("-") + `-${id}`;
    } else {
        return utf8String?.toLowerCase()?.split(" ")?.join("-")
    }
  }

  
