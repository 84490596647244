// DownloadAppComponentStyled.tsx

import styled from "styled-components";
//MISC
import {
  COLORS,
  SIZE,
  BORDER_RADIUS,
  DEVICE_SIZE,
} from "../../../constants/variables";

export const ComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SIZE.max65} 0;
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    padding: ${SIZE.max84} 0;
  }
`;

export const CardGrid = styled.div`
  position: relative;
  width: 80%;
  max-width: 960px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    width: 75%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    padding: ${SIZE.max84} 0;
  }
`;
export const CardWrapper = styled.div`
  background-color: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius40};
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 5;
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 5;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 5;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 4;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 4;
  }
  z-index: 1;
`;

export const InfoContainer = styled.div`
  padding: ${SIZE.max84} ${SIZE.max84} ${SIZE.max84} ${SIZE.max32};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${SIZE.max16};
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    padding: ${SIZE.fix42} ${SIZE.max84} ${SIZE.fix32} 0;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    padding: ${SIZE.fix42} ${SIZE.max55} ${SIZE.fix32} ${SIZE.max55};
    margin-top: 10%;
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 4;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    padding: ${SIZE.max65} ${SIZE.fix32} ${SIZE.fix32} ${SIZE.fix32};
    margin-top: 13%;
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 4;
  }
`;
export const DescriptionWrapper = styled.div`
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    margin-top: 3%;
    padding: 0 7px;
  }
`;
export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    display: none;
  }
`;

export const ImageContainer = styled.img`
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 610px;
  width: auto;
  object-fit: contain;
  z-index: 5;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 6;
  align-self: stretch;
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    width: 100%;
    height: auto;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    width: 100%;
    grid-column-start: 1;
    grid-row-start: 1;
    align-self: stretch;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    width: 80%;
    justify-self: center;
    height: auto;
    margin-bottom: -5%;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    width: 105%;
    justify-self: center;
    margin-left: -5%;
    margin-bottom: 5%;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }
`;
export const LinkText = styled.div`
  width: 50%;
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    width: 60%;
  }
`;
export const QrContainer = styled.img`
  width: ${SIZE.max160};
  height: ${SIZE.max160};
`;
export const ButtonWrapper = styled.div`
  margin: ${SIZE.max16} 0;
  display: none;
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    display: block;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    margin: 5px 0;
  }
`;
export const Image1 = styled.img`
  width: 300px;
  height: 300px;
  position: absolute;
  top: -10%;
  left: -14%;
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    top: -16%;
    left: -16%;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    top: -10%;
    left: unset;
    right: -15%;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    top: 15%;
    left: unset;
    right: -25%;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    top: 5%;
    left: unset;
    right: -35%;
  }
`;
export const Image2 = styled.img`
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: -10%;
  right: -14%;
  @media (max-width: ${DEVICE_SIZE.DESKTOP}) {
    bottom: -16%;
    right: -16%;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MED}) {
    right: unset;
    left: -15%;
    bottom: -10%;
  }
  @media (max-width: ${DEVICE_SIZE.TABLET_MIN}) {
    right: unset;
    left: -25%;
    bottom: -15%;
  }
  @media (max-width: ${DEVICE_SIZE.MOBILE}) {
    left: -40%;
    bottom: -15%;
  }
`;
