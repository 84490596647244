import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import animationData from './animations/ChatTyping.json';



const ChatTyping = (props: any) => {
    const { width, height } = props;
    const defaultOptions = {
        loop: true,
        autoplay: true, // Set to true if you want the animation to play automatically
        animationData: animationData, // Lottie animation JSON
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {

    }, []);


    return (
        <Lottie
            options={defaultOptions}
            height={height}
            width={width}
            style={{ float: 'left', margin: "1em 0 5em 0" }}

        />


    );
};

export default ChatTyping;
