interface ITemplateInitialState {
  accessibilityBiggerText: boolean;
  accessibilityBiggerCursor: boolean;
  accessibilityBiggerLineHeight: boolean;
  accessibilityBiggerLetterSpacing: boolean;
  accessibilityBlackAndWhite: boolean;
  accessibilityInvertColors: boolean;
}

export const initialState: ITemplateInitialState = {
  accessibilityBiggerText: false,
  accessibilityBiggerCursor: false,
  accessibilityBiggerLineHeight: false,
  accessibilityBiggerLetterSpacing: false,
  accessibilityBlackAndWhite: false,
  accessibilityInvertColors: false,
};
