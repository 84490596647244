//LIBRARY
import styled from "styled-components";
//MISC
import {
  COLORS,
  BORDER_RADIUS,
  SIZE,
  MARGIN,
  DEVICE_TYPE,
} from "constants/variables";
import cursorIcon from "assets/img/Cursor.png";

export const BigContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${SIZE.max24};
  @media (max-width: ${DEVICE_TYPE.tablet}) and (min-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-top: ${MARGIN.max160};
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-top: ${MARGIN.max120};
  }
`;

export const ChatBoxPromo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  & > div:first-of-type{
    padding: 1em 0;
    text-align: center ;
    & > span {
        letter-spacing: .05em;
    }
  }
   & > div:last-of-type{
    
    & > span {
        letter-spacing: .1em;
    }
  }
  
    
  
    
  
  & > img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid ${COLORS.WhiteSmoke} 1em;
  }
`;
export const GridContainer = styled.div`
  display: grid;
  gap: ${SIZE.max24};
  grid-template-columns: repeat(2, 1fr) auto;
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const BackToHomeButton = styled.button`
  background-color: transparent;
  border: none;
  margin-bottom: ${MARGIN.max6};
  cursor: pointer;
`;

export const BackToHomeWrapper = styled.div`
  
  cursor: pointer;
`;

export const BackToHomeImage = styled.img`
  margin-right: 8px;
  width: 16px;
  height: 10px;
  transform: rotate(90deg);
`;
export const ChatBox = styled.div`
  display: flex;
  flex-grow: 10;
  flex-direction: column;
  width: 100%;
  
  & a {
    text-decoration: none;
    color: ${COLORS.Cinnabar};
    &:hover {
      color: ${COLORS.MandarianOrange};
    }
  }
  
  
`;
export const ChatBoxRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${MARGIN.max24};
  &:last-of-type{
    margin-bottom: ${MARGIN.max56};
  }
`;
export const ChatIcon = styled.div`
  width: ${SIZE.max32};

  & > img {
    width: ${SIZE.max32};
    height: ${SIZE.max32};
    margin: 0;
  }
`
export const ChatText = styled.div`
   flex-grow: 10;
   width: 100%;
    
   padding-left: ${ SIZE.max16};
   > * {
   
   }
`
export const Column = styled.div`
  
  width: 100%;
  background: ${COLORS.Solitude};
  
  padding: ${SIZE.max16} ${SIZE.max24};
  &:first-child {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    
  }
  &:nth-of-type(2) {
    display: flex;
    position: relative;
    z-index: 0;
    max-width: ${SIZE.max1200};
    margin: 4em auto;
    
    flex-direction: column-reverse;
    
  }
  &:last-child {
    text-align: center;
    position: fixed;
    bottom: 0;
    padding: 1rem 0 0 0;
    height: auto;
    
  }
  

  gap: ${SIZE.max24};
  
  
`;

export const ThirdColumnCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 218px;
  height: 100%;
  min-height: 128px;
  width: 22.396vw;
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  padding: ${MARGIN.max40};
  @media (max-width: ${DEVICE_TYPE.tablet}) {
    width: 100%;
  }
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    width: 100%;
  }
`;

export const ChatBotCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background: ${COLORS.WhiteSmoke};
  border-radius: ${BORDER_RADIUS.radius32};
  padding: ${SIZE.max40};
  width: 100%;
  height: 100%;
  background: ${COLORS.WhiteSmoke};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    height: auto;
  }
`;

export const RowTablet = styled.div`
  display: flex;
  align-items: stretch;
  gap: ${SIZE.max24};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.form`
  
  height: auto;
  max-width: ${SIZE.max1200};
  padding: 0 .5em .5em 0;
  margin: 0 auto;
  background: ${COLORS.Solitude};
  display: flex;
  
  justify-content: center;
  
`;

export const MoreLink = styled.a`
    color: ${COLORS.Cinnabar}
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 42px;
  margin-top: auto;
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-top: 40px;
  }
`;
export const Icon = styled.img`
  display: block;
  width: 36px;
  height: auto;
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: ${MARGIN.max40};
  right: ${MARGIN.max40};
`;

export const TitleWrapper = styled.div`
  
`;

export const FormularTextWrapper = styled.div`
  margin-bottom: ${MARGIN.max24};
  @media (max-width: ${DEVICE_TYPE.mobileHeader}) {
    margin-bottom: ${MARGIN.max16};
  }
`;

export const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-bottom: ${SIZE.max84};
`;

export const MainWrapper = styled.div`
  background: ${COLORS.Solitude};
  
  height: auto;
  width: 100vw;
`;

export const StyledLink = styled.a`
  cursor: ${({ biggerCursor }) =>
    biggerCursor ? `url(${cursorIcon}), auto` : `pointer`};
  text-decoration: none;
  color: inherit;
`;
