import React from "react";

// ASSETS

// STYLES
import {
  InputWrapper,
  InputLabel,
  InputField,
  EndAdornment,
  StartAdornment,

  Content,
} from "./InputAtomStyled";

// LIBRARIES

// MISC
import { InputAtomProps } from "./InputAtomModel";

// REDUX
import { useSelector } from "react-redux";
import { accessibilityBiggerCursorSelector } from "slices/UISlice/uiSliceSelectors";
import {
  accessibilityBiggerLineHeightSelector,
  accessibilityBiggerTextSelector,
  accessibilityBiggerLetterSpacingSelector,
} from "slices/UISlice/uiSliceSelectors";
// COMPONENTS

const InputAtom = (props: InputAtomProps) => {
  const {
    startAdornment,
    endAdornment,
    label,
    showLabel = false,
    isMultiLine = false,
    placeholder,
    isSearchBar = false,
    error = false,
    value = "",
    onChange,
    onBlur,
    onKeyPress
  } = props;
  const [rows, setRows] = React.useState<number>(1);
  // PROPERTIES

  // LIBRARY CONSTANTS
  const biggerCursor = useSelector(accessibilityBiggerCursorSelector);

  // STATE CONSTANTS
  const accessibilityBiggerText: boolean = useSelector(
    accessibilityBiggerTextSelector
  );
  const accessibilityBiggerLineHeight: boolean = useSelector(
    accessibilityBiggerLineHeightSelector
  );
  const accessibilityBiggerLetterSpacing: boolean = useSelector(
    accessibilityBiggerLetterSpacingSelector
  );
  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const renderInput = (rows= 5) => {
    if (isMultiLine) {
      return (
        <InputField
          as="textarea"
          rows={rows}
          placeholder={placeholder}
          isMultiLine={true}
          biggerCursor={biggerCursor}
          error={error}
          value={value}
          style={{overflowY: "auto"}}
          onChange={(event)=>{
            onChange(event)
            const splitter = event.target.value.split("\n")
            const height = splitter.reduce((a, b) => a + (1 + (b.length > 64 ? b.length / 64 : 0)),0);
            setRows(height > 5 ? 5 :height);

          }}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      );
    }
    return (
      <InputField
        type="text"
        placeholder={placeholder}
        biggerCursor={biggerCursor}
        accessibilityBiggerText={accessibilityBiggerText}
        accessibilityBiggerLineHeight={accessibilityBiggerLineHeight}
        accessibilityBiggerLetterSpacing={accessibilityBiggerLetterSpacing}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };
  return (
    <Content>

      <InputWrapper
        isMultiLine={isMultiLine}
        isSearchBar={isSearchBar}
        hasOptions={false}
      >
        {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
        {renderInput(rows)}
        {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
      </InputWrapper>
     {showLabel && label && (
        <InputLabel showLabel={showLabel} biggerCursor={biggerCursor}>
          {label}
        </InputLabel>
      )}

    </Content>
  );
};

export default InputAtom;
