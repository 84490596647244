import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

// LIBRARIES
import styled from "styled-components";

// MISC

export const GuidesPageContainer = styled.div`
  width: 100%;
  height: 100dvh;
  background-color: ${COLORS.Solitude};
  padding-block: ${SIZE.max160} 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  @media (max-width: 768px) {
    padding-top: ${SIZE.max180};
  }
`;

export const GuidesPageWrapper = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  row-gap: ${SIZE.max24};
`;

export const GuidesPageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${SIZE.max24};
  z-index: 2;
`;

export const DropdownWrapper = styled.div`
  z-index: 2;
`;

export const GuidesPageNoGuidesTextWrapper = styled.div``;

export const GuidesPageContentWrapper = styled.div`
  padding: 4px;
  padding-bottom: ${SIZE.max24};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const GuidesCardWrapper = styled.div`
  max-width: 320px;
  border-radius: ${BORDER_RADIUS.radius32};
  width: 16.6vw;
  aspect-ratio: 1/1;

  @media (max-width: 1300px) {
    width: 20vw;
  }

  @media (max-width: 1000px) {
    width: 25vw;
  }
  @media (max-width: 768px) {
    width: 25vw;
  }
  @media (max-width: 600px) {
    width: 40.25vw;
  }
`;

export const CirclesWrapper = styled.div`
  z-index: 0;
`;
