
// ASSETS

// STYLES
import { StrapiTextLabel } from "./StrapiTextStyled";

// LIBRARIES

// MISC
import { IProps, TextKerning, TextSize, TextSpacing, TextType } from "./StrapiTextModel";

// REDUX
import { useSelector } from "react-redux";
import {
  accessibilityBiggerLetterSpacingSelector,
  accessibilityBiggerLineHeightSelector,
  accessibilityBiggerTextSelector,
} from "slices/UISlice/uiSliceSelectors";

// COMPONENTS

// CONFIGURATION
const StrapiText = (props: IProps) => {
  // PROPERTIES
  const {
    type,
    children,
    size = TextSize.default,
    color,
    rowSpacing = TextSpacing.default,
    kerning = TextKerning.default,
    // eslint-disable-next-line
    withContainer = true
  } = props;

  const getHtmlTag = (type) => {

    if (typeof type === "string") {
      if (type.startsWith("H")) {
        return `h${type.substring(1)}`;
      } else if (type.startsWith("P")) {
        return "p";
      }
    }
    if (typeof type === "number" && type === TextType.UL) {
      return "ul";
    } else if (type === TextType.OL) {
      return "ol";
    } else if (type === TextType.LI) {
      return "li";
    }
    return "span";
  };

  const Tag = getHtmlTag(type);

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const accessibilityBiggerText: boolean = useSelector(
    accessibilityBiggerTextSelector
  );
  const accessibilityBiggerLineHeight: boolean = useSelector(
    accessibilityBiggerLineHeightSelector
  );
  const accessibilityBiggerLetterSpacing: boolean = useSelector(
    accessibilityBiggerLetterSpacingSelector
  );

  // LIFE CYCLE

  // EVENT HANDLERS

  return (


    <StrapiTextLabel
      as={Tag}
      type={type}
      size={size}
      rowSpacing={rowSpacing}
      kerning={kerning}
      color={color}
      accessibilityBiggerText={accessibilityBiggerText}
      accessibilityBiggerLineHeight={accessibilityBiggerLineHeight}
      accessibilityBiggerLetterSpacing={accessibilityBiggerLetterSpacing}
    >
      {children}
    </StrapiTextLabel>


  );
};
export default StrapiText;
