import { AxiosInstance } from 'axios';
import axiosBase from './base';
import { store } from 'store/store';

class GetTerms {
    base: AxiosInstance;

    constructor() {
        this.base = axiosBase.base;
    }

    getTermsAndConditions(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/fp-term?locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getPrivacyPolicy(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/fp-privacy?locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }

    getDisclaimer(): Promise<any[]> {
        const state = store.getState();
        const language = state.language.language;

        return new Promise(async (resolve, reject) => {
            this.base
                .get(`/api/fp-disclaimer?locale=${language}`)
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => reject(err));
        });
    }
}

const getTermsService = new GetTerms();
export default getTermsService;
