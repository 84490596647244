import React, { useEffect, useState } from 'react';

// STYLES
import { Buttons, ModalWrapper } from 'view/CookieModule/CookieModuleStyled';

// MISC
import { ButtonType } from 'components/Atoms/ButtonAtom/ButtonAtomModel';
import { TextType } from 'components/Atoms/CustomText/CustomTextModel';
import { CookieConsentProps } from 'view/CookieModule/CookieModuleModel';
import { COLORS } from "../../config/variables";
import useCookieConsent from '../../hooks/useCookieConsent';

// COMPONENTS
import ButtonAtom from 'components/Atoms/ButtonAtom/ButtonAtom';
import CustomText from 'components/Atoms/CustomText/CustomText';
import CookiesPreferencesModal from '../CookiePreferencesModal/CookiesPreferencesModal';
import { getCookie } from 'view/CookieModule/helpers/cookieHelper';
import { translations } from 'view/CookieModule/config/cookieModuleConfig';

const CookieConsent: React.FC<CookieConsentProps> = ({
  language,
  necessaryCookies: initialNecessaryCookies,
  analyticsCookies: initialAnalyticsCookies,
}) => {

  const {
    isVisible,
    showModal,
    necessaryCookies,
    analyticsCookies,
    setNecessaryCookies,
    setAnalyticsCookies,
    handleAccept,
    handleSaveSettings,
    handleManageSettings,
    setShowModal,
  } = useCookieConsent(initialNecessaryCookies, initialAnalyticsCookies);

  const t = translations[language];

  const handleClosePreferencesModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {isVisible && !showModal && (
        <ModalWrapper>
          <CustomText
            type={TextType.H3}
            text={t.consentTitle}
            color={COLORS.RaisinBlack}
          />
          <CustomText
            type={TextType.P2}
            text={t.consentText}
            color={COLORS.RaisinBlack}
          />
          <Buttons>
            <ButtonAtom
              type={ButtonType.RedButton}
              text={t.acceptButtonText}
              onClick={handleAccept}
            />
            <ButtonAtom
              type={ButtonType.ManagementButton}
              text={t.manageSettingsText}
              onClick={handleManageSettings}
            />
          </Buttons>
        </ModalWrapper>
      )}
      <CookiesPreferencesModal
        showModal={showModal}
        setShowModal={handleClosePreferencesModal}
        necessaryCookies={necessaryCookies}
        analyticsCookies={analyticsCookies}
        setNecessaryCookies={setNecessaryCookies}
        setAnalyticsCookies={setAnalyticsCookies}
        handleSaveSettings={handleSaveSettings}
        language={language}
      />
    </>
  );
};

export default CookieConsent;
