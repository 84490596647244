import React, { useEffect, useState } from "react";

// ASSETS
import HeroIconDesktop from "assets/svg/AboutPageHeroDesktop.svg";
import HeroIconMobile from "assets/svg/AboutPageHeroMobile.svg";
import logoDsu from "assets/svg/LogoDsu.svg";
import LogoNovaMatter from "assets/svg/LogoNovaMatter.svg";
import image from "assets/img/ImageExample.png";

// STYLES
import {
  AboutContainer,
  AboutPageHeroComponent,
  AboutPageHeroIcon,
  AboutPageHeroIconWrapper,
  AboutPageHeroTextContainer,
  AboutPageHeroContainer,
  CirclesWrapper,
  AboutPageHeroTitleWrapper,
  AboutPageHeroTextContainerWrapper,
  AboutPageHeroTextWrapper,
  AboutPagePreventionContainer,
  AboutPagePreventionCardsContainer,
  TitleWrapper,
  SubtitleWrapper,
  AboutPageSupportContainer,
  AboutPageSupportersWrapper,
  AboutPageSupportersLogosWrapper,
  SupportersLogoContainer,
  SupportersLogo,
  PartnersSectionsWrapper,
  PartnersCardsContainer,
  PartnersCardsContainerWrapper,
  DeveloperLogoContainer,
  DeveloperLogo,
} from "./AboutStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

// MISC
import { COLORS } from "constants/variables";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { partnersData } from "constants/mocks/PartnersData";
import { baseUrl } from "../../services/base";
import partsService from "../../services/partsService";

// REDUX

import { useSelector } from "react-redux";
import { currentLanguageSelector } from "slices/LanguageSlice/languageSliceSelectors";

// COMPONENTS
import CirclesAtom from "components/Atoms/CirclesAtom/CirclesAtom";
import CustomText from "components/Atoms/CustomText/CustomText";
import CardCarousel from "components/CardCarousel/CardCarousel";
import PartnerCard from "components/Atoms/PartnerCard/PartnerCard";

const About = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const currentLanguage = useSelector(currentLanguageSelector);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const heroIcon = isMobile ? HeroIconMobile : HeroIconDesktop;
  const colors = {
    firstColor: COLORS.Solitude,
    secondColor: COLORS.WhiteSmoke,
  };

  const sizes = isMobile
    ? ["300px", "250px", "200px", "150px", "100px"]
    : ["400px", "325px", "250px", "175px", "100px"];

  // STATE CONSTANTS
  // eslint-disable-next-line
  const [gallery, setGallery] = useState<any>([]);
  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    (async () => {
      try {
        const response: any = await partsService.getEmergencyGallery();
        if (response.data) {
          setGallery(
            response.data.map((el: any) => {
              return {
                id: el.id,
                fullName: el.attributes.Title,
                occupation: el.attributes.Date,
                image: el.attributes.Image.data
                  ? `${baseUrl}${el.attributes.Image.data.attributes.url}`
                  : image,
              };
            })
          );
        }
      } catch (err) {}
    })();
  }, []);

  // EVENT HANDLERS

  return (
    <AboutContainer>
      <AboutPageHeroContainer>
        <AboutPageHeroComponent>
          <AboutPageHeroIconWrapper>
            <AboutPageHeroIcon src={heroIcon} />
          </AboutPageHeroIconWrapper>
          <AboutPageHeroTextContainer>
            <CirclesWrapper>
              <CirclesAtom
                sizes={sizes}
                colors={colors}
                containerTopPosition={isMobile ? "calc(100% - 15px)" : "15px"}
                containerLeftPosition={isMobile ? "calc(100% - 15px)" : "15px"}
              />
            </CirclesWrapper>
            <AboutPageHeroTextContainerWrapper>
              <AboutPageHeroTitleWrapper>
                <CustomText
                  text={t("view.about.section1.title")}
                  type={TextType.H1prime}
                  color={COLORS.Cinnabar}
                />
              </AboutPageHeroTitleWrapper>
              <AboutPageHeroTextWrapper tabIndex={0}>
                <CustomText
                  text={t("components.header.beprepared")}
                  type={TextType.P1bold}
                  color={COLORS.BlackRussian}
                  isAboutPage={true}
                />{" "}
                <CustomText
                  text={t("view.about.section1.text1")}
                  type={TextType.P1}
                  color={COLORS.BlackRussian}
                  isAboutPage={true}
                />
              </AboutPageHeroTextWrapper>
              <AboutPageHeroTextWrapper>
                <CustomText
                  text={t("view.about.section1.text2")}
                  type={TextType.P1}
                  color={COLORS.BlackRussian}
                />
              </AboutPageHeroTextWrapper>
            </AboutPageHeroTextContainerWrapper>
          </AboutPageHeroTextContainer>
        </AboutPageHeroComponent>
      </AboutPageHeroContainer>
      <AboutPagePreventionContainer tabIndex={0}>
        <TitleWrapper>
          <CustomText
            text={t("view.about.section2.title")}
            type={TextType.H2fix}
            color={COLORS.BlackRussian}
          />
        </TitleWrapper>
        <SubtitleWrapper></SubtitleWrapper>
        <AboutPagePreventionCardsContainer>
          <CardCarousel
            type="ABOUT"
            data={gallery.length !== 0 ? gallery : new Array(9).fill(null)}
          />
        </AboutPagePreventionCardsContainer>
      </AboutPagePreventionContainer>
      <AboutPageSupportContainer>
        <AboutPageSupportersWrapper>
          <TitleWrapper>
            <CustomText
              text={t("view.about.section3.title1")}
              type={TextType.H2fix}
              color={COLORS.BlackRussian}
            />
          </TitleWrapper>
          <AboutPageSupportersLogosWrapper>
            <SupportersLogoContainer>
              <SupportersLogo src={logoDsu} />
              <CustomText
                text={t("view.about.section3.DSU")}
                type={TextType.H4}
                color={COLORS.BlackRussian}
              />
            </SupportersLogoContainer>
          </AboutPageSupportersLogosWrapper>
          <PartnersSectionsWrapper>
            <TitleWrapper>
              <CustomText
                text={t("view.about.section3.title")}
                type={TextType.H2fix}
                color={COLORS.BlackRussian}
              />
            </TitleWrapper>
            <SubtitleWrapper>
              <CustomText
                text={t("view.about.section3.subtitle")}
                type={TextType.H4fix}
                color={COLORS.Cinnabar}
              />
            </SubtitleWrapper>
            <PartnersCardsContainerWrapper>
              <PartnersCardsContainer>
                {partnersData.map((partner) => (
                  <PartnerCard
                    key={partner.id}
                    img={partner.img}
                    text={
                      currentLanguage === "ro" ? partner.text : partner.textEn
                    }
                    title={partner.title}
                    link={partner.link}
                  />
                ))}
              </PartnersCardsContainer>
            </PartnersCardsContainerWrapper>
          </PartnersSectionsWrapper>
          <TitleWrapper>
            <CustomText
              text={t("view.about.section3.title3")}
              type={TextType.H2fix}
              color={COLORS.BlackRussian}
            />
          </TitleWrapper>
          <AboutPageSupportersLogosWrapper>
            <DeveloperLogoContainer>
              <DeveloperLogo src={LogoNovaMatter} />
            </DeveloperLogoContainer>
          </AboutPageSupportersLogosWrapper>
        </AboutPageSupportersWrapper>
      </AboutPageSupportContainer>
    </AboutContainer>
  );
};

export default About;
